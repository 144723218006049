import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { showImage } from "../../../utils/Utils";
import { Card, Carousel, Col, Nav, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Transaction from "./Transaction";
import Topup from "./Topup";
import Filter from "./Filter";

const History = () => {
  const { api } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [category, setCategory] = useState({});
  const history = useHistory();
  const refInput = useRef();
  const [local, setLocal] = useState(null);

  useEffect(() => {
    let localData = localStorage.getItem("useranarastore");
    if (localData !== null) setLocal(JSON.parse(localData));
    else history.push("/login");
  }, []);
 
 

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm cursor-pointer">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-2">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/")}
                  className="d-inline-block align-text-top "
                />
              </div>

              <div className="d-inline-block ml-2">
                <span className="ml-3">Riwayat</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="grid riwayat">
        <div className="g-col-6 content bg-light">
          <ul
            className="nav nav-pills nav-fill mb-3"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active py-3"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                Transaksi
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link py-3"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                Topup Saldo
              </button>
            </li>
          </ul>
          <div className="tab-content min-vh-100" id="pills-tabContent">
             <Transaction/>
             <Topup/>  
          </div>
        </div>
      </div>
      {/* <Filter/> */}
    </React.Fragment>
  );
};

export default History;
