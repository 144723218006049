import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import photo from "../../assets/images/insert-foto.svg";
import { useForm } from "react-hook-form";
import { showImage } from "../../utils/Utils";
import pencil from "../../assets/images/pencil-square.svg";
import { ErrorMessage } from "@hookform/error-message";
import { NotificationManager } from "react-notifications";

const UpdateSupplier = () => {
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const [id, setId] = useState("");
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [business_name, setBusiness_name] = useState("");
  const [email, setEmail] = useState("");
  const [rekening, setRekening] = useState("");
  const [image, setImage] = useState("");
  const [phone, setPhone] = useState("");
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  useEffect(() => {
    setId(history.location.state.id);
    setName(history.location.state.name);
    setBusiness_name(history.location.state.business_name);
    setEmail(history.location.state.email);
    setRekening(history.location.state.rekening);
    setPhone(history.location.state.phone);
    setAddress(history.location.state.address);
    setImage(history.location.state.image);
  }, [history]);
  console.log("id :", id);
  console.log(history);

  const { api } = useContext(AppContext);

  const onSubmit = async (params) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("id", id);
    formData.append("img", params.img[0]);
    formData.append("name", params.name);
    formData.append("business_name", params.business_name);
    formData.append("email", params.email);
    formData.append("address", params.address);
    formData.append("phone", params.phone);
    formData.append("rekening", params.rekening);

    console.log(formData);
    let UpdateSupplier = await api("post", "supplier-grosir/update", formData);

    if (UpdateSupplier.success) {
      history.push({ pathname: "/daftar-supplier" });
    } else NotificationManager.error(UpdateSupplier.message);

    setLoading(false);
  };
  const inputImage = async () => {
    setImage(true);
  };
  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/detail-supplier")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Ubah supplier</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <form onSubmit={handleSubmit(onSubmit)} className="bg-white p-3">
      <Form.Group className="font-14 mt-2">
          <Form.Label>Nama Pajak</Form.Label>
          <Form.Control
            defaultValue={name}
            className="font-14 shadow-none"
            {...register("name", {
              required: "name is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="name" />
        </div>
        <Form.Group className="font-14 mt-2">
          <Form.Label>Nama Toko</Form.Label>
          <Form.Control
            defaultValue={business_name}
            className="font-14 shadow-none"
            {...register("business_name", {
              required: "business_name is required",
            })}
          />
        </Form.Group>
        <Form.Group className="font-14 mt-2">
          <Form.Label>Email</Form.Label>
          <Form.Control
            defaultValue={email}
            className="font-14 shadow-none"
            {...register("email", {
              required: "email is required",
            })}
          />
        </Form.Group>
        
        <Form.Group className="font-14 mt-2">
          <Form.Label>No. Telepon</Form.Label>
          <Form.Control
            defaultValue={phone}
            className="font-14 shadow-none"
            {...register("phone", {
              required: "phone is required",
            })}
          />
        </Form.Group>

        <Form.Group className="font-14 mt-2">
          <Form.Label>No. Rekening</Form.Label>
          <Form.Control
            defaultValue={rekening}
            className="font-14 shadow-none"
            {...register("rekening", {
              required: "rekening is required",
            })}
          />
        </Form.Group>
        

        <Form.Group className="font-14 mt-2">
        <Form.Label>Alamat</Form.Label>
          <textarea
            defaultValue={address}
            className="font-14 shadow-none w-100 rounded"
            {...register("address", {
              required: "Address is required",
            })}
          />
        </Form.Group>
        <div className="row justify-content-center">
          <div className="App">
            <Form.Group controlId="formFile" className="font-11">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                className="font-14"
                {...register("img", {})}
              />
              <img src={showImage(image)} width="200" height="100" className="mt-4"/>
            </Form.Group>

            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="img" />
            </div>
          </div>
        </div>
        <Form.Group className="font-14 mt-2 w-100">
          <Button
            variant="primary"
            className="btn sticky-bottom btn-primary  w-100 text-white font-14"
            type="submit"
          >
           Simpan
          </Button>
        </Form.Group>
      </form>
    </React.Fragment>
  );
};

export default UpdateSupplier;