import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner, Form, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { showImage } from "../../utils/Utils";
import pencil from "../../assets/images/pencil-square.svg";
import { NotificationManager } from "react-notifications";
import trash from "../../assets/images/trash-icon.svg";

const ProductPrice = () => {
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const [id, setId] = useState([]);
  const [productPrice, setProductPrice] = useState([]);
  const [show, setShow] = useState(false);
  const { api, setSpinner } = useContext(AppContext);
  const [purchasePrice, setPurchasePrice] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm();

  useEffect(() => {
    if (!history.location.product) {
      return history.push("/daftar-produk");
    }
    let id = history.location.product;

    setId(id);
    setPurchasePrice(history.location.purchase_price);
    getProductPrice(id);
  }, [history.location.product]);

  const getProductPrice = async (id) => {
    setSpinner(true);
    let productPrice = await api("get", "products-price/get", {
      product_id: id,
    });
    if (productPrice.success) {
      setProductPrice(productPrice.data);
    }
    setSpinner(false);
  };

  const onSubmit = async (params) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("product_id", id);
    formData.append("qty", params.qty);
    formData.append("qty_max", params.qty_max);
    formData.append("price", params.price);
    formData.append("margin", params.margin);

    setSpinner(true);
    let tambahVarian = await api("post", "products-price/post", formData);
    if (tambahVarian.success) {
      setShow(false);
      getProductPrice(id);
    } else NotificationManager.error(tambahVarian.message);
    setSpinner(false);
    setLoading(false);
  };

  const deleteData = async (deleteId) => {
    setSpinner(true);
    await api("delete", "products-price/delete", {
      id: deleteId,
    });
    setSpinner(false);
    getProductPrice(id);
  };

  const handleChange = async (value) => {
    let target = parseInt(value);
    let max = watch("qty_max");
    let margin = (target / parseInt(max)).toFixed(2);

    setValue("price", parseInt(purchasePrice) + parseFloat(margin), {
      shouldValidate: false,
    });
    setValue("margin", margin, {
      shouldValidate: false,
    });
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/daftar-produk")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Varian Harga</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="bg-white p-3">
        {productPrice.map((item, key) => {
          return (
            <Row key={key}>
              <Col>
                <Form.Group className="font-14 mt-1">
                  <Form.Label>Qty</Form.Label>
                  <Form.Control
                    readOnly
                    defaultValue={item.qty}
                    className="font-14 shadow-none"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="font-14 mt-1">
                  <Form.Label>Qty Max</Form.Label>
                  <Form.Control
                    readOnly
                    defaultValue={item.qty_max}
                    className="font-14 shadow-none"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="font-14 mt-1">
                  <Form.Label>Margin</Form.Label>
                  <Form.Control
                    readOnly
                    defaultValue={item.margin}
                    className="font-14 shadow-none"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="font-14 mt-1">
                  <Form.Label>Harga</Form.Label>
                  <Form.Control
                    readOnly
                    defaultValue={item.price}
                    className="font-14 shadow-none"
                  />
                </Form.Group>
              </Col>
              <Col
                md="1"
                className="d-flex align-items-center  justify-content-center mt-4 cursor-pointer"
              >
                <img
                  src={trash}
                  width="20"
                  onClick={() => deleteData(item.id)}
                />
              </Col>
            </Row>
          );
        })}

        <Form.Group
          className="font-14 mt-2 w-100"
          data-bs-toggle="modal"
          data-bs-target="#batal"
          aria-controls="batal"
          onClick={() => setShow(true)}
        >
          <Button
            variant="primary"
            className="btn sticky-bottom btn-primary mt-3  w-100 text-white font-14"
            type="submit"
          >
            Tambah Varian
          </Button>
        </Form.Group>
      </div>

      <Modal
        className="border-0"
        show={show}
        size={"lg"}
        centered
        onHide={handleClose}
      >
        <Modal.Header className="rounded-top">
          <Modal.Title className="w-100">
            <center>
              <h6 className="mt-2">Tambah Data Varian</h6>
            </center>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row g-2  g-lg-3 me-2 mx-2">
            <form onSubmit={handleSubmit(onSubmit)} className="bg-white p-3">
              <Row>
                <Col>
                  <Form.Group className="font-14 mt-1">
                    <Form.Label>Qty</Form.Label>
                    <Form.Control
                      className="font-14 shadow-none"
                      {...register("qty", {
                        required: "Qty is required",
                      })}
                    />
                  </Form.Group>
                  <div className="my-2 text-left float-start font-12 text-danger">
                    <ErrorMessage errors={errors} name="qty" />
                  </div>
                </Col>
                <Col>
                  <Form.Group className="font-14 mt-1">
                    <Form.Label>Qty Max</Form.Label>
                    <Form.Control
                      className="font-14 shadow-none"
                      {...register("qty_max", {
                        required: "Qty Max is required",
                      })}
                    />
                  </Form.Group>
                  <div className="my-2 text-left float-start font-12 text-danger">
                    <ErrorMessage errors={errors} name="qty_max" />
                  </div>
                </Col>
                <Col>
                  <Form.Group className="font-14 mt-1">
                    <Form.Label>Target Margin</Form.Label>
                    <Form.Control
                      onInput={(e) => handleChange(e.target.value)}
                      className="font-14 shadow-none"
                      {...register("margin-target", {})}
                    />
                  </Form.Group>
                  <div className="my-2 text-left float-start font-12 text-danger">
                    <ErrorMessage errors={errors} name="margin" />
                  </div>
                </Col>
                <Col>
                  <Form.Group className="font-14 mt-1">
                    <Form.Label>Margin Satuan</Form.Label>
                    <Form.Control
                      className="font-14 shadow-none"
                      {...register("margin", {
                        required: "Margin is required",
                      })}
                    />
                  </Form.Group>
                  <div className="my-2 text-left float-start font-12 text-danger">
                    <ErrorMessage errors={errors} name="margin" />
                  </div>
                </Col>
                <Col>
                  <Form.Group className="font-14 mt-1">
                    <Form.Label>Harga</Form.Label>
                    <Form.Control
                      defaultValue={purchasePrice}
                      className="font-14 shadow-none"
                      {...register("price", {
                        required: "Price is required",
                      })}
                    />
                  </Form.Group>
                  <div className="my-2 text-left float-start font-12 text-danger">
                    <ErrorMessage errors={errors} name="price" />
                  </div>
                </Col>
              </Row>
              <Row>
                <div className="d-grid col-sm-6 col-md">
                  <button
                    type="button"
                    className="mt-2 btn-outline-secondary bg-light border rounded  d-block text-muted font-16"
                    onClick={handleClose}
                  >
                    Batal
                  </button>
                </div>
                <div className="d-grid col-sm-6 col-md">
                  <button
                    type="submit"
                    className="mt-2 btn btn-primary btn-lg d-block text-white  font-16"
                  >
                    Tambah
                  </button>
                </div>
              </Row>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ProductPrice;
