import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import maria from "../../../assets/images/maria.png" 
import Dikirim from "./Dikirim";
import Dibatalkan from "./Dibatalkan";
import Selesai from "./Selesai";
import Semua from "./Semua";

const PesananAdmin = () => {
  const { api } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [keyword, setKeyword] = useState({});
  const history = useHistory();
  const refInput = useRef();

    const getData = async () => {
        let data = await api("get", "transactions-grosir/get-for-admin");
        setData(data.data)
    }

    const changeInput = () => {
        let value = refInput.current.value;
        let keyword = {
            status: "Semua",
            name: value,
        }

        setKeyword(keyword)
    }
  return (
    <React.Fragment>
        <nav className=" bg-light navbar shadow-sm  cursor-pointer w-100">
            <div className=" p-2 ps-4 w-100 d-flex align-items-center ">
                <div className="d-inline-block">
                    <img
                        src="img/arrow-left.svg"
                        width="30"
                        height="24"
                        onClick={() => history.push("/dashboard")}
                        className="d-inline-block align-text-top "
                    />
                </div>
                <div  className="d-inline-block ms-2" style={{width: "90%"}}>
                    <form className="w-100">
                        <div className="input-group w-100">
                            <input
                                type="text"
                                className="form-control form-control-lg font-16"
                                placeholder="Cari pesanan"
                                ref={refInput}
                                aria-describedby="basic-addon2"
                                onChange={() => changeInput()}
                            />
                                <button class="input-group-text bg-white border">
                                    <i class="bi bi-search"></i>
                                </button>
                            </div>
                        </form>                        
                    </div>
                </div>
                    <div className="grid riwayat w-100">
                        <div className=" content bg-light">
                            <ul
                                className="nav nav-pills nav-fill mb-3"
                                id="pills-tab"
                                role="tablist"
                            >
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link active py-3"
                                        id="pills-semua-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-semua"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-semua"
                                        aria-selected="true"
                                    >
                                        Semua
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link py-3"
                                        id="pills-dikirim-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-dikirim"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-dikirim"
                                        aria-selected="true"
                                    >       
                                        Dikirim
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link py-3"
                                        id="pills-dibatalkan-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-dibatalkan"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-dibatalkan"
                                        aria-selected="true"
                                    >
                                        Dibatalkan
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link py-3"
                                        id="pills-selesai-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-selesai"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-selesai"
                                        aria-selected="true"
                                    >
                                        Selesai
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                
            </nav>
            <div className="tab-content min-vh-100" id="pills-tabContent">
                <Semua keyword={keyword}/>
                <Dikirim/>
                <Dibatalkan/> 
                <Selesai/>
            </div>
    </React.Fragment>
  );
};

export default PesananAdmin;