import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { NotificationManager } from "react-notifications";

const TambahRekapKas = () => {
  const { api } = useContext(AppContext);
  const [isLoading, setLoading] = useState(false);
  const [account, setAccount] = useState([]);

  const history = useHistory();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let acc = await api("get", "cash-account/get-for-kas");
    setAccount(acc.data);
  };

  const onSubmit = async (params) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("type", params.type);
    formData.append("category", params.category ? params.category : "Kas");
    formData.append("nominal", params.nominal);
    formData.append("description", params.description);

    let summaryData = await api("post", "cash-grosir/post", formData);
    if (summaryData.success) {
      history.push({ pathname: "/rekap-kas" });
    } else NotificationManager.error(summaryData.message);

    setLoading(false);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/tanggal-rekap")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Tambah Rekap Kas</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <form onSubmit={handleSubmit(onSubmit)} className="bg-white p-3">
        <Form.Group className="font-14 mt-2">
          <Form.Label>Tipe Kas</Form.Label>
          <Form.Select
            {...register("type", {})}
            className="font-14 shadow-none"
          >
            <option>Pemasukan</option>
            <option>Pengeluaran</option>
          </Form.Select>
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="type" />
        </div>
        <Form.Group className="font-14 mt-2">
          <Form.Label>Account</Form.Label>
          <Form.Select
            {...register("category", {})}
            className="font-14 shadow-none"
          >
            {account.map((item, key) => {
              return (
                <option key={key} value={item.name}>
                  {item.name}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="category" />
        </div>
        <Form.Group className="font-14 mt-2">
          <Form.Label>Nominal</Form.Label>
          <Form.Control
            type="number"
            className="font-14 shadow-none"
            {...register("nominal", {
              required: "nominal is required",
            })}
          />
        </Form.Group>

        <Form.Group className="font-14 mt-2">
          <Form.Label>Deskripsi</Form.Label>
          <textarea
            className="font-14 shadow-none w-100 rounded form-control"
            placeholder="Deskripsi Produk"
            {...register("description", {
              required: "description is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="description" />
        </div>

        <Form.Group className="font-14 mt-3 w-100">
          <Button
            variant="primary"
            className="btn sticky-bottom btn-primary  w-100 text-white font-14"
            type="submit"
          >
            Simpan
          </Button>
        </Form.Group>
      </form>
    </React.Fragment>
  );
};

export default TambahRekapKas;
