import React, {useContext, useEffect, useState} from "react";
import './Profile.css'
import Card from 'react-bootstrap/Card';
import back from "../../../assets/images/back.png"
import { useForm } from "react-hook-form";
import { AppContext } from "../../../context/AppContext";
import { NotificationManager } from "react-notifications";
import { Button, Spinner, Form } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const PengaturanKoneksi = () => {
    const history = useHistory();
    const [isLoading, setLoading] = useState(false)
    const [data, setData] = useState(false)
    const { api, setSpinner } = useContext(AppContext)
    const {
      register,
      formState: { errors },
      handleSubmit,
      setValue,
      setError,
    } = useForm({ mode: "onBlur" })
  
    useEffect(() => { 
      getData()
    }, [])
  
    const getData = async () => {
      setSpinner(true)
      let data = await api("get", "connection-ip/edit", {})
      if (data.success) {
        setData(data.data)
        
      }
      setSpinner(false)
    }
  
    const onSubmit = async (params) => {
      if (params.type === "Pilih Tipe Koneksi") {
        setError("type", {
          type: "manual",
          message: "Silahkan pilih tipe koneksi",
        })
        return
      }
      setLoading(true)
      let data = await api("put", "connection-ip/update", params)
      if (data.success) {
        NotificationManager.success(data.message)
      }
      setLoading(false)
    }
  
    return (
      <React.Fragment>
        <nav className="navbar bg-light shadow-sm cursor-pointer">
          <div className="contain p-2 px-cst1 pb-2 ps-4">
            <Row>
              <Col className="align-items-center d-flex">
                <div className="d-inline-block pe-2">
                  <img
                    src="img/arrow-left.svg"
                    alt=""
                    width="30"
                    height="24"
                    onClick={() => history.push("/akun")}
                    className="d-inline-block align-text-top "
                  />
                </div>

                <div className="d-inline-block ml-2">
                  <span className="ml-3">Koneksi</span>
                </div>
              </Col>
            </Row>
          </div>
        </nav>
        <div className="px-4 pt-2 min-vh-100">
          <div className="card my-3 cursor-pointer">
            <div className="card-body px-3 pb-0">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 ms-3">
                  <p className="text-dark mb-0">Nama Pengguna</p>
                </div>
                <div className="flex-grow-2 ms-3">
                  <div className="text-primary mb-0">
                    <span>{data.username}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="hr-line" />
            <div className="card-body px-3 pt-2 pb-0">
              <a href="/forgot" className="text-decoration-none">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 ms-3">
                    <p className="text-dark mb-0">API Key</p>
                  </div>
                  <div className="flex-grow-2 ms-3">
                    <div className="text-muted mb-0">
                      <span>{data.production_key}</span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="hr-line" />
            <div className="card-body px-3 pt-1 pb-0">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 ms-3">
                  <p className="text-dark mb-0">Whitelist IP</p>
                </div>
                <div className="flex-grow-2 ms-3">
                  <div className="text-muted mb-0">
                    <span>{data.production_ip}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="hr-line" />
            <div className="card-body px-3 pt-1 pb-0">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 ms-3">
                  <p className="text-dark mb-0">Tipe Koneksi</p>
                </div>
                <div className="flex-grow-2 ms-3">
                  <div className="text-muted mb-0">
                    <span>API</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="hr-line" />
            <div className="card-body px-3 pt-1 pb-3">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 ms-3">
                  <p className="text-dark mb-0">Callback URL</p>
                </div>
                <div className="flex-grow-2 ms-3">
                  <div className="text-muted mb-0">
                    <span>{data.callback_url}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-grid ">
            <button
              onClick={() => {
                 NotificationManager.error("Silahkan gunakan mobile aplikasi untuk atur ulang koneksi")
              }}
              type="button"
              class="mt-2 btn btn-primary btn-lg d-block text-white  font-16"
            >
              Atur Ulang
            </button>
          </div>
        </div>
      </React.Fragment>
    );
}
export default PengaturanKoneksi