import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, NavItem, Row, Spinner, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import cewek1 from "../../../assets/images/cewek1.svg";
import nama from "../../../assets/images/nama-user.svg";
import telp from "../../../assets/images/telp-user.svg";
import email from "../../../assets/images/email-user.svg";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import printIcon from "../../../assets/images/print-icon.svg";
import moment from "moment";

const DetailMember = () => {
  const history = useHistory();
  const { api, setSpinner } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [member, setMember] = useState({});
  const [username, setUsername] = useState("");
  const [showPrint, setShowPrint] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  useEffect(() => {
    if (!history.location.member) history.push("/data-member");
    else {
      setMember(history.location.member);
      setUsername(history.location.member.username);
    }
    console.log("halo");
  }, []);

  useEffect(() => {
    if (member.username) getData();
  }, [member]);

  const getData = async () => {
    setSpinner(true);
    let data = await api("post", "member/findMember", { username: username });
    setData(data.data);
    console.log(data.data);
    setSpinner(false);
  };

  const handleClickPrint = async (item) => {
    setShowPrint(true);
  };

  return (
    <React.Fragment>
      <nav className="bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center justify-content-between w-100 d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/data-member")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>

              <span className="ml-3 fw-bold fs-6">Detail Member</span>
              <div className="d-inline-block float-end pe-4">
                  <div className="align-items-end ml-auto py-2">
                    <img
                      src={printIcon}
                      width={25}
                      className="cursor-pointer  "
                      onClick={() => {
                        handleClickPrint();
                      }}
                    />
                  </div>
                </div>
            </Col>
          </Row>
        </div>
      </nav>
      <Modal
        className="border-0"
        show={showPrint}
        size="lg"
        onHide={setShowPrint}>

          <Modal.Body>
            <div id="section-to-print" className="font-14 w-100">
              <div className="text-center ">Member Nara Store</div>
              <div className="text-center border-bottom">
                <span>{moment(member.createdAt).format('DD-MM-YYYY HH:mm:ss')}</span>
              </div>
              <div className="mt-2 ">
                <React.Fragment>
                  <div className="mt-2">
                    <span>Username Member : {member.username}</span>
                  </div>
                  <div className="mt-2">
                    <span>Alamat : {member.address}</span>
                  </div>
                  <div className="mt-2">
                    <span>No. Handphone : {member.hp}</span>
                  </div>
                  <div className="mt-2">
                    <span>Umur : {member.age}</span>
                  </div>
                    <div className="mt-2 border-bottom ">
                      <span>Poin : {member.points}</span>
                  </div>
                </React.Fragment>
              </div>
            </div>
          </Modal.Body>
      </Modal>
      <div className="container px-4 mt-4">
        <div className="row">
          <div className="col-md-12">
            <img src={cewek1} alt="" className="w-100" height="170px" />
            <div className="card my-3 border-0">
              <div className="card-body my-3">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 ">
                    <img src={nama} className="mx-4 mb-3" width="25" alt="" />
                  </div>
                  <div className="flex-grow-1 ">
                    <small className="text-muted">Username Member</small>
                    <h6 className="text-black mt-1">{member.username}</h6>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-5">
                  <div className="flex-shrink-0 ">
                    <img src={nama} className="mx-4 mb-3" width="25" alt="" />
                  </div>
                  <div className="flex-grow-1 ">
                    <small className="text-muted">Alamat</small>
                    <h6 className="text-black mt-1">{member.address}</h6>
                  </div>
                </div>

                <div className="d-flex align-items-center mt-5">
                  <div className="flex-shrink-0 ">
                    <img src={telp} className="mx-4 mb-3" width="25" alt="" />
                  </div>
                  <div className="flex-grow-1 ">
                    <small className="text-muted">No. Handphone</small>
                    <h6 className="text-black mt-1">{member.hp}</h6>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-5">
                  <div className="flex-shrink-0 ">
                    <img src={nama} className="mx-4 mb-3" width="25" alt="" />
                  </div>
                  <div className="flex-grow-1 ">
                    <small className="text-muted">Umur</small>
                    <h6 className="text-black mt-1">{member.age}</h6>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-5">
                  <div className="flex-shrink-0 ">
                    <img src={nama} className="mx-4 mb-3" width="25" alt="" />
                  </div>
                  <div className="flex-grow-1 ">
                    <small className="text-muted">Poin</small>
                    <h6 className="text-black mt-1">{member.points}</h6>
                  </div>
                </div>
              </div>
            </div>
            <button
              onClick={() =>
                history.push({
                  pathname: "/data-member/" + member.id,
                  // data: data[0],
                })
              }
              type="button"
              className="mt-2 btn btn-primary sticky-bottom btn-lg d-block text-white w-100 font-16"
            >
              Ubah Data
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DetailMember;
