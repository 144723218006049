import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import { Link, useHistory } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { Col, Row } from "react-bootstrap";
import { showImage } from "../../../utils/Utils";
import trash from "../../../assets/images/trash-icon.svg";
import tambah from "../../../assets/images/tambah.svg";
import { useForm } from "react-hook-form";
import { useRef } from "react";
import { useDebounce } from "use-debounce";

export default function ListMember() {
  const { api, setSpinner } = useContext(AppContext);
  const [text, setText] = useState("");
  const [data, setData] = useState([]);
  const refInput = useRef();
  const [members, setMembers] = useState([]);
  const history = useHistory();

  useEffect(() => {
    getData();
  }, []);

  const changeInput = async () => {
    let value = refInput.current.value;
    let keyword = {
      usernameMember: value
    }
    let result = await api('get', 'member', keyword)
    setMembers(result.data)
  };

  const getData = async () => {
    setSpinner(true);
    let result = await api("get", "member");
    if (result.success) {
      setMembers(result.data);
    }
    setSpinner(false);
  };

  const deleteMember = async (memberId) => {
    setSpinner(true);
    let params = {
      id: memberId,
    };
    let result = await api("post", "member/delete", params);
    if (result.success) {
      getData();
      return;
    } else {
      alert(result.message);
    }
    setSpinner(false);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4 ms-3">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/data-master")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className=" p-2 pb-2 ps-4 w-100 d-flex align-items-center ms-4">
                <span className="ml-3 w-50 me-5">Data Member</span>
                <div className="d-inline-block ms-5" style={{ width: "90%" }}>
                  <form className="w-100 ">
                    <div className="input-group w-100 ">
                      <input
                        type="text"
                        className="form-control form-control-lg font-16"
                        placeholder="Cari Member"
                        ref={refInput}
                        aria-describedby="basic-addon2"
                        onChange={() => changeInput()}
                      />
                      <button className="input-group-text bg-white border">
                        <i className="bi bi-search"></i>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="g-col-6  px-cst1 pb-2 list-denom ">
        <Row className="mt-2">
          {members.map((member, key) => {
            return (
              <div className="col-md-12 mt-3" key={key}>
                <div
                  className="card"
                  style={{ borderRadius: "10px" }}
                  //   onClick={() => history.push("/gudang-detail")}
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <h6 className="text-dark">
                          {member.username}{" "}
                          <span className="text-primary">
                            ({member.points} Poin)
                          </span>
                        </h6>
                        <small className="text-dark">{member.hp}</small>
                      </div>
                      <div className="flex-grow-2 ms-3">
                        <div className="text-muted mb-0">
                          <img
                            className="cursor-pointer"
                            src="img/next.svg"
                            alt=""
                            onClick={() =>
                              history.push({
                                pathname: "/detail-member",
                                member: member,
                              })
                            }
                          />
                        </div>
                        {/* <i 
                            className="fa fa-trash cursor-pointer"
                            onClick={() => deleteMember(member.id)} >
                        </i>   */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Row>
      </div>
      <div
        className="float-end me-4 sticky-bottom py-3 cursor-pointer"
        onClick={() => history.push("/data-member/register")}
      >
        <img src={tambah} />
      </div>
    </React.Fragment>
  );
}
