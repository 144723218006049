import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { NotificationManager } from "react-notifications";
import { Button, Spinner } from "react-bootstrap";

const OTP = () => {
  const history = useHistory();
  const [status, setStatus] = useState("Kirim Ulang");
  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [type, setType] = useState("");

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  let [timerClock, setTimerClock] = useState(60);
  const { api } = useContext(AppContext);

  useEffect(() => {
    const timer = setTimeout(function () {
      if (timerClock === 0) return;
      setTimerClock(timerClock - 1);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [timerClock]);

  useEffect(() => {
    setEmail(history.location.state.email);
    setType(history.location.state.type);
  }, [history]);

  const onSubmit = async (params) => {
    setLoading(true);
    let paramOtp = {
      otp: params.otp1 + params.otp2 + params.otp3 + params.otp4 + params.otp5,
      email: email,
      type: type,
    };
    let otp = await api("post", "auth/cek_otp", paramOtp);

    if (otp.success) {
      let token = otp.data.token;
      localStorage.removeItem("useranarastoretoken");
      localStorage.setItem("useranarastoretoken", token);
      if (type == 1) {
        history.push({ pathname: "detailusaha", state: { email: email } });
      }
      if (type == 2) {
        history.push({ pathname: "reset-password", state: { email: email } });
      }
    } else NotificationManager.error(otp.message);

    setLoading(false);
  };

  return (
    <div className="w-100">
      <div className="float-right" style={{ height: "200px" }}>
        <img src="img/bg-top-right.svg" className="float-end"></img>
      </div>
      <div className="text-center px-5">
        <div>
          <img src="img/logo-small.svg"></img>
        </div>
        <div className="mt-3">
          <span className="text-dark font-14">Masukan Kode OTP</span>
        </div>
        <div className="mt-1">
          <span className="text-grey font-12">Silahkan cek email Anda</span>
        </div>

        <div className="mt-4 w-100">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div class="my-4 row">
              <div class="col">
                <input
                  type="text"
                  maxLength={1}
                  {...register("otp1", {
                    required: "otp is required",
                  })}
                  class="form-control form-control"
                />
              </div>
              <div class="col">
                <input
                  type="text"
                  maxLength={1}
                  {...register("otp2", {
                    required: "otp is required",
                  })}
                  class="form-control form-control"
                />
              </div>
              <div class="col">
                <input
                  type="text"
                  maxLength={1}
                  {...register("otp3", {
                    required: "otp is required",
                  })}
                  class="form-control form-control"
                />
              </div>
              <div class="col">
                <input
                  type="text"
                  maxLength={1}
                  {...register("otp4", {
                    required: "otp is required",
                  })}
                  class="form-control form-control"
                />
              </div>
              <div class="col">
                <input
                  type="text"
                  maxLength={1}
                  {...register("otp5", {
                    required: "otp is required",
                  })}
                  class="form-control form-control"
                />
              </div>
            </div>
            <div class="mb-3 cursor-pointer text-primary font-14 text-center text-dark">
              <span>
                {timerClock === 0
                  ? status
                  : timerClock.length === 1
                  ? "00:0" + timerClock
                  : "00:" + timerClock}
              </span>
            </div>

            <Button
              variant="primary"
              className="button w-100 text-white font-14"
              disabled={isLoading}
              type="submit"
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Lanjutkan"
              )}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OTP;
