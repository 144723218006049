import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { showImage } from "../../utils/Utils";
import { Card, Carousel, Col, Nav, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import filter from "../../assets/images/filter.svg";
import love from "../../assets/images/love.svg";
import shop from "../../assets/images/shop.svg";
import _ from "lodash";
import { commafy } from "../../utils/Utils";
import { NotificationManager } from "react-notifications";

const PagesProduct = () => {
  const { api } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [category, setCategory] = useState([]);
  const [gudang, setGudang] = useState([]);
  const [categoryId, setCategoryId] = useState(0);
  const history = useHistory();
  const refInput = useRef();
  const { setSpinner } = useContext(AppContext);

  useEffect(() => {
    getData();
    getGudang();
  }, [history]);

  const getData = async () => {
    setSpinner(true);
    if (history.location.category_id) {
      setCategoryId(history.location.category_id);
      let data = await api("get", "products-grosir/get", {
        category_id: history.location.category_id,
      });
      setData(data.data);
    } else {
      let data = await api("get", "products-grosir/get");
      setData(data.data);
    }
  
    let category = await api("get", "category-grosir/get", {});
    setCategory(category.data);
    setSpinner(false);
  };

  const selectSingle = (id, selected) => {
    let newData = data.map((item) => {
      if (item.category_id === id) item.selected = selected;
      console.log(item);
      return item;
    });

    setData(newData);
  };

  const getGudang = async () => {
    let gudang = await api("get", "gudang/get");
    setGudang(gudang.data);
  };

  const handleClickType = async (param) => {
    let category_id = {
      category_id: param.id,
    };
    setSpinner(true);
    let data = await api("get", "products-grosir/get", category_id);
    setData(data.data);
    setSpinner(false);
  };

  // const handleClick = async (param) => {
  //     let params = {
  //       product_id: param.id,
  //       user_id: profile.first_name,
  //     };
  //     console.log(params);

  //     let postWishlist = await api("post", "wishlist-grosir/post", params);
  // };

  const changeInput = async () => {
    let value = refInput.current.value;
    let keyword = {
      name: value,
    }; 
    let data = await api("get", "products-grosir/get", keyword);
    setData(data.data);
     
  };
  const addToWish = async (item) => {
    setSpinner(true);
    let params = {
      product_id: item.id,
    };
    let postWishlist = await api("post", "wishlist-grosir/post", params);
    if (postWishlist.success) {
      NotificationManager.success(postWishlist.message);
      getData();
    } else NotificationManager.error(postWishlist.message);
    setSpinner(false);
  };

  const addToChart = async (item) => {
    Object.assign(item, { product_id: item.id });
    setSpinner(true);
    let ongkir = await api("get", "data-ongkir/get-byname", {
      name: "Kurir Toko",
      lat: item.gudang.lat,
      lng: item.gudang.lng,
    });
    if (!ongkir.data.length) {
      NotificationManager.error(
        "Area kamu melebihi batas jangkauan pengiriman."
      );
      setSpinner(false);
      return;
    }
    item.price = parseInt(item.price);
    let cart = await api("post", "cart-grosir/post", item);
    if (cart.success) {
      NotificationManager.success(cart.message);
    } else NotificationManager.error(cart.message);
    setSpinner(false);
  };

  const semuaProduct = async () => {
    setSpinner(true);
    let semua = await api("get", "products-grosir/get");
    setData(semua.data);
    setSpinner(false);
  };
  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm cursor-pointer w-100">
        <div className="p-2 pb-2 ps-4 w-100 d-flex align-items-center">
          <div className="d-inline-block">
            <img
              src="img/arrow-left.svg"
              alt=""
              width="30"
              height="24"
              onClick={() => history.push("/grosir-home")}
              className="d-inline-block align-text-top "
            />
          </div>
          <div className="d-inline-block ms-2" style={{ width: "80%" }}>
            <form className="w-100">
              <div className="input-group w-100">
                <input
                  type="text"
                  className="form-control form-control-lg font-16"
                  placeholder="Cari pesanan"
                  ref={refInput}
                  aria-describedby="basic-addon2"
                  onChange={() => changeInput()}
                />
                <button className="input-group-text bg-white border">
                  <i className="bi bi-search"></i>
                </button>
              </div>
            </form>
          </div>
          <div
            className="col-md-1"
            data-bs-toggle="offcanvas"
            data-bs-target="#filter"
            aria-controls="filter"
          >
            <div className="d-inline-block mt-2 align-text-top">
              <img
                src={filter}
                className="float-right"
                width="70"
                height="40"
              />
            </div>
          </div>
        </div>
        <div className="riwayat w-100  overflow-scroll">
          <div className="bg-light mx-4">
            <ul
              className="nav nav-pills nav-fill mb-3"
              id="pills-tab"
              role="tablist"
            >
              <div className="d-flex">
                <li className="nav-item " role="presentation" hidden={categoryId === 0 ? false : true}>
                  <button
                    className="nav-link py-3 active no-wrap"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    role="tab"
                    onClick={() => semuaProduct()}
                  >
                    Semua
                  </button>
                </li>
                {category.map((item, key) => {
                    if(categoryId !== 0 && item.id !== categoryId) return
                  return (
                    <li className="nav-item" role="presentation">
                      <button
                        className={categoryId === item.id ? "nav-link py-3 no-wrap active" : "nav-link py-3 no-wrap"}
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        role="tab"
                        key={key}
                        md={category.column}
                        onClick={() => handleClickType(item)}
                      >
                        {item.name}
                      </button>
                    </li>
                  );
                })}
              </div>
            </ul>
          </div>
        </div>
      </nav>
      <Row className="no-gutters px-3 mt-2">
        {data.map((item, key) => {
          return (
            <div className="col-6 p-2" key={key}>
              <div className="card w-100 border-0 mb-3 shadow cursor-pointer">
                <center>
                  <div className="bg-lime rounded m-2">
                    <div className="w-100">
                      <img
                        src={
                          item.wishlist_grosir
                            ? "img/love_red.svg"
                            : "img/love_white.svg"
                        }
                        alt=""
                        className="float-end mt-2 me-2"
                        width="30"
                        height="30"
                        onClick={() => addToWish(item)}
                      />
                    </div>
                    <div
                      className="p-3"
                      onClick={() =>
                        history.push({
                          pathname: "/deskripsi-produk",
                          product: item,
                          type: 1,
                        })
                      }
                    >
                      <img
                        className="card-img-top mt-2"
                        src={showImage(item.img)}
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>
                  </div>
                </center>
                <div className="card-body ">
                  <div
                    onClick={() =>
                      history.push({
                        pathname: "/deskripsi-produk",
                        product: item,
                      })
                    }
                  >
                    <div
                      className="font-16 font-weight-bold"
                      style={{ height: "45px" }}
                    >
                      <span className="mt-1">{item.name}</span>
                    </div>
                    <span className="text-muted font-13">
                      {item.weight} {item.unit_weight}
                    </span>
                    <div className="w-100 d-flex mt-1 font-14">
                      <h6 className="font-weight-bold text-primary ">
                        Rp {commafy(item.price)}
                      </h6>
                      <small className="ms-2 text-secondary font-13">
                        <del> Rp{commafy(item.price_before)}</del>
                      </small>
                    </div>
                  </div>
                  <div
                    className="d-flex font-14 justify-content-center align-items-center"
                    style={{ height: "30px" }}
                  >
                    <img src={"img/ic_loc.svg"} className="" width="10" />
                    <span className="flex-grow-1 ms-2 justify-content-center align-items-center font-12">
                      {item.gudang.name +
                        " " +
                        item.distance.distance +
                        item.distance.unit}
                    </span>
                    <img
                      src={shop}
                      onClick={() => addToChart(item)}
                      hidden={item.stock === 0 ? true : false}
                      className="cursor-pointer"
                      width="30"
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Row>
      <div className="row justify-content-center sticky-bottom border-top filter">
        <div className="col-md-12">
          <div className="grid">
            <div
              className="offcanvas offcanvas-bottom g-col-6 rounded-top"
              tabIndex="-1"
              id="filter"
              aria-labelledby="shortLabel"
              style={{ height: "490px" }}
            >
              <div className="offcanvas-body">
                <div className="grid">
                  <div className="g-col-12 px-4 py-3 bg-white">
                    <p className="fs-6 mb- fw-bold">Filter</p>
                    <div className="container px-0 py-1">
                      {category.map((item, key) => {
                        return (
                          <div className="row gx-8">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                                onClick={(e) =>
                                  selectSingle(item.id, e.target.checked)
                                }
                              />
                              <label
                                class="form-check-label mt-1 ms-2"
                                for="flexCheckDefault"
                              >
                                {item.name}
                              </label>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <hr className="mt-4 hr-product " />
                    <p className="mt-4">Pilih Gudang</p>
                    <div className="container px-0  text-center">
                      <div className="row gx-8">
                        {gudang.map((item, key) => {
                          return (
                            <a href="#" className="col text-decoration-none">
                              <div className="d-grid">
                                <button
                                  type="button"
                                  className="text-gudang btn btn-outline-primary  btn-lg border d-block"
                                >
                                  {item.name}
                                  <br />
                                  <small className="font-10 text-gudang ">
                                    Jarak 8,3km
                                  </small>
                                </button>
                              </div>
                            </a>
                          );
                        })}
                      </div>
                    </div>

                    <div className="container px-0 py-5 text-center">
                      <div className="row gx-8">
                        <a href="#" className="col text-decoration-none">
                          <div className="d-grid">
                            <button
                              type="button"
                              className="btn btn-outline-secondary btn-lg border d-block"
                            >
                              Reset
                            </button>
                          </div>
                        </a>
                        <a href="#" className="col text-decoration-none">
                          <div className="d-grid">
                            <button
                              type="button"
                              className="btn btn-primary btn-lg d-block text-white"
                            >
                              Terapkan
                            </button>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PagesProduct;
