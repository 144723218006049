import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { NotificationManager } from "react-notifications";

const TambahBrand = () => {
  const { api, setSpinner } = useContext(AppContext);
  const [isLoading, setLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const history = useHistory();
  const [role, setRole] = useState([]);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let category = await api("get", "category-grosir/get");
    if (category.success) {
      setCategory(category.data);
      setValue("category_id", category.data.category_id, {
        shouldValidate: false,
      });
    }
  };

  const onSubmit = async (params) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("img", params.img[0]);
    formData.append("name", params.name);
    formData.append("category_id", params.category_id);
    formData.append("description", params.description);

    let tambahData = await api("post", "brands-grosir/post", params);
    if (tambahData.success) {
      history.push({ pathname: "/data-brand" });
    } else NotificationManager.error(tambahData.message);

    setLoading(false);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/data-brand")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Tambah Brand</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <form className="bg-white p-3" onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="font-14 mt-2">
          <Form.Label>Nama Brand</Form.Label>
          <Form.Control
            className="font-14 shadow-none"
            {...register("name", {
              required: "Name is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="first_name" />
        </div>

        <Form.Group className="font-14 mt-2">
          <Form.Label>Kategori</Form.Label>
          <Form.Select
            {...register("category_id", {})}
            className="font-14 shadow-none"
          >
            {category.map((item, key) => {
              return (
                <option key={key} value={item.id}>
                  {item.name}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="category_id" />
        </div>

        <div className="row justify-content-center">
          <div className="App">
            <Form.Group controlId="formFile" className="font-11 mt-2">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                className="font-14"
                {...register("img", {})}
              />
            </Form.Group>

            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="img" />
            </div>
          </div>
        </div>
        <Form.Group className="font-14">
          <Form.Label>Deskripsi</Form.Label>

          <textarea
            className="font-14 shadow-none w-100 rounded form-control"
            placeholder="Deskripsi Produk"
            {...register("description", {
              required: "description is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="description" />
        </div>

        <Form.Group className="font-14 mt-4 w-100">
          <Button
            variant="primary"
            className="btn sticky-bottom btn-primary  w-100 text-white font-14"
            type="submit"
          >
            Tambah
          </Button>
        </Form.Group>
      </form>
    </React.Fragment>
  );
};

export default TambahBrand;
