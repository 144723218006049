import React, { useContext, useState, useEffect } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { NotificationManager } from "react-notifications";

const TambahCashAccount = () => {
  const { api, setSpinner } = useContext(AppContext);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const [parentId, setParentId] = useState([]);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let parent_id = await api("get", "cash-account/get");
    if (parent_id.success) {
      setParentId(parent_id.data);
    }
  }

  const onSubmit = async (params) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("code", params.code);
    formData.append("name", params.name);
    formData.append("parent_id", params.parent_id);

    setSpinner(true);
    let tambah = await api("post", "cash-account/post", formData);

    if (tambah.success) {
      history.push({ pathname: "/cash-account" });
    } else NotificationManager.error(tambah.message);
    setSpinner(false);

    setLoading(false);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/cash-account")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Tambah Cash Account</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <form onSubmit={handleSubmit(onSubmit)} className="bg-white p-3">
        <Form.Group className="font-14 mt-2">
          <Form.Label>Code</Form.Label>
          <Form.Control
            className="font-14 shadow-none"
            {...register("code", {
              required: "Code is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="code" />
        </div>

        <Form.Group className="font-14 mt-2">
          <Form.Label>Name</Form.Label>
          <Form.Control
            className="font-14 shadow-none"
            {...register("name", {
              required: "Name is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="code" />
        </div>

        <Form.Group className="font-14 mt-1">
          <Form.Label>Parent Id</Form.Label>
            <Form.Select
              // defaultValue={product.unit_weight}
              {...register("parent_id", {
                // required: "Parent Id is required",
              })}
              className="font-14 shadow-none"
            >
              <option value={0}>Null</option>
                {parentId.map((item, key) => {
                  return (
                    <option value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>

        {/* <Form.Group className="font-14 mt-2">
          <Form.Label>Parent Id</Form.Label>
          <Form.Control
            className="font-14 shadow-none"
            {...register("parent_id", {
              required: "Parent Id is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="code" />
        </div> */}

        <Form.Group className="font-14 mt-4 w-100">
          <Button
            variant="primary"
            className="btn sticky-bottom btn-primary  w-100 text-white font-14"
            type="submit"
          >
            Tambah
          </Button>
        </Form.Group>
      </form>
    </React.Fragment>
  );
};

export default TambahCashAccount;
