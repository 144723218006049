import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { showImage } from "../../utils/Utils";
import { Row, Modal, Button, Form, Offcanvas } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import moment from "moment";
import "moment/locale/id";
moment.locale("id");

export default function RedeemHistory() {
  const { api, setSpinner } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [member, setMember] = useState([]);
  const history = useHistory();

  useEffect(() => {
    let localDataMember = JSON.parse(localStorage.getItem("member"));
    getDataMember(localDataMember.username);
    getData(localDataMember.id);
  }, []);

  const getDataMember = async (username) => {
    setSpinner(true);
    let data = await api("post", "member/findMember", { username });
    setMember(data.data);
    setSpinner(false);
  };

  const getData = async (id) => {
    // setSpinner(true);
    let data = await api("get", "point-redeem-history/history-member", {
      member_id: id,
    });
    setData(data.data);
    // setSpinner(false);
  };
  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm cursor-pointer w-100">
        <div className="p-2 pb-2 ps-4 w-100 d-flex align-items-center">
          <div className="d-inline-block">
            <img
              src="img/arrow-left.svg"
              alt=""
              width="30"
              height="24"
              onClick={() => history.push("/tukar-point")}
              className="d-inline-block align-text-top "
            />
          </div>
          <div className="d-flex align-items-center justify-content-between w-100 gap-2 ms-2">
            <form className="w-100">
              <div className="input-group w-100">
                <span>Riwayat Penukaran Produk Member : {member.username}</span>
              </div>
            </form>
          </div>
        </div>
      </nav>
      <Row className="no-gutters px-3 mt-2">
        {_.isEmpty(data) ? (
          <>
            <h2 className="text-center text-muted mt-4">
              Belum ada riwayat Penukaran Poin
            </h2>
          </>
        ) : (
          <>
            {data.map((item, key) => {
              return (
                <div className="col-6 p-2" key={key}>
                  <div className="card w-100 border-0 mb-3 shadow cursor-pointer">
                    <center>
                      <div className="bg-lime rounded m-2">
                        <div className="p-3">
                          <img
                            className="card-img-top mt-2"
                            src={showImage(
                              item.reward_point.products_grosir.img
                            )}
                            style={{ width: "100px", height: "100px" }}
                          />
                        </div>
                      </div>
                    </center>
                    <div
                      className="card-body "
                      onClick={() => {
                        if (item.status === true) {
                          history.push({
                            pathname: "/verifikasi-toko",
                            product: item.reward_point.products_grosir,
                            rewardPoint: item.reward_point,
                            member: member,
                            redeemHistory: item,
                            type: 1,
                          });
                        }
                      }}
                    >
                      <div>
                        <div
                          className="font-16 font-weight-bold"
                          style={{ height: "45px" }}
                        >
                          <span className="mt-1">
                            {item.reward_point.products_grosir.name}
                          </span>
                        </div>
                        <p className="fw-bold mb-0 font-13">
                          Point : {item.reward_point.point}
                        </p>
                        <p className="mb-0 text-muted font-13 text-primary">
                          Tgl Penukaran :{" "}
                          <span className="fw-bold">
                            {moment(item.createdAt).format("DD MMMM YYYY")}
                          </span>
                        </p>
                        <span className="text-muted font-13">
                          Tgl Expired :{" "}
                          <span className="fw-bold">
                            {moment(item.reward_point.expired_at).format(
                              "DD MMMM YYYY"
                            )}
                          </span>
                        </span>
                        <p
                          className={`fw-bold ${
                            item.status ? "text-success" : "text-danger"
                          }`}
                        >
                          Status : {item.status ? "Open" : "Close"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </Row>
    </React.Fragment>
  );
}
