import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, NavItem, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { showImage } from "../../utils/Utils";
import { AppContext } from "../../context/AppContext";
import tambah from "../../assets/images/lalala.svg";
import sampah from "../../assets/images/sampah.svg";
import { NotificationManager } from "react-notifications";
import satuanunit1 from "../../assets/images/satuanunit1.svg";
import pensil from "../../assets/images/pencil-square.svg";

const DataSatuanUnit = () => {
  const history = useHistory();
  const { api, setSpinner } = useContext(AppContext);
  const [category, setCategory] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setSpinner(true);
    let data = await api("get", "data-unit/get");
    data.data.map((item) => {
      Object.assign(item, { selected: false });
    });
    setData(data.data);
    setSpinner(false);
  };

  const selectAllData = (e) => {
    console.log(e.target.checked);
    let newData = data.map((item) => {
      item.selected = e.target.checked;
      return item;
    });
    setData(newData);
  };

  const selectSingleData = (id, selected) => {
    let newData = data.map((item) => {
      if (item.id === id) item.selected = selected;
      return item;
    });
    setData(newData);
  };

  const delData = async () => {
    let id = "",
      separator = "";
    data.map((item) => {
      if (item.selected === true) {
        id = id + separator + item.id;
        separator = ",";
      }
    });

    let param = { id_all: id };
    let del = await api("post", "data-unit/delete-all", param);

    if (del.success) {
      NotificationManager.success(del.message);
    }
    getData();
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/kelola-data")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Data Satuan Unit</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="d-flex align-item-center ms-4 mt-4">
        <input
          className="form-check-input"
          type="checkbox"
          onClick={(e) => selectAllData(e)}
          id="flexCheckDefault"
        />
        <label
          className="form-check-label flex-grow-1 ms-3"
          for="flexCheckDefault"
        >
          Pilih semua
        </label>
        <div className="flex-grow-2 mx-5">
          <div className="text-muted mb-0">
            <img src={sampah} width="25" onClick={() => delData()} />
          </div>
        </div>
      </div>

      <div className="g-col-6  px-cst1 pb-2 list-denom ">
        <Row>
          {data.map((item, key) => {
            return (
              <div className="col-md-12 mt-3" key={key}>
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onClick={(e) =>
                          selectSingleData(item.id, e.target.checked)
                        }
                        checked={item.selected}
                        id="flexCheckDefault"
                      />
                      <div className="flex-shrink-0 px-3">
                        <img src={satuanunit1} width="40" alt="" />
                      </div>
                      <div className="flex-grow-1">
                        <small className="text-muted">#KTG-{item.id}</small>
                        <h6 className="text-dark">{item.name}</h6>
                      </div>
                      <div className="flex-grow-2 ms-3 cursor-pointer">
                        <div className="text-muted mb-0">
                          <img
                            src={pensil}
                            onClick={() =>
                              history.push({
                                pathname: "/update-satuan-unit",
                                unit: item,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Row>
      </div>
      <div
        className="float-end me-4 sticky-bottom py-3 cursor-pointer"
        onClick={() => history.push("tambah-satuan-unit")}
      >
        <img src={tambah} />
      </div>
    </React.Fragment>
  );
};

export default DataSatuanUnit;
