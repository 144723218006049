import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Pesanan from "../../assets/images/pesanan.svg";

const GrosirBottom = () => {
  const history = useHistory();
  const [local, setLocal] = useState(null);

  useEffect(() => {
    let localData = localStorage.getItem("useranarastore");
    setLocal(JSON.parse(localData));
  }, []);

  return (
    <div className="sticky-bottom">
      <div className="border-top py-2 px-0 bg-white">
        <div className="row row-cols-1 row-cols-md-2 g-0 text-center navbar-bottom">
          <div
            className="col-3 cursor-pointer"
            onClick={() => history.push("/grosir-home")}
          >
            <div className="card border border-0 bg-transparent">
              <img
                src="img/beranda.svg"
                className="img-fluid mx-auto d-block"
              />
              <div className="card-footer p-0 bg-transparent border border-0">
                <small className="text-muted">Beranda</small>
              </div>
            </div>
          </div>

          <div
            className="col-3 cursor-pointer"
            onClick={() => history.push("/pesanan-saya")}
          >
            <div className="card border border-0 bg-transparent">
              <img src={Pesanan} className="img-fluid mx-auto d-block" />
              <div className="card-footer p-0 bg-transparent border border-0">
                <small className="text-muted">Pesanan</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrosirBottom;
