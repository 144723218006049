import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import pink from "../../assets/images/uang-pink.svg";
import kuning from "../../assets/images/uang-kuning.svg";
import biru from "../../assets/images/uang-biru.svg";
import iconSupplier from "../../assets/images/Supplier.svg";
import iconPelanggan from "../../assets/images/pelanggan.svg";
import { commafy } from "../../utils/Utils";
import { useForm } from "react-hook-form";
import moment from "moment";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";

const DashboardPage = () => {
  const history = useHistory();
  const { api, setSpinner } = useContext(AppContext);
  const [data, setData] = useState([]);
  const refInput = useRef();
  const refInputEnd = useRef();
  const refInputChart = useRef();
  const refInputEndChart = useRef();
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [dateChart, setDateChart] = useState(moment().add(-6, 'days').format("YYYY-MM-DD"));
  const [endDateChart, setEndDateChart] = useState(moment().format("YYYY-MM-DD"));
  const [dataChart, setDataChart] = useState({})

  useEffect(() => {
    let localData = localStorage.getItem("useranarastore");
    if (!localData) {
      return history.push("/login");
    }

    getData();
    getDataChart();
  }, [date, endDate, dateChart, endDateChart]);

  const changeInput = async () => {
    let value = refInput.current.value;
    setDate(value);
  };

  const changeInputEnd = async () => {
    let value = refInputEnd.current.value;
    setEndDate(value);
  };

  const changeInputChart = async () => {
    let value = refInputChart.current.value;
    setDateChart(value);
  };

  const changeInputEndChart = async () => {
    let value = refInputEndChart.current.value;
    setEndDateChart(value);
  };

  const getData = async () => {
    setSpinner(true);
    let param = { date: date, endDate: endDate };
    let data = await api("get", "transactions-grosir/summary", param);
    setData(data.data);
    setSpinner(false);
  };

  const getDataChart = async () => {
    setSpinner(true);
    let paramChart = { startDate: dateChart, endDate: endDateChart}
    let chartData = await api("get", "transactions-grosir/summary-chart", paramChart);
    setSpinner(false);

    let categorys = _.map(chartData.data, "date");
    let penjualans = _.map(chartData.data, "penjualan");
    let margins = _.map(chartData.data, "margin");

    const options = {
      chart: {
        type: "column",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: categorys,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: ""
        }
      },
      tooltip: {
        shared: true
      },
      plotOptions: {
        series: {
          stacking: 'normal'
        }
      },
      series: [
        {
          name: "Margin",
          data: margins,
          color: "#F0B83ABF",
        },
        {
          name: "Penjualan",
          data: penjualans,
          color: "#00C6C8BF",
        },
      ],
      credits: {
        enabled: false,
      },
    };
    setDataChart(options);
  }

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm cursor-pointer">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-2">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/dashboard")}
                  className="d-inline-block align-text-top"
                />
              </div>
              <div className="d-inline-block ml-2">
                <span className="ml-3">Dashboard</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="g-col-6 px-cst1 pb-2 list-denom mt-4">
        <div className="card px-3" style={{ borderRadius: "13px" }}>
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="w-100 d-flex mt-2 font-14">
                <h6 className="flex-grow-1 text-dark mt-2">Transaksi</h6>
                <input
                  type="date"
                  ref={refInput}
                  onChange={() => changeInput()}
                  className="d-flex px-2 rounded border flex-grow-2 me-2 cursor-pointer tanggal"
                  defaultValue={date}
                />
                <input
                  type="date"
                  ref={refInputEnd}
                  onChange={() => changeInputEnd()}
                  className="d-flex px-2 rounded border flex-grow-2 cursor-pointer tanggal"
                  defaultValue={endDate}
                />
              </div>
            </div>
            <div
              className="card mt-4 "
              style={{ backgroundColor: "#F9F9F9", borderRadius: "13px" }}
            >
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 py-2">
                    <img src={pink} width="80" alt="" />
                  </div>
                  <div className="flex-grow-1 ms-3 pt-2">
                    <h6 className="text-dark mb-0 dashboardpage">
                      Total Keuntungan
                    </h6>
                    <h5 className="mt-2 dashboardpage">
                      Rp {commafy(data.keuntungan)}
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="pb-3 list-denom mt-3">
              <Row>
                <Col>
                  <div
                    className="card"
                    style={{ backgroundColor: "#F9F9F9", borderRadius: "13px" }}
                  >
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 w-100">
                          <img src={biru} width="70" alt="" />
                          <div className="mt-3 row">
                            <div className="col-md-6">
                              <h6 className="text-dark mb-0 dashboardpage">
                                Total Penjualan
                              </h6>
                              <h5 className="mt-2 dashboardpage">
                                Rp {commafy(data.penjualan)}
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h6 className="text-dark mb-0 dashboardpage">
                                Produk Terjual
                              </h6>
                              <h5 className="mt-2 dashboardpage">
                                {data.count_product}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div
                    className="card"
                    style={{ backgroundColor: "#F9F9F9", borderRadius: "13px" }}
                  >
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 ">
                          <img src={kuning} width="70" alt="" />
                          <div className="flex-grow-1 ms-1 mt-3">
                            <h6 className="text-dark mb-0 dashboardpage">
                              Total Pembelian
                            </h6>
                            <h5 className="mt-2 dashboardpage">
                              Rp {commafy(data.pembelian)}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="pb-3 list-denom">
              <Row>
                <Col>
                  <div
                    className="card"
                    style={{ backgroundColor: "#F9F9F9", borderRadius: "13px" }}
                  >
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 ">
                          <img src={biru} width="70" alt="" />
                          <div className="flex-grow-1 ms-1 mt-3">
                            <h6 className="text-dark mb-0 dashboardpage">
                              Pembelian Belum Lunas
                            </h6>
                            <h5 className="mt-2 dashboardpage">
                              Rp {commafy(data.pembelian_bl)}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div
                    className="card"
                    style={{ backgroundColor: "#F9F9F9", borderRadius: "13px" }}
                  >
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 ">
                          <img src={kuning} width="70" alt="" />
                          <div className="flex-grow-1 ms-1 mt-3">
                            <h6 className="text-dark mb-0 dashboardpage">
                              Pembelian Lunas
                            </h6>
                            <h5 className="mt-2 dashboardpage">
                              Rp {commafy(data.pembelian_lunas)}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="pb-3 list-denom">
              <Row>
                <Col>
                  <div
                    className="card"
                    style={{ backgroundColor: "#F9F9F9", borderRadius: "13px" }}
                  >
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 ">
                          <img src={biru} width="70" alt="" />
                          <div className="flex-grow-1 ms-1 mt-3">
                            <h6 className="text-dark mb-0 dashboardpage">
                              Penjualan Belum Lunas
                            </h6>
                            <h5 className="mt-2 dashboardpage">
                              Rp {commafy(data.penjualan_bl)}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div
                    className="card"
                    style={{ backgroundColor: "#F9F9F9", borderRadius: "13px" }}
                  >
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 ">
                          <img src={kuning} width="70" alt="" />
                          <div className="flex-grow-1 ms-1 mt-3">
                            <h6 className="text-dark mb-0 dashboardpage">
                              Penjualan Lunas
                            </h6>
                            <h5 className="mt-2 dashboardpage">
                              Rp {commafy(data.penjualan_lunas)}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>

      <div className="g-col-6  px-cst1 pb-2 list-denom mt-2">
        <div className="card px-3" style={{ borderRadius: "13px" }}>
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="w-100 d-flex  mt-2 font-14">
                <h6 className="flex-grow-1 text-dark mt-2">Transaksi</h6>
                <input
                  type="date"
                  ref={refInputChart}
                  onChange={() => changeInputChart()}
                  className="d-flex px-2 rounded border flex-grow-2 me-2 cursor-pointer tanggal"
                  defaultValue={dateChart}
                />
                <input
                  type="date"
                  ref={refInputEndChart}
                  onChange={() => changeInputEndChart()}
                  className="d-flex px-2 rounded border flex-grow-2 cursor-pointer tanggal"
                  defaultValue={endDateChart}
                />
              </div>
            </div>
            <div className="mt-5">
              <HighchartsReact highcharts={Highcharts} options={dataChart} />
            </div>
          </div>
        </div>
      </div>

      <div className="g-col-6 px-cst1 pb-4 list-denom mt-4">
        <div className="card px-3" style={{ borderRadius: "13px" }}>
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="w-100 d-flex  mt-2 font-14">
                <h6 className="flex-grow-1 text-dark mt-2">Pengguna</h6>
              </div>
            </div>
            <div className="pb-3 list-denom mt-3">
              <Row>
                <Col>
                  <div
                    className="card"
                    style={{ backgroundColor: "#F9F9F9", borderRadius: "13px" }}
                  >
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 ">
                          <img src={iconPelanggan} width="70" alt="" />
                          <div className="flex-grow-1 ms-1 mt-3">
                            <h6 className="text-dark mb-0 dashboardpage">
                              Total Pelanggan
                            </h6>
                            <h5 className="mt-2 dashboardpage">{data.users}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div
                    className="card"
                    style={{ backgroundColor: "#F9F9F9", borderRadius: "13px" }}
                  >
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 ">
                          <img src={iconSupplier} width="70" alt="" />
                          <div className="flex-grow-1 ms-1 mt-3">
                            <h6 className="text-dark mb-0 dashboardpage">
                              Total Supplier
                            </h6>
                            <h5 className="mt-2 dashboardpage">
                              {data.supplier}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DashboardPage;
