import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import ImgBg from "../../assets/images/bg-top.svg";
import { useHistory } from "react-router-dom";
import { commafy } from "../../utils/Utils";

const StatusPayment = () => {
  const history = useHistory();
  const [data, setData] = useState({});


  useEffect(() => {
    if (!history.location.data) return;
    setData(history.location.data);
  }, [history.location.data]);

  return (
    <React.Fragment>
      <div className="w-100 min-vh-100 text-center">
        <div className="d-inline-block mt-5">
          <div className="align-items-center">
            <img src="img/sukses.svg"></img>
          </div>

          <div className="mt-2 font-24 text-dark">
            <span>Pembayaran Berhasil</span>
          </div>
          <div className="mt-2 font-16 text-dark">
            <span>Selamat pembayaran transaksi kamu telah berhasil</span>
          </div>
          <div className="mt-2 font-16 text-dark">
            <span>Terima kasih</span>
          </div>
          <div className="mt-2 font-14 text-dark">
            <span>ID Transaksi : {data.trxid}</span>
          </div>
          <div className="mt-2 font-14 text-dark">
            <span>Tanggal Transaksi : {data.createdAt}</span>
          </div>
          <div className="mt-5 font-14 text-dark">
            <Button
              className="primary text-white px-5 font-14 shadow-sm"
              onClick={() => history.push({pathname:"/detail-transaction", data:data})}
            >
              Rincian Transaksi
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StatusPayment;
