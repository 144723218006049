import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap"; 
import { useHistory } from "react-router-dom";
import { showImage } from "../../utils/Utils";
import { commafy } from "../../utils/Utils";
import { AppContext } from "../../context/AppContext"; 
import trash from "../../assets/images/trash-icon.svg";
import popup from "../../assets/images/popup-icon.svg";
import PopupRincian from "./PopupRincian";
import { NotificationManager } from "react-notifications";

const Keranjang = () => {
  const { api, setSpinner } = useContext(AppContext);
  const history = useHistory();
  const [data, setData] = useState([]);  
  const [total, setTotal] = useState(0);
  const [isSelectAll, setSelectAll] = useState(false);
  const isFirstRun = useRef(true);

  useEffect(() => {
    // if (isFirstRun.current) {
    //   isFirstRun.current = false;
    //   return;
    // }  
    getData();
  }, []);

  useEffect(() => { 
    let isSelectedAxis = false
    let price = 0
    data.map((item) => {
      if (item.selected === true) {
        price =
        item.qty * (parseInt(price) +
          (parseInt(item.price)));
        isSelectedAxis = true;
      }
    });
    setTotal(price)
    setSelectAll(isSelectedAxis) 
  }, [data]);

  const getData = async () => {
    setSpinner(true);
    let param = {
      source: "Nara Store"
    }
    let data = await api("get", "cart-grosir/get-by-user", param);
    if(data.success){
      let newData = data.data.map(item =>{
        item.selected = false
        return item
      })
      setData(newData);
    }
    setSpinner(false);
  };

  const incrementDecrement = async (param, isInc) => {
    let params = { qty: 0, id: 0 }; 
    let newData = data.map((item) => {
      if (item.qty === 1 && !isInc) return item;
      if (item.qty >= item.products_grosir.stock && isInc) return item;
      if (item.id === param.id) {
        item.qty = isInc ? item.qty + 1 : item.qty - 1;
        params.qty = item.qty;
        params.id = item.id;
      }
      return item;
    });
    if (params.qty === 0) return;
    setSpinner(true)
    let update = await api("post", "cart-grosir/update", params);
    if (update.success) getData();
    else NotificationManager.error(update.message)
    setSpinner(false)
  }; 

  const selectAll = (e) => {  
    let newData = data.map((item) => {
      item.selected = e.target.checked;
      return item;
    });

    setData(newData);
  };

  const selectSingle = (id, selected) => {
    let newData = data.map((item) => {
      if (item.id === id){
        item.selected = selected;
      }
      return item;
    }); 
    setData(newData);
  };

  const delData = async () => {
    let id = "",
      separator = "";
    data.map((item) => {
      if (item.selected === true) {
        id = id + separator + item.id;
        separator = ",";
      }
    });

    setSpinner(true)
    let param = { id_all: id };
    let del = await api("post", "cart-grosir/delete-all", param);
    if (del.success) getData();
  };

  const deleteData = async (id) => {
    setSpinner(true)
    let del = await api("post", "cart-grosir/delete", {id:id});
    if(del.success) getData()
  };
  
  const handleCheckout = async () =>{
    let items = []
    data.map((item) => {
      if (item.selected === true) items.push(item);
    });
    if(!items.length) {
      NotificationManager.error("Minimal satu barang harus di pilih.")
    } else {
      history.push({ pathname: "/checkout", data: items});
    }
  } 

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm cursor-pointer ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-2">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/grosir-home")}
                  className="d-inline-block align-text-top "
                />
              </div>

              <div className="d-inline-block ml-2">
                <span className="ml-3">Keranjang</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>
      <div
        className="text-center mt-5 no-riwayat"
        hidden={data.length > 0 ? true : false}
      >
        <img src="img/no-riwayat.svg" className="img-fluid" alt="" />
        <h6>Tidak Ada Data</h6>
      </div>
      <div className="container px-4 mt-4" style={{ minHeight: "600px" }}>
        {data.length ? (
          <div className="deleteform d-flex align-item-center ms-3 mt-4">
            <input
              className="form-check-input  "
              type="checkbox"
              onClick={(e) => selectAll(e)}
              value=""
              id="flexCheckDefault"
            />
            <label
              className="form-check-label flex-grow-1 ms-3"
              htmlFor="flexCheckDefault"
            >
              Pilih semua
            </label>
            <div
              className="flex-grow-2 mx-3"
              hidden={isSelectAll ? false : true}
            >
              <div className="text-muted mb-0 cursor-pointer">
                <img src={trash} width="20" onClick={() => delData()} />
              </div>
            </div>
          </div>
        ) : null}
        <br />
        <div className="row">
          {data.map((item, key) => {
            return (
              <div className="col-mb-12" key={key}>
                <div className="card mb-3 ">
                  <div className="card-body ">
                    <div className="d-flex align-items-center">
                      <div className="deleteform form-check-delete">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={(e) =>
                            selectSingle(item.id, e.target.checked)
                          }
                          value=""
                          checked={item.selected}
                          id="flexCheckDefault"
                        />
                      </div>
                      <div
                        className="d-flex flex-shrink-0 p-2 ms-3 rounded-3 bg-lime justify-content-center align-items-center"
                        style={{ minHeight: "80px" }}
                      >
                        <img
                          src={showImage(item.products_grosir.img)}
                          width="50"
                          className=""
                          style={{ minWidth: "80px" }}
                        />
                      </div>
                      <div className="flex-grow-1 ms-3 mt-3 w-100">
                        <span className="mb-1 fs-6">
                          {item.products_grosir.name}
                        </span>
                        <img
                          src={trash}
                          width="20"
                          className="float-end cursor-pointer"
                          onClick={() => deleteData(item.id)}
                        />
                        <div className="d-flex mb-2 font-12">
                          <span>Stock : {item.products_grosir.stock} </span>
                        </div>
                        <div className="d-flex mb-2">
                          <h6 className="mb-0 text-primary date-time">
                            Rp {commafy(item.price)}
                          </h6>
                          <small className="ms-2 text-secondary font-13">
                            <del>
                              {" "}
                              Rp {commafy(item.price_before)}
                            </del>
                          </small>
                        </div>
                        <div className="d-flex flex-grow-1  justify-content-center align-items-center">
                          <img src={"img/ic_loc.svg"} className="" width="10" />
                          <span className="mb-0 align-middle ms-2">
                            {item.products_grosir.gudang.name +
                              " " +
                              item.distance.distance +
                              item.distance.unit}
                          </span>
                          <div
                            className="input-group ms-auto mb-0 "
                            style={{ width: "126px" }}
                          >
                            <button
                              className="col btn border rounded-left   "
                              type="submit"
                              onClick={() => incrementDecrement(item, false)}
                            >
                              -
                            </button>
                            <small className="col btn border ">
                              {item.qty}
                            </small>
                            <button
                              className="col btn border rounded-right "
                              type="submit"
                              onClick={() => incrementDecrement(item, true)}
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <PopupRincian total={total} />

      <div className="sticky-bottom bg-white mt-5 ">
        <div className=" py-2 px-0  d-flex">
          <div className="px-4 py-2 flex-grow-1">
            <div>
              <span>Total Bayar</span>
            </div>
            <div className="mt-1 text-primary ">
              <div
                data-bs-toggle="offcanvas"
                data-bs-target="#rincian"
                aria-controls="rincian"
              >
                <span>Rp {commafy(total)}</span>
                <img
                  className="mx-3"
                  src={popup}
                  // onClick={() => totalBayar()}
                />
              </div>
            </div>
          </div>
          <div className="px-4 py-2 w-50">
            <Button
              variant="primary"
              className="button w-100 text-white font-14 btn btn-primary"
              onClick={() => handleCheckout()}
              type="submit"
            >
              Checkout
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Keranjang;
