import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import filterhitam from "../../assets/images/filterhitam.svg";
import { commafy, showImage } from "../../utils/Utils";

const DetailBiaya = () => {
  const history = useHistory();
  const { api } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [biaya, setBiaya] = useState({});
  const [id, setId] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!history.location.biaya) history.push("/data-biaya");
    else {
      setId(history.location.biaya.id);
      setBiaya(history.location.biaya);
    }
  });

  useEffect(() => {
    getData();
  }, [biaya]);

  const getData = async () => {
    let param = { id: id };
    let data_biaya = await api("get", "data-biaya/detail", param);
    setData(data_biaya.data[0]);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/data-biaya")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Detail Biaya</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="g-col-6  px-cst1 pb-2 list-denom mt-4">
        <div className="card">
          <div className="card-body">
            <div className="grid">
              <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                <div className="flex-grow-1">
                  <small className="text-secondary">Nama Biaya</small>
                </div>
              </div>
              <div className="w-100 d-flex px-3 font-14 ">
                <div className="flex-grow-1">
                  <p className="text-dark">{data.name}</p>
                </div>
              </div>
              <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                <div className="flex-grow-1">
                  <small className="text-secondary">Jenis Biaya</small>
                </div>
              </div>
              <div className="w-100 d-flex px-3 font-14 ">
                <div className="flex-grow-1">
                  <p className="text-dark">{data.type}</p>
                </div>
              </div>
              <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                <div className="flex-grow-1">
                  <small className="text-secondary">Jumlah Biaya</small>
                </div>
              </div>
              <div className="w-100 d-flex px-3 font-14 ">
                <div className="flex-grow-1">
                  <p className="text-dark">Rp {commafy(data.amount)}</p>
                </div>
              </div>
              <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                <div className="flex-grow-1">
                  <small className="text-secondary">Status Pembayaran</small>
                </div>
              </div>
              <div className="w-100 d-flex px-3 font-14 ">
                <div className="flex-grow-1">
                  <p className="text-dark">{data.status_pembayaran}</p>
                </div>
              </div>
              <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                <div className="flex-grow-1">
                  <small className="text-secondary">Tanggal</small>
                </div>
              </div>
              <div className="w-100 d-flex px-3 font-14 ">
                <div className="flex-grow-1">
                  <p className="text-dark">{data.date}</p>
                </div>
              </div>
              <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                <div className="flex-grow-1">
                  <small className="text-secondary">PIC</small>
                </div>
              </div>
              <div className="w-100 d-flex px-3 font-14 ">
                <div className="flex-grow-1">
                  <p className="text-dark">{data.pic}</p>
                </div>
              </div>
              <div className="w-100 px-3 font-14 mt-3">
                <div className="flex-grow-1">
                  <small className="text-secondary">Deskripsi</small>
                  <p>{data.description}</p>
                </div>
              </div>
              <div className="py-3 ms-3">
                <small className="text-muted">Bukti Lampiran</small>
                <div className="  align-text-top py-2">
                  <img
                    src={showImage(data.img)}
                    className="float-right"
                    width="200"
                    height="100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-grid py-4">
        <center>
          <Button
            variant="primary"
            className="mt-2 btn btn-primary sticky-bottom btn-lg d-block text-white w-75 font-16"
            disabled={isLoading}
            type="submit"
            onClick={() =>
              history.push({
                pathname: "update-biaya",
                id: id, 
                data: data,
              })
            }
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Ubah Data"
            )}
          </Button>
        </center>
      </div>
    </React.Fragment>
  );
};

export default DetailBiaya;
