import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { NotificationManager } from "react-notifications";
import { Button, Spinner } from "react-bootstrap";

const Reset = () => {
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const [email, setEmail] = useState("");
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const { api } = useContext(AppContext);

  useEffect(() => {
    setEmail(history.location.state.email);
  }, [history]);

  console.log(email);

  const onSubmit = async (params) => {
    console.log(params);
    if(params.password == params.confirm_password) {
      setLoading(true);
      let paramReset = {
        password: params.password,
        email: email,
      };
      console.log(paramReset);
  
      let reset = await api("post", "auth/reset_password", paramReset);
  
      if (reset.success) {
        history.push({ pathname: "/login" });
      } else NotificationManager.error(reset.message);
  
      setLoading(false);
    } else {
      console.log("Password dan Confirm Password tidak sama");
    }
  };

  return (
    <div className="w-100">
      <div className="float-right" style={{ height: "200px" }}>
        <img src="img/bg-top-right.svg" className="float-end"></img>
      </div>
      <div className="text-center px-5">
        <div>
          <img src="img/logo-small.svg"></img>
        </div>
        <div className="mt-3">
          <span className="text-dark font-14">Masukan password baru</span>
        </div>

        <div className="mt-4 w-100">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div class="mb-3">
              <input
                type="Password"
                placeholder="Password"
                autocomplete="off"
                class="form-control form-control-lg font-14"
                id="exampleInputText1"
                {...register("password", {
                  required: "Password is required",
                })}
              />
              <div className="my-2 text-left float-start font-12 text-danger">
                <ErrorMessage errors={errors} name="Password" />
              </div>
            </div>

            <div class="mb-3">
              <input
                type="password"
                placeholder="Password"
                autocomplete="off"
                class="form-control form-control-lg font-14"
                id="exampleInputText1"
                {...register("confirm_password", {
                  required: "Password is required",
                })}
              />
              <div className="my-2 text-left float-start font-12 text-danger">
                <ErrorMessage errors={errors} name="confirm_password" />
              </div>
            </div>
            <Button
              variant="primary"
              className="button w-100 text-white font-14"
              disabled={isLoading}
              type="submit"
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "selesai"
              )}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Reset;
