import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { NotificationManager } from "react-notifications";

const UpdateGudang = () => {
  const { api } = useContext(AppContext);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const [data, setData] = useState({});

  const [id, setId] = useState("");
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  useEffect(() => {
    setId(history.location.gudang.id);
    setData(history.location.gudang);
  }, [history]);

  const onSubmit = async (params) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("id", id);
    formData.append("name", params.name);
    formData.append("address", params.address);
    formData.append("lat", params.lat);
    formData.append("lng", params.lng);

    let UpdateGudang = await api("post", "gudang/update", formData);

    if (UpdateGudang.success) {
      history.push({ pathname: "/data-gudang" });
    } else NotificationManager.error(UpdateGudang.message);

    setLoading(false);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/data-gudang")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Update Gudang</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <form onSubmit={handleSubmit(onSubmit)} className="bg-white p-3">
        <Form.Group className="font-14 mt-2">
          <Form.Label>Gudang</Form.Label>
          <Form.Control
            defaultValue={data.name}
            className="font-14 shadow-none"
            {...register("name", {
              required: "name is required",
            })}
          />
        </Form.Group>

        <Form.Group className="font-14 mt-2">
          <Form.Label>Alamat</Form.Label>
          <Form.Control
            defaultValue={data.address}
            className="font-14 shadow-none"
            {...register("address", {
              required: "address is required",
            })}
          />
        </Form.Group>

        <Form.Group className="font-14 mt-2">
          <Form.Label>Latitude</Form.Label>
          <Form.Control
            defaultValue={data.lat}
            className="font-14 shadow-none"
            {...register("lat", {
              required: "Lat is required",
            })}
          />
        </Form.Group>

        <Form.Group className="font-14 mt-2">
          <Form.Label>Longitude</Form.Label>
          <Form.Control
            defaultValue={data.lng}
            className="font-14 shadow-none"
            {...register("lng", {
              required: "Lng is required",
            })}
          />
        </Form.Group>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <Form.Group className="font-14 mt-2 w-100">
          <Button
            variant="primary"
            className="btn sticky-bottom btn-primary  w-100 text-white font-14"
            type="submit"
          >
            Simpan
          </Button>
        </Form.Group>
      </form>
    </React.Fragment>
  );
};

export default UpdateGudang;
