import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { Redirect, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { NotificationManager } from "react-notifications";
import { Button, Spinner, Row, Col, Form } from "react-bootstrap";

const MemberRegister = () => {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  
  const {
    register,
    formState: { errors },
    handleSubmit,
    // reset
  } = useForm();

  const { api } = useContext(AppContext);

  useEffect(() => {}, []);

  const onSubmit = async (params) => {
    let noHp = params.hp
    noHp = noHp.substring(1)
    noHp = "62" + noHp
    const format = `https://api.whatsapp.com/send?phone=${noHp}&text=Selamat%20Anda%20telah%20terdaftar%20sebagai%20member%20nara%20store!%20Berikut%20data%20diri%20Anda%20%3A%0A%0AUsername%20%3A%20${params.username}%0AAlamat%20%3A%20${params.address}%0ANomor%20HP%20%3A%20${params.hp}%0AUmur%20%3A%20${params.age}%0A%0A`
    window.location.href = format
    setLoading(true);
    let register = await api("post", "member/register", params);
    if (register.success) {
      history.push("/data-member");
      NotificationManager.success(register.message);
    } else {
      NotificationManager.warning(register.message);
    }
    setLoading(false);
  };


  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="/img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/data-member")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Tambah Akun Member</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <form onSubmit={handleSubmit(onSubmit)} className="bg-white p-3">
        <Form.Group className="font-14 mt-2" style={{ zIndex: "999" }}>
          <Form.Label>Username Member</Form.Label>
          <Form.Control
            type="text"
            maxLength={8}
            className="font-14 shadow-none"
            {...register("username", {
              required: "username is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="username" />
        </div>
        <Form.Group className="font-14 mt-2">
          <Form.Label>Alamat</Form.Label>
          <Form.Control
            className="font-14 shadow-none"
            {...register("address", {
              required: "address is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="address" />
        </div>
        <Form.Group className="font-14 mt-2">
          <Form.Label>Nomor HP</Form.Label>
          <Form.Control
            type="number"
            className="font-14 shadow-none"
            {...register("hp", {
              required: "hp is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="hp" />
        </div>

        <Form.Group className="font-14 mt-2">
          <Form.Label>Umur</Form.Label>
          <Form.Control
            type="number"
            className="font-14 shadow-none"
            {...register("age", {
              required: "age is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="age" />
        </div>

        <Form.Group className="font-14 mt-4 w-100">
          <Button
            variant="primary"
            className="btn btn-primary w-100 text-white font-14"
            type="submit"
          >
            Simpan
          </Button>
        </Form.Group>
      </form>
    </React.Fragment>
  );
};

export default MemberRegister;
