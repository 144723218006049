import React, { useContext, useEffect, useRef, useState } from "react"; 
import {Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";  

const TopupSaldo = () => { 
  const history = useHistory(); 

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm cursor-pointer">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-2">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/")}
                  className="d-inline-block align-text-top "
                />
              </div>

              <div className="d-inline-block ml-2">
                <span className="ml-3">Metode Pembayaran</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="g-col-6 content bg-light min-vh-100" onClick={()=>history.push("/transfer")}>
        <div className="g-col-6 bg-light px-cst1 pb-2 list-denom pt-4">
          <div className="card mb-3 cursor-pointer">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src="img/bank.svg" width="40" alt="" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <p className="text-dark mb-0">Transfer Bank</p>
                </div>
                <div className="flex-grow-2 ms-3">
                  <div className="text-muted mb-0">
                    <img src="img/next.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TopupSaldo;
