import React, { useContext, useEffect, useState, useRef } from "react";
import { Button, Col, Row, Spinner, Form } from "react-bootstrap";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import ImgBg from "../../assets/images/bg-top.svg";
import { useHistory } from "react-router-dom";
import { showImage } from "../../utils/Utils";
import { commafy } from "../../utils/Utils";
import { AppContext } from "../../context/AppContext";
import Nama from "../../assets/images/nama-user.svg";
import toko from "../../assets/images/toko-user.svg";
import emailicon from "../../assets/images/email-user.svg";
import alamat from "../../assets/images/alamatpenerima.svg";
import telp from "../../assets/images/telp-user.svg";
import norek from "../../assets/images/rekening-user.svg";

const DetailSupplier = () => {
  const { api } = useContext(AppContext);
  const history = useHistory();
  const [supplier, setSupplier] = useState({});
  const [data, setData] = useState("");
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [business_name, setBusiness_name] = useState("");
  const [email, setEmail] = useState("");
  const [rekening, setRekening] = useState("");
  const [phone, setPhone] = useState("");
  const [image, setImage] = useState("");
  const [id, setId] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!history.location.supplier) history.push("/daftar-supplier");
    else {
      setSupplier(history.location.supplier);
      setId(history.location.supplier.id);
      setName(history.location.supplier.name);
      setBusiness_name(history.location.supplier.business_name);
      setEmail(history.location.supplier.email);
      setRekening(history.location.supplier.rekening);
      setPhone(history.location.supplier.phone);
      setAddress(history.location.supplier.address);
      setImage(history.location.supplier.image);
    }
  });

  useEffect(() => {
    getData();
  }, [supplier]);

  const getData = async () => {

    if (!id) return

    let param = { id: id };
    let data = await api("get", "supplier-grosir/get-by-id", param);
    setData(data.data);
  };

  console.log(data);
  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-2">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/daftar-supplier")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>

              <div className="d-inline-block ml-2">
                <span className="ml-3">Detail supplier</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="container px-4 mt-4">
        <div className="row">
          <div className="col-mb-12">
            <div className="card mb-3 border-0 bg-li">
              <div className="bg-lime p-2 rounded align-items-center justify-content-center d-flex">
                <img
                  src={showImage(data.img)}
                  alt=""
                  style={{ height: "100px" }}
                />
              </div>
              <div className="card-body">
                <div className="gird">
                  <div className="d-flex align-items-center">
                    <div className="d-flex flex-row flex-shrink-0">
                      <div className="ms-3">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <img
                              src={Nama}
                              className="mx-3"
                              width="20"
                              alt=""
                            />
                          </div>
                          <div className="w-100 px-3 font-14 mt-3">
                            <div className="flex-grow-1">
                              <small className="text-secondary">
                                Nama Supplier
                              </small>
                              <p>{data.name} </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex-grow-2 ms-3"></div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="d-flex flex-row flex-shrink-0">
                      <div className="ms-3">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <img
                              src={toko}
                              className="mx-3"
                              width="20"
                              alt=""
                            />
                          </div>
                          <div className="w-100 px-3 font-14 mt-3">
                            <div className="flex-grow-1">
                              <small className="text-secondary">
                                Nama Toko
                              </small>
                              <p>{data.business_name} </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex-grow-2 ms-3"></div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="d-flex flex-row flex-shrink-0">
                      <div className="ms-3">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <img
                              src={emailicon}
                              className="mx-3"
                              width="20"
                              alt=""
                            />
                          </div>
                          <div className="w-100 px-3 font-14 mt-3">
                            <div className="flex-grow-1">
                              <small className="text-secondary">Email</small>
                              <p>{data.email} </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex-grow-2 ms-3"></div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="d-flex flex-row flex-shrink-0">
                      <div className="ms-3">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <img
                              src={telp}
                              className="mx-3"
                              width="20"
                              alt=""
                            />
                          </div>
                          <div className="w-100 px-3 font-14 mt-3">
                            <div className="flex-grow-1">
                              <small className="text-secondary">
                                No. Telepon
                              </small>
                              <p>{data.phone} </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex-grow-2 ms-3"></div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="d-flex flex-row flex-shrink-0">
                      <div className="ms-3">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <img
                              src={norek}
                              className="mx-3"
                              width="20"
                              alt=""
                            />
                          </div>
                          <div className="w-100 px-3 font-14 mt-3">
                            <div className="flex-grow-1">
                              <small className="text-secondary">
                                No. Rekening
                              </small>
                              <p>{data.rekening} </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex-grow-2 ms-3"></div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="d-flex flex-row flex-shrink-0">
                      <div className="ms-3">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <img
                              src={alamat}
                              className="mx-3"
                              width="20"
                              alt=""
                            />
                          </div>
                          <div className="w-100 px-3 font-14 mt-3">
                            <div className="flex-grow-1">
                              <small className="text-secondary">Alamat</small>
                              <p>{data.address} </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex-grow-2 ms-3"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-grid py-4 sticky-bottom">
              <Button
                variant="primary"
                className="mt-2 btn btn-primary btn-lg d-block text-white w-100 font-14"
                disabled={isLoading}
                type="submit"
                onClick={() =>
                  history.push({
                    pathname: "/update-supplier",
                    state: {
                      id: id,
                      name: name,
                      business_name: business_name,
                      rekening: data.rekening,
                      email: data.email,
                      phone: data.phone,
                      address: data.address,
                      image: data.img,
                    },
                  })
                }
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Ubah Data"
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DetailSupplier;
