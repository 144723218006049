import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { showImage } from "../../utils/Utils";
import { Card, Carousel, Col, Nav, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import filterbiru from "../../assets/images/filterbiru.svg"
import love from "../../assets/images/love.svg"
import birupanah from "../../assets/images/panahbiru.svg"
import shop from "../../assets/images/shop.svg"
// import FilterProduct from "./FilterProduct";
import _ from "lodash";
import { commafy } from "../../utils/Utils";


const RingkasanPenjualan = () => {
    const { api } = useContext(AppContext);
    const [data, setData] = useState([]);
    const[type, setType] = useState([])
    const [category, setCategory] = useState([]);
    const [profile, setProfile] = useState([]);
    const history = useHistory();
    const refInput = useRef();
    const { setSpinner } = useContext(AppContext)
  
    useEffect(() => {
        getData();
    }, []);
  
    const getData = async () => {
        let data = await api("get", "products-grosir/get")
        setData(data.data)

        let category = await api("get", "category-grosir/get")
        setCategory(category.data)

        let profile = await api("get", "user/profile");
        setProfile(profile.data);
    };

    const handleClickType = async(param) => {
        let category_id = {
            category_id: param.id
        }

        let data = await api("get", "products-grosir/get", category_id)
        setData(data.data)
    }

    const handleClick = async (param) => {
        let params = {
          product_id: param.id,
          user_id: profile.first_name,
        };
        console.log(params);
    
        let postWishlist = await api("post", "wishlist-grosir/post", params);
    };
    
    const changeInput = async () => {
        let value = refInput.current.value;
        let keyword = {
            name: value,
        }

        let data = await api("get", "products-grosir/get", keyword)
        setData(data.data)
    };
  
    return (
        <React.Fragment>
             <nav className="navbar bg-light shadow-sm ">
                <div className="contain p-2 px-cst1 pb-2 ps-4">
                <Row>
                    <Col className="align-items-center d-flex">
                      <div className="d-inline-block pe-4">
                          <img
                          src="img/arrow-left.svg"
                          alt=""
                          width="30"
                          height="24"
                          onClick={() => history.push("/dashboard-pos")}
                          className="d-inline-block align-text-top cursor-pointer"
                          />
                      </div>
                      <div className="d-inline-block">
                          <span className="font-15 datapelanggan">Ringkasan Penjualan</span>
                      </div>
                      <div
                            className="col-md-1" 
                            data-bs-toggle="offcanvas"
                            data-bs-target="#laporan"
                            aria-controls="laporan"
                      >
                        <div className="  align-text-top py-2">
                          <img
                            src={filterbiru}
                            className="float-right ringkasanfilter"
                          />
                        </div>
                      </div>
                    </Col>
                </Row>
                </div>
            </nav>
           
                            
            <div className="card mx-4 my-4" >
                <div className="card-body">
                    <div className="grid">
                        <div className="d-flex align-items-center ms-4">
                            <div className="flex-grow-1">
                                <p className="mt-3 ">Jumlah Transaksi</p>
                            </div>
                            <div className="flex-grow-2 px-4">
                                <p className="mt-3">0</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center  ms-4">
                            <div className="flex-grow-1">
                                <p >Penjualan Kotor</p>
                            </div>
                            <div className="flex-grow-2 px-4">
                                <p>Rp 0</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center  ms-4">
                            <div className="flex-grow-1 text-muted">
                                <p >Diskon</p>
                            </div>
                            <div className="flex-grow-2 px-4">
                                <p>- Rp 0</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center ms-4">
                            <div className="flex-grow-1 text-muted">
                                <p >Retur</p>
                            </div>
                            <div className="flex-grow-2 px-4">
                                <p>- Rp 0</p>
                            </div>
                        </div>
                    </div>
                    <hr className="hr-dashed"/>
                    <div className="d-flex align-items-center mt-3 ms-4">
                            <div className="flex-grow-1 ">
                                <p >Penjualan Bersih</p>
                            </div>
                            <div className="flex-grow-2 px-4">
                                <p> Rp 0</p>
                            </div>
                        </div>
                </div>
            </div>
             
        </React.Fragment>
    );
};

export default RingkasanPenjualan;