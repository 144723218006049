import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import ImgBg from "../../../assets/images/bg-top.svg";
import { useHistory } from "react-router-dom";
import { commafy } from "../../../utils/Utils";

const PembayaranBerhasil = () => {
  const history = useHistory(); 
  const isFirstRun = useRef(true);
  const [data, setData] = useState({})

  useEffect(() => {
    // if (isFirstRun.current) {
    //   isFirstRun.current = false;
    //   return;
    // }

    if (!history.location.data) history.push("/grosir-home");
    else 
      setData(history.location.data); 
    
  }, [history.location.data]);
   

  return (
    <React.Fragment>
      <div className="w-100 min-vh-50 mt-5 text-center">
        <div className="d-inline-block mt-5">
          <div className="align-items-center">
            <img src="img/sukses.svg"></img>
          </div>

          <div className="mt-2 font-24 text-dark">
            <span>Pesanan Berhasil</span>
          </div>
          <div className="mt-2 font-16 text-dark">
            <span>Selamat pesanan transaksi kamu berhasil dibuat</span>
          </div>
          <div className="mt-2 font-16 text-dark">
            <span>Terima kasih</span>
          </div>
          <div className="mt-2 font-14 text-dark">
            <span>No Pesanan : {data.no}</span>
          </div>
          <div className="mt-2 font-14 text-dark">
            <span>Tanggal Pesanan : {data.createdAt}</span>
          </div>
        </div>
      </div> 
      <div className="container px-0 py-4 text-center">
          <a href="#" className="col text-decoration-none">
            <div className="d-grid">
              <button 
              type="button" 
              className="btn btn-primary font-16 d-block text-white me-4 mx-4"
              onClick={() => 
              history.push("/pesanan-saya")}
              >Rincian Pesanan</button>
            </div>
          </a>
      </div>
    </React.Fragment>
  );
};

export default PembayaranBerhasil;
