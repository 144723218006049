import React, { useContext, useEffect, useState, useRef } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import ImgBg from "../../assets/images/bg-top.svg";
import { useHistory } from "react-router-dom";
import { showImage } from "../../utils/Utils";
import { commafy } from "../../utils/Utils";
import { AppContext } from "../../context/AppContext";
import tambah from "../../assets/images/tambah.svg";

const StokRendah = () => {
  const { api, setSpinner } = useContext(AppContext);
  const history = useHistory();
  const [data, setData] = useState([]);
  const [category, setCategory] = useState([]);
  const [categoryId, setCategoryId] = useState(0);
  const [idCategory, setIdCategory] = useState(0);
  const [sort, setSort] = useState("DESC");
  const [from, setFrom] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [local, setLocal] = useState(null);
  const refInput = useRef();

  useEffect(() => {
    let localData = localStorage.getItem("useranarastore");
    if (localData !== null) setLocal(JSON.parse(localData));
    if (localData !== null) {
      setLocal(JSON.parse(localData));
    }
    setFrom(history.location.from);
    getData();
  }, []);

  const getData = async (sort) => {

    if (history.location.category_id) {
      setCategoryId(history.location.category_id);
      let data = await api("get", "products-grosir/get-stock-rendah", {
        category_id: history.location.category_id,
        admin: true,
        is_web: true,
        sort: sort,
      });
      setData(data.data);
    } else {
      let data = await api("get", "products-grosir/get-stock-rendah", {
        admin: true,
        is_web: true,
        sort: sort,
      });
      setData(data.data);
    }
    
    setSpinner(true);
    let category = await api("get", "category-grosir/get", {is_web: true});
    setCategory(category.data);
    setSpinner(false);
  };

  const semuaProduct = async () => {
    setIdCategory(0);
    setSpinner(true);
    let semua = await api("get", "products-grosir/get-stock-rendah", {
      admin: true,
      is_web: true,
    });
    setData(semua.data);
    setSpinner(false);
  };

  const handleClickType = async (param) => {
    let category_id = {
      category_id: param.id,
      admin: true,
      is_web: true,
      sort: sort,
    };
    setIdCategory(param.id);
    setSpinner(true);
    let data = await api("get", "products-grosir/get", category_id);
    setData(data.data);
    setSpinner(false);
  };
  
  

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm  ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-2">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/dashboard")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>

              <div className="d-inline-block ml-2">
                <span className="ml-3">Stok Rendah</span>
              </div>
            </Col>
          </Row>
        </div>

        <div className="riwayat w-100  overflow-scroll">
          <div className="bg-light mx-4">
            <ul
              className="nav nav-pills nav-fill mb-3"
              id="pills-tab"
              role="tablist"
            >
              <div className="d-flex">
                <li
                  className="nav-item "
                  role="presentation"
                  hidden={categoryId === 0 ? false : true}
                >
                  <button
                    className="nav-link py-3 active no-wrap"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    role="tab"
                    onClick={() => semuaProduct()}
                  >
                    Semua
                  </button>
                </li>
                {category.map((item, key) => {
                  if (categoryId !== 0 && item.id !== categoryId) return;
                  return (
                    <li className="nav-item" role="presentation" key={key}>
                      <button
                        className={
                          categoryId === item.id
                            ? "nav-link py-3 no-wrap active"
                            : "nav-link py-3 no-wrap"
                        }
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        role="tab"
                        key={key}
                        md={category.column}
                        onClick={() => handleClickType(item)}
                      >
                        {item.name}
                      </button>
                    </li>
                  );
                })}
              </div>
            </ul>
          </div>
        </div>
      </nav>

      <div className="container px-4 mt-4">
        <br />
        <div className="row">
          {data.map((item, key) => {
            return (
              <div className="col-mb-12">
                <div className="card mb-3 ">
                  <a className="card-body ">
                    <div className="d-flex align-items-center">
                      <div class="deleteform form-check-delete">
                      </div>
                      <div
                        className="flex-shrink-0 p-3 rounded-3"
                        // style={{ background: rgbs(0, 198, 200, 0.3) }}
                      >
                        <img src={showImage(item.img)}  width="80" class="" style={{ minWidth: "80px", }}/>
                      </div>
                      <div className="flex-grow-1 ms-3 mt-3 ">
                        <small className="mb-1 text-secondary font-14">
                          #PRD-001
                        </small>
                        <div className="d-flex ">
                          <h5 className="mb-0 text-primary date-time font-16">
                            {item.name}
                          </h5>
                          <div className="col ">
                          <h6 className="text-danger float-end mg-5  font-14 nilaistok">Stok {item.stock}</h6>
                          </div>
                        </div>
                        <div className="d-flex">
                          <span className="text-secondary  font-14 ">Batas Stok Rendah: {item.limit_stock}</span>
                          <div
                            className="input-group ms-auto "
                            style={{ width: "126px" }}
                          ></div>
                        </div>
                        <span className="text-secondary  font-14 ">Harga Pembelian: {commafy(item.purchase_price)}</span>
                          <div
                            className="input-group ms-auto "
                            style={{ width: "126px" }}
                          ></div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default StokRendah;
