import React from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";  
 
import Index from "../pages/app/Index";  

export const MainRoutes = () => {
  return (
    <Router>
      <Switch>
       <Route path="/">
          <Index />
        </Route> 
      </Switch>
    </Router>
  );
};
