import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { NotificationManager } from "react-notifications";
import { Button, Spinner, Col, Row, Form } from "react-bootstrap";
import AsyncSelect from "react-select/async";

const AddRewardPoints = () => {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [inputValue, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const {
    register,
    formState: { errors },
    handleSubmit,
    // reset
  } = useForm();

  const { api } = useContext(AppContext);

  // useEffect(() => {
  //   getData();
  // }, []);

  // handle input change event
  const handleInputChange = (value) => {
    setValue(value);
  };

  // handle selection
  const handleChange = (value) => {
    setSelectedValue(value);
  };

  const loadOptions = async (searchValue, callback) => {
    let result = await api("get", "products-grosir/get?admin=true");
    let getData = result.data;
    // console.log(result)
    const filteredOptions = getData.filter((option) =>
      option.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    // console.log("loadOptions", searchValue, filteredOptions);
    callback(filteredOptions);
    return getData;
  };

  const onSubmit = async (params) => {
    setLoading(true);
    if (selectedValue == null) {
      NotificationManager.warning("Product is required");
      setLoading(false);
      return;
    }
    params.product_id = selectedValue.id;
    let register = await api("post", "reward-points/store", params);
    if (register.success) {
      history.push("/reward-points");
      NotificationManager.success(register.message);
      // reset()
    } else {
      NotificationManager.warning(register.message);
    }
    setLoading(false);
  };

  // nyobain

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="/img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/reward-points")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Tambah Reward Points</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <form onSubmit={handleSubmit(onSubmit)} className="bg-white p-3">
        <Form.Group className="font-14 mt-2" style={{ zIndex: "999" }}>
          <Form.Label>Nama Product</Form.Label>
          {/* <pre>Input Value: "{inputValue}"</pre> */}
          <AsyncSelect
            cacheOptions
            defaultOptions
            value={selectedValue}
            getOptionLabel={(e) => e.name}
            getOptionValue={(e) => e.id}
            loadOptions={loadOptions}
            onInputChange={handleInputChange}
            onChange={handleChange}
          />
          {/* <pre>
            Selected Value: {JSON.stringify(selectedValue || {}, null, 2)}
          </pre> */}
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="name" />
        </div>
        <Form.Group className="font-14 mt-2">
          <Form.Label>Point</Form.Label>
          <Form.Control
            className="font-14 shadow-none"
            {...register("point", {
              required: "point is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="point" />
        </div>
        <Form.Group className="font-14 mt-2">
          <Form.Label>Expired At</Form.Label>
          <Form.Control
            type="date"
            className="font-14 shadow-none"
            {...register("expired_at", {
              required: "expired_at is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="expired_at" />
        </div>

        <Form.Group className="font-14 mt-4 w-100">
          <Button
            variant="primary"
            className="btn btn-primary w-100 text-white font-14"
            type="submit"
          >
            Simpan
          </Button>
        </Form.Group>
      </form>
    </React.Fragment>
  );
};

export default AddRewardPoints;
