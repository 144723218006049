import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { NotificationManager } from "react-notifications";
import { Button, Spinner } from "react-bootstrap";

const Forgot = () => {
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const { api } = useContext(AppContext);

  const onSubmit = async (params) => {
    setLoading(true);
    let Forgot = await api("post", "auth/forgot_password", params);

    if (Forgot.success) {
      history.push({
        pathname: "otp",
        state: { email: params.email, type: 2 },
        
      });
    } else NotificationManager.error(Forgot.message);

    setLoading(false);
  };
  return (
    <div className="w-100">
      <div className="float-right" style={{ height: "200px" }}>
        <img src="img/bg-top-right.svg" className="float-end"></img>
      </div>
      <div className="text-center px-5">
        <div>
          <img src="img/logo-small.svg"></img>
        </div>
        <div className="mt-3">
          <span className="text-dark font-14">
            Masukan email untuk reset password
          </span>
        </div>

        <div className="mt-4 w-100">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div class="mb-3">
              <div className="my-2 text-left float-start font-12 text-danger">
                <ErrorMessage errors={errors} name="em" />
              </div>
            </div>
            <div class="mb-3">
              <input
                type="email"
                placeholder="Email"
                autocomplete="off"
                class="form-control form-control-lg font-14"
                id="exampleInputText1"
                {...register("email", {
                  required: "Email is required",
                })}
              />
              <div className="my-2 text-left float-start font-12 text-danger">
                <ErrorMessage errors={errors} name="email" />
              </div>
            </div>

            <Button
              variant="primary"
              className="button w-100 text-white font-14"
              disabled={isLoading}
              type="submit"
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Lanjutkan"
              )}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Forgot;
