import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { commafy, showImage } from "../../../utils/Utils"; 
import { useHistory } from "react-router-dom";
import moment from "moment" 

const Transaction = () => {
  const { api } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(moment().format('yyyy-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('yyyy-MM-DD'));
  const [status, setStatus] = useState("");
  const [orderBy, setOrderBy] = useState("Desc");
  const history = useHistory();
  const refInput = useRef();

  useEffect(() => {
    getData()
  }, []);
 

  const getData = async (prefix) => {
    let param = {
      // start_date: moment(startDate).subtract(1,'days').format('yyyy-MM-DD'),
      start_date: startDate,
      end_date: moment(endDate).add(1,'days').format('yyyy-MM-DD'),
      order_by: orderBy,
      status: status
    };
    let data = await api("get", "transaction/history", param);  
    setData(data.data);
  };

   
  return (
    <React.Fragment>
      <div
        className="tab-pane fade show active"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
        tabIndex="0"
      >
        <div className="g-col-6 bg-light px-cst1 pb-2 list-denom min-vh-100">
          {data.map((item, key) => {
            return (
              <div
                className="card mb-3 cursor-pointer"
                onClick={() =>
                  history.push({
                    pathname: "/detail-transaction",
                    data: item,
                    from: "history",
                  })
                }
              >
                <div className="card-body" key={key}>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <img src={showImage(item.brand.img)} width="50" alt="" />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <div className="d-flex">
                        <p className="mb-0 fs-6">{item.product_name}</p>
                        <p className="ms-auto mb-0">
                          Rp {commafy(item.price_margin)}
                        </p>
                      </div>
                      <div className="d-flex mb-2">
                        <p className="mb-0 text-muted date-time">
                          <span>{item.tujuan}</span>
                        </p>
                        <h6
                          className={
                            item.status === "Gagal"
                              ? "ms-auto mb-0 text-danger"
                              : item.status === "Success"
                              ? "ms-auto mb-0 text-primary"
                              : "ms-auto mb-0 text-warning"
                          }
                        >
                          {item.status}
                        </h6>
                      </div>
                      <div className="d-flex">
                        <p className="mb-0">{item.createdAt}</p>
                        {item.status === "Gagal" ? (
                          <p className="ms-auto mb-0">
                            {commafy(item.saldo_awal) +
                              " - " +
                              0 +
                              " = " +
                              commafy(item.saldo_awal)}
                          </p>
                        ) : (
                          <p className="ms-auto mb-0">
                            {commafy(item.saldo_awal) +
                              " - " +
                              commafy(item.price_margin) +
                              " = " +
                              commafy(item.saldo_akhir)}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          <div
            className="text-center mt-5 no-riwayat"
            hidden={data.length > 0 ? true : false}
          >
            <img src="img/no-riwayat.svg" className="img-fluid" alt="" />
            <h6>Tidak Ada Riwayat</h6>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Transaction;
