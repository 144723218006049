import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { showImage } from "../../utils/Utils";

const CategoryDetail = () => {
  const history = useHistory();
  const { api, setSpinner } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [category, setCategory] = useState({});
  const [image, setImage] = useState({});
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!history.location.category) history.push("/category-data");
    else {
      setId(history.location.category.id);
      setCategory(history.location.category.name);
      setImage(history.location.category.img);
    }
  });

  useEffect(() => {
    getData();
  }, [id]);

  const getData = async () => {
    setSpinner(true);
    let param = { id: id };
    let data = await api("get", "category-grosir/detail", param);
    setData(data.data);
    console.log(data);
    setSpinner(false);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/category-data")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Detail Kategori</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>
      {data.map((item, key) => {
        return (
          <div className="g-col-6  px-cst1 pb-2 list-denom mt-4">
            <div className="card cursor-pointer">
              <div className="card-body">
                <div className="flex-grow-1">
                  <small className="text-secondary">Kode Produk</small>
                  <p>#KTG-001</p>
                </div>
                <div className="flex-grow-1">
                  <small className="text-secondary">Kode Produk</small>
                  <p>{item.name}</p>
                </div>
                <div className="flex-grow-1">
                  <small className="text-secondary">Gambar</small>
                  <p>
                    <img src={showImage(item.img)} width="200" height="100" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      <div className="d-grid mt-4">
        <center>
          <Button
            variant="primary"
            className="btn btn-primary sticky-bottom btn-lg d-block text-white w-75 font-16"
            disabled={isLoading}
            type="submit"
            onClick={() =>
              history.push({
                pathname: "/update-category",
                state: { id: id, category: category, image: image },
              })
            }
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Ubah Data"
            )}
          </Button>
        </center>
      </div>
    </React.Fragment>
  );
};

export default CategoryDetail;
