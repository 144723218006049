import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { showImage } from "../../../utils/Utils";
import { Card, Carousel, Col, Nav, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const DesProduk = (description) => {
  const { api } = useContext(AppContext);
  const [data, setData] = useState("");

  useEffect(() => {
    setData(description);
  }, [description]);

  return (
    <React.Fragment>
      <div
        className="tab-pane fade show active"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
        tabIndex="0"
      >
        <div className="g-col-6 px-cst1 pb-2 pt-2">
          {/* <p>{data.description}</p> */}
          <div dangerouslySetInnerHTML={{ __html: data.description }} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DesProduk;
