import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Row, Offcanvas } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import imgLokasi from "../../assets/images/lokasi.svg";
import dus from "../../assets/images/dus.svg";
import gudang from "../../assets/images/gudang.svg";
import rincian from "../../assets/images/rincian.svg";
import check from "../../assets/images/check.svg";
import kurirIcon from "../../assets/images/kurir.svg";
import penerima from "../../assets/images/penerima.svg";
import alamat from "../../assets/images/alamatpenerima.svg";
import narapay from "../../assets/images/narapay.svg";
import dibatalkan from "../../assets/images/dibatalkan.svg";
import alarm from "../../assets/images/icon-info.png";
import { commafy, showImage } from "../../utils/Utils";

const RincianPesanan = () => {
  const { api, setSpinner } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [id, setId] = useState(null);
  const history = useHistory();
  const [show, setShow] = useState(false);
  const isFirstRun = useRef(true);

  useEffect(() => {
    // if (isFirstRun.current) {
    //   isFirstRun.current = false;
    //   return;
    // }
    if (!history.location.pesanan) history.push("/pesanan-saya");
    else {
      setId(history.location.pesanan.id);
      getData(history.location.pesanan.id);
    }
  }, [history.location]);

  const getData = async (id) => {
    setSpinner(true);
    let param = { id: id };
    let rincian = await api("get", "transactions-grosir/detail", param);
    setData(rincian.data);
    setSpinner(false);
    console.log(data);
  };

  const handleBatal = async () => {
    setShow(false)
    setSpinner(true);
    let param = { id: id };
    let batal = await api("post", "transactions-grosir/batal", param);
    if (batal.success) {
      history.push({ pathname: "/pesanan-saya" });
    }
    setSpinner(false);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm cursor-pointer">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-2">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/pesanan-saya")}
                  className="d-inline-block align-text-top "
                />
              </div>

              <div className="d-inline-block ml-2">
                <span className="ml-3">Rincian pesanan</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div>
        {data.map((item, key) => {
          return (
            <>
              <div className="container px-4 mt-4" key={key+2}>
                {item.status === "Proses" ? (
                  <div className="card border-0 bg-primary shadow-sm rounded mt-3">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 ">
                        <img src={check} className="mx-3" width="50" alt="" />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h6 className="text-white mt-3">
                          {item.status_operations}
                        </h6>
                        <p className="text-white">{item.status_desc}</p>
                      </div>
                    </div>
                  </div>
                ) : item.status === "Selesai" ? (
                  <div className="card border-0 bg-warning shadow-sm rounded mt-3">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 ">
                        <img src={check} className="mx-3" width="50" alt="" />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h6 className="text-white mt-3">{item.status}</h6>
                        <p className="text-white">
                          Nilai pesanan kamu untuk meningkatkan kualitas,
                          pelayanan serta produk yang kami jual.
                        </p>
                      </div>
                    </div>
                  </div>
                ) : item.status === "Dibatalkan" ? (
                  <div
                    className="card border-0 shadow-sm rounded mt-3"
                    style={{ backgroundColor: "#ED1D61" }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 ">
                        <img
                          src={dibatalkan}
                          className="mx-3"
                          width="50"
                          alt=""
                        />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h6 className="text-white mt-3">
                          {item.status_operations}
                        </h6>
                        <p className="text-white">{item.status_desc}</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="card-body border rounded mt-3">
                  <br />

                  <div className="d-flex align-items-center">
                    <div className="card-body">
                      <div className="d-flex align-items-center flex-grow-1 ms-1">
                        <div className="flex-shrink-0">
                          <img src={dus} width="25" alt="" className="ms-3" />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <span className="d-inline-block ml-2 text-bold">
                            Informasi Pengiriman
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex-grow-2 ms-3"></div>
                  </div>
                  <hr className="mt-4 hr-product " />
                  <div className="d-flex align-items-center mt-3">
                    <div className="d-flex flex-row flex-shrink-0">
                      <div className="ms-3">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 mb-5">
                            <img
                              src={kurirIcon}
                              className="mx-3"
                              width="20"
                              alt=""
                            />
                          </div>
                          <div className="flex-grow-1 ms-3 mb-3">
                            <h6 className="text-black">{item.delivery_type}</h6>
                            {
                              item.delivery_type === "Diantar kurir toko" ? (
                                <p className="text-black">
                                  {item.kurir && item.kurir["first_name"]} - {item.kurir && item.kurir["phone"]}
                                </p>
                              ) : (
                                <p className="text-black">
                                  {item.gudang["address"]}
                                </p>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex-grow-2 ms-3"></div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="d-flex flex-row flex-shrink-0">
                      <div className="ms-3">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 mb-5">
                            <img
                              src={penerima}
                              className="mx-3"
                              width="20"
                              alt=""
                            />
                          </div>
                          <div className="flex-grow-1 ms-3 mb-3">
                            <h6 className="text-black">Penerima</h6>
                            <p className="text-black">
                              {item.user["first_name"]} | {item.user["phone"]}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="d-flex flex-row flex-shrink-0">
                      <div className="ms-3 ">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 mb-5">
                            <img
                              src={alamat}
                              className="mx-3"
                              width="20"
                              alt=""
                            />
                          </div>
                          <div className="flex-grow-1 ms-3 mb-3">
                            <h6 className="text-black">Alamat</h6>
                            <p className="text-black">{item.user["address"]}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container px-4 mt-3">
                <div className="card-body border rounded mt-3">
                  {item.transactions_details.map((detail, key) => {
                    return (
                      <div className="d-flex align-items-center m-3" key={key+1}>
                        <div className="d-flex flex-row flex-shrink-0">
                          <div className="flex-shrink-0 p-3 rounded bg-lime">
                            <img
                              src={showImage(
                                detail.cart_grosir.products_grosir.img
                              )}
                              width="50"
                            />
                          </div>
                          <div className="ms-3">
                            <div className="row">
                              <div className="d-flex align-items-center mt-2">
                                <span className="text-dark font-14">
                                  {detail.cart_grosir.products_grosir.name}
                                </span>
                              </div>
                            </div>
                            <span className="text-primary mb-0 font-14">
                              Rp {commafy(detail.cart_grosir.price)}
                            </span>
                            <small className="ms-2 text-secondary font-13">
                              <del>
                                Rp {commafy(detail.cart_grosir.price_before)}
                              </del>
                            </small>
                            <p className="text-dark mb-0 text-muted">
                              x{detail.cart_grosir.qty}
                            </p>
                          </div>
                        </div>
                        <div className="flex-grow-2 ms-3"></div>
                      </div>
                    );
                  })}

                  <hr className="mt-4 hr-product " />
                  <div className="grid">
                    <div className="w-100 d-flex px-3 font-14 mt-3">
                      <div className="flex-grow-1">Subtotal produk</div>
                      <div className="d-flex flex-grow-2">
                        Rp {commafy(item.price)}
                      </div>
                    </div>
                    <div className="w-100 d-flex px-3 font-14 mt-3">
                      <div className="flex-grow-1">Subtotal pengiriman</div>
                      <div className="d-flex flex-grow-2">
                        {" "}
                        {commafy(item.ongkir)}
                      </div>
                    </div>
                    <div className="w-100 d-flex px-3 font-14 mt-3">
                      <div className="flex-grow-1">Biaya penanganan</div>
                      <div className="d-flex flex-grow-2 ">
                        {" "}
                        RP {commafy(item.fee)}
                      </div>
                    </div>
                    <div className="w-100 d-flex px-3 font-14 my-3">
                      <div className="flex-grow-1">Total pembiayaan</div>
                      <div className="d-flex flex-grow-2 text-warning ">
                        Rp {commafy(item.fee + item.price)}
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-2 ms-3"></div>
                  <br />
                </div>
              </div>

              <div className="bg-primary row  mx-4 mt-3 rounded">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 ">
                    <img src={narapay} width="30" alt="" className="" />
                  </div>
                  <div className="flex-grow-1 ms-3 mt-2">
                    <div className="mt-1">
                      <h6 className="text-white">Metode pembayaran</h6>
                    </div>
                    <div className="mb-2">
                      <span className="text-white ">{item.payment_method}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container border-0 px-4 mt-3">
                <div className="card-body border rounded mt-3">
                  <br />
                  <div className="d-flex align-items-center">
                    <div className="d-flex flex-row flex-shrink-0">
                      <img src={rincian} className="ms-3" width="25" alt="" />
                      <div className="ms-3">
                        <div className="row">
                          <div className="d-flex align-items-center mt-2">
                            <span className="d-inline-block ml-2 text-bold font-16">
                              Informasi pesanan
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex-grow-2 ms-3"></div>
                  </div>
                  <hr className="mt-4 hr-product " />
                  <br />
                  <div className="grid">
                    <div className="w-100 d-flex px-3 font-14 mt-3">
                      <div className="flex-grow-1">No. pesanan</div>
                      <div className="d-flex flex-grow-2">{item.no}</div>
                    </div>
                    <div className="w-100 d-flex px-3 font-14 mt-3">
                      <div className="flex-grow-1">Waktu pemesanan</div>
                      <div className="d-flex flex-grow-2">{item.createdAt}</div>
                    </div>
                    <div className="w-100 d-flex px-3 font-14 mt-3">
                      <div className="flex-grow-1">Waktu pembayaran</div>
                      <div className="d-flex flex-grow-2 ">
                        {" "}
                        {item.createdAt}
                      </div>
                    </div>
                    <div className="w-100 d-flex px-3 font-14 my-3">
                      <div className="flex-grow-1">Waktu pengiriman</div>
                      <div className="d-flex flex-grow-2 ">{item.status === "Selesai" || item.status === "Dikirim" ? item.send_date : "-"}</div>
                    </div>
                    <div className="w-100 d-flex px-3 font-14 my-3">
                      <div className="flex-grow-1">Waktu pesanan selesai</div>
                      <div className="d-flex flex-grow-2 ">
                      {item.status === "Selesai" ? item.updatedAt : "-" }
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-2 ms-3"></div>

                  <br />
                </div>
              </div>
              <br />

              {item.status_operations === "Pesanan Diproses" ? (
                <div className="sticky-bottom bg-white mt-5 ">
                  <div className="row mb-2 g-2 g-lg-3 me-2 mx-2">
                    <div
                      className="d-grid col-sm-6 col-md"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#batalkan"
                      aria-controls="batalkan"
                    >
                      <button
                        type="button"
                        onClick={()=> setShow(true)}
                        className="mt-2 btn border-primary rounded btn-lg d-block text-primary font-16"
                      >
                        Batalkan Pesanan
                      </button>
                    </div>
                  </div>
                </div>
              ) : item.status === "Selesai" ? (
                <div className="sticky-bottom bg-white mt-5 ">
                  <div className="row mb-2 g-2 g-lg-3 me-2 mx-2">
                    <div
                      className="d-grid col-sm-6 col-md"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#beli"
                      aria-controls="beli"
                    ></div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </>
          );
        })}
      </div>

      <div className="row justify-content-center sticky-bottom border-top filter">
        <div className="col-md-12">
          <div className="grid">
            <Offcanvas
              show={show}
              placement="bottom"
              onHide={() => setShow(false)}
              className="rounded-top rincian-pesanan"
            >
              <Offcanvas.Body>
                <div className="grid ">
                  <center>
                    <img src={alarm} />
                    <h6 className="mt-2">Perhatian!</h6>
                    <small>Apakah kamu yakin ingin membatalkan pesanan?</small>
                    <div className="row g-2 mt-1 g-lg-3 me-2 mx-2">
                      <div className="d-grid col-sm-6 col-md">
                        <button
                          type="button"
                          className="mt-2  btn-outline-secondary bg-light border rounded  d-block text-muted font-16"
                          onClick={() => handleBatal()}
                        >
                          Yes
                        </button>
                      </div>
                      <div className="d-grid col-sm-6 col-md">
                        <button
                          type="button"
                          className="mt-2 btn btn-primary btn-lg d-block text-white  font-16"
                          onClick={() => setShow(false)}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </center>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RincianPesanan;
