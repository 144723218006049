import React, { useContext, useEffect, useState } from "react";
import './Profile.css'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import back from "../../../assets/images/back.png"
import go from "../../../assets/images/go.png"
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useHistory } from "react-router-dom";



const Keamanan = () => {
  const history = useHistory();
  const { api } = useContext(AppContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
    console.log(111);
  }, []);

  const getData = async () => {
    let data = await api("get", "user/profile");
    setData(data.data);
    console.log(data);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm cursor-pointer">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-2">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/akun")}
                  className="d-inline-block align-text-top "
                />
              </div>

              <div className="d-inline-block ml-2">
                <span className="ml-3">Keamanan</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>
      <div className="px-4 pt-2 min-vh-100">
        <div className="card my-3 cursor-pointer">
          <div className="card-body px-3 pt-3 pb-0">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 ms-3">
                <p className="text-dark mb-0">Nama Pengguna</p>
              </div>
              <div className="flex-grow-2 ms-3">
                <div className="text-primary mb-0">
                  <span>{data.username}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="hr-line" />
          <div className="card-body px-3 pt-2 pb-3 cursor-pointer" onClick={()=>history.push("/forgot")}>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 ms-3">
                <p className="text-dark mb-0">Ganti Password</p>
              </div>
              <div className="flex-grow-2 ms-3">
                <div className="text-muted mb-0">
                  <img src="img/next.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Keamanan