import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import { showImage } from "../../utils/Utils";
import { useHistory } from "react-router-dom";

const Dashboard = () => {
  const { api, setSpinner } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [profile, setProfile] = useState([]);
  const [menu, setMenu] = useState([])
  const [local, setLocal] = useState(null);
  const history = useHistory();
  
  
  useEffect(() => {
    let localData = localStorage.getItem("useranarastore")
    if(!localData)  return history.push("/login")
    setLocal(JSON.parse(localData))
  }, []);

  useEffect(() => {
    getMenu();
  },[])

  const getMenu = async () => {
    setSpinner(true);
    let menu = await api ("get", "menu/get")
    setMenu(menu.data)
    setSpinner(false);
  }
  
  return (
    <React.Fragment>
      <div
        className="w-100 position-relative img-bg-top"
        style={{ height: "150px" }}
      >
        <div className="contain p-2 px-cst1 pb-2" hidden={local ? false : true}>
          <div className="d-flex align-items-center pt-2">
            <div className="align-items-center md-1">
              <div className="d-inline-block pe-2 ps-3">
                <div className="ps-2 mt-5">
                  <span className="d-block ml-3  text-white font-20 font-weight-bold">
                    Good Morning {profile.first_name}!
                  </span>
                  <p className="d-block ml-3  text-white  font-weight-light">
                    Selamat Datang di Nara Stock Management
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="g-col-6  px-cst1 pb-5 list-denom mt-3">
        <Row>
          {menu.map((item,key) => {
            return (
              <Col md={6}  className="py-2" key={key}>
                <div
                  className="card cursor-pointer"
                  style={{ borderRadius: "15px" }}
                  onClick={() =>
                    history.push({
                      pathname: item.url,
                      param: "dashboard"
                    })
                  }
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 py-2 ">
                        <img
                          src={showImage(item.img)}
                          width="70"
                          alt=""
                          className="dashboardfoto"
                        />
                      </div>
                      <div className="flex-grow-1 ms-3 pt-2">
                        <h6 className="text-dark mb-0">{item.name}</h6>
                        <small className="">{item.description}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
