import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { NotificationManager } from "react-notifications";

const TambahBiaya = () => {
  const { api } = useContext(AppContext);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const [statusPembayaran, setStatusPembayaran] = useState("Lunas");
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = async (params) => {
    setLoading(true);

    if (params.status_pembayaran === "Belum Lunas") {
      if (params.type === "Gaji Karyawan") {
        const formDataGaji = new FormData();
        formDataGaji.append("type", "Pemasukan");
        formDataGaji.append("category", "Hutang Gaji");
        formDataGaji.append("nominal", params.amount);
        formDataGaji.append("description", "Hutang Gaji");

        let hutangGaji = await api("post", "cash-grosir/post", formDataGaji);
        if (hutangGaji.success) {
          NotificationManager.success(hutangGaji.message);
        } else NotificationManager.error(hutangGaji.error);
      }
      
      if (params.type === "Bangunan" || params.type === "Kendaraan" || params.type === "Perlengkapan Kantor") {
        const formDataKantor = new FormData();
        formDataKantor.append("type", "Pemasukan");
        formDataKantor.append("category", "Hutang Kantor");
        formDataKantor.append("nominal", params.amount);
        formDataKantor.append("description", "Hutang Kantor");

        let hutangKantor = await api("post", "cash-grosir/post", formDataKantor);
        if (hutangKantor.success) {
          NotificationManager.success(hutangKantor.message);
        } else NotificationManager.error(hutangKantor.error);
      } 

      if (params.type === "Biaya Kantor" || params.type === "Biaya Transport" || params.type === "Depresiasi") {
        const formDataDagang = new FormData();
        formDataDagang.append("type", "Pemasukan");
        formDataDagang.append("category", "Hutang Dagang");
        formDataDagang.append("nominal", params.amount);
        formDataDagang.append("description", "Hutang Dagang");

        let hutangDagang = await api("post", "cash-grosir/post", formDataDagang);
        if (hutangDagang.success) {
          NotificationManager.success(hutangDagang.message);
        } else NotificationManager.error(hutangDagang.error);
      }

      const formData = new FormData();
      formData.append("name", params.name);
      formData.append("type", params.type);
      formData.append("status_pembayaran", params.status_pembayaran);
      formData.append("pic", params.pic);
      formData.append("amount", params.amount);
      formData.append("description", params.description);
      formData.append("date", params.date);
      formData.append("img", params.img[0]);
  
      let TambahBiaya = await api("post", "data-biaya/post", formData);
  
      if (TambahBiaya.success) {
        history.push({ pathname: "/data-biaya" });
        NotificationManager.success(TambahBiaya.message);
      } else NotificationManager.error(TambahBiaya.error);
    } else {
      const formDataLunas = new FormData();
      formDataLunas.append("type", "Pengeluaran");
      formDataLunas.append("category", params.pay_from);
      formDataLunas.append("nominal", params.amount);
      formDataLunas.append("description", "Pengeluaran");

      let hutangLunas = await api("post", "cash-grosir/post", formDataLunas);
      if (hutangLunas.success) {
        NotificationManager.success(hutangLunas.message);
      } else NotificationManager.error(hutangLunas.error);

      const formData = new FormData();
      formData.append("name", params.name);
      formData.append("type", params.type);
      formData.append("status_pembayaran", params.status_pembayaran);
      formData.append("pic", params.pic);
      formData.append("amount", params.amount);
      formData.append("description", params.description);
      formData.append("date", params.date);
      formData.append("img", params.img[0]);
  
      let TambahBiaya = await api("post", "data-biaya/post", formData);
  
      if (TambahBiaya.success) {
        history.push({ pathname: "/data-biaya" });
        NotificationManager.success(TambahBiaya.message);
      } else NotificationManager.error(TambahBiaya.error);
    }

    setLoading(false);
  };

  const changeStatusPembayaran = async (value) => {
    setStatusPembayaran(value);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/data-biaya")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Tambah Biaya</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <form onSubmit={handleSubmit(onSubmit)} className="bg-white p-3">
        <Form.Group className="font-14 mt-2">
          <Form.Label>Nama Biaya</Form.Label>
          <Form.Control
            className="font-14 shadow-none"
            {...register("name", {
              required: "Name is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="name" />
        </div>
        <Form.Group className="font-14 mt-1">
          <Form.Label>Jenis Biaya</Form.Label>
          <Form.Select
            {...register("type", {})}
            className="font-14 shadow-none"
          >
            <option>Gaji Karyawan</option>
            <option>Biaya Kantor</option>
            <option>Biaya Transport</option>
            <option>Depresiasi</option>
            <option>Bangunan</option>
            <option>Kendaraan</option>
            <option>Perlengkapan Kantor</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="font-14 mt-1">
          <Form.Label>Status Pembayaran</Form.Label>
          <Form.Select
            {...register("status_pembayaran", {})}
            className="font-14 shadow-none"
            onChange={(e) => {
              changeStatusPembayaran(e.target.value);
            }}
          >
            <option>Lunas</option>
            <option>Belum Lunas</option>
          </Form.Select>
        </Form.Group>

        {
          statusPembayaran === "Lunas" ?
            <Form.Group className="font-14 mt-1">
              <Form.Label>Bayar Dari</Form.Label>
              <Form.Select
                {...register("pay_from", {})}
                className="font-14 shadow-none"
              >
                <option>BCA</option>
                <option>Mandiri</option>
              </Form.Select>
            </Form.Group> : null
        }

        <Form.Group className="font-14 mt-2">
          <Form.Label>PIC</Form.Label>
          <Form.Control
            className="font-14 shadow-none"
            {...register("pic", {
              required: "pic is required",
            })}
          />
        </Form.Group>
        <Form.Group className="font-14 mt-2">
          <Form.Label>Jumlah Biaya</Form.Label>
          <Form.Control
            type="number"
            className="font-14 shadow-none"
            {...register("amount", {
              required: "amount is required",
            })}
          />
        </Form.Group>

        <Form.Group className="font-14 mt-2">
          <Form.Label>Keterangan</Form.Label>

          <textarea
            className="font-14 shadow-none w-100 rounded form-control"
            placeholder="Keterangan"
            {...register("description", {
              required: "description is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="description" />
        </div>

        <Form.Group className="font-14 mt-2">
          <Form.Label>Tanggal</Form.Label>
          <Form.Control
            type="date"
            className="form-control form-control-lg font-14 shadow-none"
            placeholder="Tanggal"
            style={{ backgroundColor: "#F9F9F9" }}
            {...register("date", {
              required: "date is required",
            })}
          />
        </Form.Group>

        <div className="row justify-content-center mt-1">
          <div className="App">
            <Form.Group controlId="formFile" className="font-11">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                className="font-14"
                {...register("img", {})}
              />
            </Form.Group>

            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="img" />
            </div>
          </div>
        </div>

        <Form.Group className="font-14 mt-2 w-100">
          <Button
            variant="primary"
            className="btn sticky-bottom btn-primary  w-100 text-white font-14"
            type="submit"
          >
            Tambah
          </Button>
        </Form.Group>
      </form>
    </React.Fragment>
  );
};

export default TambahBiaya;
