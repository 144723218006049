import React, { useContext, useEffect, useRef, useState } from "react";
import {Button, Col, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";

const DetailOngkir = () => {
    const history = useHistory();
    const { api, setSpinner } = useContext(AppContext);
    const [data, setData] = useState([]);
    const [ongkir, setOngkir] = useState([]);
    const [id, setId] = useState("");
    const [isLoading, setLoading] = useState(false);


    useEffect(() => {
        if (!history.location.ongkir) history.push("/data-ongkir");
        else{
            setId(history.location.ongkir.id)
            setOngkir(history.location.ongkir)
        }
    });

    useEffect(() => {getData()}, [ongkir]);

    const getData = async () => {
        setSpinner(true);
        let param = {id : id}
        let detail_data = await api("get", "data-ongkir/detail", param);
        setData(detail_data.data);
        setSpinner(false);
      };

    return (
        <React.Fragment>
            <nav className="navbar bg-light shadow-sm ">
                <div className="contain p-2 px-cst1 pb-2 ps-4">
                    <Row>
                        <Col className="align-items-center d-flex">
                            <div className="d-inline-block pe-4">
                                <img
                                src="img/arrow-left.svg"
                                alt=""
                                width="30"
                                height="24"
                                onClick={() => history.push("/data-ongkir")}
                                className="d-inline-block align-text-top cursor-pointer"
                                />
                            </div>
                            <div className="d-inline-block ">
                                <span className="ml-3">Detail Ongkir</span>
                            </div>
                        </Col>
                    </Row>
                </div>
            </nav>
            {data.map((item, key) =>{
                return(
                  <>
             <div className="g-col-6  px-cst1 pb-2 list-denom mt-4">
                <div className="card cursor-pointer" >
                    <div className="card-body">
                        <div className="grid">
                  <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                    <div className="flex-grow-1">
                      <small className="text-secondary">Nama Ongkos Kirim</small>
                    </div>
                  </div>
                  <div className="w-100 d-flex px-3 font-14 ">
                    <div className="flex-grow-1">
                      <p className="text-dark">{item.name}</p>
                    </div>
                    
                  </div>
                  <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                    <div className="flex-grow-1">
                      <small className="text-secondary">Jarak Lokasi</small>
                    </div>
                    <div className="d-flex flex-grow-2">
                      <small className="text-secondary">Biaya Ongkos</small>
                    </div>
                  </div>
                  <div className="w-100 d-flex px-3 font-14 ">
                    <div className="flex-grow-1">
                      <p className="text-dark"> {item.distance}km</p>
                    </div>
                    <div className="d-flex flex-grow-2">
                      <p className="text-dark">Rp {item.amount}</p>
                    </div>
                  </div>
                  
                    
                  <div className="w-100 px-3 font-14 mt-3">
                    <div className="flex-grow-1">
                      <small className="text-secondary">Deskripsi</small>
                      <p >
                     {item.description}
                      </p>
                    </div>
                  </div>
                </div>
                    </div>
                </div>
            </div> 
            </>
                )
            })}

<br/> 
<br/> 
<br/> 
<br/> 
<br/> 
<br/> 
<br/> 
<br/>
<br/> 
<br/> 
<br/> 
<br/>
<br/> 
<br/> 
<br/> 
<br/> 
<br/>
<br/>
        <div className="d-grid py-4" >
              
          <center>
            <Button
              variant="primary"
              className="mt-2 btn btn-primary sticky-bottom btn-lg d-block text-white w-75 font-16"
              disabled={isLoading}
              type="submit"
              onClick={()=> history.push({
                pathname: "/update-data-ongkir",
                state: {id: id, data: ongkir}
              })} 
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Ubah Data"
              )}
            </Button>
          </center>
        </div>

        

    </React.Fragment>
    )
};

export default DetailOngkir;