import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { NotificationManager } from "react-notifications";

const TambahPegawai = () => {
  const { api, setSpinner } = useContext(AppContext);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const [role, setRole] = useState([]);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  useEffect(() => {
    getRole();
  }, []);

  const getRole = async () => {
    let role = await api("get", "roles/get");
    if (role.success) {
      setRole(role.data);
    }
  };

  const onSubmit = async (params) => {
    setLoading(true);
    let tambahData = await api("post", "user/tambah-pegawai", params);
    if (tambahData.success) {
      history.push({ pathname: "/data-pegawai" });
    } else NotificationManager.error(tambahData.message);

    setLoading(false);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/data-pegawai")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Tambah Pegawai</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <form className="bg-white p-3" onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="font-14 mt-2">
          <Form.Label>Nama Pegawai</Form.Label>
          <Form.Control
            className="font-14 shadow-none"
            {...register("first_name", {
              required: "Name is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="first_name" />
        </div>

        <Form.Group className="font-14 mt-2">
          <Form.Label>Password</Form.Label>
          <Form.Control
          type="password"
            className="font-14 shadow-none"
            {...register("password", {
              required: "Name is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="password" />
        </div>

        <Form.Group className="font-14 mt-2">
          <Form.Label>Role</Form.Label>
          <Form.Select
            className="font-14 shadow-none"
            defaultValue={role.name}
            {...register("role_id", {
              required: "Role is required",
            })}
          >
            {role.map((item, key) => {
              return (
                <option
                  key={key}
                  value={item.id}
                  selected={item.id === role.name ? true : false}
                >
                  {item.name}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>

        <Form.Group className="font-14 mt-2">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            className="font-14 shadow-none"
            {...register("email", {
              required: "Email is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="email" />
        </div>

        <Form.Group className="font-14 mt-2">
          <Form.Label>No.Handphone</Form.Label>
          <Form.Control
            type="number"
            className="font-14 shadow-none"
            {...register("phone", {
              required: "Phone is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="phone" />
        </div>
        
        <div className="d-flex mt-3">
          <Form.Check
            type="switch"
            id="custom-switch"
            label="Active"
            {...register("is_active")}
          />
          <div className="my-2 text-left float-start font-12 text-danger">
            <ErrorMessage errors={errors} name="is_active" />
          </div>

          <Form.Check
            type="switch"
            id="custom-switch"
            className="ms-3"
            label="Verified"
            {...register("is_verified")}
          />
          <div className="my-2 text-left float-start font-12 text-danger">
            <ErrorMessage errors={errors} name="is_verified" />
          </div>
        </div>

        <br />
        <br />
        <br />
        <br />
        <Form.Group className="font-14 mt-2 w-100">
          <Button
            variant="primary"
            className="btn sticky-bottom btn-primary  w-100 text-white font-14"
            type="submit"
          >
            Tambah
          </Button>
        </Form.Group>
      </form>
    </React.Fragment>
  );
};

export default TambahPegawai;
