import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import tambah from "../../assets/images/tambah.svg";
import cod from "../../assets/images/cod.svg";
import trash from "../../assets/images/trash.svg";
import target from "../../assets/images/target.png";
import { commafy, showImage } from "../../utils/Utils";
import { NotificationManager } from "react-notifications";

const TargetPenjualan = () => {
  const history = useHistory();
  const { api, setSpinner } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setSpinner(true);
    let data = await api("get", "target-grosir/get");
    setData(data.data);
    setSpinner(false);
  };

  const selectAll = (e) => {
    console.log(e.target.checked);
    let newData = data.map((item) => {
      item.selected = e.target.checked;
      return item;
    });

    setData(newData);
  };

  const selectSingle = (id, selected) => {
    let newData = data.map((item) => {
      if (item.id === id) item.selected = selected;
      return item;
    });

    setData(newData);
  };

  const delData = async (item) => {
    let param = { id: item.id };
    let del = await api("delete", "target-grosir/delete", param);

    if (del.success) {
      NotificationManager.success(del.message);
    } else NotificationManager.error(del.error)

    getData();
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/dashboard")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3"> Target Penjualan Bulanan </span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="d-flex align-item-center ms-4 mt-4">
        <div className="flex-grow-2 mx-5"></div>
      </div>
      <div className="g-col-6  px-cst1 pb-2 list-denom ">
        <Row>
          {data.map((item, key) => {
            return (
              <div className="col-md-12 mt-3" key={key}>
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <img src={target} width="50" alt="" className="mt-1" />
                      </div>

                      <div className="flex-grow-1 ms-3 mt-4">
                        <span className="text-dark font-16 mb-0">
                          {item.name}
                        </span>
                        <h6 className="">RP {commafy(item.target)}</h6>
                      </div>
                      <div
                        className="btn btn-sm btn-primary text-white float-end mt-4 me-4"
                        onClick={() =>
                          history.push({
                            pathname: "/update-target",
                            data: item,
                          })
                        }
                      >
                        Update
                      </div>
                      <div className="cursor-pointer mb-0 mt-4">
                        <img
                          src={trash}
                          width="25"
                          onClick={() => delData(item)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Row>
      </div>
      <div
        className="float-end me-4 sticky-bottom py-3 cursor-pointer"
        onClick={() => history.push("/tambah-target")}
      >
        <img src={tambah} />
      </div>
    </React.Fragment>
  );
};

export default TargetPenjualan;
