import React, { useContext, useEffect, useState } from "react"; 
import { AppContext } from "../../../context/AppContext";
import { useHistory } from "react-router-dom";
import Bottom from "../../app/Bottom";
import { showImage } from "../../../utils/Utils";

const Akun = () => {
  const { api } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [profile, setProfile] = useState([]);
  const history = useHistory();

  useEffect(() => {
    let localData = localStorage.getItem("useranarastore");
    if (localData !== null) { 
      getData();
    } else history.push("/login");
   
  }, []);

  const getData = async () => {
    let param = {
      id: "i8l7DzgKEatN3e7SNCI38mA67ey0o5SiFgMKEJl7Va5pnD0BKW",
      view: "category",
    };
    let data = await api("get", "produk/public", param);
    setData(data.data);

    let profile = await api("get", "user/profile");
    setProfile(profile.data);
  };

  return (
    <React.Fragment>
      <nav
        className="navbar bg-light shadow-sm cursor-pointer img-bg-top"
        style={{ height: "120px" }}
      >
        <div className="contain p-2 px-cst1 pb-2">
          <div className="d-flex align-items-center">
            <div className="align-items-center md-1">
              <div className="d-inline-block pe-2 ps-3">
                <img
                  src={showImage(profile.img)}
                  style={{ borderRadius: "50%", height: "50px" }}
                  width="50"
                />
              </div>
            </div>
            <div>
              <div className="ps-2">
                <span className="d-block ml-3 text-white font-14">
                  {profile.first_name}
                </span>
              </div>
              <div className="ps-2">
                <span className="d-block ml-3 text-white font-12">
                  {profile.phone}
                </span>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div className="px-4 pt-4 min-vh-100">
        <div>
          <span className="text-grey-sm">Pengtauran Aplikasi</span>
        </div>
        <div className="card my-3 cursor-pointer">
          <div
            className="card-body px-3 pt-2 pb-0 mt-1"
            onClick={() => history.push("/pengaturan-profile")}
          >
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <img src="img/setting.svg" width="35" alt="" />
              </div>
              <div className="flex-grow-1 ms-3">
                <p className="text-dark mb-0">Pengaturan Profile</p>
              </div>
              <div className="flex-grow-2 ms-3">
                <div className="text-muted mb-0">
                  <img src="img/next.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="hr-line" />
          <div
            className="card-body px-3 pt-1 pb-2 cursor-pointer"
            onClick={() => history.push("/keamanan")}
          >
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <img src="img/keamanan.svg" width="35" alt="" />
              </div>
              <div className="flex-grow-1 ms-3">
                <p className="text-dark mb-0">Keamanan</p>
              </div>
              <div className="flex-grow-2 ms-3">
                <div className="text-muted mb-0">
                  <img src="img/next.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <span className="text-grey-sm">Pengtauran Host To Host</span>
        </div>
        <div className="card my-3 cursor-pointer">
          <div
            className="card-body px-3 pt-2 pb-2 cursor-pointer"
            onClick={() => history.push("/pengaturan-koneksi")}
          >
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <img src="img/koneksi.svg" width="35" alt="" />
              </div>
              <div className="flex-grow-1 ms-3">
                <p className="text-dark mb-0">Pengaturan Koneksi</p>
              </div>
              <div className="flex-grow-2 ms-3">
                <div className="text-muted mb-0">
                  <img src="img/next.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <span className="text-grey-sm">Informasi Umum</span>
          <div className="card my-3 cursor-pointer">
            <div
              className="card-body px-3 pt-2 pb-0 cursor-pointer"
              onClick={() => history.push("/bantuan")}
            >
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src="img/pusat.svg" width="35" alt="" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <p className="text-dark mb-0">Pusat Bantuan</p>
                </div>
                <div className="flex-grow-2 ms-3">
                  <div className="text-muted mb-0">
                    <img src="img/next.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="hr-line" />
            <div
              className="card-body px-3 pt-1 pb-0 cursor-pointer"
              onClick={() => history.push("/syarat-ketentuan")}
            >
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src="img/syarat.svg" width="35" alt="" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <p className="text-dark mb-0">Syarat & Ketentuan</p>
                </div>
                <div className="flex-grow-2 ms-3">
                  <div className="text-muted mb-0">
                    <img src="img/next.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="hr-line" />
            <div
              className="card-body px-3 pt-1 pb-3 cursor-pointer"
              onClick={() => history.push("/kebijakan-privasi")}
            >
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src="img/privasi.svg" width="35" alt="" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <p className="text-dark mb-0">Kebijakan Privasi</p>
                </div>
                <div className="flex-grow-2 ms-3">
                  <div className="text-muted mb-0">
                    <img src="img/next.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-grid ">
          <button
            onClick={() => {
              history.push("/login");
              localStorage.clear("useranarastore");
              localStorage.clear("useranarastoretoken");
            }}
            type="button"
            class="mt-2 btn btn-primary btn-lg d-block text-white  font-16"
          >
            Keluar
          </button>
        </div>
        <div class="mt-3 w-100 text-center">
          <img src="img/logo-small.svg" alt="" width={80} />
        </div>
        <div class="mt-2 w-100 text-center text-grey font-12">
          <span>Versi 1.0</span>
        </div>
      </div>
      <Bottom />
    </React.Fragment>
  );
};

export default Akun;
