
import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { commafy, showImage } from "../../../utils/Utils";
import {Col, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Button from "react-bootstrap/esm/Button"; 
import _ from "lodash";
import NotificationManager from "react-notifications/lib/NotificationManager";

const Transfer = () => {
  const { api } = useContext(AppContext);
  const [data, setData] = useState([]);
  const history = useHistory();
  const refInput = useRef();
  const [isLoading, setLoading] = useState(false);
  const [selectedNominal, setSelectedNominal] = useState("");
  const [selectedBank, setSelectedBank] = useState("");
  const [nominal, setNominal] = useState([
    { nominal: 50000, selected: false },
    { nominal: 100000, selected: false },
    { nominal: 150000, selected: false },
    { nominal: 200000, selected: false },
    { nominal: 250000, selected: false },
    { nominal: 500000, selected: false },
  ]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let data = await api("get", "saldo-history/get-bank");
    setData(data.data); 
  };

  const handleClickNominal = (param) => {
    let data = _.map(nominal, (item) => {
      return item.nominal === param.nominal
        ? { ...param, selected: true }
        : { ...item, selected: false };
    });
    setSelectedNominal(param.nominal)
    setNominal(data); 
    refInput.current.value = param.nominal
  };

  const handleClickBank = (param) => {
    let dataTmp = _.map(data, (item) => {
      return item.bank === param.bank
        ? { ...param, selected: true }
        : { ...item, selected: false };
    }); 
    setSelectedBank(param.bank)
    setData(dataTmp); 
  };

  const handleClick = async ()=>{
     if(parseInt(refInput.current.value) < 50000){
      NotificationManager.error("Minimal Topup 50.000")
      return
     }

    if(selectedNominal === "" && refInput.current.value === ""){
      NotificationManager.error("Silahkan pilih nominal")
      return
    }

    if(selectedBank === ""){
      NotificationManager.error("Silahkan pilih bank")
      return
    }

    setLoading(true)
    let param = {nominal: refInput.current.value, bank : selectedBank}
    let data = await api("post", "saldo-history/store", param);
    if(data.success){
      history.push({pathname:"/payment-saldo", data:data.data})
    }
    setLoading(false)
     
  }

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm cursor-pointer">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-2">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/topup-saldo")}
                  className="d-inline-block align-text-top "
                />
              </div>

              <div className="d-inline-block ml-2">
                <span className="ml-5">Transfer Bank</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="g-col-6 content bg-light py-4 min-vh-100 px-4">
        
          <div className="input-group">
            <input
              type="number"
              className="form-control form-control-lg font-14"
              placeholder="Pilih Nominal"
              ref={refInput}
              required
              aria-describedby="basic-addon2"
            />
          </div>

          <div className="g-col-6 bg-ligh pb-2 list-denom mt-4">
            <span>Pilih Nominal</span>
            <div className="d-flex w-100 mt-2" style={{ overflow: "scroll" }}>
              {nominal.map((item, key) => {
                return (
                  <div
                    key={key}
                    className="d-inline-blok pe-2 w-auto text-nowrap "
                    onClick={() => handleClickNominal(item)}
                  >
                    <div
                      className={
                        item.selected
                          ? "card py-2 cursor-pointer bg-primary text-white px-2 text-center"
                          : "card py-2 cursor-pointer text-dark px-2 text-center"
                      }
                      style={{ minWidth: "80px" }}
                    >
                      <span className="font-12">{commafy(item.nominal)}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="g-col-6 bg-light pb-2 list-denom mt-3">
            <span>Pilih Bank</span>
            <div className="mt-2">
              {data.map((item, key) => {
                return (
                  <div
                    className={
                      item.selected
                        ? "card mb-3 cursor-pointer border-selected"
                        : "card mb-3 cursor-pointer "
                    }
                    onClick={() => handleClickBank(item)}
                    key={key}
                  >
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img src={showImage(item.img)} width="40" alt="" />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p className="text-dark mb-0">{item.bank}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="col mt-3 perhatian"></div>

            <Button
              variant="primary"
              className="button w-100 text-white font-16 btn btn-primary btn-lg"
              disabled={isLoading}
              onClick={()=>handleClick()}
              type="submit"
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Lanjutkan"
              )}
            </Button>
          </div>
        
      </div>
    </React.Fragment>
  );
};

export default Transfer;
