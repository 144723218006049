import React, { useContext, useEffect, useState } from "react";
import { Modal, Col, Row } from "react-bootstrap";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import ImgBg from "../../assets/images/bg-top.svg";
import { useHistory } from "react-router-dom";
import { commafy } from "../../utils/Utils";
import moment from "moment";
import { AppContext } from "../../context/AppContext";

const SuksesPembayaran = () => {
  const { api, setSpinner } = useContext(AppContext);
  const history = useHistory();
  const [totalakhir, setTotalakhir] = useState(0);
  const [pelanggan, setPelanggan] = useState([]);
  const [profile, setProfile] = useState([]);
  const [total, setTotal] = useState(0);
  const [order, setOrder] = useState([]);
  const [price, setPrice] = useState("");
  const [product_id, setProduct_id] = useState("");
  const [nominal, setNominal] = useState(0);
  const [idPelanggan, setIdPelanggan] = useState();
  const [idAll, setIdAll] = useState("");
  const [totalDiskon, setTotalDiskon] = useState();
  const [kembalian, setKembalian] = useState(0);
  const [diskonselected, setDiskonselected] = useState(0);
  const [qty, setQty] = useState();
  const [idtrx, setIdtrx] = useState();
  const [totalMargin, setTotalMargin] = useState();
  const [showPrint, setShowPrint] = useState(false);

  const [date, setDate] = useState(
    moment().format("dddd, DD MMMM  yyy | HH:mm")
  );
  const [diskonname, setDiskonname] = useState();

  useEffect(() => {
    setTotalakhir(history.location.totalakhir);
    setDiskonname(history.location.diskonname);
    setDiskonselected(history.location.diskonselected);
    setNominal(history.location.nominal);
    setPelanggan(history.location.pelanggan);
    setProfile(history.location.profile);
    setOrder(history.location.order);
    setTotal(history.location.total);
    setPrice(history.location.price);
    // setProduct_id(history.location.order.products_grosir.id);
    setIdPelanggan(history.location.idPelanggan);
    setIdAll(history.location.idAll);
    setTotalDiskon(history.location.totalDiskon);
    setKembalian(nominal - totalakhir);
    setQty(history.location.qty);
    setIdtrx(history.location.idtrx);
    setTotalMargin(history.location.totalMargin);
  }, [history.location]);

  const handleClick = async () => {
    let param = {
      source: "POS",
      change: nominal - totalakhir,
      cash: nominal,
      price: price,
      total: total,
      product_id: 0,
      total_margin: totalMargin,
      discount: (total * diskonselected) / 100,
      qty: qty,
      gudang_id: "10",
      delivery_type: "ambil sendiri",
      gudang_distance: "10",
      buyer_id: idPelanggan,
      ongkir: "gratis",
      fee: 0,
      recipient_address: "Kasir Grosir",
      id_all: idAll.toString(),
      total_discount: totalDiskon,
      payment_method: "COD",
    };
    setSpinner(true);
    let trx = await api("post", "transactions-grosir/transaction", param);
    if (trx.success) {
      history.push({ pathname: "/pesanan-pos", order: trx.data[0] });
    }
    setSpinner(false);
  };

  return (
    <React.Fragment>
      <div className="container px-4 mt-4 border-none">
        <div className="w-100 min-vh-50 mt-5 text-center">
          <div className="d-inline-block mt-5">
            <div className="align-items-center">
              <img src="img/sukses.svg"></img>
            </div>

            <div className="mt-2 font-24 text-dark">
              <span>Pembayaran Berhasil</span>
            </div>
            <div className="mt-2 font-16 text-dark">
              <span>ID Pesanan {idtrx}</span>
            </div>
            <div className="mt-2 font-16 text-dark">
              <span>{date}</span>
            </div>
          </div>
        </div>
        <hr className="mt-5" />
        <div className="grid">
          <div className="w-100 d-flex px-3 font-14 mt-3">
            <div className="flex-grow-1">Total Tagihan</div>
            <div className="d-flex flex-grow-2">Rp {commafy(totalakhir)} </div>
          </div>
          <div className="w-100 d-flex px-3 font-14 mt-3">
            <div className="flex-grow-1">Uang Diterima</div>
            <div className="d-flex flex-grow-2">Rp {commafy(nominal)} </div>
          </div>
        </div>
        <div className="grid">
          <div className="w-100 d-flex px-3 font-14 mt-3">
            <div className="flex-grow-1">Diskon</div>
            <div className="d-flex flex-grow-2">{diskonselected}% </div>
          </div>
          <div className="w-100 d-flex px-3 font-14 mt-3">
            <div className="flex-grow-1">Kembalian</div>
            <div className="d-flex flex-grow-2">
              Rp {commafy(nominal - totalakhir)}
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col">
            <div
              className="btn btn-white border w-100"
              // onClick={() =>
              //   history.push({
              //     nominal: nominal,
              //     pathname: "/struk-pembelian",
              //     totalakhir: totalakhir,
              //     diskonname: diskonname,
              //     diskonselected: diskonselected,
              //     order: order,
              //     pelanggan: pelanggan,
              //     profile: profile,
              //     date: date,
              //     total: total,
              //   })
              // }
              onClick={() => {
                history.push("/riwayat-transaksi-pos")
                // setShowPrint(true);
                // console.log(order);
              }}
            >
              Cetak Struk
            </div>
          </div>
          <div className="col" hidden>
            <div
              className="btn btn-white border w-100"
              onClick={() => handleClick()}
            >
              Kirim Struk
            </div>
          </div>
        </div>
      </div>
      <div className="container px-0 py-4 text-center">
        {/* <a href="#" className="col text-decoration-none"> */}
        <div className="d-grid">
          <button
            type="button"
            className="btn btn-primary btn-lg d-block text-white me-4 mx-4"
            onClick={() => history.push("/pesanan-pos")}
          >
            Transaksi Baru
          </button>
        </div>
        {/* </a> */}
      </div>

      <Modal
        className="border-0"
        show={showPrint}
        size="lg"
        onHide={setShowPrint}
      >
        <Modal.Body>
          <div className="font-14 w-100">
            <div className="text-center">Nara Store</div>
            <div className="text-center"></div>
            <div className="grid border-bottom text-center">
              <div className=""> {idtrx}</div>
              <div className="">{date} </div>
            </div>
            <div className="mt-2 border-bottom">
              {order.map((item, key) => {
                return (
                  <React.Fragment>
                    <Row className="font-14" key={key}>
                      <Col>
                        <span>{item.products_grosir.name}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <span>
                          {commafy(item.price.toFixed(0))} x {commafy(item.qty)}{" "}
                          = Rp {commafy(item.total_price.toFixed(0))}
                        </span>
                      </Col>
                    </Row>
                  </React.Fragment>
                );
              })}
            </div>
            <div className="mt-2">
              <span>Total : Rp {commafy(total)}</span>
            </div>
            <div className="mt-2">
              <span>Uang Diterima : Rp {commafy(nominal)}</span>
            </div>
            <div className="mt-2 border-bottom">
              <span>Kembalian : Rp {commafy(nominal - total)}</span>
            </div>
            <div className="text-center mt-2 w-50 mx-auto">
              Order via WA Hubungi 082113831489
            </div>
            <div className="text-center mt-2 w-50 mx-auto">
              Pengiriman Gratis Di Antar Sampe Dapur
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default SuksesPembayaran;
