import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { showImage } from "../../../utils/Utils";
import { Card, Carousel, Col, Nav, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import imgMie3 from "../../../assets/images/indomie3.svg"
import dompet1 from "../../../assets/images/dompet1.svg"


const GantiPengiriman = () => {
  const { api } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [gudang, setGudang] = useState([]);
  const [category, setCategory] = useState({});
  const history = useHistory();
  const refInput = useRef();
  const [num, setNum] = useState(1);

  useEffect(() => {}, []);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const param = {
      id: 6
    }
    const data = await api("get", "products-grosir/detail-product", param)
    console.log(data);
    setData(data.data);

    const gudang = await api("get", "gudang/get");
    console.log(gudang);
      setGudang(gudang.data);
    
    
  };

  const changeInput = () => {
    let value = refInput.current.value;
    if (value.length === 4) {
      getData(value);
    }

    if (value.length === 0) {
      getData("");
    }
  };


  return (
    <React.Fragment>                
        <div className="row justify-content-center sticky-bottom border-top filter">
            <div className="col-md-10"> 
                <div className="grid">
                    <div className="offcanvas offcanvas-bottom g-col-6 rounded-top ganti-gudang" tabIndex="-1" id="pengiriman"
                            aria-labelledby="shortLabel">
                            <div className="offcanvas-body">
                                <div className="grid">
                                    <div className="g-col-10 px-4 py-3 bg-white">
                                   
                                        <div className="container px-0 py-3 category">
                                            <div className="row gx-8">
                                                <p className="mb-2 ">Pilih Pengiriman</p>
                                                <div className="d-inline-flex p-2">
                                       
                                                    <div className="col btn btn-outline-primary border rounded-2  me-3 ">
                                                        <a href="#" className=" text-decoration-none text-dark pt-2 d-block">Kurir Toko</a>
                                                        <center>
                                                            <small className="d-block pb-2 font-12">
                                                                <img src={dompet1} width="20"
                                                                    height="24"
                                                                    className="ms-2" 
                                                                />
                                                                Biaya 25.000
                                                            </small>
                                                        </center>
                                                    </div>
                                               
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div  className="container px-0 py-3 category">
                                        <div className="row gx-8">
                                            <p className="mb-2 text-black">*Pengiriman oleh kurir toko akan dikenakan biaya tambahan jika melebihi batas area pengiriman</p>
                                        </div>
                                        </div>
                                        <div className="container px-0 py-4 text-center">
                                            <div className="row gx-8">
                                                <a href="#" className="col text-decoration-none">
                                                    <div className="d-grid">
                                                        <button 
                                                        type="button" 
                                                        className="btn btn-primary btn-lg d-block text-white"
                                                        onClick={() => 
                                                        history.push("/checkout")}
                                                        >Konfrimasi</button>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      
                    </div>
                </div>
               
            </div>              
                 
    </React.Fragment>
  );
};

export default GantiPengiriman;