import React, { useContext, useEffect, useState } from "react";
import './Profile.css'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import back from "../../../assets/images/back.png"
import go from "../../../assets/images/go.png"
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { showImage } from "../../../utils/Utils";
import { useHistory } from "react-router-dom";


const KebijakanPrivasi = () => {
    const history = useHistory();
    const { api } = useContext(AppContext);
    const [data, setData] = useState([]);

    useEffect(() => {
      getData();
    }, []);

    const getData = async () => {
        let params = {
            id: "3"
        };
        let data = await api("get", "descriptions-text/get-one", params);
        setData(data.data);
    };
    return (
        <React.Fragment>
            <nav className="navbar bg-light shadow-sm cursor-pointer">
                <div className="contain p-2 px-cst1 pb-2 ps-4">
                    <Row>
                        <Col className="align-items-center d-flex">
                            <div className="d-inline-block pe-2">
                                <img
                                src="img/arrow-left.svg"
                                alt=""
                                width="30"
                                height="24"
                                onClick={() => history.push("/akun")}
                                className="d-inline-block align-text-top "
                                />
                            </div>

                            <div className="d-inline-block ml-2">
                                <span className="ml-3">Kebijakan Privasi</span>
                            </div>
                        </Col>
                    </Row>
                </div>
            </nav>
        <div className="container">
            <div className="row justify-content-center p-3">
                <div>
                <p>{data.text}</p>
                </div>
            </div>
        </div>
        </React.Fragment>
    )
}
export default KebijakanPrivasi