import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Offcanvas } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import gudang from "../../assets/images/gudang.svg";
import { useForm } from "react-hook-form";
import { NotificationManager } from "react-notifications";

const VerifikasiToko = () => {
  const history = useHistory();
  const { api, setSpinner } = useContext(AppContext);
  const [dataGudang, setDataGudang] = useState([]);
  const [singleGudang, setSingleGudang] = useState({});
  const [redeemHistory, setRedeemHistory] = useState({});
  const [member, setMember] = useState({});
  const [rewardPoint, setRewardPoint] = useState({});
  const [product, setProduct] = useState({});
  const [showKonfirmasi, setShowKonfirmasi] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (!history.location.redeemHistory) history.push("/redeem-history");
    setRedeemHistory(history.location.redeemHistory);
    setMember(history.location.member);
    setRewardPoint(history.location.rewardPoint);
    setProduct(history.location.product);
    getDataGudang();
  }, []);

  const getDataGudang = async () => {
    setSpinner(true);
    let dataGudang = await api("get", "gudang/get");
    setDataGudang(dataGudang.data);
    setSpinner(false);
  };

  const onSubmit = async (params) => {
    setLoading(true);
    params = {
      redeemHistory_id: redeemHistory.id,
      member_id: member.id,
      transactions_id: 10,
      point: rewardPoint.point,
      gudang_id: singleGudang.id,
      product_id: product.id,
    };
    let confirm = await api("post", "point-historys/store", params);
    if (confirm.success) {
      NotificationManager.success(confirm.message, "Success", 2000);
      history.push("/tukar-point");
    } else {
      NotificationManager.error(confirm.message, "Error", 2000);
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/redeem-history")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Pilih Toko untuk Verifikasi</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>
      <div className="g-col-6  px-cst1 pb-2 list-denom ">
        <Row className="mt-2">
          {dataGudang.map((item, key) => {
            return (
              <div className="col-md-13 mt-3" key={key}>
                <div className="card " style={{ borderRadius: "10px" }}>
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 px-3">
                        <img src={gudang} width="40" alt="" />
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="text-dark">{item.name}</h6>
                        <small className="text-dark">{item.address}</small>
                      </div>
                      <div className="flex-grow-2 ms-3">
                        <div className="text-muted mb-0">
                          <img
                            src="img/next.svg"
                            className="cursor-pointer"
                            alt=""
                            onClick={() => {
                              setShowKonfirmasi(true);
                              setSingleGudang(item);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="row justify-content-center sticky-bottom border-top filter">
            <div className="col-md-12">
              <Offcanvas
                show={showKonfirmasi}
                placement="bottom"
                onHide={() => setShowKonfirmasi(false)}
                className="rounded-top detail-product"
                style={{ height: "57vh" }}
              >
                <Offcanvas.Body>
                  <div className="fw-bold text fs-5 border-bottom mb-3">
                    <p>Konfirmasi Verifikasi Toko</p>
                  </div>
                  <div className="fw-bold border border-3 p-2 rounded">
                    <p className="mb-0">Nama Toko : {singleGudang.name}</p>
                    <p className="text-muted fw-normal">
                      Alamat Toko : {singleGudang.address}
                    </p>
                  </div>
                  <hr />
                  <div className="border border-3 p-2 rounded">
                    <p className="fw-bold fs-6 mb-1">Informasi Produk : </p>
                    <p className="mb-0">{product.name}</p>
                    <p className="text-muted mb-0">
                      Sisa Stock : {product.stock}
                    </p>
                    <p className="mb-0">{rewardPoint.point} Poin</p>
                  </div>
                  <div className="text-dark fw-bold mt-3">
                    <div className="d-flex justify-content-between gap-4">
                      <button
                        className="btn btn-outline-primary rounded-pill w-50"
                        onClick={() => {
                          setShowKonfirmasi(false);
                        }}
                      >
                        Cancel
                      </button>
                      <form className="w-50" onSubmit={handleSubmit(onSubmit)}>
                        <button
                          type="submit"
                          className="btn btn-primary text-white rounded-pill w-100"
                        >
                          {loading ? "Loading..." : "Konfirmasi"}
                        </button>
                      </form>
                    </div>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
            </div>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default VerifikasiToko;
