import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { showImage } from "../../utils/Utils";
import { Card, Carousel, Col, Nav, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import favorit from "../../assets/images/love2.svg";
import shop from "../../assets/images/shop-icon.svg";
import _ from "lodash";
import { commafy } from "../../utils/Utils";

const GrosirWishlist = () => {
  const { api } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [type, setType] = useState([]);
  const [category, setCategory] = useState([]);
  const [profile, setProfile] = useState([]);
  const history = useHistory();
  const refInput = useRef();
  const { setSpinner } = useContext(AppContext);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let data = await api("get", "wishlist-grosir/get-by-user");
    setData(data.data); 
  };
  const addToChart = async (item) => {
    Object.assign(item, { product_id: item.id });
    setSpinner(true);
    let ongkir = await api("get", "data-ongkir/get-byname", {
      name: "Kurir Toko",
      lat: item.gudang.lat,
      lng: item.gudang.lng,
    });
    if (!ongkir.data.length) {
      NotificationManager.error(
        "Area kamu melebihi batas jangkauan pengiriman."
      );
      setSpinner(false);
      return;
    }
    item.price = parseInt(item.price) - parseInt(item.discount);
    let cart = await api("post", "cart-grosir/post", item);
    if (cart.success) {
      NotificationManager.success(cart.message); 
    } else NotificationManager.error(cart.message);
    setSpinner(false);
  };

  
  const addToWish = async (item) => {
    setSpinner(true);
    let params = {
      product_id: item.id,
    };
    let postWishlist = await api("post", "wishlist-grosir/post", params);
    if (postWishlist.success) {
      NotificationManager.success(postWishlist.message);
      getData();
    } else NotificationManager.error(postWishlist.message);
    setSpinner(false);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm cursor-pointer w-100">
        <div className="p-2 pb-2 ps-4 w-100 d-flex align-items-center">
          <div className="d-inline-block">
            <img
              src="img/arrow-left.svg"
              alt=""
              width="30"
              height="24"
              onClick={() => history.push("/grosir-home")}
              className="d-inline-block align-text-top "
            />
          </div>

          <div className="d-inline-block ms-2" style={{ width: "90%" }}>
            <form className="w-100">
              <div className="input-group w-100">
                <input
                  type="text"
                  className="form-control form-control-lg font-16"
                  placeholder="Cari Produk"
                  ref={refInput}
                  aria-describedby="basic-addon2"
                  // onChange={() => changeInput()}
                />
                <button className="input-group-text bg-white border">
                  <i className="bi bi-search"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </nav>

      <Row className="no-gutters px-3">
        {data.map((item, key) => {
          return (
            <div className="col-6 p-2" key={key}>
              <div className="card w-100 border-0 shadow cursor-pointer">
                <center>
                  <div className="bg-lime rounded m-2">
                    <div className="w-100">
                      <img
                        src="img/love_red.svg"
                        alt=""
                        className="float-end mt-2 me-2"
                        width="30"
                        height="30"
                        onClick={() => addToWish(item)}
                      />
                    </div>
                    <div
                      className="p-3"
                      onClick={() =>
                        history.push({
                          pathname: "/deskripsi-produk",
                          product: item,
                        })
                      }
                    >
                      <img
                        className="card-img-top mt-2"
                        src={showImage(item.img)}
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>
                  </div>
                </center>
                <div className="card-body">
                  <div
                    onClick={() =>
                      history.push({
                        pathname: "/deskripsi-produk",
                        product: item,
                      })
                    }
                  >
                    <div
                      className="font-16 font-weight-bold"
                      style={{ height: "45px" }}
                    >
                      <span className="mt-1">{item.name}</span>
                    </div>
                    <span className="text-muted font-13">
                      {item.weight} {item.unit_weight}
                    </span>
                    <div className="w-100 d-flex mt-1 font-14">
                      <h6 className="font-weight-bold text-primary ">
                        Rp {commafy(item.price - item.discount)}
                      </h6>
                      <small className="ms-2 text-secondary font-13">
                        <del> Rp{commafy(item.price)}</del>
                      </small>
                    </div>
                  </div>
                  <div
                    className="d-flex font-14 justify-content-center align-items-center"
                    style={{ height: "30px" }}
                  >
                    <img src={"img/ic_loc.svg"} className="" width="10" />
                    <span className="flex-grow-1 ms-2 justify-content-center align-items-center font-12">
                      {item.gudang.name +
                        " " +
                        item.distance.distance +
                        item.distance.unit}
                    </span>

                    <img
                      src={shop}
                      onClick={() => addToChart(item)}
                      hidden={item.stock === 0 ? true : false}
                      className="cursor-pointer"
                      width="30"
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Row>

      {/* <FilterProduct/> */}
    </React.Fragment>
  );
};

export default GrosirWishlist;
