import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { showImage } from "../../utils/Utils";
import { useHistory } from "react-router-dom";
import Card from "react-bootstrap/Card";
import {  Row,Spinner } from "react-bootstrap"; 
import Troli from "../../assets/images/troli-white.svg";
import shop from "../../assets/images/shop-icon.svg";
import lovewhite from "../../assets/images/love-white.svg";
import GrosirBottom from "./Grosir-botton";
import PromoGrosir from "./PromoGrosir";
import { commafy } from "../../utils/Utils";
import { NotificationManager } from "react-notifications";

const GrosirHome = () => {
  const { api, setSpinner } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [category, setCategory] = useState([]);
  const [local, setLocal] = useState({});
  const history = useHistory();
  const refInput = useRef();
  const [count, setCount] = useState(0);
  const isFirstRun = useRef(true);

  useEffect(() => {
    // if (isFirstRun.current) {
    //   isFirstRun.current = false;
    //   return;
    // }
    let localData = localStorage.getItem("useranarastore");
    if (!localData) {
      return history.push("/login");
    }
    setLocal(JSON.parse(localData));
    getData();
  }, []);

  const getData = async () => {
    setSpinner(true);
    let data = await api("get", "products-grosir/get",{is_popular : "true"});
    setData(data.data);

    let category = await api("get", "category-grosir/get");
    setCategory(category.data);
    countCart();
    setSpinner(false);
  };

  const addToChart = async (item) => {
    Object.assign(item, { product_id: item.id });
    setSpinner(true);
    let ongkir = await api("get", "data-ongkir/get-byname", {
      name: "Kurir Toko",
      lat: item.gudang.lat,
      lng: item.gudang.lng,
    });
    if (!ongkir.data.length) {
      NotificationManager.error(
        "Area kamu melebihi batas jangkauan pengiriman."
      );
      setSpinner(false);
      return;
    }
    item.price = parseInt(item.price) - parseInt(item.discount);
    let cart = await api("post", "cart-grosir/post", item);
    if (cart.success) {
      NotificationManager.success(cart.message);
      countCart();
    } else NotificationManager.error(cart.message);
    setSpinner(false);
  };

  const addToWish = async (item) => {
    setSpinner(true);
    let params = {
      product_id: item.id,
    };
    let postWishlist = await api("post", "wishlist-grosir/post", params);
    if (postWishlist.success) {
      NotificationManager.success(postWishlist.message);
      getData();
    } else NotificationManager.error(postWishlist.message);
    setSpinner(false);
  };

  const countCart = async () => {
    let count = await api("get", "cart-grosir/count-by-user");
    if (count.success) setCount(count.data[0].count);
  };

  return (
    <React.Fragment>
      <div className="w-100 position-relative img-bg-top">
        <div className="contain p-2 px-cst1 pb-3 ">
          <div className="w-100 d-flex font-14 pt-3">
            <div className="flex-grow-1 text-white mx-2 font-16">
              <span>Hallo {local.first_name}</span>
            </div>
            <div
              className="d-flex float-right me-2 cursor-pointer"
              onClick={() =>
                history.push({
                  pathname: "/grosir-wishlist",
                })
              }
            >
              <img src={lovewhite} width={30} alt="" />
            </div>

            <div
              className="d-flex float-right me-2 cursor-pointer"
              onClick={() =>
                history.push({
                  pathname: "/keranjang",
                })
              }
            >
              <div className="button">
                <img src={Troli} width={30} alt="" className="float-right" />
                <span
                  className="button__badge"
                  hidden={count === 0 ? true : false}
                >
                  {count}
                </span>
              </div>
            </div>
          </div>
          <span className="btn btn-light rounded-pill font-10 p-2 text-primary mx-2">
            <span className="text-12">{local.address}</span>
          </span>
          <div
            className="input-group px-2 pt-3"
            onClick={() =>
              history.push({
                pathname: "/pages-product",
              })
            }
          >
            <input
              type="text"
              className="form-control form-control-lg border-0 font-16"
              placeholder="Cari Produk"
              ref={refInput}
              // onChange={() => changeInput()}
              aria-describedby="basic-addon2"
            />
            <button className="input-group-text bg-white border-0">
              <i className="bi bi-search"></i>
            </button>
          </div>
        </div>
      </div>

      <PromoGrosir isGrosir={false} />

      <div className="container mt-4">
        <Row className="">
          <div className=" d-flex px-1 font-14 ">
            <div className="flex-grow-1 mx-4">
              <h6>Kategori</h6>
            </div>
            <div
              className="d-flex flex-grow-2 text-primary me-3 cursor-pointer"
              onClick={() =>
                history.push({
                  pathname: "/pages-product",
                })
              }
            >
              <h6>Lihat semua</h6>
            </div>
          </div>
          <div className="d-flex overflow-scroll">
            {category.map((item, key) => {
              return (
                <Card
                  key={key}
                  className="shadow border-0 m-2 cursor-pointer"
                  style={{ minWidth: "150px", height: "150px" }}
                  onClick={() =>
                    history.push({
                      pathname: "/pages-product",
                      category_id: item.id,
                    })
                  }
                >
                  <center>
                    <Card.Img
                      className="w-50 item-center mt-3"
                      src={showImage(item.img)}
                    />
                  </center>
                  <Card.Body className="">
                    <center>{item.name}</center>
                  </Card.Body>
                </Card>
              );
            })}
          </div>
        </Row>

        <div className="pt-3 mt-4">
          <h6 className="mx-3">Produk Paling Terlaris</h6>
        </div>
        <Row className="no-gutters px-3">
          {data.map((item, key) => {
            return (
              <div className="col-6 p-2" key={key}>
                <div className="card w-100 border-0 shadow cursor-pointer">
                  <center>
                    <div className="bg-lime rounded m-2">
                      <div className="w-100">
                        <img
                          src={
                            item.wishlist_grosir
                              ? "img/love_red.svg"
                              : "img/love_white.svg"
                          }
                          alt=""
                          className="float-end mt-2 me-2"
                          width="30"
                          height="30"
                          onClick={() => addToWish(item)}
                        />
                      </div>
                      <div
                        className="p-3"
                        onClick={() =>
                          history.push({
                            pathname: "/deskripsi-produk",
                            product: item,
                          })
                        }
                      >
                        <img
                          className="card-img-top mt-2"
                          src={showImage(item.img)}
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>
                    </div>
                  </center>
                  <div className="card-body">
                    <div
                      onClick={() =>
                        history.push({
                          pathname: "/deskripsi-produk",
                          product: item,
                        })
                      }
                    >
                      <div
                        className="font-16 font-weight-bold"
                        style={{ height: "45px" }}
                      >
                        <span className="mt-1">{item.name}</span>
                      </div>
                      <span className="text-muted font-13">
                        {item.weight} {item.unit_weight}
                      </span>
                      <div className="w-100 d-flex mt-1 font-14">
                        <h6 className="font-weight-bold text-primary ">
                          Rp {commafy(item.price)}
                        </h6>
                        <small className="ms-2 text-secondary font-13">
                          <del> Rp{commafy(item.price_before)}</del>
                        </small>
                      </div>
                    </div>
                    <div
                      className="d-flex font-14 justify-content-center align-items-center"
                      style={{ height: "30px" }}
                    >
                      <img src={"img/ic_loc.svg"} className="" width="10" />
                      <span className="flex-grow-1 ms-2 justify-content-center align-items-center font-12">
                        {item.gudang.name +
                          " " +
                          item.distance.distance +
                          item.distance.unit}
                      </span>

                      <img
                        src={shop}
                        onClick={() => addToChart(item)}
                        hidden={item.stock === 0 ? true : false}
                        className="cursor-pointer"
                        width="30"
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Row>
      </div>

      <GrosirBottom />
    </React.Fragment>
  );
};

export default GrosirHome;
