import React, { useContext, useEffect, useRef, useState } from "react";
import {Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";

const PengaturanProfile = () => {
    const history = useHistory();
    const { api } = useContext(AppContext);
    const [data, setData] = useState([]);

    useEffect(()=>{
        getData();
    },[])
   
    
    const getData = async (prefix) => {
      let data = await api("get", "user/profile");
      setData(data.data);
    };

    return (
      <React.Fragment>
        <nav className="navbar bg-light shadow-sm cursor-pointer">
          <div className="contain p-2 px-cst1 pb-2 ps-4">
            <Row>
              <Col className="align-items-center d-flex">
                <div className="d-inline-block pe-2">
                  <img
                    src="img/arrow-left.svg"
                    alt=""
                    width="30"
                    height="24"
                    onClick={() => history.push("/akun")}
                    className="d-inline-block align-text-top "
                  />
                </div>

                <div className="d-inline-block ml-2">
                  <span className="ml-3">Pengaturan Profile</span>
                </div>
              </Col>
            </Row>
          </div>
        </nav>

        <div className="px-4 pt-2 min-vh-100">
          <div className="card my-3">
            <div className="card-body px-3 pt-2 pb-0 mt-2 cursor-pointer" onClick={()=>history.push("/ubah-foto")}>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 ms-3">
                  <p className="text-dark mb-0">Ubah Gambar Profile</p>
                </div>
                <div className="flex-grow-2 ms-3">
                  <div className="text-muted mb-0">
                    <img src="img/next.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="hr-line" />
            <div className="card-body px-3 pt-1 pb-0">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 ms-3">
                  <p className="text-dark mb-0">Nama Lengkap</p>
                </div>
                <div className="flex-grow-2 ms-3">
                  <div className="text-muted mb-0">
                    <span>{data.first_name}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="hr-line" />
            <div className="card-body px-3 pt-1 pb-0">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 ms-3">
                  <p className="text-dark mb-0">Nama Pengguna</p>
                </div>
                <div className="flex-grow-2 ms-3">
                  <div className="text-primary mb-0">
                    <span>{data.username}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="hr-line" />
            <div className="card-body px-3 pt-1 pb-0">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 ms-3">
                  <p className="text-dark mb-0">Email</p>
                </div>
                <div className="flex-grow-2 ms-3">
                  <div className="text-muted mb-0">
                    <span>{data.email}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="hr-line" />
            <div className="card-body px-3 pt-1 pb-0">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 ms-3">
                  <p className="text-dark mb-0">Nomor Handphone</p>
                </div>
                <div className="flex-grow-2 ms-3">
                  <div className="text-muted mb-0">
                    <span>{data.phone}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="hr-line" />
            <div className="card-body px-3 pt-1 pb-0">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 ms-3">
                  <p className="text-dark mb-0">Alamat</p>
                </div>
                <div className="flex-grow-2 ms-3">
                  <div className="text-muted mb-0">
                    <span>{data.address}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="hr-line" />
            <div className="card-body px-3 pt-1 pb-0">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 ms-3">
                  <p className="text-dark mb-0">Nama Usaha</p>
                </div>
                <div className="flex-grow-2 ms-3">
                  <div className="text-muted mb-0">
                    <span>{data.business_name}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="hr-line" />
            <div className="card-body px-3 pt-1 pb-3">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 ms-3">
                  <p className="text-dark mb-0">Jenis Usaha</p>
                </div>
                <div className="flex-grow-2 ms-3">
                  <div className="text-muted mb-0">
                    <span>{data.business_type}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-grid ">
            <button
              onClick={() => {
                history.push("/ubahdata");
              }}
              type="button"
              class="mt-2 btn btn-primary btn-lg d-block text-white  font-16"
            >
              Ubah Data
            </button>
          </div>
        </div>
      </React.Fragment>
    );
};

export default PengaturanProfile;