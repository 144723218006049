import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import tambah from "../../assets/images/tambah.svg";
import trash from "../../assets/images/trash-icon.svg";

const CashAccount = () => {
  const history = useHistory();
  const { api, setSpinner } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setSpinner(true);
    let data = await api("get", "cash-account/get");
    setData(data.data);
    setSpinner(false);
  };

  const selectAll = (e) => {
    console.log(e.target.checked);
    let newData = data.map((item) => {
      item.selected = e.target.checked;
      return item;
    });
    console.log(newData);
    setData(newData);
  };

  const selectSingle = (id, selected) => {
    let newData = data.map((item) => {
      if (item.id === id) item.selected = selected;
      return item;
    });

    setData(newData);
  };

  const delData = async () => {
    let id = "",
      separator = "";
    data.map((item) => {
      if (item.selected === true) {
        id = id + separator + item.id;
        separator = ",";
      }
    });

    let param = { id_all: id };
    let del = await api("post", "cash-account/delete-all", param);

    if (del.success) getData();
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/dashboard")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Cash Account</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="container px-4 mt-4">
        <div className="deleteform d-flex align-item-center ms-3 mt-4">
          <input
            class="form-check-input  "
            type="checkbox"
            onClick={(e) => selectAll(e)}
            value=""
            id="flexCheckDefault"
          />
          <label
            className="form-check-label flex-grow-1 ms-3"
            for="flexCheckDefault"
          >
            Pilih semua
          </label>
          <div className="flex-grow-2 mx-3">
            <div className="text-muted mb-0">
              <img src={trash} width="25" onClick={() => delData()} />
            </div>
          </div>
        </div>
      </div>

      <div className="g-col-6  px-cst1 pb-2 list-denom">
        <Row className="mt-2">
          {data.map((item, key) => {
            return (
              <div className="col-md-12 mt-3" key={key}>
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div class="deleteform form-check-delete">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          onClick={(e) =>
                            selectSingle(item.id, e.target.checked)
                          }
                          value=""
                          checked={item.selected}
                          id="flexCheckDefault"
                        />
                      </div>

                      <div className="flex-grow-1 mt-2 ms-3">
                        <h6 className="text-dark">{item.name}</h6>
                      </div>
                      <div className="flex-grow-2 ms-3">
                        <div className="text-muted mb-0">
                          <img
                            src="img/next.svg"
                            className="cursor-pointer"
                            alt=""
                            onClick={() =>
                              history.push({
                                pathname: "/detail-cash-account",
                                data: item,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Row>
      </div>
      <div
        className="float-end me-4 sticky-bottom py-3"
        onClick={() =>
          history.push({
            pathname: "/tambah-cash-account",
          })
        }
      >
        <img src={tambah} />
      </div>
    </React.Fragment>
  );
};

export default CashAccount;
