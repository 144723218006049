import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import filterbiru from "../../assets/images/filterbiru.svg";
import keluar from "../../assets/images/out.svg";
import masuk from "../../assets/images/in.svg";
import garislurus from "../../assets/images/garislurus.svg";
import koin from "../../assets/images/koin.svg";
import moment from "moment";

const LaporanTransaksiInventory = () => {
  const { api, setSpinner } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [qty, setQty] = useState([]);
  const history = useHistory();
  const refInputStart = useRef();
  const refInputEnd = useRef();
  const [startDate, setStartDate] = useState(moment().format("MM-DD-YYYY"));
  const [endDate, setEndDate] = useState(moment().format("MM-DD-YYYY"));

  useEffect(() => {
    getData();
  }, []);

  const changeInputStart = () => {
    let value = refInputStart.current.value;
    setStartDate(moment(value).format("MM-DD-YYYY"));
  };

  const changeInputEnd = () => {
    let value = refInputEnd.current.value;
    setEndDate(moment(value).format("MM-DD-YYYY"));
  };

  const getData = async () => {
    setSpinner(true);
    let param = {
      startDate: startDate,
      endDate: endDate,
    };
    let data = await api("get", "transactions-inventory/get", param);
    setData(data.data);

    let qtyData = await api("get", "transactions-inventory/count-qty", param);
    setQty(qtyData.data);
    setSpinner(false);
  };

  const resetApi = async () => {
    setSpinner(true);
    let data = await api("get", "transactions-inventory/get");
    setData(data.data);
    setSpinner(false);
  };

  const setBulanan = async () => {
    setSpinner(true);
    let data = await api("get", "transactions-inventory/get", {
      filter: "Bulanan",
    });
    setData(data.data);
    setSpinner(false);
  };

  const handleClick = async () => {
    getData();
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm">
        <div className="contain p-2 px-cst1 pb-2 ps-4 w-100">
          <Row>
            <Col className="align-items-center w-100">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/dashboard-inventory")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block">
                <span className="font-15 datapelanggan">
                  Laporan Transaksi Inventory
                </span>
              </div>
              <div
                className="d-inline-block float-end pe-4"
                data-bs-toggle="offcanvas"
                data-bs-target="#laporaninventory"
                aria-controls="laporaninventory"
              >
                <div className="align-text-top ">
                  <img src={filterbiru} width={25} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="g-col-6  px-cst1 pb-2 list-denom ">
        <Row className="mt-4">
          <Col>
            <div className="card cursor-pointer">
              <div className="card-body">
                <Row>
                  <div className="col-md-6">
                    <div className="d-flex align-items-center ">
                      <div className="flex-shrink-0 px-3">
                        <img src={masuk} width="40" alt="" />
                      </div>
                      <div className="flex-grow-1">
                        <small className="text-muted mt-2">
                          Total Barang Masuk
                        </small>
                        <h6 className="text-dark">{qty.masuk}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className="d-flex align-items-center ">
                      <img src={garislurus} className="pe-3" />
                      <div className="flex-shrink-0 ms-4 px-3">
                        <img src={keluar} className="ms-4" width="40" alt="" />
                      </div>
                      <div className="flex-grow-1">
                        <small className="text-muted mt-2">
                          Total Barang Keluar
                        </small>
                        <h6 className="text-dark">{qty.keluar}</h6>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        {data.map((item) => {
          return (
            <Row className="mt-4">
              <Col>
                <div
                  className="card"
                  onClick={() =>
                    history.push({
                      pathname: "/riwayat-transaksi-inventori",
                      date: item.date,
                      param: "laporan-inventory",
                    })
                  }
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 pe-4">
                        <img src={koin} width="40" alt="" />
                      </div>
                      <div className="flex-grow-1 py-1">
                        <h6 className="text-dark mt-2 fw-normal">
                          {item.date}
                        </h6>
                      </div>
                      <div className="flex-grow-2 ms-3">
                        <h6 className="mt-2 text-dark">{item.jumlah} Produk</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          );
        })}
      </div>

      <div
        className="offcanvas offcanvas-bottom g-col-6 rounded-top"
        tabIndex="-1"
        id="laporaninventory"
        aria-labelledby="shortLabel"
        style={{ height: "370px" }}
      >
        <div className="offcanvas-body">
          <div className="grid">
            <div className="g-col-12 px-4 py-3 bg-white">
              <p className="fs-6 mb- fw-bold">Filter</p>
              <div className="container px-0 py-1">
                <div className="row gx-8">
                  <p className="mb-2">Pilih tanggal yang ingin ditampilkan</p>
                  <a className="col text-decoration-none">
                    <div className="card p-3">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img src="img/kalender.svg" width="50" alt="" />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p id="alternate2" className="mb-1">
                            Tanggal Awal
                          </p>
                          <input
                            id="datepicker1"
                            className="form-control form-control-sm"
                            autoComplete="off"
                            type="date"
                            placeholder="Pilih tanggal"
                            aria-label="example"
                            ref={refInputStart}
                            onChange={() => changeInputStart()}
                            defaultValue={startDate}
                          />
                        </div>
                      </div>
                    </div>
                  </a>
                  <a className="col text-decoration-none">
                    <div className="card p-3">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img src="img/kalender.svg" width="50" alt="" />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p id="alternate2" className="mb-1">
                            Tanggal Akhir
                          </p>
                          <input
                            id="datepicker2"
                            className="form-control form-control-sm"
                            autoComplete="off"
                            type="date"
                            placeholder="Pilih Tanggal"
                            aria-label="example"
                            ref={refInputEnd}
                            onChange={() => changeInputEnd()}
                            defaultValue={endDate}
                          />
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="container px-0 py-3 category">
                <div className="row gx-8">
                  <div className="col text-decoration-none">
                    <a
                      className="btn btn-outline-primary border-secondary d-block"
                      onClick={() => getData()}
                    >
                      Harian
                    </a>
                  </div>
                  <div className="col text-decoration-none">
                    <a
                      className="btn btn-outline-primary border-secondary d-block"
                      onClick={() => setBulanan()}
                    >
                      Bulanan
                    </a>
                  </div>
                </div>
              </div>
              <div className="container px-0 py-4 text-center">
                <div className="row gx-8">
                  <a className="col text-decoration-none">
                    <div className="d-grid">
                      <button
                        type="button"
                        className="btn btn-outline-secondary btn-lg d-block font-16"
                        onClick={() => resetApi()}
                      >
                        Reset
                      </button>
                    </div>
                  </a>
                  <div className="col text-decoration-none">
                    <div className="d-grid">
                      <button
                        type="button"
                        className="btn btn-primary btn-lg d-block text-white font-16"
                        onClick={() => handleClick()}
                      >
                        Terapkan
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LaporanTransaksiInventory;
