import React, { useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import { commafy, showImage } from "../../utils/Utils";
import Promo from "./Promo";
import { useHistory } from "react-router-dom";
import Bottom from "./Bottom";
import { Link } from "react-router-dom";

const Home = () => {
  const { api, setSpinner } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [profile, setProfile] = useState({});
  const [saldo, setSaldo] = useState({});
  const [local, setLocal] = useState(null);
  const history = useHistory();
  

  useEffect(() => {
    let localData = localStorage.getItem("useranarastore")
    if(localData !== null){
      setLocal(JSON.parse(localData))
      getSaldo()
    }  
    getData();
  }, []);

  const getData = async () => {
    setSpinner(true)
    let param = {
      id: "i8l7DzgKEatN3e7SNCI38mA67ey0o5SiFgMKEJl7Va5pnD0BKW",
      view: "category",
    };
    let data = await api("get", "produk/public", param);
    setData(data.data);
    setSpinner(false)
  };

  const getSaldo = async () => {
    let profile = await api("get", "user/profile");
    if(profile.success)
    setProfile(profile.data);

    let saldo = await api("get", "saldo-history/get-saldo");
    if(saldo.success)
    setSaldo(saldo.data);
  }

  return (
    <React.Fragment>
      <div
        className="w-100 position-relative img-bg-top"
        style={{ height: "150px" }}
      >
        <div className="contain p-2 px-cst1 pb-2" hidden={local ? false : true}>
          <div className="d-flex align-items-center pt-2">
            <div className="align-items-center md-1">
              <div className="d-inline-block pe-2 ps-3">
                {profile.img ? (
                  <img
                    src={showImage(profile.img)}
                    style ={{borderRadius:"50%", height:'50px'}}
                    width="50"  
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div>
              <div className="ps-2">
                <span className="d-block ml-3 text-white font-14">
                  {profile.first_name}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 d-flex justify-content-center">
        <div
          className="bg-saldo bg-warning card boder-none shadow-sm w-100"
          hidden={local ? false : true}
          style={{
            marginTop: "-50px",
            marginLeft: "24px",
            marginRight: "24px",
          }}
        >
          <div className="card-body p-3 container">
            <div className="row align-items-center">
              <div className="col-7">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <img src="img/wallet-saldo.svg" width="50" alt="" />
                  </div>
                  <div className="flex-grow-1 ms-3 text-white">
                    <p className="mb-0 font-12">Saldo</p>
                    <p className="mb-0 fs-6  font-16">
                      Rp {commafy(saldo.nominal)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-5">
                <Link to="topup-saldo">
                  <button className="btn btn-light rounded-pill float-end font-12 p-2 w-50">
                    Isi Saldo
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container px-4 mt-4">
        <div className="card border-0 shadow-sm rounded">
          <div className="card-header bg-white py-3">
            <p className="mb-0 fs-6">Daftar Produk</p>
          </div>
          <div className="card-body text-center">
            <div className="row">
              {data.map((item, key) => {
                return (
                  <Col
                    key={key}
                    id={item.img}
                    style={{ maxWidth: "120px" }}
                    className="cursor-pointer"
                    onClick={() =>
                      history.push({
                        pathname: item.name === "Games" ? "games" : "brands",
                        category: item,
                      })  
                    }
                  >
                    <div className="p-2 mt-3" style={{ width: "90px" }}>
                      <img
                        src={showImage(item.img)}
                        style={{ width: "95%" }}
                        alt=""
                      />
                      <p className="mb-0 mt-2 font-12">{item.name}</p>
                    </div>
                  </Col>
                );
              })}
            </div>
          </div>
        </div>
        <Promo isGame={false} />

        <div className="card border-0 shadow-sm rounded mt-4 mb-5 cursor-pointer" onClick={()=> history.push("/bantuan")}>
          <div className="card-body text-left">
            <div className="row">
              <Col md="10" xs="8">
                <p className="text-primary font-16">Punya kendala?</p>
                <p className="text-dark font-14">
                  Kamu bisa menghubungi layanan customer service kami
                </p>
              </Col>
              <Col className="align-items-center justify-content-center d-flex text-center">
                <img src="/img/cs.svg" className="img-fluid" alt="" />
              </Col>
            </div>
          </div>
        </div>
      </div>
      <Bottom />
    </React.Fragment>
  );
};

export default Home;
