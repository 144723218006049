import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import ImgBg from "../../assets/images/bg-top.svg";
import { useHistory } from "react-router-dom";
import { commafy } from "../../utils/Utils";
import moment from "moment";
import { AppContext } from "../../context/AppContext";

const TransaksiSukses = () => {
  const { api, setSpinner } = useContext(AppContext);
  const history = useHistory();
  const [data, setData] = useState({});
  const [product, setProduct] = useState([]);
  const [supplier, setSupplier] = useState("");
  const [date, setDate] = useState(
    moment().format("dddd, DD MMMM  yyy | HH:mm")
  );

  useEffect(() => {
    setData(history.location.data);
    setProduct(history.location.product);
    setSupplier(history.location.supplier);
  }, [history.location]);

  // const handleClick = async () => {
  //   let param = {
  //     source: "POS",
  //     change: nominal - totalakhir,
  //     cash: nominal,
  //     price: price,
  //     total: total,
  //     product_id: 0,
  //     total_margin: totalMargin,
  //     discount: (total * diskonselected) / 100,
  //     qty: qty,
  //     gudang_id: "10",
  //     delivery_type: "ambil sendiri",
  //     gudang_distance: "10",
  //     buyer_id: idPelanggan,
  //     ongkir: "gratis",
  //     fee: 0,
  //     recipient_address: "Kasir Grosir",
  //     id_all: idAll.toString(),
  //     total_discount: totalDiskon,
  //     payment_method: "COD",
  //   };
  //   setSpinner(true);
  //   let trx = await api("post", "transactions-grosir/transaction", param);
  //   if (trx.success) {
  //     history.push({ pathname: "/pesanan-pos", order: trx.data[0] });
  //   }
  //   setSpinner(false);
  // };

  return (
    <React.Fragment>
      <div className="container px-4 mt-4 border-none">
        <div className="w-100 min-vh-50 mt-5 text-center">
          <div className="d-inline-block mt-5">
            <div className="align-items-center">
              <img src="img/sukses.svg"></img>
            </div>
            <div className="mt-2 font-24 text-dark">
              <span>Transaksi Berhasil</span>
            </div>
            <div className="mt-2 font-16 text-dark">
              <span>ID Transaksi {data.no}</span>
            </div>
            <div className="mt-2 font-16 text-dark">
              <span>{date}</span>
            </div>
          </div>
        </div>
        <hr className="mt-5" />
        <div className="grid">
          <div className="w-100 d-flex px-3 font-14 mt-3">
            <div className="flex-grow-1">Total Harga</div>
            <div className="d-flex flex-grow-2">Rp {commafy(data.price)} </div>
          </div>
          <div className="w-100 d-flex px-3 font-14 mt-3">
            <div className="flex-grow-1">Jumlah Produk</div>
            <div className="d-flex flex-grow-2">{data.qty}</div>
          </div>
        </div>
        {/* <div className="grid">
          <div className="w-100 d-flex px-3 font-14 mt-3">
            <div className="flex-grow-1">Diskon</div>
            <div className="d-flex flex-grow-2">{diskonselected}% </div>
          </div>
          <div className="w-100 d-flex px-3 font-14 mt-3">
            <div className="flex-grow-1">Kembalian</div>
            <div className="d-flex flex-grow-2">
              Rp {commafy(nominal - totalakhir)}
            </div>
          </div>
        </div> */}
        <div className="row mt-5">
          <div className="col">
            <div
              className="btn btn-white border w-100"
              onClick={() =>
                history.push({
                  pathname: "/struk-inventory",
                  data: data,
                  product: product,
                  supplier: supplier,
                  date: date,
                  source: "Inventory",
                })
              }
            >
              Cetak Struk
            </div>
          </div>
          <div className="col">
            <div
              className="btn btn-white border w-100"
              // onClick={() => handleClick()}
            >
              Kirim Struk
            </div>
          </div>
        </div>
      </div>
      <div className="container px-0 py-4 text-center">
        <div className="d-grid">
          <button
            type="button"
            className="btn btn-primary btn-lg d-block text-white me-4 mx-4"
            onClick={() => history.push("/transaksi-inventory")}
          >
            Transaksi Baru
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TransaksiSukses;
