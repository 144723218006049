import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { NotificationManager } from "react-notifications";

const UpdateCashAccount = () => {
  const { api, setSpinner } = useContext(AppContext);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const [data, setData] = useState({});
  const [id, setId] = useState("");
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  useEffect(() => {
    if (!history.location.data) history.push("/cash-account");
    else {
      setId(history.location.data.id);
      setData(history.location.data);
    }
  }, [history.location]);

  const onSubmit = async (params) => {
    console.log(params);
    setLoading(true);
    const formData = new FormData();
    formData.append("id", id);
    formData.append("name", params.name);
    formData.append("code", params.code);
    formData.append("parent_id", params.parent_id);

    setSpinner(true);
    let update = await api("post", "cash-account/update", formData);
    setSpinner(false);
    if (update.success) {
      history.push({ pathname: "/cash-account" });
    } else NotificationManager.error(update.message);

    setLoading(false);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/cash-account")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Update Cash Account</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <form onSubmit={handleSubmit(onSubmit)} className="bg-white p-3">
        <Form.Group className="font-14 mt-2">
          <Form.Label>Name</Form.Label>
          <Form.Control
            defaultValue={data.name}
            className="font-14 shadow-none"
            {...register("name", {
              required: "Name is required",
            })}
          />
        </Form.Group>
        <Form.Group className="font-14 mt-2">
          <Form.Label>Code</Form.Label>
          <Form.Control
            defaultValue={data.code}
            className="font-14 shadow-none"
            {...register("code", {
              required: "Code is required",
            })}
          />
        </Form.Group>
        <Form.Group className="font-14 mt-2">
          <Form.Label>Parent Id</Form.Label>
          <Form.Control
            defaultValue={data.parent_id}
            className="font-14 shadow-none"
            {...register("parent_id", {
              required: "Parent Id is required",
            })}
          />
        </Form.Group>

        <Form.Group className="font-14 mt-4 w-100">
          <Button
            variant="primary"
            className="btn sticky-bottom btn-primary  w-100 text-white font-14"
            type="submit"
          >
            Update
          </Button>
        </Form.Group>
      </form>
    </React.Fragment>
  );
};

export default UpdateCashAccount;
