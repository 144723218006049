import React, { useContext, useEffect, useState } from "react";
import './Profile.css'
import {Col, Row } from "react-bootstrap";
import back from "../../../assets/images/back.png"
import { AppContext } from "../../../context/AppContext";
import { showImage } from "../../../utils/Utils";
import { useHistory } from "react-router-dom";

const FotoProfile = () => {
    const history = useHistory();
    const { api } = useContext(AppContext);
    const [data, setData] = useState([]);
    const [file, setFile] = useState();


    useEffect(() => {
        getData();
       
    }, []);

    const getData = async () => {
        let data = await api("get", "user/profile");
        setData(data.data); 
    };

    function handleChange(e) {
        console.log(e.target.file[0]);
        setFile(URL.createObjectURL(e.target.files[0]));
    }

    const handleFileSelected = async(event) =>{  
        const formData = new FormData() 
        formData.append('img', event.target.files[0])   
        
        const result = await api('post', 'user/update-user-photo', formData)
  
    }

    return (
      <React.Fragment>
        <nav className="navbar bg-light shadow-sm cursor-pointer">
          <div className="contain p-2 px-cst1 pb-2 ps-4">
            <Row>
              <Col className="align-items-center d-flex">
                <div className="d-inline-block pe-2">
                  <img
                    src="img/arrow-left.svg"
                    alt=""
                    width="30"
                    height="24"
                    onClick={() => history.push("/pengaturan-profile")}
                    className="d-inline-block align-text-top "
                  />
                </div>

                <div className="d-inline-block ml-2">
                  <span className="ml-3">Ubah Foto Profile</span>
                </div>
              </Col>
            </Row>
          </div>
        </nav>
        <div className="container p-4 w-100">
          <div className="row justify-content-center">
            <img
              src={showImage(data.img)}
              style={{ width: "150px" }}
              className="float-left"
            />
            <div className="mt-3">
              
              <form onChange={(e) => handleFileSelected(e)}>
                <input type="file" className="form form-control"/>
                <div className="w-100 mt-3">
                  <button type="submit" className="btn btn-primary text-16 text-white w-100">
                    Ubah
                  </button>
                </div>
              </form>
              <img className="w-25" src={file} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
}
export default FotoProfile;