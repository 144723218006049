import React, { useContext, useEffect, useState, useRef } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import ImgBg from "../../assets/images/bg-top.svg";
import { useHistory } from "react-router-dom";
import { showImage, commafy } from "../../utils/Utils";
import { AppContext } from "../../context/AppContext";
import UserProses from "./User-proses";
import UserSelesai from "./User-selesai";
import UserBatalkan from "./User-batalkan";
import UserDikirim from "./User-dikirim"

const PesananSaya = () => {
  const { api } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [keyword, setKeyword] = useState({});
  const history = useHistory();
  const refInput = useRef();
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
  }, []);
  const changeInput = () => {
    let value = refInput.current.value;
    let key = {
      source: "Nara Store",
      status: "Proses",
      no: value,
    };

    setKeyword(key);
  };
  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm cursor-pointer w-100">
        <div className="p-2 pb-2 ps-4 w-100 d-flex align-items-center">
          <div className="d-inline-block">
            <img
              src="img/arrow-left.svg"
              alt=""
              width="30"
              height="24"
              onClick={() => history.push("/grosir-home")}
              className="d-inline-block align-text-top "
            />
          </div>

          <div className="d-inline-block ms-2" style={{ width: "90%" }}>
            <form className="w-100">
              <div className="input-group w-100">
                <input
                  type="text"
                  className="form-control form-control-lg font-16"
                  placeholder="Cari pesanan"
                  ref={refInput}
                  aria-describedby="basic-addon2"
                  onChange={() => changeInput()}
                />
                <button className="input-group-text bg-white border">
                  <i className="bi bi-search"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="grid riwayat w-100">
          <div className="content bg-light">
            <ul
              className="nav nav-pills nav-fill mb-3"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active py-3"
                  id="pills-userproses-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-userproses"
                  type="button"
                  role="tab"
                  aria-controls="pills-userproses"
                  aria-selected="true"
                >
                  Proses
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link  py-3"
                  id="pills-userdikirim-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-userdikirim"
                  type="button"
                  role="tab"
                  aria-controls="pills-userdikirim"
                  aria-selected="true"
                >
                  Dikirim
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link py-3"
                  id="pills-userselesai-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-userselesai"
                  type="button"
                  role="tab"
                  aria-controls="pills-userselesai"
                  aria-selected="false"
                >
                  Selesai
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link py-3"
                  id="pills-userbatalkan-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-userbatalkan"
                  type="button"
                  role="tab"
                  aria-controls="pills-userbatalkan"
                  aria-selected="false"
                >
                  Dibatalkan
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="tab-content min-vh-100" id="pills-tabContent">
        <UserProses keyword={keyword} />
        <UserDikirim />
        <UserBatalkan />
        <UserSelesai />
      </div>
    </React.Fragment>
  );
};

export default PesananSaya;