import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import imgtiga from "../../assets/images/titiktiga.svg";
import cewek1 from "../../assets/images/cewek1.svg";
import tambah from "../../assets/images/tambah.svg";
import diskonfoto from "../../assets/images/diskonfoto.svg";
import nama from "../../assets/images/nama-user.svg";
import { useForm } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { ErrorMessage } from "@hookform/error-message";

const UpdatePegawai = () => {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const { api } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [dataPegawai, setDataPegawai] = useState({});
  const [role, setRole] = useState([]);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  // const { api } = useContext(AppContext);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!history.location.data){
        return history.push("/data-pegawai");
    }
    setValue("role", role.name, {
      shouldValidate: false,
    });
    setDataPegawai(history.location.data);
    setId(history.location.data.id);
  }, [history]);

  console.log("dataPegawai", dataPegawai);

  const onSubmit = async (params) => {
    console.log(params);
    const formData = new FormData();
    formData.append("user_id", id);
    formData.append("role_id", params.id);

    let UpdatePegawai = await api("post", "user/update-pegawai", formData);

    if (UpdatePegawai.success) {
      history.push({ pathname: "/data-pegawai" });
    } else NotificationManager.error(UpdatePegawai.message);

    setLoading(false);
  };

  const getData = async () => {
    let role = await api("get", "roles/get");
    if (role.success) {
      setRole(role.data);
    }
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/detail-pegawai")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Ubah Pegawai</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="font-14 px-3 py-3">
          <Form.Label>Role</Form.Label>
          <Form.Select
            className="font-14 shadow-none"
            defaultValue={role.name}
            {...register("id", {
              required: "Role is required",
            })}
          >
            {role.map((item, key) => {
              return (
                <option
                  key={key}
                  value={item.id}
                  selected={item.id === role.name ? true : false}
                >
                  {item.name}
                </option>
              );
            })}
          </Form.Select>
            
        <div className="d-flex mt-2">
          <Form.Check
          type="switch"
          id="custom-switch"
          label="Active"
          defaultChecked = {dataPegawai.is_active ? true : false}
        />
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="is_active" />
        </div>

        <Form.Check
          type="switch"
          className="ms-3"
          id="custom-switch"
          label="Verified"
          defaultChecked = {dataPegawai.is_verified ? true : false}
        />
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="is_verified" />
        </div>

        </div>
        </Form.Group>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <Form.Group className="font-14 mt-2 p-3 w-100">
          <Button
            variant="primary"
            className="btn sticky-bottom btn-primary  w-100 text-white font-14"
            type="submit"
          >
            Simpan
          </Button>
        </Form.Group>
      </form>
    </React.Fragment>
  );
};

export default UpdatePegawai;
