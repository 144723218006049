import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext"; 
import { NotificationManager } from "react-notifications";
import ReactStars from "react-stars";
import { showImage } from "../../utils/Utils";

const BeriPenilaian = () => { 
  const history = useHistory();  
  const [detailTransaction, setDetailTransaction] = useState([]);
  const { api , setSpinner} = useContext(AppContext);
  let inputRating = useRef([])
  let ratingValue = useRef([])

  useEffect(() => {
    if (!history.location.product) history.push("/pesanan-saya");
    else {   
      console.log(history.location.product.transactions_details)
      setDetailTransaction(history.location.product.transactions_details);
    }
  }, [history]);
 
   

  const handleSend = async (item, key) => {
    let review = inputRating.current[key]
    let rating = ratingValue.current[key]
    let params = {
      product_id : item.cart_grosir.products_grosir.id,
      feedback : review.value,
      rating : rating.state.value
    } 
    if(params.rating === 0){
      NotificationManager.error("Rating harus di isi.")
      return
    }
    if(params.feedback === ""){
      NotificationManager.error("Feedback harus di isi.")
      return
    }

    setSpinner(true)
    let nilai = await api("post", "feedback-grosir/post", params);
    if(nilai.success){
      NotificationManager.success("Kirim penilaian berhasil.")
      history.push("/pesanan-saya")
    }
    setSpinner(false)
    
  }
 

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm cursor-pointer ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-2">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/pesanan-saya")}
                  className="d-inline-block align-text-top "
                />
              </div>

              <div className="d-inline-block ml-2">
                <span className="ml-3">Penilaian Produk</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="container px-4 mt-4">
        <div className="row">
          <div className="col-mb-12"> 
              {detailTransaction.map((item, key) => { 
                return (
                  <div className="card mb-3 " key={key}>
                    <div href="#" className="card-body ">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 p-3 rounded bg-lime">
                          <img
                            src={showImage(
                              item.cart_grosir.products_grosir.img
                            )}
                            width="80" 
                          />
                        </div>
                        <div className="flex-grow-1 ms-3 mt-3 ">
                          <h5 className="mb-1 fs-6 mx-3">
                            {item.cart_grosir.products_grosir.name}
                          </h5>

                          <div className="d-flex mx-3">
                            <ReactStars
                              count={5}
                              size={18}
                              value={
                                item.cart_grosir.feedback_grosir &&
                                item.cart_grosir.feedback_grosir.rating
                              }
                              ref={(ref) => (ratingValue.current[key] = ref)}
                              half={false}
                            />
                          </div>
                          <div className="card-body">
                            <input
                              ref={(e) => (inputRating.current[key] = e)}
                              className="form-control w-100"
                              rows="3"
                              defaultValue={
                                item.cart_grosir.feedback_grosir &&
                                item.cart_grosir.feedback_grosir.feedback
                              }
                              placeholder=""
                            />
                            <Button
                              variant="primary"
                              onClick={() => handleSend(item, key)}
                              className="btn  btn-primary btn-lg d-block text-white mt-2 float-end font-14"
                              type="submit"
                            >
                              Kirim
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
           
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BeriPenilaian;