import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import ImgBg from "../../assets/images/bg-top.svg";
import { useHistory } from "react-router-dom";
import { showImage } from "../../utils/Utils";
import { commafy } from "../../utils/Utils";
import { AppContext } from "../../context/AppContext";
import { useForm } from "react-hook-form";
import luwak from "../../assets/images/background1.jpg";

import { NotificationManager } from "react-notifications";

const TambahJumlahInventori = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const { api } = useContext(AppContext);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory({});
  const [data, setData] = useState([]);
  const [cart, setCart] = useState([]);
  const [id, setId] = useState([]);
  const [local, setLocal] = useState(null);
  const [num, setNum] = useState(1);
  const [total, setTotal] = useState(0);
  const [name, setName] = useState("");
  const [img, setImg] = useState("");
  const [price, setPrice] = useState("");
  useEffect(() => {
    // if (!history.location.cart) history.push("/tambah-jumlah");
    // else {
    //   setId(history.location.cart.id);
    //   setCart(history.location.cart);
    //   setName(history.location.cart.products_grosir.name);
    //   setPrice(history.location.cart.products_grosir.price);
    //   setImg(history.location.cart.products_grosir.img);
    // }
  }, [history]);

  const incNum = () => {
    setNum(num + 1);
  };

  const decNum = () => {
    if (num > 1) {
      setNum(num - 1);
    } else {
      setNum(1);
    }
  };

  //   const onSubmit = async (params) => {
  //     const formData = new FormData();
  //     formData.append("id", id);
  //     formData.append("qty", params.qty);
  //     let TambahJumlah = await api("post", "cart-grosir/update", formData);
  //     if (TambahJumlah.success) {
  //       history.push({ pathname: "/order-pos" });
  //     } else NotificationManager.error(TambahJumlah.message);

  //     setLoading(false);
  //   };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-2">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/transaksi-inv")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>

              <div className="d-inline-block ml-2">
                <span className="ml-3">Luwak White coffee</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="container px-4 mt-4">
        <div className="row gx-8">
          <div className="col text-decoration-none">
            <a
              href="#"
              className="btn btn-outline-primary btn-lg border-secondary d-block"
            >
              Barang Masuk
            </a>
          </div>
          <div className="col text-decoration-none">
            <a
              href="#"
              className="btn btn-outline-primary btn-lg border-secondary d-block"
            >
              Barang Keluar
            </a>
          </div>
        </div>  
        <div className="row mt-3">
          <div className="col-mb-12">
            <div className="card mb-3 border-primary">
              <a href="#" className="card-body ">
                <div className="d-flex align-items-center">
                  <div
                    className="flex-shrink-0 p-3 rounded-3"
                    // style={{ background: rgbs(0, 198, 200, 0.3) }}
                  >
                    <img
                      src={luwak}
                      width="80"
                      class=""
                      style={{ minWidth: "100px" }}
                    />
                  </div>
                  <div className="flex-grow-1 ms-3 mt-3 ">
                    <div className="d-flex ">
                      <h6 className="mb-0 text-dark date-time">
                        Luwak White Coffee
                      </h6>
                    </div>
                    <div className="d-flex">
                      <h6 className="mb-0 align-middle text-primary mt-2 ">
                        Rp 100.000
                      </h6>
                      <div
                        className="input-group ms-auto mb-0 "
                        style={{ width: "126px" }}
                      >
                        <button
                          className="col btn border rounded-left   "
                          type="submit"
                          onClick={(dec) => decNum(-1)}
                        >
                          -
                        </button>
                        <small className="col btn border ">{num}</small>
                        <button
                          className="col btn border rounded-right "
                          type="submit"
                          onClick={(inc) => incNum(+1)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="g-col-6 content  pb-2">
        <div class="py-3 px-4 ms-1">
          <textarea
            class="form-control pt-3 ps-4"
            id="exampleFormControlTextarea1"
            placeholder="Catatan"
            rows="3"
            style={{ backgroundColor: "#F9F9F9" }}
          />
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="container sticky-bottom px-4 mt-4">
        <div className=" bg-white mt-5 ">
          <div className="row mt-5">
            <div className="col">
              <Button
                variant="white"
                className="btn sticky-bottom border btn-white btn-lg d-block text-dark me-4 w-100"
                disabled={isLoading}
                type="submit"
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Hapus Pesanan"
                )}
              </Button>
            </div>
            <div className="col">
              <Button
                variant="primary"
                className="btn sticky-bottom btn-primary btn-lg d-block text-white me-4 w-100"
                disabled={isLoading}
                type="submit"
                onClick={() => history.push("/transaksi-inv")}
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Simpan"
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <br />
    </React.Fragment>
  );
};

export default TambahJumlahInventori;
