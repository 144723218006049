import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Nama from "../../assets/images/nama-user.svg";
import toko from "../../assets/images/toko-user.svg";
import emailicon from "../../assets/images/email-user.svg";
import alamat from "../../assets/images/alamatpenerima.svg";
import telp from "../../assets/images/telp-user.svg";
import norek from "../../assets/images/rekening-user.svg";

const DetailCashAccount = () => {
  const { api } = useContext(AppContext);
  const history = useHistory();
  const [data, setData] = useState("");
  const [id, setId] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!history.location.data) history.push("/cash-account");
    else {
      setId(history.location.data.id);
      setData(history.location.data);
    }
  }, [history.location]);

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-2">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/cash-account")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>

              <div className="d-inline-block ml-2">
                <span className="ml-3">Detail Cash Account</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="container px-4 mt-4">
        <div className="row">
          <div className="col-mb-12">
            <div className="card mb-3 border-0 bg-li">
              <div className="card-body">
                <div className="gird">
                  <div className="d-flex align-items-center">
                    <div className="d-flex flex-row flex-shrink-0">
                      <div className="ms-3">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <img
                              src={Nama}
                              className="mx-3"
                              width="20"
                              alt=""
                            />
                          </div>
                          <div className="w-100 px-3 font-14 mt-3">
                            <div className="flex-grow-1">
                              <small className="text-secondary">
                                Name
                              </small>
                              <p>{data.name} </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex-grow-2 ms-3"></div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="d-flex flex-row flex-shrink-0">
                      <div className="ms-3">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <img
                              src={toko}
                              className="mx-3"
                              width="20"
                              alt=""
                            />
                          </div>
                          <div className="w-100 px-3 font-14 mt-3">
                            <div className="flex-grow-1">
                              <small className="text-secondary">
                                Code
                              </small>
                              <p>{data.code} </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex-grow-2 ms-3"></div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="d-flex flex-row flex-shrink-0">
                      <div className="ms-3">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <img
                              src={emailicon}
                              className="mx-3"
                              width="20"
                              alt=""
                            />
                          </div>
                          <div className="w-100 px-3 font-14 mt-3">
                            <div className="flex-grow-1">
                              <small className="text-secondary">Parent Id</small>
                              <p>{data.parent_id} </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex-grow-2 ms-3"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-grid py-4 sticky-bottom">
              <Button
                variant="primary"
                className="mt-2 btn btn-primary btn-lg d-block text-white w-100 font-14"
                disabled={isLoading}
                type="submit"
                onClick={() =>
                  history.push({
                    pathname: "/update-cash-account",
                    data: data,
                  })
                }
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Ubah Data"
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DetailCashAccount;
