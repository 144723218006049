import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import { showImage } from "../../utils/Utils";
import { useHistory } from "react-router-dom";

const KelolaData = () => {
  const history = useHistory();
  const { api, setSpinner } = useContext(AppContext);
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    getMenu();
  }, []);

  const getMenu = async () => {
    setSpinner(true);
    let menu = await api("get", "menu/get-kelola-data-pos");
    setMenu(menu.data);
    setSpinner(false);
  };
  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-2">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/dashboard-pos")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ml-2">
                <span className="ml-3">Kelola Data</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="g-col-6  px-cst1 pb-5 list-denom mt-3">
        <Row>
          {menu.map((item, key) => {
            return (
              <Col md={6} className="py-2" key={key}>
                <div
                  className="card cursor-pointer"
                  onClick={() =>
                    history.push({
                      pathname: item.url,
                      param: "kelola-data",
                    })
                  }
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 py-2 ">
                        <img
                          src={showImage(item.img)}
                          width="70"
                          alt=""
                          className="dashboardfoto"
                        />
                      </div>
                      <div className="flex-grow-1 ms-3 pt-2">
                        <h6 className="text-dark mb-0">{item.name}</h6>
                        <small className="">{item.description}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </React.Fragment>
  );
};

export default KelolaData;
