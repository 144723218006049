import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { commafy, showImage } from "../../utils/Utils";
import { Col, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Button from "react-bootstrap/esm/Button";
import _ from "lodash";
import NotificationManager from "react-notifications/lib/NotificationManager";

const PembayaranPosMp = () => {
  const { api } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [pelanggan, setPelanggan] = useState([]);
  const [idPelanggan, setIdPelanggan] = useState();
  const [profile, setProfile] = useState([]);
  const [order, setOrder] = useState("");
  const history = useHistory();
  const refInput = useRef();
  const [idAll, setIdAll] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [totalakhir, setTotalakhir] = useState(0);
  const [diskonselected, setDiskonselected] = useState(0);
  const [diskonname, setDiskonname] = useState();
  const [price, setPrice] = useState("");
  const [totalDiskon, setTotalDiskon] = useState();
  const [qty, setQty] = useState();
  const [totalMargin, setTotalMargin] = useState();
  const [selectedNominal, setSelectedNominal] = useState("");
  const [selectedBank, setSelectedBank] = useState("");
  const [orderedFrom, setOrderedFrom] = useState("");
  const [statusPembayaran, setStatusPembayaran] = useState("");
  const [biaya, setBiaya] = useState({});
  const [nominal, setNominal] = useState([
    { nominal: 10000, selected: false },
    { nominal: 20000, selected: false },
    { nominal: 25000, selected: false },
    { nominal: 30000, selected: false },
    { nominal: 50000, selected: false },
    { nominal: 60000, selected: false },
    { nominal: 70000, selected: false },
    { nominal: 80000, selected: false },
    { nominal: 100000, selected: false },
  ]);

  useEffect(() => {
    setTotalakhir(history.location.totalakhir);
    setDiskonname(history.location.diskonname);
    setDiskonselected(history.location.diskonselected);
    setPelanggan(history.location.pelanggan);
    setProfile(history.location.profile);
    setOrder(history.location.order);
    setTotal(history.location.total);
    setPrice(history.location.price);
    setIdPelanggan(history.location.idPelanggan);
    setIdAll(history.location.idAll);
    setTotalDiskon(history.location.totalDiskon);
    setQty(history.location.qty);
    setTotalMargin(history.location.totalMargin);
    setOrderedFrom(history.location.orderedFrom);
    setStatusPembayaran(history.location.statusPembayaran);
    setBiaya(history.location.biaya);
  }, [history.location]);

  const handleClickNominal = (param) => {
    let data = _.map(nominal, (item) => {
      return item.nominal === param.nominal
        ? { ...param, selected: true }
        : { ...item, selected: false };
    });
    setSelectedNominal(param.nominal);
    setNominal(data);
    refInput.current.value = param.nominal;
  };

  const handleClickBank = (param) => {
    let dataTmp = _.map(data, (item) => {
      return item.bank === param.bank
        ? { ...param, selected: true }
        : { ...item, selected: false };
    });
    setSelectedBank(param.bank);
    setData(dataTmp);
  };

  const handleClick = async () => {

    if (parseInt(price) > parseInt(refInput.current.value)) return NotificationManager.error("Uang Tidak Cukup.")

    setLoading(true);
    let param = {
      source: "POS",
      change: refInput.current.value - totalakhir,
      cash: refInput.current.value,
      price: price,
      total: total,
      product_id: 0,
      total_margin: totalMargin,
      discount: (total * diskonselected) / 100,
      qty: qty,
      gudang_id: "10",
      delivery_type: "Ambil Sendiri",
      gudang_distance: "10",
      buyer_id: idPelanggan,
      ongkir: "gratis",
      fee: 0,
      recipient_address: "Kasir Grosir",
      id_all: idAll.toString(),
      total_discount: totalDiskon,
      payment_method: "COD",
      ordered_from: orderedFrom,
      status_pembayaran: statusPembayaran,
      biaya: JSON.stringify(biaya),
    };
    // setSpinner(true);
    let trx = await api("post", "transactions-grosir/transaction-marketplace", param);
    if (trx.success) {
      history.push({
        nominal: refInput.current.value,
        pathname: "/sukses-pembayaran",
        totalakhir: totalakhir,
        diskonname: diskonname,
        diskonselected: diskonselected,
        order: order,
        pelanggan: pelanggan,
        profile: profile,
        total: total,
        idPelanggan: idPelanggan,
        idAll:idAll,
        price: price,
        totalDiskon: totalDiskon,
        qty: qty,
        totalMargin: totalMargin,
        idtrx: trx.data[0].no,
        orderedFrom: orderedFrom,
        statusPembayaran: statusPembayaran,
      });
    }

    // setSpinner(false);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-2">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/pesanan-pos-marketplace")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>

              <div className="d-inline-block ml-2">
                <span className="ml-5">Pembayaran</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="g-col-6 content bg-light py-4 min-vh-100 px-4">
        <div className="input-group">
          <input
            type="number"
            className="form-control form-control-lg font-14"
            placeholder="Jumlah"
            ref={refInput}
            required
            aria-describedby="basic-addon2"
          />
        </div>

        <div className="g-col-6 bg-ligh pb-2 list-denom mt-4">
          <Row className="">
            {nominal.map((item, key) => {
              return (
                <div key={key}>
                  <div className="col-md-6 mt-3">
                    <div
                      key={key}
                      className="d-inline-blok pe-2 w-auto text-nowrap "
                      onClick={() => handleClickNominal(item)}
                    >
                      <div
                        className={
                          item.selected
                            ? "card py-2 cursor-pointer bg-primary text-white px-2 text-center"
                            : "card py-2 cursor-pointer text-dark px-2 text-center"
                        }
                        style={{ minWidth: "80px" }}
                      >
                        <span className="font-12">{commafy(item.nominal)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Row>
        </div>
      </div>
      <div className="sticky-bottom bg-white border-top mt-5 ">
        <div className=" py-2 px-0  d-flex">
          <div className="px-4 py-2 flex-grow-1">
            <div>
              <span>Total Bayar</span>
            </div>
            <div className="mt-1 text-primary ">
              <div
                data-bs-toggle="offcanvas"
                data-bs-target="#rincian"
                aria-controls="rincian"
              >
                <span>Rp {commafy(totalakhir)}</span>
              </div>
            </div>
          </div>
          <div className="px-4 py-2 flex-grow-1">
            <Button
              variant="primary"
              className="button w-100 text-white  btn btn-primary font-14"
              disabled={isLoading}
              type="submit"
              onClick={() => handleClick()}
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Bayar"
              )}
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PembayaranPosMp;
