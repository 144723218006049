import React, { useContext, useEffect, useRef, useState } from "react";
import {Button, Col, Row, Spinner, Offcanvas } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import imgLokasi from "../../../assets/images/lokasi.svg"; 
import dus from "../../../assets/images/dus.svg"; 
import rincian from "../../../assets/images/rincian.svg" 
import { commafy, showImage } from "../../../utils/Utils"; 

const CheckOut = (...props) => {
  const history = useHistory();
  const { api, setSpinner } = useContext(AppContext);
  const [isLoading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [profile, setProfile] = useState({});
  const [idAll, setIdAll] = useState("");
  const [total, setTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [pengiriman, setPengiriman] = useState("Diantar kurir toko");
  const [data, setData] = useState([]); 
  const [ori, setOri] = useState([]); 
  const [ongkir, setOngkir] = useState({});
  const [isDesc, setIsDesc] = useState(false);
  const isFirstRun = useRef(true); 

  useEffect(() => {
    // if (isFirstRun.current) {
    //   isFirstRun.current = false;
    //   return;
    // }
    if (!history.location.data) history.push("/keranjang");
    else {
        let localData = localStorage.getItem("useranarastore");
        if (localData !== null) {
            setProfile(JSON.parse(localData));
        } 
        let tmpData = history.location.data 
        setIsDesc(history.location.isDesc)
        setOri(history.location.ori ?history.location.ori : [])
        setData(tmpData); 
    }
  
  }, [history.location.data]);

  useEffect(() => {
    if (!data.length) return;
    getData();

  }, [data]);

  const getData = async () => {
    setSpinner(true)
    let ongkir = await api("get", "data-ongkir/get-byname", {
        name: "Kurir Toko",
        lat: data[0].products_grosir.gudang.lat,
        lng: data[0].products_grosir.gudang.lng,
      });
      if(ongkir.success){
        let ongkirBiaya = 0;
        if (ongkir.data[0].amount === "Gratis") ongkirBiaya = 0;
        else ongkirBiaya = ongkir.data[0].amount;
        let total = 0,
          subTotal = 0,
          id ="", separator = "";
        data.map((item) => {
            id = id + separator + item.id
            separator = ","
          subTotal =
            subTotal +
            parseInt(item.qty) * (parseInt(item.price));
        });
        total = subTotal + ongkirBiaya + parseInt(ongkir.data[0].fee);
        setSubTotal(subTotal);
        setTotal(total); 
        setIdAll(id)
        setOngkir(ongkir.data[0]);
      }
      setSpinner(false);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm cursor-pointer">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-2">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() =>
                    history.push({
                      pathname: isDesc ? "/deskripsi-produk" : "/keranjang",
                      product: ori[0],
                    })
                  }
                  className="d-inline-block align-text-top "
                />
              </div>

              <div className="d-inline-block ml-2">
                <span className="ml-3">Checkout</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="row m-0 p-3" style={{ backgroundColor: "#DCF4F4" }}>
        <span className="text-dark">Alamat Pengiriman</span>
        <div className="d-flex align-items-center mt-2">
          <div className="mb-0 pt-1"></div>
          <div className="flex-shrink-0 ">
            <img src={imgLokasi} width="70" alt="" />
          </div>
          <div className="flex-grow-1 ms-3">
            <div className="mt-1">
              <span className="text-dark font-16">
                {profile.first_name} | {profile.phone}
              </span>
            </div>

            <p className="text-dark mb-0 pt-1 font-12">{profile.address}</p>
          </div>
          <div className="flex-grow-2 ms-3"></div>
        </div>
      </div>

      <div className="container border-0 px-4">
        {data.map((item, key) => {
          return (
            <div
              className="card-body border rounded bg-white p-3 mt-4"
              key={key}
            >
              <div className="d-flex align-items-center">
                <div className="d-flex flex-row flex-shrink-0 justify-content-center align-items-center">
                  <div
                    className="d-flex flex-shrink-0 p-2 rounded-3 bg-lime "
                    style={{ minHeight: "80px" }}
                  >
                    <img
                      src={showImage(item.products_grosir.img)}
                      width="50"
                      className=""
                      style={{ minWidth: "80px" }}
                    />
                  </div>
                  <div className="ms-3">
                    <div className="row">
                      <div className="d-flex align-items-center mt-2">
                        <h5 className="text-dark fs-6">
                          {item.products_grosir.name}
                        </h5>
                      </div>
                    </div>
                    <span className="text-primary mb-0 font-16">
                      Rp{" "}
                      {commafy(item.price)}
                    </span>
                    <small className="ms-2 text-secondary font-13">
                      <del> Rp {commafy(item.price_before)}</del>
                    </small>
                    <p className="text-dark mb-0 text-muted">x{item.qty}</p>
                  </div>
                </div>
                <div className="flex-grow-2 ms-3"></div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="container border-0 px-4 mt-4">
        <div className="card-body border rounded mt-3 bg-white">
          <br />
          <div className="d-flex align-items-center flex-grow-1 ms-1">
            <div className="flex-shrink-0">
              <img src={dus} width="25" alt="" className="ms-3" />
            </div>

            <div className="flex-grow-1 w-50 ms-3 flex-fill">
              <span className="d-inline-block ml-2 text-bold font-16">
                Pengiriman
              </span>
            </div>

            <div
              className="w-100 me-2 cursor-pointer"
              onClick={() => setShow(true)}
            >
              <div className="text-muted mb-0 float-end">
                <span className="text-dark mb-0 font-13">Ganti Pengiriman</span>
              </div>
            </div>
            <div className="flex-grow-1 flex-fill">
              <img src="img/next.svg" className="me-3" />
            </div>
          </div>
          <hr className="mt-4 hr-product " />
          <div className="align-items-center">
            <div className="">
              <div className="ms-3">
                <div className="row">
                  <div className="d-inline-block ml-2 w-100">
                    <span className="font-16 text-dark">{pengiriman}</span>
                    <span className="font-16 float-end text-warning me-4">
                      {ongkir.amount}
                    </span>
                    <div>
                      <img src={"img/ic_loc.svg"} className="" width="10" />
                      <span className="mb-0 align-middle ms-2">
                        {data.length &&
                          data[0].products_grosir.gudang.name +
                            " " +
                            data[0].distance.distance +
                            " " +
                            data[0].distance.unit}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-grow-2 ms-3"></div>
          </div>
          <br />
        </div>
      </div>

      <div className="container border-0 px-4 mt-4">
        <div className="card-body border rounded mt-3 bg-white">
          <br />
          <div className="d-flex align-items-center">
            <div className="d-flex flex-row flex-shrink-0">
              <img src={rincian} className="ms-3" width="25" alt="" />
              <div className="ms-3">
                <div className="gird">
                  <div className="d-flex align-items-center mt-2">
                    <span className="d-inline-block ml-2 text-bold font-16">
                      Rincian
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-grow-2 ms-3"></div>
          </div>
          <hr className="mt-4 hr-product " />
          <div className="grid">
            <div className="w-100 d-flex px-3 font-14 mt-3">
              <div className="flex-grow-1">Subtotal Produk</div>
              <div className="d-flex flex-grow-2">Rp {commafy(subTotal)}</div>
            </div>
            <div className="w-100 d-flex px-3 font-14 mt-3">
              <div className="flex-grow-1">Subtotal Pengiriman</div>
              <div className="d-flex flex-grow-2">
                Rp {commafy(ongkir.amount === "Gratis" ? 0 : ongkir.amount)}
              </div>
            </div>
            <div className="w-100 d-flex px-3 font-14 mt-3">
              <div className="flex-grow-1">Biaya Penanganan</div>
              <div className="d-flex flex-grow-2 ">
                {" "}
                Rp {commafy(ongkir.fee)}
              </div>
            </div>
            <div className="w-100 d-flex px-3 font-14 my-3">
              <div className="flex-grow-1">Total Pembayaran</div>
              <div className="d-flex flex-grow-2 text-warning ">
                Rp {commafy(total)}
              </div>
            </div>
          </div>
          <div className="flex-grow-2 ms-3"></div>
          <br />
        </div>
      </div>

      <br />

      <div className="row mb-2 g-2 g-lg-3 m-0">
        <div className="border-top py-2 px-0  d-flex  bg-white">
          <div className="px-4 py-2 flex-grow-1">
            <div>
              <span>Total Bayar</span>
            </div>
            <div className="mt-1 text-primary ">
              <div
                data-bs-toggle="offcanvas"
                data-bs-target="#rincian"
                aria-controls="rincian"
              >
                <span>Rp {commafy(total)}</span>
              </div>
            </div>
          </div>
          <div className="px-4 py-2 w-50">
            <Button
              variant="primary"
              className="button w-100 text-white font-16 btn btn-primary btn-lg"
              disabled={isLoading}
              onClick={() =>
                history.push({
                  pathname: "/pembayaranco",
                  data: Object.assign(data[0], {
                    address: profile.address,
                    price: subTotal,
                    total: total,
                    ongkir: ongkir.amount,
                    fee: ongkir.fee,
                    id_all: idAll,
                    pengiriman: pengiriman,
                  }),
                })
              }
              type="submit"
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Bayar"
              )}
            </Button>
          </div>
        </div>
      </div>

      <div className="justify-content-center sticky-bottom border-top filter">
        <div className="col-md-12">
          <Offcanvas
            show={show}
            placement="bottom"
            onHide={() => setShow(false)}
            {...props}
            className="rounded-top ganti-gudang"
          >
            <Offcanvas.Body>
              <div className="grid">
                <div className="g-col-10 px-2 py-2 bg-white">
                  <div className="container px-0 py-3 category">
                    <div className="row gx-8">
                      <p className="mb-2 ">Pilih Pengiriman</p>
                      <div className="d-inline-flex p-2">
                        <div
                          className={
                            pengiriman === "Diantar kurir toko"
                              ? "col btn btn-outline-primary border rounded-2 me-3 bg-primary "
                              : "col btn btn-outline-primary border rounded-2 me-3 "
                          }
                          onClick={() => setPengiriman("Diantar kurir toko")}
                        >
                          <div
                            className={
                              pengiriman === "Diantar kurir toko"
                                ? "text-decoration-none text-white pt-1 d-block"
                                : "text-decoration-none text-dark pt-1 d-block"
                            }
                          >
                            Kurir Toko
                          </div>
                          <center>
                            <small className="d-block pb-1 font-12">
                              <img
                                src={
                                  pengiriman === "Diantar kurir toko"
                                    ? "img/wallet-white.svg"
                                    : "img/wallet-grey.svg"
                                }
                                width="20"
                                height="24"
                                className="ms-2"
                              />
                              <span
                                className={
                                  pengiriman === "Diantar kurir toko"
                                    ? "ms-1 text-white"
                                    : "ms-1 text-dark"
                                }
                              >
                                {ongkir.amount}
                              </span>
                            </small>
                          </center>
                        </div>
                        <div
                          className={
                            pengiriman === "Diantar kurir toko"
                              ? "col btn btn-outline-primary border rounded-2 me-3 "
                              : "col btn btn-outline-primary border rounded-2 me-3 bg-primary "
                          }
                          onClick={() => setPengiriman("Ambil Sendiri")}
                        >
                          <div
                            className={
                              pengiriman === "Diantar kurir toko"
                                ? "text-decoration-none text-dark pt-1 d-block"
                                : "text-decoration-none text-white pt-1 d-block"
                            }
                          >
                            Ambil sendiri{" "}
                          </div>
                          <center>
                            <small className="d-block pb-1 font-12">
                              <img
                                src={
                                  pengiriman === "Diantar kurir toko"
                                    ? "img/wallet-grey.svg"
                                    : "img/wallet-white.svg"
                                }
                                width="20"
                                height="24"
                                className="ms-2"
                              />
                              <span
                                className={
                                  pengiriman === "Diantar kurir toko"
                                    ? "ms-1 text-dark"
                                    : "ms-1 text-white"
                                }
                              >
                                Gratis
                              </span>
                            </small>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container px-0 category">
                    <div className="row gx-8">
                      <p className="mb-2 text-black">
                        *Pengiriman oleh kurir toko akan dikenakan biaya
                        tambahan jika melebihi batas area pengiriman
                      </p>
                    </div>
                  </div>
                  <div className="container px-0 text-center sticky-bottom mt-3">
                    <div className="row gx-8">
                      <div className="d-grid">
                        <button
                          type="button"
                          className="btn btn-primary btn-lg d-block text-white font-16"
                          onClick={() => setShow(false)}
                        >
                          Konfrimasi
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CheckOut;