import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import { showImage } from "../../utils/Utils";
import { useHistory } from "react-router-dom";
import wallet from "../../assets/images/wallet-biru.svg";
import laporan from "../../assets/images/laporan-pos.svg";
import { commafy } from "../../utils/Utils";

const DashboardPos = () => {
  const [data, setData] = useState(); 
  const [local, setLocal] = useState(null);
  const history = useHistory();
  const { api, setSpinner } = useContext(AppContext);
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    let localData = localStorage.getItem("useranarastore");
    if (localData !== null) setLocal(JSON.parse(localData));
    getData();
  }, []);

  useEffect(() => { 
    if(!data) return
    getMenu();
  }, [data]);

  const getData = async () => {
    setSpinner(true)
    let param = {
      source: "POS",
    };
    let data = await api(
      "get",
      "transactions-grosir/summary-penjualan-today",
      param
    );
    setData(data.data); 
    setSpinner(false)
  };

  const getMenu = async () => {
    setSpinner(true);
    let menu = await api("get", "menu/get-for-pos");
    setMenu(menu.data);
    setSpinner(false);
  };

  return (
    <React.Fragment>
      <div className="g-col-6  px-cst1 pb-5 list-denom mt-3">
        <Row>
          {menu.map((item, key) => {
            return (
              <Col md={6} className="py-2" key={key}>
                <div
                  className="card cursor-pointer"
                  onClick={() =>
                    history.push({
                      pathname: item.url,
                    })
                  }
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 py-2 ">
                        <img
                          src={showImage(item.img)}
                          width="70"
                          className="dashboardfoto"
                        />
                      </div>
                      <div className="flex-grow-1 ms-3 pt-2">
                        <h6 className="text-dark mb-0">{item.name}</h6>
                        <small>
                          {item.name === "Penjualan Hari Ini"
                            ? "Rp " + commafy(data.total)
                            : item.description}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </React.Fragment>
  );
};

export default DashboardPos;
