import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Modal, Col, Row, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import hashtag from "../../../assets/images/hashtag.svg";
import truck from "../../../assets/images/truck-fast.svg";
import alamat from "../../../assets/images/alamat.svg";
import paymentIcon from "../../../assets/images/wallet-biru.svg";
import { showImage } from "../../../utils/Utils";
import alarm from "../../../assets/images/icon-info.png";
import sukses from "../../../assets/images/ceklis.png";
import fast from "../../../assets/images/truck-fast2.png";
import { commafy } from "../../../utils/Utils";
import { NotificationManager } from "react-notifications";

const RincianPesananAdmin = () => {
  const history = useHistory();
  const { api, setSpinner } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [kurir, setKurir] = useState([]);
  let refInput = useRef("");
  const [selectedKurir, setSelectedKurir] = useState("Pilih Kurir");
  const [show, setShow] = useState(false);
  const [id, setId] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (!history.location.pesanan) history.push("/pesanan-admin");
    else {
      setData([history.location.pesanan]);
      setId(history.location.pesanan.id);
      getKurir();
    }
    console.log(history.location.pesanan);
  }, [history.location]);

  const getKurir = async () => {
    setSpinner(true);
    let dataKurir = await api("get", "user/get-kurir");
    setKurir(dataKurir.data);
    setSpinner(false);
  };

  const handleBatal = async () => {
    let param = {
      id: id,
    };
    setShow(false);
    setSpinner(true);
    let batal = await api("post", "transactions-grosir/batal", param);
    if (batal.success) {
      NotificationManager.success(batal.message);
      history.push("/pesanan-admin");
    }
    setSpinner(false);
  };

  const handleTerima = async (id) => {
    let param = {
      id: id,
      note: refInput.current.value,
    };

    setSpinner(true);
    let terima = await api("post", "transactions-grosir/terima", param);
    if (terima.success) {
      NotificationManager.success(terima.message);
      history.push("/pesanan-admin");
    }
    setSpinner(false);
  };

  const handleSelesai = async (id) => {
    let param = {
      action: "Selesai",
      id: id,
      note: refInput.current.value,
    };

    setSpinner(true);
    let selesai = await api("post", "transactions-grosir/update-status", param);
    if (selesai.success) {
      NotificationManager.success(selesai.message);
      history.push("/pesanan-admin");
    }
    setSpinner(false);
  };

  const handleKirim = async (id) => {
    if (selectedKurir === "Pilih Kurir")
      return NotificationManager.error("Silahkan pilih kurir");

    let param = {
      action: "Kirim",
      id: id,
      note: refInput.current.value,
      kurir: parseInt(selectedKurir),
    };

    console.log(param);

    setSpinner(true);
    let terima = await api("post", "transactions-grosir/update-status", param);
    if (terima.success) {
      NotificationManager.success(terima.message);
      history.push("/pesanan-admin");
    }
    setSpinner(false);
  };


  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/pesanan-admin")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Rincian Transaksi</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      {data.map((item, key) => {
        return (
          <>
            {item.status === "Dibatalkan" ? (
              <div className="container px-4 mt-4" key={key}>
                <div
                  className="card border-0 bg-warning shadow-sm  mt-4 "
                  style={{ borderRadius: "15px" }}
                >
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 ">
                      <img src={alarm} className="ms-4" width="50" alt="" />
                    </div>
                    <div className="flex-grow-1 ms-3 py-2">
                      <h6 className="text-white mt-3">
                        {item.status_operations}
                      </h6>
                      <p className="text-white">{item.status_desc}</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : item.status === "Selesai" ? (
              <div className="container px-4" key={key}>
                <div
                  className="card border-0 bg-primary shadow-sm  mt-4 mb-2"
                  style={{ borderRadius: "15px" }}
                >
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 ">
                      <img src={sukses} className="ms-4" width="50" alt="" />
                    </div>
                    <div className="flex-grow-1 ms-3 py-2">
                      <h6 className="text-white mt-3">
                        {item.status_operations}
                      </h6>
                      <p className="text-white">{item.status_desc}</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : item.status === "Dikirim" ? (
              <div className="container px-4" key={key}>
                <div
                  className="card border-0 bg-primary shadow-sm  mt-4 mb-2"
                  style={{ borderRadius: "15px" }}
                >
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 ">
                      <img src={fast} className="ms-4" width="50" alt="" />
                    </div>
                    <div className="flex-grow-1 ms-3 py-2">
                      <h6 className="text-white mt-3">
                        {item.status_operations}
                      </h6>
                      <p className="text-white">{item.status_desc}</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}

            <div
              className="card-body border bg-light mx-4 mt-3"
              style={{ borderRadius: "15px" }}
              key={key}
            >
              <div className="d-flex align-items-center">
                <div className="card-body">
                  <div className="d-flex align-items-center flex-grow-1 ms-2 py-2">
                    <div className="flex-shrink-0">
                      <img
                        src={showImage(item.user["img"])}
                        style={{ borderRadius: "50%", height: "50px" }}
                        width="50"
                        className="ms-3 mt-1"
                      />
                    </div>
                    <div className="flex-grow-1 ms-2">
                      <h6 className="mt-3 ">{item.user["first_name"]}</h6>
                    </div>
                    <div className="flex-grow-2 px-4 text-bold text-primary">
                      <span>{item.createdAt}</span>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mt-1 hr-product" />
              <div className="d-flex align-items-center">
                <div className="d-flex flex-row flex-shrink-0">
                  <div className="ms-3">
                    <div className="d-flex align-items-center pt-3">
                      <div className="flex-shrink-0 mb-5">
                        <img src={hashtag} className="mx-3" width="20" alt="" />
                      </div>
                      <div className="flex-grow-1 ms-3 mb-3">
                        <h6 className="text-black">No. Pesanan</h6>
                        <p className="text-black">{item.no}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="d-flex flex-row flex-shrink-0">
                  <div className="ms-3">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 mb-5">
                        <img src={truck} className="mx-3" width="20" alt="" />
                      </div>
                      <div className="flex-grow-1 ms-3 mb-3">
                        <h6 className="text-black">Jasa Pengiriman</h6>
                        <p className="text-black">
                          {item.delivery_type} -{" "}
                          {item.kurir && item.kurir.first_name}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="d-flex flex-row flex-shrink-0">
                  <div className="ms-3">
                    <div className="d-flex align-items-center ">
                      <div className="flex-shrink-0 mb-5">
                        <img src={alamat} className="mx-3" width="20" alt="" />
                      </div>
                      <div className="flex-grow-1 ms-3 mb-3">
                        <h6 className="text-black">Alamat Pengiriman</h6>
                        <p className="text-black">{item.recipient_address}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="d-flex flex-row flex-shrink-0">
                  <div className="ms-3">
                    <div className="d-flex align-items-center ">
                      <div className="flex-shrink-0 mb-5">
                        <img src={paymentIcon} className="mx-3" width="20" alt="" />
                      </div>
                      <div className="flex-grow-1 ms-3 mb-3">
                        <h6 className="text-black">Metode Pembayaran</h6>
                        <p className="text-black">{item.payment_method}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="card-body border bg-light mx-4 mt-3"
              style={{ borderRadius: "15px" }}
            >
              {item.transactions_details.map((detail, key) => {
                return (
                  <div className="d-flex align-items-center m-3" key={key + 1}>
                    <div className="d-flex flex-row flex-shrink-0">
                      <div className="flex-shrink-0 p-3 rounded bg-lime">
                        <img
                          src={showImage(
                            detail.cart_grosir.products_grosir.img
                          )}
                          width="50"
                        />
                      </div>
                      <div className="ms-3">
                        <div className="row">
                          <div className="d-flex align-items-center mt-2">
                            <span className="text-dark font-14">
                              {detail.cart_grosir.products_grosir.name}
                            </span>
                          </div>
                        </div>
                        <span className="text-primary mb-0 font-14">
                          Rp {commafy(detail.cart_grosir.price)}
                        </span>
                        <small className="ms-2 text-secondary font-13"></small>
                        <p className="text-dark mb-0 text-muted">
                          x {detail.cart_grosir.qty}
                        </p>
                      </div>
                    </div>
                    <div className="flex-grow-2 ms-3"></div>
                  </div>
                );
              })}
              <hr className="mt-1 hr-product" />
              <div className="w-100 d-flex  mt-3 px-4 font-14">
                <p className="flex-grow-1 text-dark ">Total Pesanan</p>
                <p className="d-flex px-2 flex-grow-2">
                  Rp {commafy(item.price)}
                </p>
              </div>
              <div className="w-100 d-flex   px-4 font-14">
                <p className="flex-grow-1 text-dark ">Ongkos Pengiriman</p>
                <p className="d-flex px-2 flex-grow-2">
                  Rp {commafy(item.fee)}
                </p>
              </div>
              <div className="w-100 d-flex  px-4 font-14">
                <p className="flex-grow-1 text-dark ">Total Pembayaran</p>
                <p className="d-flex px-2 flex-grow-2 text-warning">
                  Rp {commafy(item.total)}
                </p>
              </div>
            </div>

            {item.status === "Selesai" ? (
              <div
                className="card-body border bg-light mx-4 mt-3"
                style={{ borderRadius: "15px" }}
              >
                <div className="d-flex align-items-center">
                  <div className="card-body">
                    <div className="ms-3">
                      <div className="d-flex align-items-center pt-3">
                        <div className="flex-grow-1 ms-3 ">
                          <h6 className="text-black">Catatan</h6>
                          <p className="text-black">{item.note}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : item.status_operations === "Pesanan Dikemas" ? (
              <div
                className="card-body border bg-light mx-4 mt-3"
                style={{ borderRadius: "15px" }}
              >
                <div className="d-flex align-items-center">
                  <div className="card-body">
                    <div className="ms-3">
                      <div className="d-flex align-items-center pt-3">
                        <div className="flex-grow-1 ms-3 ">
                          <h6 className="text-black">Catatan</h6>
                          <p className="text-black">{item.note}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : item.status_operations === "Pesanan Dikirim" ? (
              <div
                className="card-body border bg-light mx-4 mt-3"
                style={{ borderRadius: "15px" }}
              >
                <div className="d-flex align-items-center">
                  <div className="card-body">
                    <div className="ms-3">
                      <div className="d-flex align-items-center pt-3">
                        <div className="flex-grow-1 ms-3 ">
                          <h6 className="text-black">Catatan</h6>
                          <p className="text-black">{item.note}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}

            {item.status_operations === "Pesanan Dikemas" ? (
              <Form.Group className="font-14 mt-2 mx-4">
                <Form.Label>Kurir</Form.Label>
                <Form.Select
                  className="font-14 shadow-none"
                  onChange={(e) => {
                    console.log("e.target.value: ", e.target.value);
                    setSelectedKurir(e.target.value);
                  }}
                >
                  <option>Pilih Kurir</option>
                  {kurir.map((it, key) => {
                    return (
                      <option key={key} value={it.id}>
                        {it.first_name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            ) : (
              <div></div>
            )}

            {item.status_operations === "Pesanan Diproses" ? (
              <div className="g-col-6 content  pb-2">
                <div class="py-3 px-4">
                  <textarea
                    class="form-control pt-3 ps-4"
                    id="exampleFormControlTextarea1"
                    placeholder="Catatan"
                    rows="3"
                    ref={refInput}
                    style={{ backgroundColor: "#F9F9F9" }}
                  />
                </div>
              </div>
            ) : (
              <div></div>
            )}

            {item.status_operations === "Pesanan Diproses" ? (
              <div className="sticky-bottom bg-white mt-5 py-3 px-2 border-top">
                <div className="row mb-2 g-2 g-lg-3 me-2 mx-2">
                  <div
                    class="d-grid col-sm-6 col-md"
                    data-bs-toggle="modal"
                    data-bs-target="#batal"
                    aria-controls="batal"
                  >
                    <button
                      type="button"
                      class="mt-2  btn-outline-secondary bg-light border rounded  btn-lg d-block text-muted font-16"
                      onClick={() => setShow(true)}
                    >
                      Batalkan
                    </button>
                  </div>
                  <div class="d-grid col-sm-6 col-md">
                    <button
                      onClick={() => handleTerima(item.id)}
                      type="submit"
                      class="mt-2 btn btn-primary btn-lg d-block text-white  font-16"
                    >
                      Terima
                    </button>
                  </div>
                </div>
              </div>
            ) : item.status === "Dikirim" ? (
              <div className="sticky-bottom bg-white mt-5 py-3 px-2 border-top">
                <div className="row mb-2 g-2 g-lg-3 me-2 mx-2">
                  <div
                    class="d-grid col-sm-6 col-md"
                    data-bs-toggle="modal"
                    data-bs-target="#batal"
                  >
                    <button
                      type="button"
                      class="mt-2  btn-outline-secondary bg-light border rounded  btn-lg d-block text-muted font-16"
                      onClick={() => setShow(true)}
                    >
                      Batalkan
                    </button>
                  </div>
                  <div class="d-grid col-sm-6 col-md">
                    <button
                      onClick={() => handleSelesai(item.id)}
                      type="submit"
                      class="mt-2 btn btn-primary btn-lg d-block text-white  font-16"
                    >
                      Selesai
                    </button>
                  </div>
                </div>
              </div>
            ) : item.status_operations === "Pesanan Dikemas" ? (
              <div className="sticky-bottom bg-white mt-5 py-3 px-2 border-top">
                <div className="row mb-2 g-2 g-lg-3 me-2 mx-2">
                  <div
                    class="d-grid col-sm-6 col-md"
                    data-bs-toggle="modal"
                    data-bs-target="#batal"
                    aria-controls="batal"
                  >
                    <button
                      type="button"
                      class="mt-2  btn-outline-secondary bg-light border rounded  btn-lg d-block text-muted font-16"
                      onClick={() => setShow(true)}
                    >
                      Batalkan
                    </button>
                  </div>
                  <div class="d-grid col-sm-6 col-md">
                    <button
                      onClick={() => handleKirim(item.id)}
                      type="submit"
                      class="mt-2 btn btn-primary btn-lg d-block text-white  font-16"
                    >
                      Kirim
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        );
      })}
      <br />
      {/* <div
        className="modal fade"
        id="batal"
        tabindex="-1"
        aria-labelledby="batal"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          style={{ width: "350px" }}
          role="document"
        >
          <div className="modal-content border-0 ">
            <div className="modal-title bg-warning  pt-2 rounded-top">
              <center>
                <img src={alarm} />
                <h6 className="mt-2">Perhatian!</h6>
              </center>
            </div>
            <div className="modal-body rounded">
              <center>
                <div className="row g-2  g-lg-3 me-2 mx-2">
                  <div class="d-grid col-sm-6 col-md">
                    <button
                      type="button"
                      class="mt-2  btn-outline-secondary bg-light border rounded  d-block text-muted font-16"
                      onClick={() => handleBatal()}
                    >
                      Yes
                    </button>
                  </div>
                  <div class="d-grid col-sm-6 col-md">
                    <button
                      type="button"
                      class="mt-2 btn btn-primary btn-lg d-block text-white  font-16"
                      onClick={() => handleNo()}
                    >
                      No
                    </button>
                  </div>
                </div>
              </center>
            </div>
          </div>
        </div>
      </div> */}

      <Modal className="border-0" show={show} centered onHide={handleClose}>
        <Modal.Header className="bg-warning rounded-top">
          <Modal.Title className="w-100">
            <center>
              <img src={alarm} />
              <h6 className="mt-2">Perhatian!</h6>
            </center>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            <div className="row g-2  g-lg-3 me-2 mx-2">
              <div>
                <span>Batalkan Pesanan?</span>
              </div>
              <div class="d-grid col-sm-6 col-md">
                <button
                  type="button"
                  class="mt-2 btn-outline-secondary bg-light border rounded  d-block text-muted font-16"
                  onClick={handleClose}
                >
                  No
                </button>
              </div>
              <div class="d-grid col-sm-6 col-md">
                <button
                  type="button"
                  class="mt-2 btn btn-primary btn-lg d-block text-white  font-16"
                  onClick={() => handleBatal()}
                >
                  Yes
                </button>
              </div>
            </div>
          </center>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default RincianPesananAdmin;
