import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { commafy, showImage } from "../../../utils/Utils";

const PaymentSaldo = () => {
    const history = useHistory();
    const [data, setData] = useState({bankrelations:{}})
    const [from, setFrom] = useState("");

    useEffect(()=>{
        if(!history.location.data) return
        setData(history.location.data)  
        setFrom(history.location.from);
    },[history.location.data])

    return (
      <React.Fragment>
        <div className="w-100  img-bg-top" style={{ height: "150px" }}>
          <div className="navbar bg-transparent cursor-pointer px-3">
            <div className="contain p-2 px-cst1 pb-2">
              <Row>
                <Col className="d-flex">
                  <div
                    className="d-inline-block pe-2"
                    onClick={() =>
                      history.push({
                        pathname: from === "history" ? "/history" : "/transfer",
                      })
                    }
                  >
                    <img
                      src="img/arrow-left-white.svg"
                      alt=""
                      width="30"
                      height="24"
                      className="d-inline-block align-text-top "
                    />
                  </div>

                  <div className="d-inline-block ml-2">
                    <span className="ml-3 text-white">Topup Saldo</span>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="w-100 d-flex justify-content-center">
          <div
            className="card border-0 shadow-sm rounded w-100 mx-4"
            style={{
              marginTop: "-50px",
            }}
          >
            <div className="card-header border-0 bg-white py-3">
              <center>
                <p className="mb-0 fs-6 font-16  mt-3">Rincian Top Up</p>
              </center>
            </div>
            <div className="w-100 d-flex px-3 font-14">
              <div className="hr w-100"></div>
            </div>
            <div className=" row w-100 d-flex px-3 font-14 mt-3">
              <div className="col-md-1 flex-grow-1">Tanggal :</div>
              <div className=" col d-flex flex-grow-2">{data.createdAt}</div>
            </div>
            <div className=" row w-100 d-flex px-3 font-14 mt-3">
              <div className=" col-md-1 flex-grow-1">ID Transaksi :</div>
              <div className=" col d-flex flex-grow-2">{data.id}</div>
            </div>
            <div className="row w-100 d-flex px-3 font-14 mt-3">
              <div className=" col-md-1 flex-grow-1">Pembayaran</div>
              <div className=" col d-flex flex-grow-2">{data.bank}</div>
            </div>
            <div className=" row w-100 d-flex px-3 font-14 mt-3">
              <div className=" col-md-1 flex-grow-1">Status :</div>
              <div className=" col d-flex flex-grow-2">{data.status}</div>
            </div>
            <div className="w-100 d-flex px-3 font-14 mt-3">
              <div className="hr w-100"></div>
            </div>
            <div className="w-100 d-flex px-3 font-14 mt-3">
              <div className="flex-grow-1">Total Bayar</div>
              <div className="d-flex flex-grow-2 text-primary mb-4">
                Rp {commafy(data.nominal_uniq)}
              </div>
            </div>
          </div>
        </div>

        <div className="card border-0 shadow-sm rounded m-4">
          <div className="card-header border-0 bg-white py-3">
            <center>
              <img src={showImage(data.bankrelations.img)} width={100}></img>
            </center>
          </div>
          <div className="w-100 d-flex px-3 font-14">
            <div className="flex-grow-1">Nomor Rekening</div>
            <div className="d-flex flex-grow-2">
              {data.bankrelations.no_rek}
            </div>
          </div>
          <div className="w-100 d-flex px-3 mt-2 font-14">
            <div className="flex-grow-1 ">Atas Nama</div>
            <div className="d-flex flex-grow-2">
              {data.bankrelations.atas_nama}
            </div>
          </div>
          <div className="w-100 d-flex px-3 mt-2 font-14">
            <div className="flex-grow-1">Jumlah Transfer</div>
            <div className="d-flex pb-4 flex-grow-2">
              Rp {commafy(data.nominal_uniq)}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
};

export default PaymentSaldo;
