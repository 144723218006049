import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import cadangan from "../../assets/images/cadangan.svg";
import hapus from "../../assets/images/bersihkan.svg";
import bersihkan from "../../assets/images/hapus-dokumen.svg";

const PengaturanAdmin = () => {
  const history = useHistory();
  const { api } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    getData();
    console.log(111);
  });

  const getData = async (prefix) => {
    let data = await api("get", "user/profile");
    setData(data.data);
    console.log(data);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                    onClick={() => history.push("/dashboard")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Pengaturan</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="px-4 pt-4 min-vh-100">
        <div>
          <span className="text-grey-sm">Pengaturan Cadangan</span>
        </div>
        <div className="card my-3 cursor-pointer">
          <div
            className="card-body px-3 pt-2 pb-0 mt-1"
            // onClick={() => history.push("/pengaturan-profile")}
          >
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
              <img src={cadangan} width="35" alt="" />
              </div>
              <div className="flex-grow-1 ms-3">
                <p className="text-dark mb-0">Buat Cadangan</p>
              </div>
              <div className="flex-grow-2 ms-3">
                <div className="text-muted mb-0">
                  <img src="img/next.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="hr-line" />
          <div
            className="card-body px-3 pt-1 pb-2 cursor-pointer"
            // onClick={() => history.push("/keamanan")}
          >
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
              <img src={cadangan} width="35" alt="" />
              </div>
              <div className="flex-grow-1 ms-3">
                <p className="text-dark mb-0">Kembalikan Cadangan</p>
              </div>
              <div className="flex-grow-2 ms-3">
                <div className="text-muted mb-0">
                  <img src="img/next.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <span className="text-grey-sm">Hapus data</span>
        </div>
        <div className="card my-3 cursor-pointer">
          <div
            className="card-body px-3 pt-2 pb-0 mt-1"
            // onClick={() => history.push("/pengaturan-profile")}
          >
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <img src={hapus} width="35" alt="" />
              </div>
              <div className="flex-grow-1 ms-3">
                <p className="text-dark mb-0">Bersihkan Database</p>
              </div>
              <div className="flex-grow-2 ms-3">
                <div className="text-muted mb-0">
                  <img src="img/next.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="hr-line" />
          <div
            className="card-body px-3 pt-1 pb-2 cursor-pointer"
            // onClick={() => history.push("/keamanan")}
          >
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
              <img src={bersihkan} width="35" alt="" />
              </div>
              <div className="flex-grow-1 ms-3">
                <p className="text-dark mb-0">Hapus Semua Dokumen</p>
              </div>
              <div className="flex-grow-2 ms-3">
                <div className="text-muted mb-0">
                  <img src="img/next.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PengaturanAdmin;
