import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext"; 
import filterbiru from "../../assets/images/filterbiru.svg";
import kantong from "../../assets/images/kantong.svg";
import tas from "../../assets/images/tas.svg"; 
import produkterjual from "../../assets/images/produkterjual.svg";
import produktransaksi from "../../assets/images/produktransaksi.svg";
import { commafy } from "../../utils/Utils";
import moment from "moment";

const LaporanPenjualan = () => {
  const { api } = useContext(AppContext);
  const [data, setData] = useState([]);
  const history = useHistory();
  const refInputStart = useRef();
  const refInputEnd = useRef();
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  useEffect(() => {
    getData();
  }, [date, endDate]);

  const changeInputStart = () => {
    let value = refInputStart.current.value;
    setDate(value);
  };

  const changeInputEnd = () => {
    let value = refInputEnd.current.value;
    setEndDate(value);
  };

  const getData = async () => {
    let param = { date: date, endDate: endDate };

    let data = await api("get", "transactions-grosir/summary-pos", param);
    setData(data.data);
  };

  const resetApi = async () => {
    let data = await api("get", "transactions-inventory/get");
    setData(data.data);
  };

  const setBulanan = async () => {
    let data = await api("get", "transactions-inventory/get", {
      filter: "Bulanan",
    });
    setData(data.data);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4 w-100">
          <Row>
            <Col className="align-items-center w-100">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/dashboard-pos")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block">
                <span className="font-15 datapelanggan">Laporan Penjualan</span>
              </div>
              <div
                className="d-inline-block float-end pe-4"
                data-bs-toggle="offcanvas"
                data-bs-target="#laporaninventory"
                aria-controls="laporaninventory">
                <div className="align-text-top ">
                  <img src={filterbiru} width={25} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="g-col-6  px-cst1 pb-2 list-denom ">
        <Row className="mt-4">
          <Col>
            <div className="card">
              <div className="card-body">
                <Row>
                  <div className="col-md-6">
                    <div className="d-flex align-items-center ">
                      <div className="flex-shrink-0 px-3">
                        <img src={tas} width="40" alt="" />
                      </div>
                      <div className="flex-grow-1">
                        <small className="text-muted mt-2">
                          {" "}
                          Total Penjualan
                        </small>
                        <h6 className="text-dark">
                          Rp {commafy(data.penjualan)}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 float-end ">
                    <div className="d-flex align-items-center">
                      {/* <img  src={lurusatas} /> */}
                      <div className="flex-shrink-0 px-3 float-end">
                        <img src={kantong} width="40" alt="" />
                      </div>
                      <div className="flex-grow-1">
                        <small className="text-muted mt-2">
                          Total Keuntungan
                        </small>
                        <h6 className="text-dark">
                          Rp {commafy(data.keuntungan)}
                        </h6>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 px-3">
                    <img src={produkterjual} width="40" alt="" />
                  </div>
                  <div className="flex-grow-1">
                    <small className="text-muted mt-2">Produk Terjual</small>
                    <h6 className="text-dark">{data.count}</h6>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 mx-3">
                    <img src={produktransaksi} width="40" alt="" />
                  </div>
                  <div className="flex-grow-1">
                    <small className="text-muted ">Total transaksi</small>
                    <h6 className="text-dark">{data.transaksi}</h6>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <div className="card">
              <div className="card-body">
                <div className="grid">
                  <div className="w-100 d-flex px-3 font-14 ">
                    <div className="flex-grow-1">
                      <h6 className="text-dark">Laporan Keuangan</h6>
                    </div>
                  </div>
                  <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                    <div className="flex-grow-1">
                      <h6 className="text-secondary">Subtotal</h6>
                    </div>
                    <div className="d-flex flex-grow-2">
                      <h6 className="text-secondary">Rp {commafy(data.subtotal)}</h6>
                    </div>
                  </div>
                  <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                    <div className="flex-grow-1">
                      <h6 className="text-secondary">Diskon</h6>
                    </div>
                    <div className="d-flex flex-grow-2">
                      <h6 className="text-secondary">
                        Rp {commafy(data.discount)}
                      </h6>
                    </div>
                  </div>
                  <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                    <div className="flex-grow-1">
                      <h6 className="text-secondary">Penjualan</h6>
                    </div>
                    <div className="d-flex flex-grow-2">
                      <h6 className="text-secondary">
                        Rp {commafy(data.penjualan)}
                      </h6>
                    </div>
                  </div>
                  <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                    <div className="flex-grow-1">
                      <h6 className="text-secondary">PPN</h6>
                    </div>
                    <div className="d-flex flex-grow-2">
                      <h6 className="text-secondary">Rp {commafy(data.fee)}</h6>
                    </div>
                  </div>
                  <hr className="hr-dashed" />
                  <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                    <div className="flex-grow-1">
                      <h6 className="text-dark">Pendapatan</h6>
                    </div>
                    <div className="d-flex flex-grow-2">
                      <h6 className="text-dark">
                        Rp {commafy(data.penjualan)}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div
        className="offcanvas offcanvas-bottom g-col-6 rounded-top"
        tabIndex="-1"
        id="laporaninventory"
        aria-labelledby="shortLabel"
        style={{ height: "370px" }}
      >
        <div className="offcanvas-body">
          <div className="grid">
            <div className="g-col-12 px-4 py-3 bg-white">
              <p className="fs-6 mb- fw-bold">Filter</p>
              <div className="container px-0 py-1">
                <div className="row gx-8">
                  <p className="mb-2">Pilih tanggal yang ingin ditampilkan</p>
                  <a className="col text-decoration-none">
                    <div className="card p-3">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img src="img/kalender.svg" width="50" alt="" />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p id="alternate2" className="mb-1">
                            Tanggal Awal
                          </p>
                          <input
                            id="datepicker1"
                            className="form-control form-control-sm"
                            autoComplete="off"
                            type="date"
                            placeholder="Pilih tanggal"
                            aria-label="example"
                            ref={refInputStart}
                            onChange={() => changeInputStart()}
                            defaultValue={date}
                          />
                        </div>
                      </div>
                    </div>
                  </a>
                  <a className="col text-decoration-none">
                    <div className="card p-3">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img src="img/kalender.svg" width="50" alt="" />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p id="alternate2" className="mb-1">
                            Tanggal Akhir
                          </p>
                          <input
                            id="datepicker2"
                            className="form-control form-control-sm"
                            autoComplete="off"
                            type="date"
                            placeholder="Pilih Tanggal"
                            aria-label="example"
                            ref={refInputEnd}
                            onChange={() => changeInputEnd()}
                            defaultValue={endDate}
                          />
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="container px-0 py-3 category">
                <div className="row gx-8">
                  <div className="col text-decoration-none">
                    <a
                      className="btn btn-outline-primary border-secondary d-block"
                      onClick={() => getData()}
                    >
                      Harian
                    </a>
                  </div>
                  {/* <div className="col text-decoration-none">
                                        <a href="#" className="btn btn-outline-primary border-secondary d-block">Mingguan</a>
                                    </div> */}
                  <div className="col text-decoration-none">
                    <a
                      className="btn btn-outline-primary border-secondary d-block"
                      onClick={() => setBulanan()}
                    >
                      Bulanan
                    </a>
                  </div>
                </div>
              </div>
              <div className="container px-0 py-4 text-center">
                <div className="row gx-8">
                  <a className="col text-decoration-none">
                    <div className="d-grid">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-lg d-block font-14"
                        onClick={() => resetApi()}
                      >
                        Reset
                      </button>
                    </div>
                  </a>
                  <div className="col text-decoration-none">
                    <div className="d-grid">
                      <button
                        type="button"
                        className="btn btn-primary btn-lg d-block text-white font-14"
                      >
                        Terapkan
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LaporanPenjualan;
