import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { showImage } from "../../utils/Utils";
import {Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const Brands = () => {
  const { api } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [category, setCategory] = useState({});
  const history = useHistory();
  const refInput = useRef();

  useEffect(() => {
    if (!history.location.category) history.push("/");
    else setCategory(history.location.category);
  }, []);

  useEffect(() => {
    if (!category.id) return;
    getData();
  }, [category]);

  const getData = async (prefix) => {
    let param = {
      id: "i8l7DzgKEatN3e7SNCI38mA67ey0o5SiFgMKEJl7Va5pnD0BKW",
      view: "brand",
      category: category.id,
      prefix: prefix,
    };
    let data = await api("get", "produk/public", param);
    setData(data.data);
  };

  const changeInput = () => {
    let value = refInput.current.value;
    if (value.length === 4) {
      getData(value);
    }

    if (value.length === 0) {
      getData("");
    }
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm cursor-pointer">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-2">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/")}
                  className="d-inline-block align-text-top "
                />
              </div>

              <div className="d-inline-block ml-2">
                <span className="ml-5">{category.name}</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="g-col-6 content bg-light py-4">
        <form>
          <div className="input-group">
            <input
              type="number"
              className="form-control form-control-lg"
              placeholder={category.msg_input}
              ref={refInput}
              onChange={() => changeInput()}
              aria-describedby="basic-addon2"
            />
          </div>
        </form>
        <div className="g-col-6 bg-light px-cst1 pb-2 list-denom mt-4">
          {data.map((item, key) => {
            return (
              <div
                className="card mb-3 cursor-pointer"
                key={key}
                onClick={() =>
                  history.push({
                    pathname: "/products",
                    brand: item,
                    category: category,
                    input: refInput.current.value
                  })
                }
              >
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <img src={showImage(item.img)} width="40" alt="" />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <p className="text-dark mb-0">{item.name}</p>
                    </div>
                    <div className="flex-grow-2 ms-3">
                      <div className="text-muted mb-0">
                        <img src="img/next.svg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Brands;
