import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { commafy } from "../../utils/Utils";
import nara from "../../assets/images/logo-mid.svg";

const CetakDetailTransaksi = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState([]);

  useEffect(() => {
    if (!history.location.data) 
      history.push("/riwayat-transaksi-inventori");
    else {
      setData(history.location.data);
      setDetail(history.location.detail);
      console.log(history.location.data);
      console.log(history.location.detail);
    }
  }, [history.location]);

  return (
    <React.Fragment>
      <div className="container px-4 mt-4 border-none">
        <div className="w-100 min-vh-50  text-center">
          <div className="d-inline-block ">
            <div className="mt-2 font-24 text-dark fw-bold">
              <span>
                <img src={nara} />
              </span>
            </div>
          </div>
        </div>
        <div className="font-16 text-center mt-3">
          <span>{data.alamat_gudang}</span>
        </div>

        <div className="grid">
          <div className="w-100 d-flex px-3 font-16 mt-3">
            <div className="flex-grow-1">
              Gudang : {data.gudang}
            </div>
            <div className="d-flex flex-grow-2">{data.date} </div>
          </div>
          <div className="w-100 d-flex px-3 font-16">
            <div className="flex-grow-1">Nama Supplier : {data.supplier} </div>
            <div className="d-flex flex-grow-2">
              Nama Pegawai : {data.pegawai}{" "}
            </div>
          </div>
        </div>
        <hr style={{ borderTop: " 5px dashed " }} />
        <div className="row font-16 ms-1">
          <Col className="">
            <h6>Barang</h6>
          </Col>
          <Col className="ms-5">
            <h6>Harga</h6>
          </Col>
          <Col className="ms-5">
            <h6>Jumlah</h6>
          </Col>
          <Col className="ms-5">
            <h6>Total</h6>
          </Col>
        </div>
        <hr style={{ borderTop: "3px dashed" }} />
        {detail.map((item, key) => {
          return (
            <div className="row font-16 ms-1" key={key}>
              <Col>
                <span>{item.products_grosir.name}</span>
              </Col>
              <Col className="ms-5">
                <span>Rp {commafy(item.price)}</span>
              </Col>
              <Col className="ms-5">
                <span>{item.qty + " " + item.products_grosir.satuan}</span>
              </Col>
              <Col className="ms-5">
                <span>Rp {commafy(item.price * item.qty)}</span>
              </Col>
            </div>
          );
        })}
        <hr style={{ borderTop: " 3px dashed " }} />
        <div className="float-end ms-5">
          <div className="row font-16 fw-bold ms-1 ">
            <Col className="">
              <span>Subtotal</span>
            </Col>
            <Col className="ms-3 me-3" md="6">
              <span>Rp {commafy(data.price)}</span>
            </Col>
            <Col className="">
              <span></span>
            </Col>
          </div>
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <hr style={{ borderTop: " 3px dashed " }} />

        <div className="font-16 text-center">
          <span>
            Barang tidak dapat ditukar / dikembalian dengan alasan apapun.
            Kerusakan barang bukan tanggung jawab kami.
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CetakDetailTransaksi;
