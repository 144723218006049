import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import filterhitam from "../../assets/images/filterhitam.svg";
import filterbiru from "../../assets/images/filterbiru.svg";
import moment from "moment";
import { commafy } from "../../utils/Utils";

const UntungRugi = () => {
  const history = useHistory();
  const { api } = useContext(AppContext);
  const [data, setData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment().format("MM-DD-YYYY"));
  const [endDate, setEndDate] = useState(moment().format("MM-DD-YYYY"));

  useEffect(() => {
    if (!history.location.startDate) history.push("/laporan")
    else {
      getData(history.location.startDate, history.location.endDate)
    }
  }, [history.location]);

  const getData = async (startDate, endDate) => {
    let data = await api("get", "transactions-grosir/get-for-untung-rugi", {startDate: startDate, endDate: endDate});
    setData(data.data);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4 w-100">
          <Row>
            <Col className="align-items-center w-100">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/laporan")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block">
                <span className="font-15 datapelanggan">Laporan Laba Rugi</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="g-col-6 mt-4 px-cst1 pb-2 list-denom ">
        <Row>
          <Col>
            <div className="card">
              <div className="card-body">
                <div className="grid">
                  <div className="w-100 d-flex px-3 font-14 ">
                    <div className="flex-grow-1">
                      <h6 className="text-dark">Pendapatan</h6>
                    </div>
                  </div>
                  <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                    <div className="flex-grow-1">
                      <h6 className="text-secondary">Penjualan Barang</h6>
                    </div>
                    <div className="d-flex flex-grow-2">
                      <h6 className="text-secondary">Rp {commafy(data.price)}</h6>
                    </div>
                  </div>
                  <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                    <div className="flex-grow-1">
                      <h6 className="text-secondary">Pendapatan atas Pengiriman</h6>
                    </div>
                    <div className="d-flex flex-grow-2">
                      <h6 className="text-secondary">Rp {commafy(data.pengiriman)}</h6>
                    </div>
                  </div>
                  <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                    <div className="flex-grow-1">
                      <h6 className="text-secondary">Diskon</h6>
                    </div>
                    <div className="d-flex flex-grow-2">
                      <h6 className="text-secondary">Rp {commafy(data.discount)}</h6>
                    </div>
                  </div>
                  <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                    <div className="flex-grow-1">
                      <h6 className="text-dark">Total Pendapatan</h6>
                    </div>
                    <div className="d-flex flex-grow-2">
                      <h6 className="text-dark">Rp {commafy(data.total_pendapatan)}</h6>
                    </div>
                  </div>
                  <hr className="hr-dashed" />
                  <div className="w-100 d-flex px-3 font-14 ">
                    <div className="flex-grow-1">
                      <h6 className="text-dark">Biaya Atas Pendapatan</h6>
                    </div>
                  </div>
                  <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                    <div className="flex-grow-1">
                      <h6 className="text-secondary">Harga Pokok Penjualan</h6>
                    </div>
                    <div className="d-flex flex-grow-2">
                      <h6 className="text-secondary">Rp {commafy(data.hpp)}</h6>
                    </div>
                  </div>
                  <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                    <div className="flex-grow-1">
                      <h6 className="text-dark">Total Biaya atas Pendapatan</h6>
                    </div>
                    <div className="d-flex flex-grow-2">
                      <h6 className="text-dark">Rp {commafy(data.hpp)}</h6>
                    </div>
                  </div>
                  <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                    <div className="flex-grow-1">
                      <h6 className="text-dark">Laba/Rugi Kotor</h6>
                    </div>
                    <div className="d-flex flex-grow-2">
                      <h6 className="text-dark">Rp {commafy(data.laba_rugi_kotor)}</h6>
                    </div>
                  </div>
                  <hr
                    className="mt-4 hr-product"
                    style={{ background: "#fff" }}
                  />
                  <div className="w-100 d-flex px-3 font-14 ">
                    <div className="flex-grow-1">
                      <h6 className="text-dark">Pengeluaran Operasional</h6>
                    </div>
                  </div>
                  <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                    <div className="flex-grow-1">
                      <h6 className="text-secondary">Gaji Karyawan</h6>
                    </div>
                    <div className="d-flex flex-grow-2">
                      <h6 className="text-secondary">Rp {commafy(data.gaji_karyawan)}</h6>
                    </div>
                  </div>
                  <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                    <div className="flex-grow-1">
                      <h6 className="text-secondary">Biaya Kantor</h6>
                    </div>
                    <div className="d-flex flex-grow-2">
                      <h6 className="text-secondary">Rp {commafy(data.biaya_kantor)}</h6>
                    </div>
                  </div>
                  <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                    <div className="flex-grow-1">
                      <h6 className="text-secondary">Biaya Transport</h6>
                    </div>
                    <div className="d-flex flex-grow-2">
                      <h6 className="text-secondary">Rp {commafy(data.biaya_transport)}</h6>
                    </div>
                  </div>
                  <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                    <div className="flex-grow-1">
                      <h6 className="text-secondary">Depresiasi</h6>
                    </div>
                    <div className="d-flex flex-grow-2">
                      <h6 className="text-secondary">Rp {commafy(data.depresiasi)}</h6>
                    </div>
                  </div>
                  <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                    <div className="flex-grow-1">
                      <h6 className="text-dark">Total Pengeluaran Operasional</h6>
                    </div>
                    <div className="d-flex flex-grow-2">
                      <h6 className="text-dark">Rp {commafy(data.total_pengeluaran_operasional)}</h6>
                    </div>
                  </div>
                  <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                    <div className="flex-grow-1">
                      <h6 className="text-dark">Laba/Rugi Bersih</h6>
                    </div>
                    <div className="d-flex flex-grow-2">
                      <h6 className="text-dark">Rp {commafy(data.laba_rugi_bersih)}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default UntungRugi;
