import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import ImgBg from "../../assets/images/bg-top.svg";
import { useHistory } from "react-router-dom";
import { commafy } from "../../utils/Utils";

const SuksesPembayaranInventori = () => {
  const history = useHistory();

  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <div className="container px-4 mt-4 border-none">
        <div className="w-100 min-vh-50 mt-5 text-center">
          <div className="d-inline-block mt-5">
            <div className="align-items-center">
              <img src="img/sukses.svg"></img>
            </div>

            <div className="mt-2 font-24 text-dark">
              <span>Pembayaran Berhasil</span>
            </div>
            <div className="mt-2 font-16 text-dark">
              <span>ID Transaksi #001</span>
            </div>
            <div className="mt-2 font-16 text-dark">
              <span>Kamis, 22 september 2022 | 14:38</span>
            </div>
          </div>
        </div>
      </div>
      
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <div className=" sticky-bottom container mt-5 px-0 py-4 text-center">
        <a href="#" className="col text-decoration-none">
          <div className="d-grid">
            <button
              type="button"
              className="btn btn-primary btn-lg d-block text-white me-4 mx-4"
              onClick={() => history.push("/transaksi-inventory")}
            >
              Transaksi Baru
            </button>
          </div>
        </a>
      </div>
    </React.Fragment>
  );
};

export default SuksesPembayaranInventori;
