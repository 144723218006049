import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { showImage } from "../../utils/Utils";
import { Row, Modal, Button, Form, Offcanvas } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";
import moment from "moment";
import "moment/locale/id";
moment.locale("id");

const TukarPoint = () => {
  const { api } = useContext(AppContext);
  const [singleData, setSingleData] = useState(null);
  const [dataRewardPoints, setDataRewardPoints] = useState([]);
  const [showModal, setShowModal] = useState(true);
  const [showTukarPoint, setShowTukarPoint] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [member, setMember] = useState({});
  const { setSpinner } = useContext(AppContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    let localDataMember = JSON.parse(localStorage.getItem("member"));
    if (localDataMember) {
      setShowModal(false);
      getDataMember(localDataMember.username);
    }
    getDataRewardPoints();
  }, [history]);

  const getDataMember = async (username) => {
    let data = await api("post", "member/findMember", {
      username,
    });
    setMember(data.data);
  };

  const getDataRewardPoints = async () => {
    setSpinner(true);
    let result = await api("get", "reward-points");
    if (result.success) {
      setDataRewardPoints(result.data);
    }
    setSpinner(false);
  };

  const onSubmit = async (params) => {
    params = {
      username: params.username.toLowerCase(),
    };
    setLoading(true);
    let result = await api("post", "member/findMember", params);
    if (result.success) {
      setShowModal(false);
      localStorage.setItem("member", JSON.stringify(result.data));
      let dataMember = JSON.parse(localStorage.getItem("member"));
      setMember(dataMember);
      // NotificationManager.success("Berhasil Masuk", "Sukses");
      reset();
    } else {
      NotificationManager.error("Username tidak ditemukan", "Gagal");
    }
    setLoading(false);
  };

  const onSubmitTukarPoin = async (e, params) => {
    e.preventDefault();
    setLoading(true);
    let reward_point_id = singleData == null ? "" : singleData.id;
    params = {
      member_id: member.id,
      reward_point_id: reward_point_id,
    };
    let confirm = await api("post", "point-redeem-history/store", params);
    if (confirm.success === true) {
      NotificationManager.success(confirm.message, "Sukses");
    } else {
      NotificationManager.error(confirm.message, "Gagal");
    }
    getDataMember(member.username);
    setLoading(false);
  };

  return (
    <React.Fragment>
      {/* Modal Input Username Memmber */}
      <Modal show={showModal} backdrop="static" keyboard={false}>
        <Modal.Header className="justify-content-center">
          <Modal.Title>Silahkan Masukan Username Member</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <Form.Group className="font-14 mt-2">
              <Form.Control
                className="font-14 shadow-none"
                placeholder="Masukan Username"
                maxLength={8}
                autoComplete="off"
                required
                {...register("username", {
                  required: "username is required",
                })}
              />
            </Form.Group>
            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="username" />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              type="submit"
              className="text-white fw-bold"
            >
              {loading ? "Loading..." : "Submit"}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <nav className="navbar bg-light shadow-sm cursor-pointer w-100">
        <div className="p-2 pb-2 ps-4 w-100 d-flex align-items-center">
          <div className="d-inline-block">
            <img
              src="img/arrow-left.svg"
              alt=""
              width="30"
              height="24"
              onClick={() => history.push("/data-master")}
              className="d-inline-block align-text-top "
            />
          </div>
          <div className="d-flex align-items-center justify-content-between w-100 gap-2 ms-2">
            <form className="w-100">
              <div className="input-group w-100">
                <span>
                  Tukarkan Point dengan Produk (Point {member.username} :{" "}
                  {member.points})
                </span>
              </div>
            </form>
            <Link to="/redeem-history" className="btn btn-primary text-white">
              Redeem History
            </Link>
            <button
              className="btn btn-primary text-white"
              onClick={() =>
                history.push({
                  pathname: "/point-history",
                  member: member,
                })
              }
            >
              Point History
            </button>
          </div>
        </div>
      </nav>
      <Row className="no-gutters px-3 mt-2">
        {dataRewardPoints.map((item, key) => {
          return (
            <div className="col-6 p-2" key={key}>
              <div className="card w-100 border-0 mb-3 shadow cursor-pointer">
                <center>
                  <div className="bg-lime rounded m-2">
                    <div
                      className="p-3"
                      onClick={() =>
                        history.push({
                          pathname: "/deskripsi-reward-produk",
                          product: item.products_grosir,
                          rewardPoint: item,
                          type: 1,
                        })
                      }
                    >
                      <img
                        className="card-img-top mt-2"
                        src={showImage(item.products_grosir.img)}
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>
                  </div>
                </center>
                <div className="card-body ">
                  <div>
                    <div
                      className="font-16 font-weight-bold"
                      style={{ height: "45px" }}
                    >
                      <span className="mt-1">{item.products_grosir.name}</span>
                    </div>
                    <span className="text-muted font-13">
                      {item.products_grosir.weight}{" "}
                      {item.products_grosir.unit_weight}
                    </span>
                    <p className="fw-bold mb-0 font-13">Point : {item.point}</p>
                    <span className="text-muted font-13">
                      Tgl Expired :{" "}
                      {moment(item.expired_at).format("DD MMMM YYYY")}
                    </span>
                  </div>
                  <div className="mt-3 d-flex justify-content-end">
                    <button
                      className="btn btn-primary text-white"
                      onClick={() => {
                        setSingleData(item);
                        setShowTukarPoint(true);
                      }}
                    >
                      Tukar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <div className="row justify-content-center sticky-bottom border-top filter">
          <div className="col-md-12">
            <Offcanvas
              show={showTukarPoint}
              placement="bottom"
              onHide={() => setShowTukarPoint(false)}
              className="rounded-top detail-product"
              style={{ height: "55vh" }}
            >
              <Offcanvas.Body>
                <div className="fw-bold text fs-5 border-bottom mb-3">
                  <p>Konfirmasi Penukaran Point</p>
                </div>
                <div className="fw-bold border border-3 p-2 rounded">
                  <p className="mb-0">
                    {singleData == null ? "" : singleData.products_grosir.name}
                  </p>
                  <p className="text-muted mb-0">
                    Sisa Stock :{" "}
                    {singleData == null ? "" : singleData.products_grosir.stock}
                  </p>
                  <p className="mb-0">
                    {singleData == null ? "" : singleData.point} Poin
                  </p>
                </div>
                <div className="text-dark fw-bold mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-0">Poin Kamu</p>
                    <p className="mb-0">{member.points} Poin</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p>Poin Terpakai</p>
                    <p>{singleData == null ? "" : singleData.point} Poin</p>
                  </div>
                </div>
                <hr className="m-0 mb-3" />
                <div className="text-dark fw-bold mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <p>Sisa Poin Kamu</p>
                    <p
                      className={`${
                        member.points -
                          (singleData == null ? 0 : singleData.point) <
                        0
                          ? "text-danger"
                          : "text-primary"
                      }`}
                    >
                      {member.points -
                        (singleData == null ? 0 : singleData.point)}{" "}
                      Poin
                    </p>
                  </div>
                  <div className="d-flex justify-content-between gap-4">
                    <button
                      className="btn btn-outline-primary rounded-pill w-50"
                      onClick={() => {
                        setShowTukarPoint(false);
                      }}
                    >
                      Cancel
                    </button>
                    <form className="w-50" onSubmit={onSubmitTukarPoin}>
                      <button
                        type="submit"
                        className="btn btn-primary text-white rounded-pill w-100"
                      >
                        {loading ? "Loading..." : "Konfirmasi"}
                      </button>
                    </form>
                  </div>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </div>
      </Row>
      <div
        className="float-end me-4 sticky-bottom cursor-pointer btn btn-danger text-white"
        style={{ bottom: "20px" }}
        onClick={() => {
          setShowModal(true);
          localStorage.removeItem("member");
          NotificationManager.warning("Berhasil Logout", "Sukses");
        }}
      >
        <h6 className="m-0">Logout</h6>
      </div>
    </React.Fragment>
  );
};

export default TukarPoint;
