import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import tambah from "../../assets/images/tambah.svg";
import tanda from "../../assets/images/tanda.svg";
import sampah from "../../assets/images/sampah.svg";
import { NotificationManager } from "react-notifications";

const DataBiaya = () => {
  const history = useHistory();
  const { api, setSpinner } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setSpinner(true);
    let data = await api("get", "data-biaya/get");
    data.data.map((item) => {
      Object.assign(item, { selected: false });
    });
    setData(data.data);
    setSpinner(false);
  };
  const selectAll = (e) => {
    console.log(e.target.checked);
    let newData = data.map((item) => {
      item.selected = e.target.checked;
      return item;
    });

    setData(newData);
  };

  const selectSingle = (id, selected) => {
    console.log(id, selected);
    let newData = data.map((item) => {
      if (item.id === id) item.selected = selected;
      return item;
    });

    setData(newData);
  };

  const delData = async () => {
    let id = "",
      separator = "";
    data.map((item) => {
      if (item.selected === true) {
        id = id + separator + item.id;
        separator = ",";
      }
    });
    setSpinner(true);
    let param = { id_all: id };
    let del = await api("post", "data-biaya/delete-all", param);
    setSpinner(false);
    if (del.success) {
      NotificationManager.success(del.message);
      getData();
    } else NotificationManager.error(del.message);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm cursor-pointer">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/dashboard")} 
                  className="d-inline-block align-text-top "
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Data Biaya</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      
      <div className="d-flex align-item-center ms-4 mt-4">
        <input
          className="form-check-input"
          type="checkbox"
          onClick={(e) => selectAll(e)}
          id="flexCheckDefault"
        />
        <label
          className="form-check-label flex-grow-1 ms-3"
          for="flexCheckDefault"
        >
          Pilih semua
        </label>
        <div className="flex-grow-2 mx-5 cursor-pointer">
          <div className="text-muted mb-0">
            <img src={sampah} width="25" onClick={() => delData()} />
          </div>
        </div>
      </div>
      {data.map((item, key) => {
        return (
            <div className="g-col-6  px-cst1 pb-2 list-denom" key={key}>
              <Row className="mt-2">
                <Col>
                  <div
                    className="card cursor-pointer"
                    style={{ borderRadius: "10px" }}
                  >
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                        onClick={(e) => selectSingle(item.id, e.target.checked)}
                          id="flexCheckDefault"
                        />
                        <div className="flex-shrink-0 px-3">
                          <img src={tanda} width="40" alt="" />
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="text-dark">{item.name}</h6>
                          <small className="text-muted">
                            {item.amount} | {item.pic} | {item.status_pembayaran}
                          </small>
                        </div>
                        <div className="flex-grow-2 ms-3">
                          <div className="text-muted mb-0">
                            <img src="img/next.svg" alt="" onClick={() => history.push({pathname:"/detail-biaya", biaya: item})}/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
        );
      })}

      <div
        className="float-end me-4 sticky-bottom py-3 cursor-pointer"
        onClick={() => history.push("/tambah-biaya")}
      >
        <img src={tambah} />
      </div>
    </React.Fragment>
  );
};

export default DataBiaya;
