import React, { useContext, useEffect, useState, useRef } from "react";
import { Modal, Col, Row, Form, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import filterbiru from "../../assets/images/filterbiru.svg";
import keluar from "../../assets/images/out.svg";
import masuk from "../../assets/images/in.svg";
import garislurus from "../../assets/images/garislurus.svg";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import printIcon from "../../assets/images/print-icon.svg";
import { commafy, showImage } from "../../utils/Utils";
import editIcon from "../../assets/images/pencil-square.svg";
import syncIcon from "../../assets/images/sync.svg";

const RiwayatTransaksiInventori = () => {
  const { api } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [dataInv, setDataInv] = useState([]);
  const [qty, setQty] = useState([]);
  const history = useHistory();
  const [date, setDate] = useState(moment().format("MM-DD-YYYY"));
  const refInputStart = useRef();
  const refInputEnd = useRef();
  const [startDate, setStartDate] = useState(moment().format("MM-DD-YYYY"));
  const [endDate, setEndDate] = useState(moment().format("MM-DD-YYYY"));
  const { setSpinner } = useContext(AppContext);
  const [isLoading, setLoading] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showPrint, setShowPrint] = useState(false);
  const [faktur, setFaktur] = useState([]);
  const [id, setId] = useState("");
  const [latestParam, setLatestParam] = useState();
  const [latestParamEnd, setLatestParamEnd] = useState();
  const [param, setParam] = useState("");
  const [sortType, setSortType] = useState("Semua");
  const [detail, setDetail] = useState([]);
  const [show, setShow] = useState(false);
  const [buktiPembayaran, setBuktiPembayaran] = useState();
  const [fullscreen, setFullscreen] = useState(true);
  const handleClose = () => setShow(false);

  useEffect(() => {
    if (history.location.param === "laporan-inventory") {
      setParam("/laporan-transaksi-inventory");
    } else {
      setParam("/dashboard-inventory");
    }
  }, [history.location]);

  useEffect(() => {
    if (history.location.date) {
      let paramDate = history.location.date;
      setLatestParam(paramDate);
      getData(paramDate);
    }
    if (history.location.latestParamEnd && history.location.latestParam) {
      let paramDate = history.location.latestParam;
      let endDate = history.location.latestParamEnd;
      setLatestParam(paramDate);
      setLatestParamEnd(endDate);
      getData(paramDate, endDate);
      return;
    }
    getData(date, date, sortType);
  }, [history.location]);

  const changeInputStart = () => {
    let value = refInputStart.current.value;
    setDate(moment(value).format("MM-DD-YYYY"));
    setLatestParam(moment(value).format("MM-DD-YYYY"));
  };

  const changeInputEnd = () => {
    let value = refInputEnd.current.value;
    setEndDate(moment(value).format("MM-DD-YYYY"));
    setLatestParamEnd(moment(value).format("MM-DD-YYYY"));
  };

  const getData = async (paramDate, endDate, sortType) => {
    setSpinner(true);
    let data = await api("get", "transactions-inventory/get-per-date", {
      date: paramDate,
      endDate: endDate,
      sortType: sortType,
    });
    setData(data.data);

    let qtyData = await api("get", "transactions-inventory/count-qty", {
      date: paramDate,
      endDate: endDate,
    });
    setQty(qtyData.data);
    setSpinner(false);
  };

  const resetApi = async () => {
    setSpinner(true);
    let paramDate = moment().format("MM-DD-YYYY");
    getData(paramDate);
    setSpinner(false);
  };

  const handleClick = async () => {
    getData(date, endDate, sortType);
  };
  const handleClickDetail = async (item) => {
    setDataInv(item);
    setShowDetail(true);
    setSpinner(true);
    let detail = await api("get", "transactions-inventory/detail", { id: item.id});
    setDetail(detail.data);

    let faktur = await api("get", "inventory-images/get", {
      transaction_id: item.id,
    });
    setFaktur(faktur.data);
    setSpinner(false);
  };
  
  const handleClickPrint = async (item) => {
    setShowDetail(false);
    setShowPrint(true)
  };

  const changeInputImg = async (value) => {
    setBuktiPembayaran(value);
  };

  const handleClickModal = async () => {
    let formData = new FormData();
    formData.append("transaction_id", id);
    for (let i = 0; i < buktiPembayaran.length; i++) {
      formData.append("img", buktiPembayaran[i]);
    }

    let upload = await api("post", "inventory-images/post", formData);
    if (upload.success) {
      NotificationManager.success(upload.message);
      getData();
    }
  };
  const handleSync = async () => {
    setSpinner(true);
    let update = await api("post", "cart-grosir/update-inv-sync");
    setSpinner(false);
    if (update.success) {
      NotificationManager.success(update.message);
    }
  };

  const handleClickType = async(value) => {
    setSortType(value);
    let sortStartDate = history.location.latestParam;
    let sortEndDate = history.location.latestParamEnd;
    if (sortStartDate && sortEndDate) {
      getData(sortStartDate, sortEndDate, value);
      return;
    } 
    getData(date, endDate, value);
  }

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm">
        <div className="contain p-2 px-cst1 pb-2 ps-4 w-100">
          <Row>
            <Col className="align-items-center w-100">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push(param)}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block">
                <span className="font-15 datapelanggan">
                  Riwayat Transaksi Inventory
                </span>
              </div>
              <div
                className="d-inline-block float-end pe-4"
                data-bs-toggle="offcanvas"
                data-bs-target="#riwayatinventory"
                aria-controls="riwayatinventory"
              >
                <div className="align-text-top ">
                  <img src={filterbiru} width={25} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="g-col-6  px-cst1 pb-2 list-denom ">
        <Row className="mt-4">
          <Col className="">
            <div className="card">
              <div className="card-body">
                <Row>
                  <div className="col-md-6 border-right cursor-pointer" onClick={() => handleClickType("Barang Masuk")}>
                    <div className="d-flex align-items-center ">
                      <div className="flex-shrink-0 px-3">
                        <img src={masuk} width="40" alt="" />
                      </div>
                      <div className="flex-grow-1">
                        <small className="text-muted mt-2">Barang Masuk</small>
                        <h6 className="text-dark">
                          {qty.masuk === null ? 0 : qty.masuk}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 cursor-pointer" onClick={() => handleClickType("Barang Keluar")}>
                    <div className="d-flex align-items-center">
                      <img src={garislurus} className="pe-3" />
                      <div className="flex-shrink-0 ms-4 px-3">
                        <img src={keluar} className="ms-4" width="40" alt="" />
                      </div>
                      <div className="flex-grow-1">
                        <small className="text-muted mt-2">Barang Keluar</small>
                        <h6 className="text-dark">
                          {qty.keluar === null ? 0 : qty.keluar}
                        </h6>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        {data.map((item, key) => {
          return (
            <Row className="mt-4" key={key}>
              <Col>
                {item.category === "Barang Masuk" ? (
                  <div
                    className="card cursor-pointer"
                    onClick={() =>
                      handleClickDetail(item,)
                    }
                  >
                    <div
                      className="card-body"
                      onClick={() =>
                        history.push({
                          pathname: "/detail-transaksi-inventori",
                          transaksi: item,
                          latestParam: latestParam,
                          latestParamEnd: latestParamEnd,
                        })
                      }
                    >
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 pe-3">
                          <img src={masuk} width="40" alt="" />
                        </div>
                        <div className="flex-grow-1 py-1">
                          <h6 className="text-dark mt-2">{item.no}</h6>
                          <h6 className="text-muted">
                            Rp {commafy(item.total_price)}
                          </h6>
                          <small className="text-muted">{item.createdAt}</small>
                        </div>
                        <div className="float-end py-2">
                          <div className="text-muted mb-0 mt-1 text-right">
                            <h6 className="text-dark">{item.supplier}</h6>
                            <span className="text-dark">{item.qty} Produk</span>
                            <h6 className="text-primary mt-2">
                              {item.category}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : item.category === "Barang Keluar" ? (
                  <div className="card cursor-pointer">
                    <div
                      className="card-body"
                      onClick={() =>
                        history.push({
                          pathname: "/detail-transaksi-inventori",
                          transaksi: item,
                          latestParam: latestParam,
                          latestParamEnd: latestParamEnd,
                        })
                      }
                    >
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 pe-4">
                          <img src={keluar} width="40" alt="" />
                        </div>
                        <div className="flex-grow-1 py-1">
                          <h6 className="text-dark mt-2">{item.no}</h6>
                          <h6 className="text-muted">
                            Rp {commafy(item.total_price)}
                          </h6>
                          <small className="text-muted">{item.createdAt}</small>
                        </div>
                        <div className=" ms-3 float-end mt-3">
                          <div className="text-muted mb-0">
                            <span className="text-dark">{item.qty} Produk</span>
                            <h6 className="text-danger mt-2">
                              {item.category}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </Col>
            </Row>
          );
        })}
      </div>
      <div
        className="offcanvas offcanvas-bottom g-col-6 rounded-top"
        tabIndex="-1"
        id="riwayatinventory"
        aria-labelledby="shortLabel"
        style={{ height: "370px" }}
      >
        <div className="offcanvas-body">
          <div className="grid">
            <div className="g-col-12 px-4 py-3 bg-white">
              <p className="fs-6 mb- fw-bold">Filter</p>
              <div className="container px-0 py-1">
                <div className="row gx-8">
                  <p className="mb-2">Pilih tanggal yang ingin ditampilkan</p>
                  <a className="col text-decoration-none">
                    <div className="card p-3">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img src="img/kalender.svg" width="50" alt="" />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p id="alternate2" className="mb-1">
                            Tanggal Awal
                          </p>
                          <input
                            id="datepicker1"
                            className="form-control form-control-sm"
                            autoComplete="off"
                            type="date"
                            placeholder="Pilih tanggal"
                            aria-label="example"
                            ref={refInputStart}
                            onChange={() => changeInputStart()}
                            defaultValue={startDate}
                          />
                        </div>
                      </div>
                    </div>
                  </a>
                  <a className="col text-decoration-none">
                    <div className="card p-3">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img src="img/kalender.svg" width="50" alt="" />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p id="alternate2" className="mb-1">
                            Tanggal Akhir
                          </p>
                          <input
                            id="datepicker1"
                            className="form-control form-control-sm"
                            autoComplete="off"
                            type="date"
                            placeholder="Pilih tanggal"
                            aria-label="example"
                            ref={refInputEnd}
                            onChange={() => changeInputEnd()}
                            defaultValue={endDate}
                          />
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="container px-0 py-4 text-center">
                <div className="row gx-8">
                  <a className="col text-decoration-none">
                    <div className="d-grid">
                      <button
                        type="button"
                        className="btn btn-outline-secondary btn-lg d-block font-16"
                        onClick={() => resetApi()}
                      >
                        Reset
                      </button>
                    </div>
                  </a>
                  <div className="col text-decoration-none">
                    <div className="d-grid">
                      <button
                        type="button"
                        onClick={() => handleClick()}
                        className="btn btn-primary btn-lg d-block text-white font-16"
                      >
                        Terapkan
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showDetail}
        onHide={() => setShowDetail(false)}
        centered
        size="lg"
      >
        <Modal.Header className="bg-primary">
          <center>
            <h1 className="modal-title fs-5 text-white" id="diskon">
              Detail Transaksi
            </h1>
          </center>
          <div className="d-inline-block float-end pe-4">
            <div className="float-end align-text-top py-2">
              <img
                src={printIcon}
                width={25}
                className="cursor-pointer"
                onClick={() => {
                  handleClickPrint();
                }}
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="g-col-6  px-cst1 pb-2 list-denom ">
            <div
              className="card-body border bg-light mt-4 py-2"
              style={{ borderRadius: "10px" }}
            >
              <div className="w-100 d-flex  mt-4 px-4 font-14 fw-bold">
                <h6 className="flex-grow-1 text-dark">Detail Transaksi</h6>
                <h6 className="d-flex px-2 flex-grow-2 text-dark">
                  {dataInv.type}
                </h6>
              </div>
              <div className="w-100 d-flex px-4  font-14 text-muted">
                <p className="flex-grow-1  ">Supplier</p>
                <p className="d-flex px-2 flex-grow-2">{dataInv.supplier}</p>
              </div>
              <div className="w-100 d-flex px-4  font-14 text-muted">
                <p className="flex-grow-1  ">Pegawai</p>
                <p className="d-flex px-2 flex-grow-2">{dataInv.pegawai}</p>
              </div>
              <div className="w-100 d-flex px-4  font-14 text-muted">
                <p className="flex-grow-1  ">Tanggal Transaksi</p>
                <p className="d-flex px-2 flex-grow-2">{dataInv.date_order}</p>
              </div>
              <div className="w-100 d-flex px-4  font-14 text-muted">
                <p className="flex-grow-1  ">Tanggal Input</p>
                <p className="d-flex px-2 flex-grow-2">{dataInv.createdAt}</p>
              </div>
              <div className="w-100 d-flex px-4  font-14 text-muted">
                <p className="flex-grow-1  ">Sub Total</p>
                <p className="d-flex px-2 flex-grow-2">
                  Rp {commafy(dataInv.price)}
                </p>
              </div>
              <div className="w-100 d-flex px-4  font-14 text-muted">
                <p className="flex-grow-1  ">Diskon</p>
                <p className="d-flex px-2 flex-grow-2">
                  Rp {commafy(dataInv.discount)}
                </p>
              </div>
              <div className="w-100 d-flex px-4  font-14 text-muted">
                <p className="flex-grow-1  ">Diskon Tambahan</p>
                <p className="d-flex px-2 flex-grow-2">
                  Rp {commafy(dataInv.discount_additional)}
                </p>
              </div>
              <div className="w-100 d-flex px-4  font-14 text-muted">
                <p className="flex-grow-1  ">Pajak</p>
                <p className="d-flex px-2 flex-grow-2">
                  Rp {commafy(dataInv.tax)}
                </p>
              </div>
              <div className="w-100 d-flex px-4  font-14 text-muted">
                <p className="flex-grow-1  ">Biaya Lainnya</p>
                <p className="d-flex px-2 flex-grow-2">
                  Rp {commafy(dataInv.fee)} ( {dataInv.fee_desc})
                </p>
              </div>
              <div className="w-100 d-flex px-4  font-14 text-muted">
                <p className="flex-grow-1  ">Total</p>
                <p className="d-flex px-2 flex-grow-2">
                  Rp {commafy(dataInv.total_price)}
                </p>
              </div>
              <div className="w-100 d-flex px-4  font-14 text-muted">
                <p className="flex-grow-1  ">Status Pembayaran</p>
                <p className="d-flex px-2 flex-grow-2">
                  {dataInv.status_pembayaran}
                </p>
              </div>
              <div className="w-100 d-flex px-4  font-14 text-muted">
                <p className="flex-grow-1  ">Faktur Pembelian</p>
                <p
                  className="d-flex px-2 flex-grow-2 text-primary cursor-pointer"
                  onClick={() => setShow(true)}
                >
                  Lihat
                </p>
              </div>
              <div className="w-100 d-flex px-4  font-14 text-muted">
                <p className="flex-grow-1  ">Catatan</p>
                <p className="d-flex px-2 flex-grow-2">{dataInv.description}</p>
              </div>
            </div>
            <div
              className="card-body border bg-light mt-4 py-2"
              style={{ borderRadius: "10px" }}
            >
              <div className="w-100 mb-2 px-4 font-14 fw-bold">
                <div className="text-muted float-end m-2">
                  <img
                    className="cursor-pointer"
                    src={editIcon}
                    onClick={() =>
                      history.push({
                        pathname: "/edit-inventory",
                        detailTransaksi: detail,
                        dataInv: dataInv,
                      })
                    }
                  />
                  <img
                    className="cursor-pointer ms-3"
                    src={syncIcon}
                    onClick={() => handleSync()}
                  />
                </div>
              </div>
              <div className="w-100 d-flex mt-4 mb-2 px-4 font-14 fw-bold">
                <h6 className="flex-grow-1 text-dark">Detail Barang</h6>
                <h6 className="d-flex px-2 flex-grow-2 text-dark">
                  {dataInv.no}
                </h6>
              </div>
              {detail.map((item, key) => {
                return (
                  <div
                    className="w-100 d-flex px-4 font-14 text-muted"
                    key={key}
                  >
                    <p className="flex-grow-1">{item.products_grosir.name}</p>
                    <p className="d-flex px-2 flex-grow-2">
                      <span className="text-primary ps-2">
                        Rp {commafy(item.cart_grosir.price.toFixed(0)) + " "}
                      </span>
                      <span className="ps-1">
                        {" "}
                        {" x " + item.cart_grosir.qty}
                      </span>
                      <span className="ps-1">
                        = {commafy(item.cart_grosir.total_price)}
                      </span>
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="border-0"
        show={showPrint}
        size="lg"
        onHide={setShowPrint}
      >
        <Modal.Body>
          <div id="section-to-print" className="font-14 w-100">
            <div className="text-center">Nara Store</div>
            <div className="text-center">
              <span>{dataInv.alamat_gudang}</span>
            </div>
            <div className="grid border-bottom ">
              <div className="w-100 d-flex font-12">
                <div className="flex-grow-1"> {dataInv.gudang}</div>
                <div className="d-flex flex-grow-2">{dataInv.date} </div>
              </div>
              <div className="w-100 d-flex font-12">
                <div className="flex-grow-1">{dataInv.supplier}</div>
                <div className="d-flex flex-grow-2 font-12">{dataInv.pegawai}</div>
              </div>
            </div>
            <div className="mt-2 border-bottom">
              {detail.map((item, key) => {
                return (
                  <React.Fragment>
                    <Row className="font-14" key={key}>
                      <Col>
                        <span>{item.products_grosir.name}</span>
                      </Col>
                    </Row>
                    <Row className="ms-3">
                      <Col>
                        <span>
                          {commafy(item.price.toFixed(0))} x {commafy(item.qty)}{" "}
                          = Rp {commafy((item.price * item.qty).toFixed(0))}
                        </span>
                      </Col>
                    </Row>
                  </React.Fragment>
                );
              })}
            </div>
            <div className="mt-2">
              <span>Total : Rp {commafy(dataInv.price)}</span>
            </div>
            
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="border-0"
        show={show}
        fullscreen={fullscreen}
        centered
        onHide={handleClose}
      >
        <Modal.Header className="rounded-top" closeButton>
          <Modal.Title className="w-100">
            <center>
              <h6 className="mt-2">Faktur Pembelian</h6>
            </center>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            <Row>
              {faktur.map((item, key) => {
                return (
                  <Col key={key}>
                    <img className="w-50" src={showImage(item.img)} />
                  </Col>
                );
              })}
            </Row>
            <Form.Group controlId="formFile" className="ms-2 w-25 mt-3">
              <Form.Control
                type="file"
                className="font-14"
                onChange={(e) => {
                  changeInputImg(e.target.files);
                }}
                multiple
              />
            </Form.Group>
            <Button
              className="btn btn-primary text-white mt-2"
              onClick={() => handleClickModal()}
            >
              Upload
            </Button>
          </center>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default RiwayatTransaksiInventori;
