import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { NotificationManager } from "react-notifications";
import { Button, Spinner, Row, Col, Form } from "react-bootstrap";

const EditMember = () => {
  const history = useHistory();
  const { api, setSpinner } = useContext(AppContext);
  const [isLoading, setLoading] = useState(false);
  const [member, setMember] = useState({});
  const {
    register,
    formState: { errors },
    handleSubmit,
    // reset
  } = useForm();
  useEffect(() => {
    if (!history.location.member) history.push("/data-member");
    else {
      setMember(history.location.member);
    }
  }, []);

  const onSubmit = async (params) => {
    setLoading(true);
    params = {
      id: member.id,
      username: params.username || member.username,
      address: params.address || member.address,
      hp: params.hp || member.hp,
      age: params.age || member.age,
      points: params.points || member.points,
    };
    let updateMember = await api("post", "member/update", params);
    if (updateMember.success) {
      history.push("/data-member");
      NotificationManager.success(updateMember.message);
      // reset()
    } else {
      console.log(updateMember);
      NotificationManager.warning(updateMember.message);
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="/img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/data-member")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Edit Akun Member</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <form onSubmit={handleSubmit(onSubmit)} className="bg-white p-3">
        <Form.Group className="font-14 mt-2" style={{ zIndex: "999" }}>
          <Form.Label>Username Member</Form.Label>
          <Form.Control
            type="text"
            maxLength={8}
            defaultValue={member.username}
            className="font-14 shadow-none"
            {...register("username", {
              // required: "username is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="username" />
        </div>
        <Form.Group className="font-14 mt-2">
          <Form.Label>Alamat</Form.Label>
          <Form.Control
            className="font-14 shadow-none"
            defaultValue={member.address}
            {...register("address", {
              // required: "address is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="address" />
        </div>
        <Form.Group className="font-14 mt-2">
          <Form.Label>Nomor HP</Form.Label>
          <Form.Control
            type="number"
            defaultValue={member.hp}
            className="font-14 shadow-none"
            {...register("hp", {
              // required: "hp is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="hp" />
        </div>

        <Form.Group className="font-14 mt-2">
          <Form.Label>Umur</Form.Label>
          <Form.Control
            type="number"
            defaultValue={member.age}
            className="font-14 shadow-none"
            {...register("age", {
              // required: "age is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="age" />
        </div>

        <Form.Group className="font-14 mt-2">
          <Form.Label>Points</Form.Label>
          <Form.Control
            type="number"
            defaultValue={member.points}
            className="font-14 shadow-none"
            {...register("points", {
              // required: "points is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="points" />
        </div>

        <Form.Group className="font-14 mt-4 w-100">
          <Button
            variant="primary"
            className="btn btn-primary w-100 text-white font-14"
            type="submit"
          >
            Simpan
          </Button>
        </Form.Group>
      </form>
    </React.Fragment>
    // <div className="w-100">
    //   <div className="float-right" style={{ height: "200px" }}>
    //     <img src="/img/bg-top-right.svg" className="float-end"></img>
    //   </div>
    //   <div className="text-center px-5">
    //     <div>
    //       <img src="/img/logo-small.svg"></img>
    //     </div>
    //     <div className="mt-3">
    //       <p className="text-dark font-14">
    //         Edit Akun Member Nara Store{" "}
    //         <span className="fw-bold">({member.username})</span>
    //       </p>
    //     </div>

    //     <div className="mt-4 w-100">
    //       <form onSubmit={handleSubmit(onSubmit)}>
    //         <div class="mb-3">
    //           <input
    //             type="text"
    //             placeholder="Username"
    //             autocomplete="off"
    //             maxLength="8"
    //             defaultValue={member.username}
    //             class="form-control form-control-lg font-14"
    //             id="exampleInputText1"
    //             {...register("username", {
    //               //   required: "Username is required",
    //             })}
    //           />
    //           <div className="my-2 text-left float-start font-12 text-danger">
    //             <ErrorMessage errors={errors} name="username" />
    //           </div>
    //         </div>
    //         <div class="mb-3">
    //           <input
    //             type="text"
    //             placeholder="Address"
    //             autocomplete="off"
    //             defaultValue={member.address}
    //             class="form-control form-control-lg font-14"
    //             id="exampleInputText1"
    //             {...register("address", {
    //               // //   required: "Address is required",
    //             })}
    //           />
    //           <div className="my-2 text-left float-start font-12 text-danger">
    //             <ErrorMessage errors={errors} name="address" />
    //           </div>
    //         </div>

    //         <div class="mb-3">
    //           <input
    //             type="number"
    //             placeholder="No.Hp"
    //             autocomplete="off"
    //             defaultValue={member.hp}
    //             class="form-control form-control-lg font-14"
    //             id="exampleInputText1"
    //             {...register("hp", {
    //               // //   required: "No.Hp is required",
    //             })}
    //           />
    //           <div className="my-2 text-left float-start font-12 text-danger">
    //             <ErrorMessage errors={errors} name="hp" />
    //           </div>
    //         </div>

    //         <div class="mb-3">
    //           <input
    //             type="number"
    //             placeholder="Umur"
    //             autocomplete="off"
    //             defaultValue={member.age}
    //             class="form-control form-control-lg font-14"
    //             id="exampleInputText1"
    //             {...register("age", {
    //               // //   required: "Umur is required",
    //             })}
    //           />
    //           <div className="my-2 text-left float-start font-12 text-danger">
    //             <ErrorMessage errors={errors} name="age" />
    //           </div>
    //         </div>

    //         <div class="mb-3">
    //           <input
    //             type="number"
    //             placeholder="Points"
    //             autocomplete="off"
    //             defaultValue={member.points}
    //             class="form-control form-control-lg font-14"
    //             id="exampleInputText1"
    //             {...register("points", {
    //               // //   required: "Points is required",
    //             })}
    //           />
    //           <div className="my-2 text-left float-start font-12 text-danger">
    //             <ErrorMessage errors={errors} name="points" />
    //           </div>
    //         </div>

    //         <Button
    //           variant="primary"
    //           className="button w-100 text-white font-16"
    //           disabled={isLoading}
    //           type="submit"
    //         >
    //           {isLoading ? (
    //             <Spinner
    //               as="span"
    //               animation="border"
    //               size="sm"
    //               role="status"
    //               aria-hidden="true"
    //             />
    //           ) : (
    //             "Daftar"
    //           )}
    //         </Button>
    //       </form>
    //       <div className="mt-3">
    //         <span className="regist">Kembali ke halaman</span>
    //         <span
    //           className="daftar ps-1 text-primary cursor-pointer"
    //           onClick={() => history.push("/member")}
    //         >
    //           List Members
    //         </span>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default EditMember;
