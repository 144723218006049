import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { NotificationManager } from "react-notifications";

const UpdateTambahOngkir= () => {
    const [isLoading, setLoading] = useState(false);
    const history = useHistory();
    const [dataOngkir, setDataOngkir] = useState([]);
    const [id, setId] = useState("");

    const {
      register,
      formState: { errors },
      handleSubmit,
    } = useForm();

    const { api } = useContext(AppContext);

    useEffect(() => {
        setId(history.location.state.id)
        setDataOngkir(history.location.state.data)
    }, [history])

    const onSubmit = async (params) => {
      setLoading(true);
      const formData = new FormData();
      formData.append("id", id);
      formData.append("name", params.name);
      formData.append("distance", params.distance);
      formData.append("amount", params.amount);
      formData.append("description", params.description);
  
      console.log(formData);
      let UpdateDataOngkir = await api("post", "data-ongkir/update", formData);
  
      if (UpdateDataOngkir.success) {
        history.push({ pathname: "/data-ongkir" });
      } else NotificationManager.error(UpdateDataOngkir.message);
  
      setLoading(false);
    };

    return (
        <React.Fragment>
            <nav className="navbar bg-light shadow-sm ">
                <div className="contain p-2 px-cst1 pb-2 ps-4">
                    <Row>
                        <Col className="align-items-center d-flex">
                            <div className="d-inline-block pe-4">
                                <img
                                src="img/arrow-left.svg"
                                alt=""
                                width="30"
                                height="24"
                                onClick={() => history.push("/detail-ongkir")}
                                className="d-inline-block align-text-top cursor-pointer"
                                />
                            </div>
                            <div className="d-inline-block ">
                                <span className="ml-3">Tambah Ongkos Kirim</span>
                            </div>
                        </Col>
                    </Row>
                </div>
            </nav>

            <form onSubmit={handleSubmit(onSubmit)}  className="bg-white p-3">
                  <Form.Group className="font-14 mt-2">
                <Form.Label>Nama Ongkir</Form.Label>
                <Form.Control
                  defaultValue={dataOngkir.name}
                  className="font-14 shadow-none"
                  {...register("name", {
                    required: "Name is required",
                  })}
                />
              </Form.Group>
              <div className="my-2 text-left float-start font-12 text-danger">
                <ErrorMessage errors={errors} name="name" />
              </div>

              <Row>
          <Col>
            <Form.Group className="font-14 mt-2">
              <Form.Label>Jarak Lokasi</Form.Label>
              <Form.Control
                defaultValue={dataOngkir.distance}
                className="font-14 shadow-none"
                {...register("distance", {
                  required: "distance is required",
                })}
              />
            </Form.Group>
            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="weight" />
            </div>
          </Col>
          <Col>
            <Form.Group className="font-14 mt-1">
              <Form.Label>Ongkos</Form.Label>
              <Form.Control
                defaultValue={dataOngkir.amount}
                className="font-14 shadow-none"
                {...register("amount", {
                  required: "amount is required",
                })}
              />
            </Form.Group>
            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="unit_weight" />
            </div>
          </Col>
        </Row>
        <Form.Group className="font-14 mt-2">
        <Form.Label>Deskripsi</Form.Label>
          <textarea
            defaultValue={dataOngkir.description}
            className="font-14 shadow-none w-100 rounded form-control"
            placeholder="Deskripsi"
            {...register("description", {
              required: "description is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="description" />
        </div>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
       
        <Form.Group className="font-14 mt-2 w-100">
          <Button
            variant="primary"
            className="btn sticky-bottom btn-primary  w-100 text-white font-14"
            type="submit"
          >
            Simpan
          </Button>
        </Form.Group>
        {/* <div className="d-grid py-4  fixed-bottom" >
          <center>
            <Button
              style={{width:"700px"}}
              variant="primary"
              className="mt-2 btn btn-primary btn-lg d-block text-white  font-14"
              disabled={isLoading}
              type="submit"
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Simpan"
              )}
            </Button>
          </center>
        </div> */}
            </form>
        </React.Fragment>
    )
};

export default UpdateTambahOngkir;