import { MainRoutes } from "./router/Index";
import { BrowserRouter as Router } from "react-router-dom";
import { AppContextProvider } from "./context/AppContext"; 
import React from "react";

function App() {
  return (
    <React.Fragment>
      <Router>
        <AppContextProvider>
          <MainRoutes />
        </AppContextProvider>
      </Router>  
    </React.Fragment>
  );
}

export default App;
