import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { showImage } from "../../utils/Utils";
import { AppContext } from "../../context/AppContext";
import supplier from "../../assets/images/icon-pelanggan.svg";
import Nama from "../../assets/images/nama-user.svg";
import alamat from "../../assets/images/alamatpenerima.svg";
import toko from "../../assets/images/toko-user.svg";
import telp from "../../assets/images/telp-user.svg";
import email from "../../assets/images/email-user.svg";
import trxIcon from "../../assets/images/trx-icon.svg";

const DetailPelanggan = () => {
  const { api } = useContext(AppContext);
  const history = useHistory();
  const [pelanggan, setPelanggan] = useState({});
  const [id, setId] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!history.location.pelanggan) history.push("/data-pelanggan");
    else {
      setPelanggan(history.location.pelanggan);
      setId(history.location.pelanggan.id)
    }
  }, []);

  useEffect(() => {
    if (pelanggan.id) getData();
  }, [pelanggan]);
  
  const getData = async () => {
    let param = { id: pelanggan.id };
    let pelanggan_detail = await api("get", "user/detail", param);
    setData(pelanggan_detail.data);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-2">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/data-pelanggan")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>

              <div className="d-inline-block ml-2">
                <span className="ml-3">Detail Pelanggan</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="container px-4 mt-4">
        <div className="row">
          <div className="col-mb-12">
            {data.map((item, key) => {
              return (
                <div key={key}>
                  <img src={supplier} alt="" className="w-100" height="200px" />
                  <div className="card mb-3 border-0 ">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="d-flex flex-row flex-shrink-0">
                          <div className="ms-3">
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0 mb-5">
                                <img
                                  src={Nama}
                                  className="mx-3"
                                  width="20"
                                  alt=""
                                />
                              </div>
                              <div className="flex-grow-1 ms-3 mb-3">
                                <h6 className="text-black">Nama Pelanggan</h6>
                                <p className="text-black">{item.first_name} </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="flex-grow-2 ms-3"></div>
                      </div>
                      <br />
                      <div className="d-flex align-items-center">
                        <div className="d-flex flex-row flex-shrink-0">
                          <div className="ms-3">
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0 mb-5">
                                <img
                                  src={toko}
                                  className="mx-3"
                                  width="20"
                                  alt=""
                                />
                              </div>
                              <div className="flex-grow-1 ms-3 mb-3">
                                <h6 className="text-black">Nama Toko</h6>
                                <p className="text-black">
                                  {item.business_name}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="d-flex flex-row flex-shrink-0">
                          <div className="ms-3 mt-3">
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0 mb-5">
                                <img
                                  src={email}
                                  className="mx-3"
                                  width="20"
                                  alt=""
                                />
                              </div>
                              <div className="flex-grow-1 ms-3 mb-3">
                                <h6 className="text-black">Email</h6>
                                <p className="text-black">{item.email}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="d-flex flex-row flex-shrink-0">
                          <div className="ms-3 mt-3">
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0 mb-5">
                                <img
                                  src={telp}
                                  className="mx-3"
                                  width="20"
                                  alt=""
                                />
                              </div>
                              <div className="flex-grow-1 ms-3 mb-3">
                                <h6 className="text-black">No. Telepon</h6>
                                <p className="text-black">{item.phone}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="d-flex flex-row flex-shrink-0">
                          <div className="ms-3 mt-3">
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0 mb-5">
                                <img
                                  src={alamat}
                                  className="mx-3"
                                  width="20"
                                  alt=""
                                />
                              </div>
                              <div className="flex-grow-1 ms-3 mb-3">
                                <h6 className="text-black">Alamat</h6>
                                <p className="text-black">{item.address}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="d-flex flex-row flex-shrink-0">
                          <div className="ms-3 mt-3">
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0 mb-5">
                                <img
                                  src={trxIcon}
                                  className="mx-3"
                                  width="20"
                                  alt=""
                                />
                              </div>
                              <div className="flex-grow-1 ms-3 mb-3">
                                <h6 className="text-black">Total Transaksi</h6>
                                <p className="text-black">{item.count}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="d-flex flex-row flex-shrink-0">
                          <div className="ms-3 mt-3">
                            <div className="d-flex align-items-center">
                              {/* <div className="flex-shrink-0 mb-5">
                                <img
                                  src={trxIcon}
                                  className="mx-3"
                                  width="20"
                                  alt=""
                                />
                              </div>
                              <div className="flex-grow-1 ms-3 mb-3">
                                <h6 className="text-black">
                                  Total TRX Narastore
                                </h6>
                                <p className="text-black">20</p>
                              </div> */}
                              {/* <div className=" mb-5 mx-5 me-3 ">
                                <img
                                  src={trxIcon}
                                  className=""
                                  width="20"
                                  alt=""
                                />
                              </div>
                              <div className="flex-grow-1 ms-3 mb-3">
                                <h6 className="text-black">Total TRX Nara Grosir</h6>
                                <p className="text-black">200</p>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

            <button
              onClick={() => history.push({
                pathname: "/update-pelanggan",
                data: data[0],
              })}
              type="button"
              className="mt-2 btn btn-primary sticky-bottom btn-lg d-block text-white w-100 font-16"
            >
              Ubah Data
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DetailPelanggan;
