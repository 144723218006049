import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { NotificationManager } from "react-notifications";

const TambahOngkir = () => {
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const { api } = useContext(AppContext);

  const onSubmit = async (params) => {
    setLoading(true);
    let TambahOngkir = await api("post", "data-ongkir/post", params);
    if (TambahOngkir.success) {
      history.push({ pathname: "/data-ongkir" });
    } else NotificationManager.error(TambahOngkir.message);

    setLoading(false);
  };
  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/detail-ongkir")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Tambah Ongkos Kirim</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <form onSubmit={handleSubmit(onSubmit)} className="bg-white p-3">
        <Form.Group className="font-14 mt-2">
          <Form.Label>Nama Produk</Form.Label>
          <Form.Control
            className="font-14 shadow-none"
            {...register("name", {
              required: "Name is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="name" />
        </div>

        <Row>
          <Col>
            <Form.Group className="font-14 mt-2">
              <Form.Label>Jarak Lokasi</Form.Label>
              <Form.Control
                className="font-14 shadow-none"
                {...register("distance", {
                  required: "distance is required",
                })}
              />
            </Form.Group>
            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="weight" />
            </div>
          </Col>
          <Col>
            <Form.Group className="font-14 mt-1">
              <Form.Label>Ongkos</Form.Label>
              <Form.Control
                className="font-14 shadow-none"
                {...register("amount", {
                  required: "amount is required",
                })}
              />
            </Form.Group>
            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="unit_weight" />
            </div>
          </Col>
        </Row>
        <Form.Group className="font-14">
          <Form.Label>Deskripsi</Form.Label>
          <textarea
            className="font-14 shadow-none w-100 rounded form-control"
            placeholder="Deskripsi Produk"
            {...register("description", {
              required: "description is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="description" />
        </div>

        <div className="d-grid py-4">
          <center>
            <Button
              variant="primary"
              className="mt-2 btn btn-primary sticky-bottom btn-lg d-block text-white w-100 font-16"
              disabled={isLoading}
              type="submit"
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Ubah Data"
              )}
            </Button>
          </center>
        </div>
      </form>
    </React.Fragment>
  );
};

export default TambahOngkir;
