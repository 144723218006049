import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Modal, Form, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { commafy, showImage } from "../../utils/Utils";
import { NotificationManager } from "react-notifications";
import printIcon from "../../assets/images/print-icon.svg";
import editIcon from "../../assets/images/pencil-square.svg";
import syncIcon from "../../assets/images/sync.svg";

const DetailTransaksiInventori = () => {
  const history = useHistory();
  const { api, setSpinner } = useContext(AppContext);
  const [id, setId] = useState("");
  const [data, setData] = useState([]);
  const [faktur, setFaktur] = useState([]);
  const [detail, setDetail] = useState([]);
  const [note, setNote] = useState([]);
  const [show, setShow] = useState(false);
  const [showPrint, setShowPrint] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const [buktiPembayaran, setBuktiPembayaran] = useState();
  const [latestParam, setLatestParam] = useState();
  const [latestParamEnd, setLatestParamEnd] = useState();

  const handleClose = () => setShow(false);
  const handleClosePrint = () => setShowPrint(false);
  const handleShow = (breakpoint) => {
    setFullscreen(breakpoint);
    setShow(true);
  };

  useEffect(() => {
    if (history.location.idFromEdit) {
      setId(history.location.idFromEdit);
      setData(history.location.dataDetail);
    } else {
      if (!history.location.transaksi)
        history.push("/riwayat-transaksi-inventori");
      else {
        setId(history.location.transaksi.id);
        setData(history.location.transaksi);
        setLatestParam(history.location.latestParam);
        setLatestParamEnd(history.location.latestParamEnd);
        getData(history.location.transaksi.category, history.location.transaksi.id);
      }
    }
  }, [history.location]);

  // useEffect(() => {
  //   if (!id) return;
  //   getData();
  // }, [id]);

  const getData = async (type, id) => {
    setSpinner(true);

    if (type === "Barang Masuk") {
      let data = await api("get", "transactions-inventory/detail", { id: id });
      setDetail(data.data);
      setNote(data.note);
    } else {
      let data = await api("get", "transactions-grosir/detail-for-inv", { id: id });
      setDetail(data.data);
      setNote(data.note);
    }

    let faktur = await api("get", "inventory-images/get", {
      transaction_id: id,
    });
    setFaktur(faktur.data);
    setSpinner(false);
  };

  const changeInputImg = async (value) => {
    setBuktiPembayaran(value);
  };

  const handleClick = async () => {
    let formData = new FormData();
    formData.append("transaction_id", id);
    for (let i = 0; i < buktiPembayaran.length; i++) {
      formData.append("img", buktiPembayaran[i]);
    }

    let upload = await api("post", "inventory-images/post", formData);
    if (upload.success) {
      NotificationManager.success(upload.message);
      getData();
    }
  };

  const print = () => {
    var content = document.getElementById("print");
    content.print();
  };

  const handleSync = async () => {
    setSpinner(true);
    let update = await api("post", "cart-grosir/update-inv-sync");
    setSpinner(false);
    if (update.success) {
      NotificationManager.success(update.message);
    }
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm">
        <div className="contain px-cst1 pb-2 ps-4 w-100">
          <Row>
            <Col className="align-items-center w-100">
              <div className="d-inline-block">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() =>
                    history.push({
                      pathname: "/riwayat-transaksi-inventori",
                      latestParam: latestParam,
                      latestParamEnd: latestParamEnd,
                    })
                  }
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>

              <div className="d-inline-block ms-4 mt-2">
                <span>Detail Transaksi Inventori</span>
              </div>

              <div className="d-inline-block float-end pe-4">
                <div className="float-end align-text-top py-2">
                  <img
                    src={printIcon}
                    width={25}
                    className="cursor-pointer"
                    // onClick={() =>
                    //   history.push({
                    //     pathname: "/cetak-detail-transaksi",
                    //     data: data,
                    //     detail: detail,
                    //   })
                    // }
                    onClick={() => {
                      setShowPrint(true);
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="g-col-6  px-cst1 pb-2 list-denom ">
        <div
          className="card-body border bg-light mt-4 py-2"
          style={{ borderRadius: "10px" }}
        >
          <div className="w-100 d-flex  mt-4 px-4 font-14 fw-bold">
            <h6 className="flex-grow-1 text-dark">Detail Transaksi</h6>
            <h6 className="d-flex px-2 flex-grow-2 text-dark">{data.type}</h6>
          </div>
          <div className="w-100 d-flex px-4  font-14 text-muted">
            <p className="flex-grow-1  ">Supplier</p>
            <p className="d-flex px-2 flex-grow-2">{data.supplier}</p>
          </div>
          <div className="w-100 d-flex px-4  font-14 text-muted">
            <p className="flex-grow-1  ">Pegawai</p>
            <p className="d-flex px-2 flex-grow-2">{data.pegawai}</p>
          </div>
          <div className="w-100 d-flex px-4  font-14 text-muted">
            <p className="flex-grow-1  ">Tanggal Transaksi</p>
            <p className="d-flex px-2 flex-grow-2">{data.date_order}</p>
          </div>
          <div className="w-100 d-flex px-4  font-14 text-muted">
            <p className="flex-grow-1  ">Tanggal Input</p>
            <p className="d-flex px-2 flex-grow-2">{data.createdAt}</p>
          </div>
          <div className="w-100 d-flex px-4  font-14 text-muted">
            <p className="flex-grow-1  ">Sub Total</p>
            <p className="d-flex px-2 flex-grow-2">Rp {commafy(data.price)}</p>
          </div>
          <div className="w-100 d-flex px-4  font-14 text-muted">
            <p className="flex-grow-1  ">Diskon</p>
            <p className="d-flex px-2 flex-grow-2">
              Rp {commafy(data.discount)}
            </p>
          </div>
          <div className="w-100 d-flex px-4  font-14 text-muted">
            <p className="flex-grow-1  ">Diskon Tambahan</p>
            <p className="d-flex px-2 flex-grow-2">
              Rp {commafy(data.discount_additional)}
            </p>
          </div>
          <div className="w-100 d-flex px-4  font-14 text-muted">
            <p className="flex-grow-1  ">Pajak</p>
            <p className="d-flex px-2 flex-grow-2">Rp {commafy(data.tax)}</p>
          </div>
          <div className="w-100 d-flex px-4  font-14 text-muted">
            <p className="flex-grow-1  ">Biaya Lainnya</p>
            <p className="d-flex px-2 flex-grow-2">
              Rp {commafy(data.fee)} ( {data.fee_desc})
            </p>
          </div>
          <div className="w-100 d-flex px-4  font-14 text-muted">
            <p className="flex-grow-1  ">Total</p>
            <p className="d-flex px-2 flex-grow-2">
              Rp {commafy(data.total_price)}
            </p>
          </div>
          <div className="w-100 d-flex px-4  font-14 text-muted">
            <p className="flex-grow-1  ">Status Pembayaran</p>
            <p className="d-flex px-2 flex-grow-2">{data.status_pembayaran}</p>
          </div>
          <div className="w-100 d-flex px-4  font-14 text-muted">
            <p className="flex-grow-1  ">Faktur Pembelian</p>
            <p
              className="d-flex px-2 flex-grow-2 text-primary cursor-pointer"
              onClick={() => setShow(true)}
            >
              Lihat
            </p>
          </div>
          <div className="w-100 d-flex px-4  font-14 text-muted">
            <p className="flex-grow-1  ">Catatan</p>
            <p className="d-flex px-2 flex-grow-2">{data.description}</p>
          </div>
        </div>
        <div
          className="card-body border bg-light mt-4 py-2"
          style={{ borderRadius: "10px" }}
        >
          <div className="w-100 mb-2 px-4 font-14 fw-bold">
            <div className="text-muted float-end m-2">
              <img
                className="cursor-pointer"
                src={editIcon}
                onClick={() =>
                  history.push({
                    pathname: "/edit-inventory",
                    detailTransaksi: detail,
                    data: data,
                  })
                }
              />
              <img
                className="cursor-pointer ms-3"
                src={syncIcon}
                onClick={() => handleSync()}
              />
            </div>
          </div>
          <div className="w-100 d-flex mt-4 mb-2 px-4 font-14 fw-bold">
            <h6 className="flex-grow-1 text-dark">Detail Barang</h6>
            <h6 className="d-flex px-2 flex-grow-2 text-dark">{data.no}</h6>
          </div>
          {detail.map((item, key) => {
            return (
              <div className="w-100 d-flex px-4 font-14 text-muted" key={key}>
                <p className="flex-grow-1">{note === "Grosir" ? item.cart_grosir.products_grosir.name : item.products_grosir.name}</p>
                <p className="d-flex px-2 flex-grow-2">
                  <span className="text-primary ps-2">
                    Rp {commafy(item.cart_grosir.price.toFixed(0)) + " "}
                  </span>
                  <span className="ps-1"> {" x " + item.cart_grosir.qty}</span>
                  <span className="ps-1">
                    = {commafy(item.cart_grosir.total_price)}
                  </span>
                </p>
              </div>
            );
          })}
        </div>
      </div>

      <Modal
        className="border-0"
        show={show}
        fullscreen={fullscreen}
        centered
        onHide={handleClose}
      >
        <Modal.Header className="rounded-top" closeButton>
          <Modal.Title className="w-100">
            <center>
              <h6 className="mt-2">Faktur Pembelian</h6>
            </center>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            <Row>
              {faktur.map((item, key) => {
                return (
                  <Col key={key}>
                    <img className="w-50" src={showImage(item.img)} />
                  </Col>
                );
              })}
            </Row>
            <Form.Group controlId="formFile" className="ms-2 w-25 mt-3">
              <Form.Control
                type="file"
                className="font-14"
                onChange={(e) => {
                  changeInputImg(e.target.files);
                }}
                multiple
              />
            </Form.Group>
            <Button
              className="btn btn-primary text-white mt-2"
              onClick={() => handleClick()}
            >
              Upload
            </Button>
          </center>
        </Modal.Body>
      </Modal>

      <Modal
        className="border-0"
        show={showPrint}
        size="lg"
        onHide={setShowPrint}
      >
        <Modal.Body>
          <div id="section-to-print" className="font-14 w-100">
            <div className="text-center">Nara Store</div>
            <div className="text-center">
              <span>{data.alamat_gudang}</span>
            </div>
            <div className="grid border-bottom ">
              <div className="w-100 d-flex font-12">
                <div className="flex-grow-1"> {data.gudang}</div>
                <div className="d-flex flex-grow-2">{data.date} </div>
              </div>
              <div className="w-100 d-flex font-12">
                <div className="flex-grow-1">{data.supplier}</div>
                <div className="d-flex flex-grow-2 font-12">{data.pegawai}</div>
              </div>
            </div>
            <div className="mt-2 border-bottom">
              {detail.map((item, key) => {
                return (
                  <React.Fragment key={key}>
                    <Row className="font-14">
                      <Col>
                        <span>{note === "Grosir" ? item.cart_grosir.products_grosir.name : item.products_grosir.name}</span>
                      </Col>
                    </Row>
                    <Row className="ms-3">
                      <Col>
                        {/* <span>{commafy(item.price.toFixed(0))} x {commafy(item.qty)}{" "}
                          = Rp {commafy((item.price * item.qty).toFixed(0))}
                        </span> */}
                      </Col>
                    </Row>
                  </React.Fragment>
                );
              })}
            </div>
            <div className="mt-2">
              <span>Total : Rp {commafy(data.price)}</span>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default DetailTransaksiInventori;
