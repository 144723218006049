import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { commafy, showImage, titleCase } from "../../utils/Utils";
import { Card, Carousel, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { NotificationManager } from "react-notifications";

const Products = () => {
  const { api } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [brand, setBrand] = useState({});
  const [category, setCategory] = useState({});
  const [type, setType] = useState([]);
  const history = useHistory();
  const refInput = useRef();

  useEffect(() => { 
    if (!history.location.brand) history.push("/brands");
    else {
      setBrand(history.location.brand);
      setCategory(history.location.category);
      refInput.current.value = history.location.input;
    }
  }, []);

  useEffect(() => {
    if (brand.id && category.id) getDataType();
  }, [brand, category]);

  useEffect(() => {
    if (!type.length) return;
    let typeActive = _.find(type, (item) => {
      return item.is_active === true;
    });
    getData(typeActive.id);
  }, [type]);

  const getData = async (type) => {
    setData([]);
    let param = {
      id: "i8l7DzgKEatN3e7SNCI38mA67ey0o5SiFgMKEJl7Va5pnD0BKW",
      view: "product",
      category: category.id,
      brand: brand.id,
      type: type,
    };
    let data = await api("get", "produk/public", param);
    setData(data.data);
  };

  const getDataType = async () => {
    let param = {
      id: "i8l7DzgKEatN3e7SNCI38mA67ey0o5SiFgMKEJl7Va5pnD0BKW",
      view: "type",
      category: category.id,
      brand: brand.id,
    };
    let data = await api("get", "produk/public", param);
    data.data.map((item, key) => {
      Object.assign(item, { is_active: key === 0 ? true : false });
    });
    setType(data.data);
  };

  const handleClickType = (param) => {
    let data = _.map(type, (item) => {
      return item.name === param.name
        ? { ...param, is_active: true }
        : { ...item, is_active: false };
    });
    setType(data);
    getData(param.id);
  };

  const changeInput = (item) => {};

  const handleClick = (item) => {
    if (refInput.current.value.trim() === "") {
      NotificationManager.error("No tujuan tidak boleh kosong.");
      return;
    }
    history.push({
      pathname: "/payment",
      product: item,
      category: category,
      brand: brand,
      input: refInput.current.value,
    });
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm cursor-pointer">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-2">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() =>
                    history.push({
                      pathname: category.name === "Games"? "/games": "/brands",
                      brand: brand,
                      category: category,
                    })
                  }
                  className="d-inline-block align-text-top "
                />
              </div>

              <div className="d-inline-block ml-2">
                <span className="ml-3">{brand.name}</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="g-col-6 content bg-light py-4">
        <form>
          <div className="input-group">
            <input
              type="number"
              className="form-control form-control-lg"
              placeholder={category.msg_input}
              ref={refInput}
              onChange={() => changeInput()}
              aria-describedby="basic-addon2"
            />
          </div>
        </form>
        <div className="g-col-6 bg-light px-cst1 pb-2 list-denom mt-4 w-100">
          <div className="d-flex w-100" style={{ overflow: "scroll" }}>
            {type.map((item, key) => {
              return (
                <div
                  key={key + 11}
                  className="d-inline-blok pe-2 w-auto text-nowrap"
                  onClick={() => handleClickType(item)}
                >
                  <div
                    className={
                      item.is_active
                        ? "card py-2 cursor-pointer bg-primary text-white px-2 text-center"
                        : "card py-2 cursor-pointer text-dark px-2 text-center"
                    }
                    style={{ minWidth: "80px" }}
                  >
                    <span className="font-12">{item.name}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="g-col-6 bg-light px-cst1 pb-2 list-denom mt-3">
          <h6>Pilih Nominal</h6>
          <Row>
            {data.map((item, key) => {
              return (
                <Col
                  key={key + 12}
                  md={category.column === 2 ? "6" : "12"}
                  onClick={() => handleClick(item)}
                >
                  <div className="card mb-3 cursor-pointer">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-3">
                          <p className="text-muted mb-0">{item.brand}</p>
                          <div className="d-flex">
                            <span className="mb-0 font-14 text-primary ">
                              {titleCase(
                                item.name
                                  .toLowerCase()
                                  .replace(item.brand.toLowerCase(), "")
                              )}
                            </span>
                            <p className="ms-auto mb-0 font-14">
                              Harga Rp {commafy(item.price)}
                            </p>
                          </div>
                        </div>
                      </div>
                      <span className="promo position-absolute top-0 end-0 text-light py-1 px-2">
                        {item.status}
                      </span>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Products;
