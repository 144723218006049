import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { showImage } from "../../../utils/Utils";
import { Card, Carousel, Col, Nav, Row } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import filter from "../../../assets/images/filter.svg";
import love from "../../../assets/images/love.svg";
import shop from "../../../assets/images/shop.svg";
import _ from "lodash";
import { commafy } from "../../../utils/Utils";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import "moment/locale/id";
moment.locale("id");

const ListRewardPoints = () => {
  const { api } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [dataRewardPoints, setDataRewardPoints] = useState([]);
  const [category, setCategory] = useState([]);
  const [gudang, setGudang] = useState([]);
  const [categoryId, setCategoryId] = useState(0);
  const history = useHistory();
  const refInput = useRef();
  const { setSpinner } = useContext(AppContext);

  useEffect(() => {
    getData();
    getGudang();
    getDataRewardPoints();
  }, [history]);

  const getDataRewardPoints = async () => {
    setSpinner(true);
    let result = await api("get", "reward-points");
    if (result.success) {
      setDataRewardPoints(result.data);
    }
    setSpinner(false);
  };

  const getData = async () => {
    setSpinner(true);
    if (history.location.category_id) {
      setCategoryId(history.location.category_id);
      let data = await api("get", "products-grosir/get", {
        category_id: history.location.category_id,
      });
      setData(data.data);
    } else {
      let data = await api("get", "products-grosir/get");
      setData(data.data);
    }

    let category = await api("get", "category-grosir/get", {});
    setCategory(category.data);
    setSpinner(false);
  };

  const selectSingle = (id, selected) => {
    let newData = data.map((item) => {
      if (item.category_id === id) item.selected = selected;
      console.log(item);
      return item;
    });

    setData(newData);
  };

  const getGudang = async () => {
    let gudang = await api("get", "gudang/get");
    setGudang(gudang.data);
  };

  const handleClickType = async (param) => {
    let category_id = {
      category_id: param.id,
    };
    setSpinner(true);
    let data = await api("get", "products-grosir/get", category_id);
    setData(data.data);
    setSpinner(false);
  };

  // const handleClick = async (param) => {
  //     let params = {
  //       product_id: param.id,
  //       user_id: profile.first_name,
  //     };
  //     console.log(params);

  //     let postWishlist = await api("post", "wishlist-grosir/post", params);
  // };

  const changeInput = async () => {
    let value = refInput.current.value;
    let keyword = {
      name: value,
    };
    let data = await api("get", "reward-points", keyword);
    setDataRewardPoints(data.data);
  };

  const semuaProduct = async () => {
    setSpinner(true);
    let semua = await api("get", "products-grosir/get");
    setData(semua.data);
    setSpinner(false);
  };

  const deleteRewardPoints = async (rewardPointsId) => {
    setSpinner(true);
    let params = {
      id: rewardPointsId,
    };
    let result = await api("post", "reward-points/delete", params);
    if (result.success) {
      getDataRewardPoints();
      NotificationManager.success(result.message, "Sukses");
      setSpinner(false);
      return;
    } else {
      NotificationManager.error(result.message, "Gagal");
    }
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm cursor-pointer w-100">
        <div className="p-2 pb-2 ps-4 w-100 d-flex align-items-center">
          <div className="d-inline-block">
            <img
              src="img/arrow-left.svg"
              alt=""
              width="30"
              height="24"
              onClick={() => history.push("/data-master")}
              className="d-inline-block align-text-top "
            />
          </div>
          <div className="d-flex align-items-center justify-content-between w-100 gap-2 ms-2">
            <form className="w-100">
              <div className="input-group w-100">
                <input
                  type="text"
                  className="form-control form-control-lg font-16"
                  placeholder="Cari produk"
                  ref={refInput}
                  aria-describedby="basic-addon2"
                  onChange={() => changeInput()}
                />
                <button className="input-group-text bg-white border">
                  <i className="bi bi-search"></i>
                </button>
              </div>
            </form>
            <Link
              to="/reward-points/store"
              className="btn btn-primary text-white"
            >
              Tambah Reward
            </Link>
          </div>
        </div>
      </nav>
      <Row className="no-gutters px-3 mt-2">
        {dataRewardPoints.map((item, key) => {
          return (
            <div className="col-6 p-2" key={key}>
              <div className="card w-100 border-0 mb-3 shadow cursor-pointer">
                <center>
                  <div className="bg-lime rounded m-2">
                    {/* <div className="w-100">
                      <img
                        src={
                          item.wishlist_grosir
                            ? "img/love_red.svg"
                            : "img/love_white.svg"
                        }
                        alt=""
                        className="float-end mt-2 me-2"
                        width="30"
                        height="30"
                        onClick={() => addToWish(item)}
                      />
                    </div> */}
                    <div
                      className="p-3"
                      onClick={() =>
                        history.push({
                          pathname: "/deskripsi-reward-produk",
                          product: item.products_grosir,
                          points: item.points,
                          rewardPoint: item,
                          type: 1,
                        })
                      }
                    >
                      <img
                        className="card-img-top mt-2"
                        src={showImage(item.products_grosir.img)}
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>
                  </div>
                </center>
                <div className="card-body ">
                  <div>
                    <div
                      className="font-16 font-weight-bold"
                      style={{ height: "45px" }}
                    >
                      <span className="mt-1">{item.products_grosir.name}</span>
                    </div>
                    <span className="text-muted font-13">
                      {item.products_grosir.weight}{" "}
                      {item.products_grosir.unit_weight}
                    </span>
                    <p className="fw-bold mb-0 font-13">Point : {item.point}</p>
                    <span className="text-muted font-13">
                      Tgl Expired :{" "}
                      {moment(item.expired_at).format("DD MMMM YYYY")}
                    </span>
                    <div className="w-100 d-flex mt-1 font-14">
                      <h6 className="font-weight-bold text-primary ">
                        {/* {console.log(item)} */}
                        Rp {commafy(item.products_grosir.price)}
                      </h6>
                      <small className="ms-2 text-secondary font-13">
                        <del> Rp{commafy(item.products_grosir.price)}</del>
                      </small>
                    </div>
                    <div className="d-flex justify-content-end gap-2">
                      <Link
                        to={`/reward-points/get?id=${item.id}&product_id=${item.product_id}`}
                        className="btn btn-success text-white"
                      >
                        Edit
                      </Link>
                      <button
                        className="btn btn-danger"
                        onClick={() => deleteRewardPoints(item.id)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Row>
      <div className="row justify-content-center sticky-bottom border-top filter">
        <div className="col-md-12">
          <div className="grid">
            <div
              className="offcanvas offcanvas-bottom g-col-6 rounded-top"
              tabIndex="-1"
              id="filter"
              aria-labelledby="shortLabel"
              style={{ height: "490px" }}
            >
              <div className="offcanvas-body">
                <div className="grid">
                  <div className="g-col-12 px-4 py-3 bg-white">
                    <p className="fs-6 mb- fw-bold">Filter</p>
                    <div className="container px-0 py-1">
                      {category.map((item, key) => {
                        return (
                          <div className="row gx-8">
                            <div class="form-check ms-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                                onClick={(e) =>
                                  selectSingle(item.id, e.target.checked)
                                }
                              />
                              <label
                                class="form-check-label mt-1 ms-2"
                                for="flexCheckDefault"
                              >
                                {item.name}
                              </label>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <hr className="mt-4 hr-product " />
                    <p className="mt-4">Pilih Gudang</p>
                    <div className="container px-0  text-center">
                      <div className="row gx-8">
                        {gudang.map((item, key) => {
                          return (
                            <a href="#" className="col text-decoration-none">
                              <div className="d-grid">
                                <button
                                  type="button"
                                  className="text-gudang btn btn-outline-primary  btn-lg border d-block"
                                >
                                  {item.name}
                                  <br />
                                  <small className="font-10 text-gudang ">
                                    Jarak 8,3km
                                  </small>
                                </button>
                              </div>
                            </a>
                          );
                        })}
                      </div>
                    </div>

                    <div className="container px-0 py-5 text-center">
                      <div className="row gx-8">
                        <a href="#" className="col text-decoration-none">
                          <div className="d-grid">
                            <button
                              type="button"
                              className="btn btn-outline-secondary btn-lg border d-block"
                            >
                              Reset
                            </button>
                          </div>
                        </a>
                        <a href="#" className="col text-decoration-none">
                          <div className="d-grid">
                            <button
                              type="button"
                              className="btn btn-primary btn-lg d-block text-white"
                            >
                              Terapkan
                            </button>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ListRewardPoints;
