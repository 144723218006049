import React, { useContext, useEffect, useState }  from "react";
import './Profile.css'
import Card from 'react-bootstrap/Card';
import back from "../../../assets/images/back.png"
import { useForm } from "react-hook-form";
import { AppContext } from "../../../context/AppContext";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Button, Spinner, Form } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message";
import { useHistory } from "react-router-dom";



const AturKoneksi = () => {
    const history = useHistory();
    const [isLoading, setLoading] = useState(false)
    const [data, setData] = useState(false)
    const { api, setSpinner } = useContext(AppContext)
    const {
      register,
      formState: { errors },
      handleSubmit,
      setValue,
      setError,
    } = useForm({ mode: "onBlur" })
  
    useEffect(() => {
      let token = localStorage.getItem("usergameshubcloudtoken")
      // if (!token) {
      //   window.location.href = "/pengaturankoneksi"
      //   return
      // }
      getData()
    }, [])
  
    const getData = async () => {
      setSpinner(true)
      let data = await api("post", "connection-ip/edit", {})
      if (data.success) {
        setData(data.data)
        setValue("development_ip", data.data.development_ip, {
          shouldValidate: false,
        })
        setValue("production_ip", data.data.production_ip, {
          shouldValidate: false,
        })
        setValue("callback_url", data.data.callback_url, {
          shouldValidate: false,
        })
        setValue("type", data.data.type, {
          shouldValidate: false,
        })
      }
      setSpinner(false)
    }
  
    const onSubmit = async (params) => {
      setLoading(true)
      console.log(params);
      // return
      let data = await api("put", "connection-ip/update", params)
      if (data.success) {
        NotificationManager.success(data.message)
      }
      setLoading(false)
    }


    return (
        <React.Fragment> 
            <nav className="navbar bg-light shadow-sm cursor-pointer">
                <div className="contain p-2 px-cst1 pb-2 ps-4">
                    <Row>
                        <Col className="align-items-center d-flex">
                            <div className="d-inline-block pe-2">
                                <img
                                src="img/arrow-left.svg"
                                alt=""
                                width="30"
                                height="24"
                                onClick={() => history.push("/pengaturan-koneksi")}
                                className="d-inline-block align-text-top "
                                />
                            </div>

                            <div className="d-inline-block ml-2">
                                <span className="ml-3">Atur Ulang Koneksi</span>
                            </div>
                        </Col>
                    </Row>
                </div>
            </nav>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-7 px-0 bg-top">
                    <div className="mt-4 mb-4 text-muted" >
                        <h6>Atur Koneksi</h6>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Control type="text" placeholder="Whitelist IP" 
                                {...register("production_ip", {
                                  required: "whitelist_ip is required",
                                })}
                              />
                          </Form.Group>
                      </div>
                      <div>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Control type="text" placeholder="Callback URL" 
                                {...register("callback_url", {
                                  required: "callback_url is required",
                                })}
                              />
                          </Form.Group>
                      </div>
                      <div className="d-grid gap-2">
                        {/* <Link to="/koneksi"> */}
                            <Button
                                variant="teal"
                                type="submit"
                                className="nextbutt w-100 text-white bggg"
                            >
                                Simpan
                          </Button>
                       {/* </Link> */}
                     </div>
                    </form>
                    <br />
                    <center>
                        <a href="/otp">
                        <h5 className="verifikasi">Verifikasi OTP</h5>
                        </a>
                    </center>
                    <br />
                    
                </div>
            </div>
            <div class="d-grid ">
                        <button
                            onClick={() => {
                            history.push("/pengaturan-koneksi");
                            }}
                            type="button"
                            class="mt-2 btn btn-primary btn-lg d-block text-white  font-16"
                        >
                            Simpan
                        </button>
                </div> 
        </div>
    </React.Fragment>
   )
 }
export default AturKoneksi