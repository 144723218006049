import React, { useContext, useEffect, useState, useRef } from "react";
import { AppContext } from "../../../context/AppContext";
import { Row, Offcanvas, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { showImage } from "../../../utils/Utils";
import imgKeranjang from "../../../assets/images/keranjang.svg";
import imgTroli from "../../../assets/images/troli.svg";
import DesProduk from "./DesProduk";
import PenilaianProduk from "./PenilaianProduk";
import { commafy } from "../../../utils/Utils";
import { NotificationManager } from "react-notifications";
import ReactStars from "react-stars";
import birupanah from "../../../assets/images/panahbiru.svg";

const DeskripsiProduk = () => {
  const history = useHistory();
  let ref = useRef();
  useEffect(() => {}, []);
  const { api, setSpinner } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [feedback, setFeedback] = useState([]);
  const [imgages, setImg] = useState([]);
  const [rating, setRating] = useState(null);
  const [diluarJangakaun, setDiluarJangkauan] = useState(false);
  const [profile, setProfile] = useState([]);
  const [product, setProduct] = useState([]);
  const [num, setNum] = useState(1);
  const [id, setId] = useState([]);
  const [description, setDescription] = useState([]);
  const [count, setCount] = useState(0);
  const [qty, setQty] = useState(1);
  let refDiv = useRef(null);
  const [show, setShow] = useState(false);
  const [showPrice, setShowPrice] = useState(false);
  const [productPrice, setProductPrice] = useState([]);
  const [hargaGrosir, setHargaGrosir] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClosePrice = () => setShowPrice(false);
  const handleShowPrice = () => setShowPrice(true);

  useEffect(() => {
    if (!history.location.product) history.push("/grosir-home");
    else {
      setId(history.location.product.id);
      setProduct(history.location.product);
      setDescription(history.location.product.description);
    }
  }, []);

  useEffect(() => {
    if (product.id) {
      getData();
      getProductPrice(product.id);
    }
  }, [product]);

  const getData = async () => {
    setSpinner(true);
    const param = {
      id: id,
    };
    const data = await api("get", "products-grosir/detail-product", param);
    if (data.data.length) {
      setImg(data.data[0].products_images);
      let ongkir = await api("get", "data-ongkir/get-byname", {
        name: "Kurir Toko",
        lat: data.data[0].gudang.lat,
        lng: data.data[0].gudang.lng,
      });
      if (!ongkir.data.length) {
        setDiluarJangkauan(true);
      }
    }
    setSpinner(false);
    setData(data.data);

    const paramFeedback = {
      product_id: id,
    };

    const feedback = await api(
      "get",
      "feedback-grosir/get-by-product",
      paramFeedback
    );
    if (feedback.data.length) {
      let rattingTmp = 0;
      feedback.data.map((item) => {
        rattingTmp = rattingTmp + parseInt(item.rating);
      });
      rattingTmp = (rattingTmp / feedback.data.length).toFixed(0);
      setRating(parseInt(rattingTmp));
    } else setRating("0");
    setFeedback(feedback.data);
    getCount();
  };

  const getCount = async () => {
    const count = await api("get", "cart-grosir/count-by-user");
    if (count.success) setCount(count.data[0].count);
  };

  const getProductPrice = async (id) => {
    setSpinner(true);
    let productPrice = await api("get", "products-price/get", {
      product_id: id,
    });
    if (productPrice.success) {
      setProductPrice(productPrice.data);
    }
    setSpinner(false);
  };

  const getHargaGrosir = async (qty) => {
    let hargaGrosir = await api("get", "products-price/get-price", {
      qty: qty,
      product_id: id,
    });
    if (hargaGrosir.data.length) {
      let newData = data.map((item) => {
        item.price = hargaGrosir.data[0].price;
        return item;
      });
      setData(newData);
    }
  };

  const addToWish = async (item) => {
    setSpinner(true);
    let params = {
      product_id: item.id,
    };
    let postWishlist = await api("post", "wishlist-grosir/post", params);
    if (postWishlist.success) {
      NotificationManager.success(postWishlist.message);
      getData();
    } else NotificationManager.error(postWishlist.message);
    setSpinner(false);
  };

  const addCart = async (param) => {
    let paramsCart = {
      product_id: param.id,
      status: "open",
      price: param.price - param.discount,
      price_before: param.price,
      total_price: param.price - param.discount,
      total_price_before: param.price,
    };
    setSpinner(true);
    let postCart = await api("post", "cart-grosir/post", paramsCart);
    setSpinner(false);
    if (postCart.success) {
      NotificationManager.success(postCart.message);
    }

    getCount();
  };

  const incrementDecrement = async (isInc) => {
    let qtyTmp = isInc ? qty + 1 : qty - 1;
    if (qty === 1 && !isInc) return qty;
    setQty(qtyTmp);
    getHargaGrosir(qtyTmp);
  };

  const handleClick = () => {
    let item = data[0];
    let param = {
      distance: item.distance,
      products_grosir: {
        img: item.img,
        name: item.name,
        gudang: item.gudang,
        discount: item.discount,
        price: parseInt(item.price),
        price_before: parseInt(item.price_before),
        stock: item.stock,
      },
      product_id: item.id,
      qty: qty,
      total_margin: qty * item.margin,
    };
    history.push({
      pathname: "checkout",
      data: [param],
      isDesc: true,
      ori: data,
    });
  };

  return (
    <React.Fragment>
      {data.map((item, key) => {
        return (
          <div key={key}>
            <div className="bg-lime" ref={refDiv}>
              <div className="p-2 px-2 pb-2 ps-4">
                <Row>
                  <Col className="align-items-center d-flex cursor-pointer me-2">
                    <div className="d-inline-block pe-2">
                      <img
                        src="img/arrow-left.svg"
                        alt=""
                        width="30"
                        height="24"
                        onClick={() => history.push("/grosir-home")}
                        className="d-inline-block align-text-top "
                      />
                    </div>

                    <div className="w-100">
                      <div className="float-end">
                        <img
                          src={
                            item.wishlist_grosir
                              ? "img/love_red.svg"
                              : "img/love_white.svg"
                          }
                          alt=""
                          className="float-end me-1"
                          width="30"
                          height="30"
                          onClick={() => addToWish(item)}
                        />
                      </div>
                    </div>

                    <div
                      className="button"
                      onClick={() => history.push("/keranjang")}
                    >
                      <img src={imgTroli} width={30} alt="" />
                      <span
                        className="button__badge"
                        hidden={count === 0 ? true : false}
                      >
                        {count}
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <Carousel style={{ minHeight: "250px" }}>
                  {imgages.map((item, key) => {
                    return (
                      <Carousel.Item
                        key={key}
                        className="w-100 d-flex justify-content-center align-items-center"
                      >
                        <img
                          style={{ width: "250px" }}
                          src={showImage(item.img)}
                        />
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              </div>
            </div>

            <div className="container pt-3 bg-white">
              <div className="row">
                <div>
                  <h4>{item.name}</h4>
                  {/* <p class="">{item.description.split(".")[0]}</p> */}
                  <div dangerouslySetInnerHTML={{ __html: item.description.split(".")[0] }} />
                </div>
              </div>
            </div>
            <div className="card-body ms-2 bg-white ">
              <div className="w-100 d-flex align-items-center font-14">
                <h3 className=" font-weight-bold text-warning ">
                  Rp {commafy(item.price)}
                </h3>
                <h6 className="ms-2 text-secondary">
                  <del> Rp {commafy(item.price_before)}</del>
                </h6>
              </div>
            </div>

            <div className="container bg-white pb-2">
              <div className="row ">
                <div className="col d-inline-block pe-2 float-left d-flex align-items-center">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="small-ratings">
                      {rating ? (
                        <ReactStars
                          count={5}
                          size={18}
                          edit={false}
                          value={rating}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="flex-box  ms-2">
                    <small className="fs-6">{rating}</small>
                  </div>
                  <div className="flex-box ms-2">
                    <small className="fs-6">|</small>
                  </div>
                  <div className="flex-box ms-2">
                    <small className="fs-6">
                      {item.sell ? item.sell : 0} Terjual
                    </small>
                  </div>
                </div>
              </div>
            </div>

            {item.products_prices.length > 1 ? (
              <div className="container p-3 bg-white mt-3 font-13">
                <Row>
                  <Col>
                    <span>Harga Grosir</span>
                  </Col>
                  <Col onClick={handleShowPrice}>
                    <span className="float-end cursor-pointer">
                      Beli (
                      {"Minimal" + " " + item.products_prices[1].qty + " Pcs"})
                      Rp{" "}
                      {commafy(item.products_prices[1].price)}
                      <img src="img/next.svg" height="15" />
                    </span>
                  </Col>
                </Row>
              </div>
            ) : (
              <div></div>
            )}

            <div
              className="grid riwayat bg-white mt-3"
              style={{ minHeight: "300px" }}
            >
              <div className="g-col-6 content">
                <ul
                  className="nav nav-pills nav-fill mb-2 "
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item text-muted" role="presentation">
                    <button
                      className="nav-link active py-3"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Deskripsi Produk
                    </button>
                  </li>
                  <li className="nav-item text-muted" role="presentation">
                    <button
                      className="nav-link py-3 px-4"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                      onClick={() =>
                        history.push({
                          pathname: "",
                          product_id: data.id,
                        })
                      }
                    >
                      Penilaian Produk
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <DesProduk description={description} />
                  <PenilaianProduk rating={feedback} />
                </div>
              </div>
            </div>
            <div
              className="bg-primary p-2 mx-0 sticky-bottom"
              hidden={diluarJangakaun ? false : true}
            >
              <div className="p-2 text-light ">
                Area kamu melebihi batas jangkauan pengiriman, silahkan pilih
                produk yang tersedia di gudang terdekat Anda.
              </div>
            </div>
            <div
              className="bg-primary p-2 mx-0 sticky-bottom"
              hidden={item.stock === 0 ? false : true}
            >
              <div className="p-2 text-light">
                Maaf stok produk habis, silahkan pilih produk lainnya.
              </div>
            </div>
            <div
              hidden={item.stock === 0 || diluarJangakaun ? true : false}
              className="sticky-bottom bg-white mt-5 py-3 px-2 border-top contain row mb-2 g-2 g-lg-3 mx-0"
            >
              <div class="d-grid col-sm-6 col-md">
                <button
                  onClick={() => addCart(item, profile)}
                  type="button"
                  class="mt-2 btn border-primary text-primary font-16"
                >
                  <img
                    src={imgKeranjang}
                    alt=""
                    width="20"
                    height="18"
                    className="d-inline-block align-text-top me-2 mb-2"
                  />
                  Keranjang
                </button>
              </div>

              <div class="d-grid col-sm-6 col-md">
                <button
                  onClick={() => setShow(true)}
                  type="button"
                  class="mt-2 btn btn-primary btn-lg d-block text-white font-16"
                >
                  Beli Sekarang
                </button>
              </div>
            </div>
          </div>
        );
      })}

      <div className="row justify-content-center sticky-bottom border-top filter">
        <div className="col-md-12">
          <Offcanvas
            show={show}
            placement="bottom"
            onHide={() => setShow(false)}
            className="rounded-top detail-product"
          >
            <Offcanvas.Body>
              <div className="grid">
                <div className="g-col-12 px-4 py-3 bg-white">
                  <div className="container px-0 py-1">
                    <div className="row gx-8">
                      {data.map((item, key) => {
                        return (
                          <Row key={key}>
                            <Col md="4">
                              <div
                                className="bg-lime p-2 rounded align-items-center justify-content-center d-flex"
                                style={{ height: "120px" }}
                              >
                                <img
                                  src={showImage(item.img)}
                                  alt=""
                                  onClick={() =>
                                    history.push("/deskripsi-produk")
                                  }
                                  className="w-50"
                                />
                              </div>
                            </Col>
                            <Col>
                              <div>
                                {item.products_prices.length > 1 ? (
                                  <>
                                    <span className="text-danger">
                                      Tersedia Harga Grosir
                                    </span>
                                    <br />
                                  </>
                                ) : null}
                                <del className="text-secondary font-12">
                                  Rp {commafy(item.price_before)}
                                </del>
                                <h4 className="text-dark mb-0">
                                  Rp {commafy(item.price)}
                                </h4>
                              </div>
                              <p className="text-dark mb-0 text-muted mt-1">
                                {item.weight + item.unit_weight}
                              </p>
                              <p className="text-dark mb-0 text-muted mt-1">
                                Stok : {item.stock}
                              </p>
                              <img
                                src={"img/ic_loc.svg"}
                                className=""
                                width="10"
                              />
                              <small className="text-dark mb-0 text-muted mt-1 ms-1">
                                {item.gudang.name +
                                  " " +
                                  item.distance.distance +
                                  item.distance.unit}
                              </small>
                            </Col>
                          </Row>
                        );
                      })}
                    </div>
                  </div>
                  <hr className="mt-4 hr-product " />
                  <div className="w-100 d-flex">
                    <div className="d-inline-block mt-2">
                      <span>Jumlah</span>
                    </div>
                    <div className="float-end ms-auto">
                      <div
                        className="input-group ms-auto mb-0 "
                        style={{ width: "126px" }}
                      >
                        <button
                          className="col btn border rounded-left   "
                          type="submit"
                          onClick={() => incrementDecrement(false)}
                        >
                          -
                        </button>
                        <small className="col btn border ">{qty}</small>
                        <button
                          className="col btn border rounded-right "
                          type="submit"
                          onClick={() => incrementDecrement(true)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="container px-0 py-4 text-center">
                    <div className="row">
                      <div className="d-grid">
                        <button
                          type="button"
                          className="btn btn-primary  d-block text-white font-16"
                          onClick={() => handleClick()}
                        >
                          Beli Sekarang
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </div>

      <Offcanvas
        show={showPrice}
        onHide={handleClosePrice}
        placement="bottom"
        className="rounded-top d-grid"
      >
        <Offcanvas.Body border>
          <center>
            <h6>Harga Grosir</h6>
            <Row>
              <Col>
                <span>Jumlah</span>
              </Col>
              <Col>
                <span>Harga Satuan</span>
              </Col>
            </Row>
            <hr />
            {productPrice.map((item, key) => {
              return (
                <Row key={key} className="mt-1">
                  <Col>
                    <span>{item.qty + " - " + item.qty_max}</span>
                  </Col>
                  <Col>
                    <span>
                      Rp {commafy(item.price)}
                    </span>
                  </Col>
                </Row>
              );
            })}
          </center>
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
};
export default DeskripsiProduk;
