import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { NotificationManager } from "react-notifications";
import { Button, Spinner, Row, Col, Form } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import moment from "moment";

const EditRewardPoints = () => {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [inputValue, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const [rewardPoints, setRewardPoints] = useState({});
  const [productGrosir, setProductGrosir] = useState({});
  const {
    register,
    formState: { errors },
    handleSubmit,
    // reset
  } = useForm();

  const { api, setSpinner } = useContext(AppContext);
  const searchParams = new URLSearchParams(document.location.search);
  const id = searchParams.get("id");
  const product_id = searchParams.get("product_id");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setSpinner(true);
    let result = await api(
      "get",
      `reward-points/get?id=${id}&product_id=${product_id}`
    );
    if (result.success) {
      setRewardPoints(result.data);
      setProductGrosir(result.product_grosir);
    } else {
      NotificationManager.warning(result.message);
    }
    setSpinner(false);
  };

  // handle input change event
  const handleInputChange = (value) => {
    setValue(value);
  };

  // handle selection
  const handleChange = (value) => {
    setSelectedValue(value);
  };

  const loadOptions = async (searchValue, callback) => {
    let result = await api("get", "products-grosir/get?admin=true");
    let getData = result.data;
    // console.log(result)
    const filteredOptions = getData.filter((option) =>
      option.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    // console.log("loadOptions", searchValue, filteredOptions);
    callback(filteredOptions);
    return getData;
  };

  const onSubmit = async (params) => {
    setLoading(true);
    params = {
      id: rewardPoints.id,
      product_id: selectedValue == null ? productGrosir.id : selectedValue.id,
      point: params.point || rewardPoints.point,
      expired_at: params.expired_at || rewardPoints.expired_at,
    };
    let updateRewardPoints = await api("post", "reward-points/update", params);
    if (updateRewardPoints.success) {
      history.push("/reward-points");
      NotificationManager.success(updateRewardPoints.message);
      // reset()
    } else {
      NotificationManager.warning(updateRewardPoints.message);
    }
    setLoading(false);
  };

  // nyobain

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="/img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/reward-points")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Ubah Reward Points</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <form onSubmit={handleSubmit(onSubmit)} className="bg-white p-3">
        <Form.Group className="font-14 mt-2" style={{ zIndex: "999" }}>
          <Form.Label>Nama Product</Form.Label>
          {/* <pre>Input Value: "{inputValue}"</pre> */}
          <AsyncSelect
            cacheOptions
            defaultOptions
            value={selectedValue == null ? productGrosir : selectedValue}
            getOptionLabel={(e) => e.name}
            getOptionValue={(e) => e.id}
            loadOptions={loadOptions}
            onInputChange={handleInputChange}
            onChange={handleChange}
          />
          {/* <pre>
            Selected Value: {JSON.stringify(selectedValue || {}, null, 2)}
          </pre> */}
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="name" />
        </div>
        <Form.Group className="font-14 mt-2">
          <Form.Label>Point</Form.Label>
          <Form.Control
            className="font-14 shadow-none"
            defaultValue={rewardPoints.point}
            {...register("point", {
              // required: "point is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="point" />
        </div>
        <Form.Group className="font-14 mt-2">
          <Form.Label>Expired At</Form.Label>
          <Form.Control
            type="date"
            defaultValue={rewardPoints.expired_at}
            className="font-14 shadow-none"
            {...register("expired_at", {
              required: "expired_at is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="expired_at" />
        </div>

        <Form.Group className="font-14 mt-4 w-100">
          <Button
            variant="primary"
            className="btn btn-primary w-100 text-white font-14"
            type="submit"
          >
            Simpan
          </Button>
        </Form.Group>
      </form>
    </React.Fragment>
  );
};

export default EditRewardPoints;
