import React, { useContext, useEffect, useRef, useState } from "react"; 
import {Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";  
import dompet from "../../../assets/images/dompet.svg"
import bank from "../../../assets/images/bank.svg"
import { AppContext } from "../../../context/AppContext";
import { NotificationManager } from "react-notifications";

const PembayaranCO = () => {
  const { api, setSpinner } = useContext(AppContext); 
  const history = useHistory(); 
  const [saldo, setSaldo] = useState(0);
  const [metode, setMetode] = useState("");
  const [data, setData] = useState({
    distance: {},
    products_grosir: { gudang: {} },
  }); 
  const isFirstRun = useRef(true);

  useEffect(() => {
    // if (isFirstRun.current) {
    //   isFirstRun.current = false;
    //   return;
    // }

    if (!history.location.data) history.push("/keranjang");
    else{
      getSaldo()
      setData(history.location.data); 
    } 
  }, [history.location.data]);
  

  const getSaldo = async () => {
    setSpinner(true)
    let saldo = await api("get", "saldo-history/get-saldo"); 
    setSaldo(saldo.data.nominal);
    setSpinner(false)
  };

  const handleClick = async()=>{
    if(metode === ""){
      NotificationManager.error("Silahkan pilih metode pembayaran.")
      return
    }  
    
   let param = { 
      recipient_address: data.address,  
      price: data.price,
      gudang_distance: "Gudang " + data.products_grosir.gudang.name + " " + data.distance.distance + data.distance.unit,
      ongkir: data.ongkir,
      fee: data.fee,
      total: data.total,
      payment_method: metode,
      gudang_id: data.products_grosir.gudang.id,
      delivery_type: data.pengiriman,  
      product_id : data.product_id,
      total_margin : data.total_margin,
      qty : data.qty,
      id_all : data.id_all,
      total_discount: data.total_discount,
    };
    setSpinner(true)
    let trx = await api("post", "transactions-grosir/transaction", param); 
    if(trx.success){
      history.push({pathname: "/pembayaran-berhasil", data : trx.data[0]})
    }
    setSpinner(false)

  }
  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm cursor-pointer">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-2">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() =>
                    history.push({ pathname: "/checkout", data: data })
                  }
                  className="d-inline-block align-text-top "
                />
              </div>

              <div className="d-inline-block ml-2">
                <span className="ml-3">Metode Pembayaran</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="g-col-5 content bg-white">
        <div
          className="g-col-6 bg-light px-cst1 pb-2 list-denom pt-4"
          onClick={() => setMetode("Nara Pay")}
        >
          <div
            className={
              metode === "Nara Pay"
                ? "card mb-3 cursor-pointer border-primary"
                : "card mb-3 cursor-pointer border"
            }
          >
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src={dompet} width="30" alt="" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <p className="text-dark mb-0">Nara Pay</p>
                </div>
                <div className="flex-grow-2 ms-3">
                  <div className="text-muted mb-0">
                    <p className="text-primary mb-0">Rp {saldo}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="g-col-6 bg-light px-cst1 pb-2 list-denom bg-white"
          onClick={() => setMetode("COD")}
        >
          <div
            className={
              metode === "COD"
                ? "card mb-3 cursor-pointer border-primary"
                : "card mb-3 cursor-pointer border"
            }
          >
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src={bank} width="30" alt="" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <p className="text-dark mb-0">COD</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container  text-center bg-white">
        <div className="d-grid">
          <button
            type="button"
            className="btn btn-primary btn-lg d-block text-white m-3 font-16" 
            onClick={() => handleClick()}
          >
            Konfirmasi Pembayaran
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PembayaranCO;