import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { showImage } from "../../utils/Utils";
import { Card, Carousel, Col, Nav, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import filterbiru from "../../assets/images/filterbiru.svg";
import love from "../../assets/images/love.svg";
import birupanah from "../../assets/images/panahbiru.svg";
import shop from "../../assets/images/shop.svg";
import _ from "lodash";
import { commafy } from "../../utils/Utils";
import FilterLaporan from "./FilterLaporan";
import moment from "moment";

const Laporan = () => {
  const { api } = useContext(AppContext);
  const { setSpinner } = useContext(AppContext);
  const [data, setData] = useState([]);
  const history = useHistory();
  const [date, setDate] = useState(moment().format("MM-DD-YYYY"));
  const refInputStart = useRef();
  const refInputEnd = useRef();
  const [startDate, setStartDate] = useState(moment().format("MM-DD-YYYY"));
  const [endDate, setEndDate] = useState(moment().format("MM-DD-YYYY"));

  useEffect(() => {
    getData();
  }, [startDate, endDate]);

  const changeInputStart = () => {
    let value = refInputStart.current.value;
    setStartDate(moment(value).format("MM-DD-YYYY"));
    // setLatestParam(moment(value).format("MM-DD-YYYY"));
  };

  const changeInputEnd = () => {
    let value = refInputEnd.current.value;
    setEndDate(moment(value).format("MM-DD-YYYY"));
    // setLatestParamEnd(moment(value).format("MM-DD-YYYY"));
  };

  const resetApi = async () => {
    setSpinner(true);
    let paramDate = moment().format("MM-DD-YYYY");
    getData(paramDate);
    setSpinner(false);
  };

  const getData = async () => {
    // console.log(startDate, endDate);
  };

  const handleClick = async () => {
    getData();
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4 w-100">
          <Row>
            <Col className="align-items-center w-100">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/dashboard")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block">
                <span className="ml-3">Laporan</span>
              </div>
              <div
                className="d-inline-block float-end pe-4"
                data-bs-toggle="offcanvas"
                data-bs-target="#laporanLabaRugi"
                aria-controls="laporanLabaRugi"
              >
                <div className="align-text-top ">
                  <img src={filterbiru} width={25} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div
        className="card-body border bg-light mx-4 mt-3"
        style={{ borderRadius: "10px" }}
      >
        <div className="d-flex align-items-center">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 ms-4 pt-3 ">
                <h6 className="font-15">Penjualan</h6>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-1 hr-product" />
        <div className="d-flex py-2 ms-4 align-items-center">
          <div className="flex-grow-1 text-left ">
            <small className="text-muted ">Penjualan Bersih</small>
            <div className="d-flex">
              <h6 className="mt-2">Rp 0</h6>
              <img src={birupanah} className="ms-2" />
            </div>
            <div className="d-flex">
              <small className="text-primary">+Rp 0</small>
              <small className="text-muted ms-1">dari kemarin</small>
            </div>
          </div>
          <div className="col">
            <div className="flex-grow-1  ms-5 text-left transaksilaporan">
              <small className="text-muted ">Jumlah Transaksi</small>
              <div className="d-flex">
                <h6 className="mt-2">Rp 0</h6>
                <img src={birupanah} className="ms-2" />
              </div>
              <div className="d-flex">
                <small className="text-primary">+Rp 0</small>
                <small className="text-muted ms-1">dari kemarin</small>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex py-3 ms-4 align-items-center">
          <div className="flex-grow-1 text-left ">
            <div className="d-flex">
              <h6 className="mt-2">Barang Paling Laku</h6>
              <img
                src={birupanah}
                className="ms-2"
                onClick={() => history.push("/barang-laku")}
              />
            </div>
            <small className="text-muted ">Belum ada transaksi penjualan</small>
          </div>
        </div>
        <hr className="mt-1 hr-product" />
        <div className="d-flex align-items-center mb-2 ms-4">
          <div className="flex-grow-1">
            <h6 className=" py-2">Penjualan per Barang</h6>
          </div>
          <div className="flex-grow-2 px-4">
            <img src={birupanah} className="ms-2" />
          </div>
        </div>
      </div>

      <div
        className="card-body border bg-light  mx-4 mt-3"
        style={{ borderRadius: "10px" }}
      >
        <div className="d-flex align-items-center">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 ms-4 pt-3 ">
                <h6 className="font-15">Laba Rugi</h6>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-1 hr-product" />
        <div className="d-flex my-4 ms-4 align-items-center">
          <div
            className="flex-grow-1 text-left cursor-pointer"
            onClick={() => history.push({
              pathname: "/untung-rugi",
              startDate: startDate,
              endDate: endDate,
            })}
          >
            <small className="text-muted ">Untung & Rugi Bersih</small>
            <div className="d-flex">
              <h6 className="mt-2">Rp 0</h6>
              <img src={birupanah} className="ms-2" />
            </div>
            <div className="d-flex">
              <small className="text-primary">+Rp 0</small>
              <small className="text-muted ms-1">dari kemarin</small>
            </div>
          </div>
          <div className="col">
            <div className="flex-grow-1  ms-5 text-left marginbersih">
              <small className="text-muted ">Margin Bersih</small>
              <div className="d-flex">
                <h6 className="mt-2">0.0%</h6>
                <img src={birupanah} className="ms-2" />
              </div>
              <div className="d-flex">
                <small className="text-primary">+0.0%</small>
                <small className="text-muted ms-1">dari kemarin</small>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="card-body border bg-light mb-4 mx-4 mt-3"
        style={{ borderRadius: "10px" }}
      >
        <div className="d-flex align-items-center">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 ms-4 pt-3 ">
                <h6 className="font-15">Pengeluaran</h6>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-1 hr-product" />
        <div className="d-flex my-4 ms-4 align-items-center">
          <div className="flex-grow-1 text-left ">
            <small className="text-muted ">Untung & Rugi Bersih</small>
            <div className="d-flex">
              <h6 className="mt-2">Rp 0</h6>
              <img src={birupanah} className="ms-2" />
            </div>
            <div className="d-flex">
              <small className="text-primary">+Rp 0</small>
              <small className="text-muted ms-1">dari kemarin</small>
            </div>
          </div>
        </div>
      </div>
      <div
        className="offcanvas offcanvas-bottom g-col-6 rounded-top"
        tabIndex="-1"
        id="laporanLabaRugi"
        aria-labelledby="shortLabel"
        style={{ height: "370px" }}
      >
        <div className="offcanvas-body">
          <div className="grid">
            <div className="g-col-12 px-4 py-3 bg-white">
              <p className="fs-6 mb- fw-bold">Filter</p>
              <div className="container px-0 py-1">
                <div className="row gx-8">
                  <p className="mb-2">Pilih tanggal yang ingin ditampilkan</p>
                  <a className="col text-decoration-none">
                    <div className="card p-3">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img src="img/kalender.svg" width="50" alt="" />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p id="alternate2" className="mb-1">
                            Tanggal Awal
                          </p>
                          <input
                            id="datepicker1"
                            className="form-control form-control-sm"
                            autoComplete="off"
                            type="date"
                            placeholder="Pilih tanggal"
                            aria-label="example"
                            ref={refInputStart}
                            onChange={() => changeInputStart()}
                            defaultValue={startDate}
                          />
                        </div>
                      </div>
                    </div>
                  </a>
                  <a className="col text-decoration-none">
                    <div className="card p-3">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img src="img/kalender.svg" width="50" alt="" />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p id="alternate2" className="mb-1">
                            Tanggal Akhir
                          </p>
                          <input
                            id="datepicker1"
                            className="form-control form-control-sm"
                            autoComplete="off"
                            type="date"
                            placeholder="Pilih tanggal"
                            aria-label="example"
                            ref={refInputEnd}
                            onChange={() => changeInputEnd()}
                            defaultValue={endDate}
                          />
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="container px-0 py-4 text-center">
                <div className="row gx-8">
                  <a className="col text-decoration-none">
                    <div className="d-grid">
                      <button
                        type="button"
                        className="btn btn-outline-secondary btn-lg d-block font-16"
                        onClick={() => resetApi()}
                      >
                        Reset
                      </button>
                    </div>
                  </a>
                  <div className="col text-decoration-none">
                    <div className="d-grid">
                      <button
                        type="button"
                        onClick={() => handleClick()}
                        className="btn btn-primary btn-lg d-block text-white font-16"
                      >
                        Terapkan
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Laporan;
