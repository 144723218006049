import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import cadangan from "../../assets/images/cadangan.svg";
import hapus from "../../assets/images/bersihkan.svg";
import bersihkan from "../../assets/images/hapus-dokumen.svg";
import pb from "../../assets/images/pb-icon.svg";
import sk from "../../assets/images/sk-icon.svg";
import kp from "../../assets/images/kebijakan.svg";

const PengaturanPos = () => {
  const history = useHistory();
  const { api } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    getData();
    console.log(111);
  });

  const getData = async (prefix) => {
    let data = await api("get", "user/profile");
    setData(data.data);
    console.log(data);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/dashboard")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Pengaturan</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="px-4 pt-4 min-vh-100">
        <div>
          <span className="text-grey-sm">Pengaturan Cadangan</span>
        </div>
        <div className="card my-3 cursor-pointer">
          <div
            className="card-body px-3 pt-2 pb-0 mt-1"
            // onClick={() => history.push("/pengaturan-profile")}
          >
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <img src={cadangan} width="35" alt="" />
              </div>
              <div className="flex-grow-1 ms-3">
                <p className="text-dark mb-0">Buat Cadangan</p>
              </div>
              <div className="flex-grow-2 ms-3"></div>
            </div>
          </div>
          <div className="hr-line" />
          <div
            className="card-body px-3 pt-1 pb-2 cursor-pointer"
            // onClick={() => history.push("/keamanan")}
          >
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <img src={cadangan} width="35" alt="" />
              </div>
              <div className="flex-grow-1 ms-3">
                <p className="text-dark mb-0">Kembalikan Cadangan</p>
              </div>
              <div className="flex-grow-2 ms-3"></div>
            </div>
          </div>
        </div>
        <div>
          <span className="text-grey-sm">Perangkat</span>
        </div>
        <div className="card my-3 cursor-pointer">
          <div
            className="card-body px-3 pt-2 pb-0 mt-1"
            // onClick={() => history.push("/pengaturan-profile")}
          >
            <div
              data-bs-toggle="offcanvas"
              data-bs-target="#printer"
              aria-controls="printer"
            >
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src={hapus} width="35" alt="" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <p className="text-dark mb-0">Printer</p>
                </div>
                <div className="flex-grow-2 ms-3"></div>
              </div>
            </div>
          </div>
          <div className="hr-line" />
          <div
            className="card-body px-3 pt-1 pb-2 cursor-pointer"
            // onClick={() => history.push("/keamanan")}
          >
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <img src={bersihkan} width="35" alt="" />
              </div>
              <div className="flex-grow-1 ms-3">
                <p className="text-dark mb-0">Cetak Salinan Struk</p>
              </div>
              <div className="flex-grow-2 ms-3">
                <div className="text-muted mb-0">
                  <div class="form-check form-switch mt-3">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <span className="text-grey-sm">Peraturan Cadangan</span>
        </div>
        <div className="card my-3 cursor-pointer">
          <div
            className="card-body px-3 pt-2 pb-0 mt-1"
            // onClick={() => history.push("/pengaturan-profile")}
          >
            <div
              data-bs-toggle="offcanvas"
              data-bs-target="#export"
              aria-controls="export"
            >
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src={cadangan} width="35" alt="" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <p className="text-dark mb-0">Export CSV</p>
                </div>
                <div className="flex-grow-2 ms-3"></div>
              </div>
            </div>
          </div>
          <div className="hr-line" />
          <div
            className="card-body px-3 pt-1 pb-2 cursor-pointer"
            // onClick={() => history.push("/keamanan")}
          >
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <img src={cadangan} width="35" alt="" />
              </div>
              <div className="flex-grow-1 ms-3">
                <p className="text-dark mb-0">Export Excel</p>
              </div>
              <div className="flex-grow-2 ms-3"></div>
            </div>
          </div>
        </div>
        <div>
          <span className="text-grey-sm">Informasi Umum</span>
        </div>
        <div className="card my-3 cursor-pointer">
          <div
            className="card-body px-3 pt-2 pb-0 mt-1"
            // onClick={() => history.push("/pengaturan-profile")}
          >
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <img src={pb} width="35" alt="" />
              </div>
              <div className="flex-grow-1 ms-3">
                <p className="text-dark mb-0">Pusat Bantuan</p>
              </div>
              <div className="flex-grow-2 ms-3">
                <div className="text-muted mb-0">
                  <img src="img/next.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="hr-line" />
          <div
            className="card-body px-3 pt-1 pb-2 cursor-pointer"
            // onClick={() => history.push("/keamanan")}
          >
            <div className="d-flex align-items-center mt-2">
              <div className="flex-shrink-0">
                <img src={sk} width="35" alt="" />
              </div>
              <div className="flex-grow-1 ms-3">
                <p className="text-dark mb-0"> Syarat Ketentuan</p>
              </div>
              <div className="flex-grow-2 ms-3">
                <div className="text-muted mb-0">
                  <img src="img/next.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="hr-line" />
          <div
            className="card-body px-3 pt-1 pb-2 cursor-pointer"
            // onClick={() => history.push("/keamanan")}
          >
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <img src={kp} width="35" alt="" />
              </div>
              <div className="flex-grow-1 ms-3">
                <p className="text-dark mb-0">Kebijakan Privasi</p>
              </div>
              <div className="flex-grow-2 ms-3">
                <div className="text-muted mb-0">
                  <img src="img/next.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="offcanvas offcanvas-bottom g-col-6 rounded-top"
        tabIndex="-1"
        id="printer"
        aria-labelledby="shortLabel"
        style={{ height: "180px" }}
      >
        <div className="offcanvas-body">
          <div className="grid">
            <div className="text-primary">
              <h6>Pilih Jenis Printer</h6>
            </div>
            <div>
              <div className="pengaturan form-check mt-3">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"/>
                <label className="form-check-label" for="defaultCheck1">
                  <h6 className="text-primary">Lan</h6>
                </label>
              </div>
              <div className="pengaturan form-check">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option1"/>
                <label className="form-check-label" for="defaultCheck1">
                  <h6 className="text-primary">Bluetooth</h6>
                </label>
              </div>
            </div>
            <div className="mt-3">
              <div className="btn btn-primary w-100 text-white">Terapkan</div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="offcanvas offcanvas-bottom g-col-6 rounded-top"
        tabIndex="-1"
        id="export"
        aria-labelledby="shortLabel"
        style={{ height: "180px" }}
      >
        <div className="offcanvas-body">
          <div className="grid">
            <div className="text-primary">
              <h6>Pilih Penyimpanan</h6>
            </div>
            <div>
              <div className="pengaturan form-check mt-3">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"/>
                <label className="form-check-label" for="defaultCheck1">
                  <h6 className="text-primary">Lokal</h6>
                </label>
              </div>
              <div className="pengaturan form-check">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option1"/>
                <label className="form-check-label" for="defaultCheck1">
                  <h6 className="text-primary">Cloud</h6>
                </label>
              </div>
            </div>
            <div className="mt-3">
              <div className="btn btn-primary w-100 text-white">Terapkan</div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PengaturanPos;
