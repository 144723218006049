import React, { useContext, useEffect, useRef, useState } from "react";
import {Button, Col, NavItem, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { showImage } from "../../utils/Utils";
import { AppContext } from "../../context/AppContext";
import tambah from "../../assets/images/lalala.svg"
import sampah from "../../assets/images/sampah.svg"
import { NotificationManager } from "react-notifications";

const CategoryData= () => {
    const history = useHistory();
    const { api, setSpinner } = useContext(AppContext);
    const [category, setCategory] = useState([]);
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        getData();
    }, []);
    
    const getData = async () => {
        setSpinner(true);
        let data = await api ("get", "category-grosir/get");
        data.data.map(item => {
            Object.assign(item,{selected : false})
        })
        setData(data.data)
        console.log(data);
        setSpinner(false);
      };

      const selectAll = (e) => {
        console.log(e.target.checked);
        let newData = data.map(item => {
            item.selected = e.target.checked
            return item
        })

        setData(newData)
      }

      const selectSingle = (id, selected) => {
        let newData = data.map(item => {
            if (item.id === id)
            item.selected = selected
            return item
        })

        setData(newData)
      }

    //   const deleteData = () => {
    //     let dataDelete = data.map(item => {
    //         const id_all = item.id;
    //         if (item.selected === true)
    //         return id_all
    //     })

    //     api("delete", "category-grosir/delete-all", dataDelete);

    //     console.log(dataDelete);
    //   }

    const deleteData = async() => {
        let id = "", 
            separator = ""
                data.map(item => { 
                    if (item.selected === true){
                        id = id + separator + item.id
                        separator = ","
                    }
                })
     
        let param = {id_all:id}
        let del = await api("post", "category-grosir/delete-all", param);

        if(del.success){
            NotificationManager.success(del.message)
        }getData()
    
        console.log(del);
    }



    return (
        <React.Fragment>
            <nav className="navbar bg-light shadow-sm">
                <div className="contain p-2 px-cst1 pb-2 ps-4">
                    <Row>
                        <Col className="align-items-center d-flex">
                            <div className="d-inline-block pe-4">
                                <img
                                src="img/arrow-left.svg"
                                alt=""
                                width="30"
                                height="24"
                                onClick={() => history.push("/data-master")}
                                className="d-inline-block align-text-top cursor-pointer"
                                />
                            </div>
                            <div className="d-inline-block ">
                                <span className="ml-3">Data Kategori</span>
                            </div>
                        </Col>
                    </Row>
                </div>
            </nav>

            <div className="d-flex align-item-center ms-4 mt-4" >
                <input 
                    class="form-check-input  " 
                    type="checkbox"
                    onClick={(e) => selectAll(e)}
                    value="" 
                    id="flexCheckDefault"
                />
                <label 
                    className="form-check-label flex-grow-1 ms-3"
                    for="flexCheckDefault">
                        Pilih semua
                </label>
                <div className="flex-grow-2 mx-5">
                    <div className="text-muted mb-0">
                        <img 
                            src={sampah} 
                            width="25"
                            onClick={()=> deleteData()}
                        />
                    </div>
                </div>
            </div>
                


            <div className="g-col-6  px-cst1 pb-2 list-denom">
                <Row className="mt-2">
                    {data.map((item, key) => {
                        return (
                        <div className="col-md-12 mt-3">
                            <div 
                                className="card" >
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <input 
                                        class="form-check-input" 
                                        type="checkbox"
                                        onClick={(e) => selectSingle(item.id, e.target.checked)} 
                                        value="" 
                                        checked={item.selected}
                                        id="flexCheckDefault"
                                    />
                                        <div className="flex-shrink-0 px-3">
                                            <img src={showImage(item.img)} width="40" alt="" />
                                        </div>
                                        <div className="flex-grow-1">
                                            <small className="text-muted">#KTG-001</small>
                                            <h6 className="text-dark">{item.name}</h6>
                                        </div>
                                        <div className="flex-grow-2 ms-3">
                                            <div className="text-mute   d mb-0">
                                                <img src="img/next.svg"className="cursor-pointer" onClick={() => history.push({
                                                    pathname: "/category-detail", 
                                                    category: item
                                                    })} 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       )
                    })}
                </Row>
            </div>
            <div className="float-end me-4 sticky-bottom py-3 cursor-pointer" 
                onClick={() => history.push("/category-form") }>
                <img src={tambah}/>
            </div>
        

    </React.Fragment>
    )
};

export default CategoryData;