import React, { useContext, useEffect, useRef, useState } from "react";
import ReactStars from "react-rating-stars-component";
import { showImage } from "../../../utils/Utils";

const PenilaianProduk = ({ rating }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(rating);
  }, [rating]);

  return (
    <React.Fragment>
      <div
        className="tab-pane fade mt-2"
        id="pills-profile"
        role="tabpanel"
        aria-labelledby="pills-profile-tab"
        tabIndex="1"
      >
        {data.map((item, key) => {
          return ( 
            <div key={key} className="d-inline-block pe-2 w-100 d-flex align-items-center g-col-6 px-cst1 pb-2 list-denom mt-2">
              <img
                src={showImage(item.user.img)} 
                style ={{borderRadius:"50%", height:'50px'}}
                width="50"  
              />
               <div className="flex-grow-1 ms-3 mt-2">
                <div className="row">
                  <div className="d-flex align-items-center"></div>
                </div>
                <p className="text-dark mb-0">{item.user["first_name"]}</p>

                <div class="d-flex justify-content-between align-items-center">
                  <ReactStars count={5} size={18} value={item.rating} />
                 
                </div>
                <p className="text-dark mb-0">{item.feedback}</p>
              </div> 
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default PenilaianProduk;
