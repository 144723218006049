import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal, Col, Row, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { showImage } from "../../utils/Utils";
import { commafy } from "../../utils/Utils";
import { AppContext } from "../../context/AppContext";
import trash from "../../assets/images/trash-icon.svg";
import order from "../../assets/images/order-kontak.svg";
import promo from "../../assets/images/order-promo.svg";
import popup from "../../assets/images/popup-icon.svg";
import moment from "moment";
import { NotificationManager } from "react-notifications";

const EditPos = () => {
  const { api, setSpinner } = useContext(AppContext);
  const history = useHistory();
  const [data, setData] = useState([]);
  const [profile, setProfile] = useState({});
  const [diskon, setDiskon] = useState([]);
  const [pelanggan, setPelanggan] = useState();
  const [idPelanggan, setIdPelanggan] = useState();
  const [id, setId] = useState("");
  const [idAll, setIdAll] = useState("");
  const [diskonselected, setDiskonselected] = useState(0);
  const [diskonname, setDiskonname] = useState();
  const [totalDiskon, setTotalDiskon] = useState();
  const [grosir, setGrosir] = useState([]);
  const [user, setUser] = useState([]);
  const refInput = useRef();
  const [date, setDate] = useState(moment().format("dddd, DD MMMM  yyy"));
  const [local, setLocal] = useState({});
  const [total, setTotal] = useState(0);
  const [totalakhir, setTotalakhir] = useState(0);
  const [keyword, setKeyword] = useState({});
  const [price, setPrice] = useState([]);
  const [kasir, setKasir] = useState([]);
  const [qty, setQty] = useState();
  const [totalDetail, setTotalDetail] = useState();
  const [totalMargin, setTotalMargin] = useState();
  const [showPelanggan, setShowPelanggan] = useState(false);
  const [showDiskon, setShowDiskon] = useState(false);
  const [orderedFrom, setOrderedFrom] = useState([]);
  const [statusPembayaran, setStatusPembayaran] = useState("Lunas");
  const [detail, setDetail] = useState([]);

  useEffect(() => {
    let localData = localStorage.getItem("useranarastore");
    if (localData !== null) setLocal(JSON.parse(localData));
    if (localData !== null) {
      setLocal(JSON.parse(localData));
    }
    getTotal();
  }, [data, total]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setDetail(history.location.detail.transactions_details);
    setTotalDetail(history.location.total);
    setKasir(history.location.kasir);
    setOrderedFrom(history.location.orderedFrom);
  }, [history.location]);
  console.log(orderedFrom);

  const changeInput = async () => {
    let value = refInput.current.value;
    setKeyword({ name: value });

    const newUser = await api("get", "user/get-pelanggan", keyword);
    setUser(newUser.data);
  };

  const getTotal = async () => {
    let price = 0;
    data.map((item) => {
      price = parseInt(price) + item.qty * parseInt(item.price);
      setTotal(price);
    });
    setTotalakhir(total - (total * diskonselected) / 100);
  };

  const getData = async () => {
    // setSpinner(true);
    // let paramcart = {
    //   source: "POS",
    // };
    // let data = await api("get", "cart-grosir/get-by-user", paramcart);
    // setSpinner(false);
    // setData(data.data);
    // let id = "",
    //   separator = "",
    //   price = 0,
    //   totalDiskon = 0,
    //   qty = 0,
    //   totalMargin = 0;

    // data.data.map((item) => {
    //   id = id + separator + item.id;
    //   price = price + item.total_price;
    //   totalDiskon = totalDiskon + item.total_discount;
    //   qty = qty + item.qty;
    //   totalMargin = totalMargin + item.total_margin;
    //   separator = ",";
    // });
    // setPrice(price);
    // setQty(qty);
    // setTotalDiskon(totalDiskon);
    // setIdAll(id);
    // setTotalMargin(totalMargin);

    let paramcount = {
      source: "POS",
    };
    const count_cart = await api(
      "get",
      "cart-grosir/count-by-user",
      paramcount
    );

    setGrosir(count_cart.data);

    const diskon = await api("get", "data-discount/get");
    setDiskon(diskon.data);

    const newUser = await api("get", "user/get-pelanggan", keyword);
    setUser(newUser.data);

    let profile = await api("get", "user/profile");
    setProfile(profile.data);
  };

  const delData = async (item) => {
    let paramdel = { id: item.id };
    let del = await api("post", "cart-grosir/delete", paramdel);

    if (del.success) {
      getData();
      NotificationManager.success(del.message);
    }
  };

  const incrementDecrement = async (param, isInc) => {
    let params = { qty: 0, id: 0 };

    let newData = data.map((item) => {
      if (item.qty === 1 && !isInc) return item;
      if (item.qty >= item.products_grosir.stock && isInc) return item;
      if (item.id === param.id) {
        item.qty = isInc ? item.qty + 1 : item.qty - 1;
        params.qty = item.qty;
        params.id = item.id;
      }
      return item;
    });
    if (params.qty === 0) return;
    setSpinner(true);

    let update = await api("post", "cart-grosir/update", params);
    if (update.success) {
      getData();
    } else NotificationManager.error(update.message);
    setSpinner(false);
  };

  const handleClickDiscount = async (item) => {
    setDiskonselected(item.percentage);
    setDiskonname(item.name);
    setShowDiskon(false);
  };

  const handleClickPelanggan = async (item) => {
    setPelanggan(item.first_name);
    setIdPelanggan(item.id);
    setShowPelanggan(false);
  };

  const handleChangeQty = async (item, e) => {
    let params = { qty: e.target.textContent, id: item.id };
    if (params.qty === "") return;
    let update = await api("post", "cart-grosir/update", params);
    if (!update.success) {
      NotificationManager.error(update.message);
      e.target.textContent = item.qty;
    }
    getData();
  };

  const changeInputOrderedFrom = async (value) => {
    setOrderedFrom(value);
  };

  const changeInputStatusPembayaran = async (value) => {
    setStatusPembayaran(value);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-2">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/riwayat-transaksi-pos")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>

              <div className="d-inline-block ml-2">
                <span className="ml-3">Edit Pesanan</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="bg-primary">
        <div
          className=" row p-2 me-1 mx-1"
          style={{ backgroundColor: "#DCF4F4" }}
        >
          <h6 className="text-dark mb-3 mt-2">{date}</h6>
          <div className="d-flex align-items-center">
            <div className="mb-0 pt-1"></div>

            <div className="flex-shrink-0 ">
              <span> Admin | {kasir.first_name} </span>
            </div>

            <div className="flex-grow-1 ms-3"></div>
            <div className="flex-grow-2 ms-3">
              {" "}
              {grosir.map((item, key) => {
                return (
                  <p className="text-dark mb-0 pt-1" key={key}>
                    Jumlah Produk : {detail.length}
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="container px-4 mt-4 border-none">
        <br />
        <div className="row">
          {detail.map((item, key) => {
            return (
              <div className="col-mb-12" key={key}>
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 ms-3 mt-3 ">
                    <div className="d-flex">
                      <h6
                        className="mb-0 text-dark date-time"
                        // onClick={() =>
                        //   history.push({
                        //     pathname: "/tambah-jumlah",
                        //     cart: item,
                        //   })
                        // }
                      >
                        {" "}
                        {item.cart_grosir.products_grosir.name}
                      </h6>
                      <h4 className="ms-auto mb-0 text-warning">
                        <img
                          src={trash}
                          width="25"
                          onClick={() => delData(item)}
                        />
                      </h4>
                    </div>
                    <div className="d-flex">
                      <span className="mb-0 align-middle text-secondary">
                        Rp {commafy(item.cart_grosir.price)}
                      </span>
                      <div
                        className="input-group ms-auto mb-0 "
                        style={{ width: "126px" }}
                      >
                        <button
                          className="col btn border rounded-left   "
                          type="submit"
                          onClick={() => incrementDecrement(item, false)}
                        >
                          -
                        </button>
                        <small
                          className="col btn border "
                          onInput={(e) => handleChangeQty(item, e)}
                          contentEditable={true}
                          suppressContentEditableWarning={true}
                        >
                          {item.cart_grosir.qty}
                        </small>
                        <button
                          className="col btn border rounded-right "
                          type="submit"
                          onClick={() => incrementDecrement(item, true)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="row justify-content-center sticky-bottom  order">
        <div className="col-md-12">
          <div className="grid">
            <div
              className="offcanvas offcanvas-bottom g-col-6 rounded-top order-product"
              tabIndex="-1"
              id="short"
              aria-labelledby="shortLabel"
            ></div>

            <div
              className="offcanvas offcanvas-bottom g-col-6 rounded-top order-product"
              tabIndex="-1"
              id="order"
              aria-labelledby="shortLabel"
            >
              <div className="offcanvas-body">
                <div className="grid">
                  <div className="w-100 d-flex px-3 font-14 mt-3">
                    <h6 className="flex-grow-1">Nama pelanggan</h6>
                    <h6 className="d-flex flex-grow-2"> {pelanggan} </h6>
                  </div>
                  <div className="w-100 d-flex px-3 font-14 mt-3">
                    <div className="flex-grow-1">Subtotal</div>
                    <div className="d-flex flex-grow-2">Rp {total} </div>
                  </div>
                  <div className="w-100 d-flex px-3 font-14 mt-3">
                    <div className="flex-grow-1">{diskonname}</div>
                    <div className="d-flex flex-grow-2">{diskonselected}%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sticky-bottom bg-white mt-5 ">
        <div className=" py-2 px-0  d-flex">
          <div className="px-4 py-2 flex-grow-1">
            <div className="mt-1 text-dark ">
              <div
                data-bs-toggle="offcanvas"
                data-bs-target="#order"
                aria-controls="order"
              >
                <span>Total</span>
                <img className="mx-3 cursor-pointer" src={popup} />
              </div>
            </div>
          </div>
          <div className="px-4 py-2 flex-grow-2">
            <h6>Rp {commafy(totalDetail)} </h6>
          </div>
        </div>
        <div className="d-flex align-items-center flex-grow-1 ms-1">
          <div
            className="flex-shrink-0 cursor-pointer"
            onClick={() => setShowPelanggan(true)}
          >
            <img src={order} width="50" className="ms-3" />
          </div>

          <div
            className="flex-shrink-0 ms-3 cursor-pointer"
            onClick={() => setShowDiskon(true)}
          >
            <img
              src={promo}
              width="50"
              className="d-inline-block ml-2 text-bold"
            />
          </div>

          <div className="flex-shrink-0 ms-3 cursor-pointer">
            <Form.Select
              onChange={(e) => {
                changeInputStatusPembayaran(e.target.value);
              }}
              className="font-14 shadow-none form form-control w-100 cursor-pointer"
            >
              <option value="Lunas">Lunas</option>
              <option value="Belum Lunas">Belum Lunas</option>
            </Form.Select>
          </div>

          <div className="float-end flex-grow-1 ms-3">
            {/* <Form.Label>Type</Form.Label> */}
            <Form.Select
              defaultValue={orderedFrom}
              onChange={(e) => {
                changeInputOrderedFrom(e.target.value);
              }}
              className="font-14 shadow-none form form-control w-50 cursor-pointer"
            >
              <option value="Offline">Offline</option>
              <option value="Shopee">Shopee</option>
              <option value="Tokopedia">Tokopedia</option>
              <option value="TikTok">TikTok</option>
            </Form.Select>
          </div>

          <div className="flex-grow-2 ms-3">
            <div className="text-muted mb-0 me-4">
              <h6
                className="btn btn-primary text-white mb-0"
                onClick={() =>
                  history.push({
                    pathname: "/pembayaran-pos",
                    totalakhir: totalakhir,
                    diskonname: diskonname,
                    diskonselected: diskonselected,
                    order: data,
                    pelanggan: pelanggan,
                    idPelanggan: idPelanggan,
                    profile: profile,
                    total: total,
                    idAll: idAll,
                    price: price,
                    totalDiskon: totalDiskon,
                    qty: qty,
                    totalMargin: totalMargin,
                    orderedFrom: orderedFrom,
                    statusPembayaran: statusPembayaran,
                  })
                }
              >
                Tagihan
              </h6>
            </div>
          </div>
        </div>
        <br />
      </div>

      <Modal show={showDiskon} onHide={() => setShowDiskon(false)} centered>
        <Modal.Header className="bg-primary">
          <center>
            <h1 className="modal-title fs-5 text-white" id="diskon">
              Diskon
            </h1>
          </center>
        </Modal.Header>
        <Modal.Body>
          <div className="overflow-scroll" style={{ maxHeight: "200px" }}>
            {diskon.map((item, key) => {
              return (
                <div key={key}>
                  <div
                    className="btn btn-light text-start w-100"
                    onClick={() => handleClickDiscount(item)}
                  >
                    {item.name} ({item.percentage}%)
                  </div>
                  <br />
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showPelanggan}
        onHide={() => setShowPelanggan(false)}
        centered
      >
        <Modal.Header className="bg-primary">
          <h6 className="modal-title fs-5 text-white" id="pelanggan">
            Pelanggan
          </h6>
          <div className="d-inline-block ms-2" style={{ width: "90%" }}>
            <form className="w-100">
              <div className="input-group w-100">
                <input
                  type="text"
                  className="form-control form-control-lg font-16"
                  placeholder="Cari Pelanggan"
                  ref={refInput}
                  aria-describedby="basic-addon2"
                  onChange={() => changeInput()}
                />
                <button className="input-group-text bg-white border">
                  <i className="bi bi-search"></i>
                </button>
              </div>
            </form>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="overflow-scroll" style={{ maxHeight: "200px" }}>
            {user.map((item, key) => {
              return (
                <div key={key}>
                  <div
                    className="btn text-start btn-light cursor-pointer w-100"
                    onClick={() => handleClickPelanggan(item)}
                  >
                    {item.first_name}
                  </div>
                  <br />
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default EditPos;
