import React, { useContext, useEffect, useRef, useState } from "react";
import {Button, Col, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { showImage } from "../../utils/Utils";
import { AppContext } from "../../context/AppContext"; 
import filterhitam from "../../assets/images/filterhitam.svg"


const BarangLaku = () => {
    const history = useHistory();
    const { api } = useContext(AppContext);
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [imgages, setImg] = useState([]);
    const [profile, setProfile] = useState([]);

    useEffect(() => {
        getData();
        console.log(111);
    },[]);
    
    const getData = async () => {
      
      const data = await api("get", "products-grosir-popular/get",); 
      if(data.data.length){
        setImg(data.data[0].products_images)
      }
      setData(data.data)
  
      const paramFeedback = {
        product_id: 13,
      }
      let profile = await api("get", "user/profile");
      setProfile(profile.data);
    };

    return (
        <React.Fragment>
           <nav className="navbar bg-light border-bottom   ">
                <div className="contain  px-cst2  ps-4">
                    <div className="row">
                        <div className="col-md-10 align-items-center py-2 d-flex">
                            <div className="d-inline-block pe-2 align-text-top">
                                <img
                                    src="img/arrow-left.svg"
                                    width="30"
                                    height="24"
                                    onClick={() => history.push("/laporan")}
                                    className="d-inline-block align-text-top cursor-pointer"
                                />
                            </div>
                            <div className="d-inline-block  align-text-top ms-2">
                                <span>Barang Paling Laku</span>
                            </div>
                        </div>
                        <div 
                            className="col-md-1" 
                            data-bs-toggle="offcanvas"
                            data-bs-target="#laporan"
                            aria-controls="laporan"
                        >
                            <div className="  align-text-top py-2">
                                <img
                                    src={filterhitam}
                                    className="float-right"
                                    width="380"
                                    height="30"
                                />
                            </div>  
                        </div>
                    </div>                          
                </div>
            </nav>
        {data.map((item) => {
            return (
              <>   
            <div className="g-col-6  px-cst1 pb-2 list-denom ">
                <Row className="mt-2">
                    <Col>
                        <div className="card cursor-pointer" style={{borderRadius:"10px"}} >
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0 px-3">
                                        <img src={showImage(item.products_grosir["img"])} width="75" alt="" />
                                    </div>
                                    <div className="flex-grow-1">
                                        <h6 className="text-dark">{item.products_grosir["name"]}</h6>
                                        <small className="text-primary">{item.products_grosir["sell"]}</small>
                                        <small className="text-muted">Kali dibeli</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>       
            </>
            )
          })}
    </React.Fragment>
    )
};

export default BarangLaku;