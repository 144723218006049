import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { Link, useHistory } from "react-router-dom";
import { Col, Row, Offcanvas } from "react-bootstrap";
import masuk from "../../assets/images/ceklis.png";
import moment from "moment";
import _ from "lodash";
import "moment/locale/id";
moment.locale("id");

export default function PointHistory() {
  const { api, setSpinner } = useContext(AppContext);
  const [pointHistory, setPointHistory] = useState([]);
  const [singleData, setSingleData] = useState(null);
  const [product, setProduct] = useState(null);
  const [gudang, setGudang] = useState(null);
  const [show, setShow] = useState(false);
  const history = useHistory();
  useEffect(() => {
    let member = JSON.parse(localStorage.getItem("member"));
    getData(member.id);
    // console.log(singleData);
  }, []);

  const getData = async (id) => {
    setSpinner(true);
    let result = await api("get", "point-historys/get", {
      member_id: id,
    });
    if (result.success) {
      setPointHistory(result.data);
    }
    setSpinner(false);
    console.log(pointHistory);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/tukar-point")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Riwayat Penukaran Point</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="g-col-6  px-cst1 pb-2 list-denom ">
        <Row className="mt-2">
          {pointHistory.map((item, key) => {
            return (
              <div className="col-md-12 mt-3" key={key}>
                <div
                  className="card"
                  style={{ borderRadius: "10px" }}
                  //   onClick={() => history.push("/gudang-detail")}
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 me-3 p-1 rounded bg-primary">
                        <img src={masuk} width="40" alt="" />
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="text-dark">(Transactions ID)</h6>
                        <h6>
                          <span className="text-danger">
                            - {item.point} Poin
                          </span>
                        </h6>
                        <p className="font-12 text-muted mb-0">
                          {moment(item.createdAt).format(
                            "DD-MM-YYYY | HH:mm:ss"
                          )}
                        </p>
                        <small className="text-dark">{item.hp}</small>
                      </div>
                      <div className="flex-grow-2 ms-3">
                        <div
                          className="text-muted mb-0 d-flex align-items-center cursor-pointer"
                          onClick={() => {
                            setShow(true);
                            setSingleData(item);
                            setProduct(item.products_grosir);
                            setGudang(item.gudang);
                          }}
                        >
                          <h6
                            className="text-dark"
                            style={{ marginTop: "7px" }}
                          >
                            Lihat Detail
                          </h6>
                          <img src="img/next.svg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <Offcanvas
            show={show}
            placement="bottom"
            onHide={() => setShow(false)}
            className="rounded-top detail-product"
            style={{ height: "55vh" }}
          >
            <Offcanvas.Body>
              <div className="fw-bold text fs-5 border-bottom mb-3">
                <p>Detail Penukaran Point</p>
              </div>
              <div className="fw-bold border border-3 p-2 rounded">
                <p className="mb-0 font-14">
                  Nama Produk :{" "}
                  <span className="mb-0 fw-normal">
                    {_.isNull(product) ? "" : product.name}
                  </span>
                </p>
                <p className="text-muted mb-0"></p>
              </div>
              <div className="fw-bold border border-3 p-2 rounded mt-3">
                <p className="mb-0 font-14">Informasi Toko :</p>
                <p className="mb-0 fw-normal">
                  Nama Toko : {_.isNull(gudang) ? "" : gudang.name}
                </p>
                <p className="mb-0 fw-normal">
                  Alamat Toko : {_.isNull(gudang) ? "" : gudang.address}
                </p>
                <p className="text-muted mb-0"></p>
              </div>
              <div className="text-dark fw-bold mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <p>Tanggal Diterima</p>
                  <p>
                    {" "}
                    {moment(
                      _.isNull(singleData) ? "" : singleData.createdAt
                    ).format("DD-MM-YYYY | HH:mm:ss")}
                  </p>
                </div>
              </div>
              <hr className="m-0 mb-3" />
              <div className="text-dark fw-bold mt-3">
                <div className="d-flex justify-content-between gap-4">
                  <button
                    className="btn btn-primary text-white fw-bold rounded-pill w-100"
                    onClick={() => {
                      setShow(false);
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </Row>
      </div>
    </React.Fragment>
  );
}
