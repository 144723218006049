import React, { useContext } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import ScrollTop from "../../utils/ScrollTop";
import GrosirHome from "../Grosir/Grosir-home";
import Akun from "./akun/Akun";
import DetailUsaha from "./akun/Detailusaha";
import Forgot from "./akun/Forgot";
import Login from "./akun/Login";
import OTP from "./akun/OTP";
import Register from "./akun/Register";
import Reset from "./akun/Reset";
import Bantuan from "./Bantuan";
import Brands from "./Brands";
import Games from "./games";
import History from "./history/History";
import Topup from "./history/Topup";
import Home from "./Home";
import Payment from "./Payment";
import Products from "./Products";
import Rincian from "./Rincian";
import StatusPayment from "./StatusPayment";
import PengaturanProfile from "./akun/PengaturanProfile";
import UbahData from "./akun/UbahData";
import UbahFoto from "./akun/UbahFoto";
import KebijakanPrivasi from "./akun/KebijakanPrivasi";
import SyaratKetentuan from "./akun/SyaratKetentuan";
import Keamanan from "./akun/Keamanan";
import PengaturanKoneksi from "./akun/PengaturanKoneksi";
import AturKoneksi from "./akun/AturKoneksi";
import DeskripsiProduk from "../Grosir/deskripsi-produk/DeskripsiProduk";
import FilterBeliSekarang from "../Grosir/deskripsi-produk/FilterBeliSekarang";
import PenilaianProduk from "../Grosir/deskripsi-produk/PenilaianProduk";
import CheckOut from "../Grosir/deskripsi-produk/CheckOut";
import PembayaranCO from "../Grosir/deskripsi-produk/PembayaranCO";
import PembayaranBerhasil from "../Grosir/deskripsi-produk/PembayaranBerhasil";
import GantiGudang from "../Grosir/deskripsi-produk/GantiGudang";
import GantiPengiriman from "../Grosir/deskripsi-produk/GantiPengiriman";
import DataBiaya from "../admin/DataBiaya";
import DetailBiaya from "../admin/DetailBiaya";
import TambahBiaya from "../admin/TambahBiaya";
import DataOngkir from "../admin/DataOngkir";
import DetailOngkir from "../admin/DetailOngkir";
import TambahOngkir from "../admin/TambahOngkir";
import BarangLaku from "../admin/BarangLaku";
import UntungRugi from "../admin/UntungRugi";
import UpdateBiaya from "../admin/UpdateBiaya";
import UpdateDataOngkir from "../admin/UpdateDataOngkir";
import TopupSaldo from "./TopUp/TopupSaldo";
import Transfer from "./TopUp/Transfer";
import PaymentSaldo from "./TopUp/PaymentSaldo";
import Keranjang from "../Grosir/Keranjang";
import PesananSaya from "../Grosir/Pesanan-saya";
import RincianPesanan from "../Grosir/Rincian-pesanan";
import ProsesPengiriman from "../Grosir/Proses-pengiriman";
import DaftarProduk from "../admin/Daftar-produk";
import AdminDetail from "../admin/Admin-detail";
import DaftarSupplier from "../admin/Daftar-supplier";
import DetailSuplier from "../admin/Detail-supplier";
import DataPelanggan from "../admin/Data-pelanggan";
import DetailPelanggan from "../admin/Detail-pelanggan";
import TambahProduk from "../admin/Tambah-produk";
import TambahSupplier from "../admin/Tambah-supplier";
import PengaturanAdmin from "../admin/Pengaturan-admin";
import BantuanAdmin from "../admin/Bantuan-admin";
import StokRendah from "../admin/Stok-rendah";
import UpdateProduct from "../admin/Update-product";
import UpdateSupplier from "../admin/Update-supplier";
import GrosirWishlist from "../Grosir/Grosir-wishlist";
import UserProses from "../Grosir/User-proses";
import UserSelesai from "../Grosir/User-selesai";
import PagesProduct from "../Grosir/PagesProduct";
import Dashboard from "../admin/Dashboard";
import DashboardPage from "../admin/DashboardPage";
import CategoryData from "../admin/CategoryData";
import CategoryDetail from "../admin/CategoryDetail";
import CategoryForm from "../admin/CategoryForm";
import DataGudang from "../admin/DataGudang";
import GudangDetail from "../admin/GudangDetail";
import GudangForm from "../admin/GudangForm";
import PesananAdmin from "../admin/PesananAdmin/PesananAdmin";
import RincianPesananAdmin from "../admin/PesananAdmin/RincianPesananAdmin";
import Laporan from "../admin/Laporan";
import RingkasanPenjualan from "../admin/RingkasanPenjualan";
import DataMaster from "../admin/DataMaster";
import UpdateGudang from "../admin/UpdateGudang";
import UpdateCategory from "../admin/UpdateCategory";
import UserBatalkan from "../Grosir/User-batalkan";
import BeriPenilaian from "../Grosir/BeriPenilaian";
import Pajak from "../pos/Pajak";
import TambahPajak from "../pos/TambahPajak";
import Diskon from "../pos/Diskon";
import TambahDiskon from "../pos/TambahDiskon";
import DataPegawai from "../pos/DataPegawai";
import TambahPegawai from "../pos/TambahPegawai";
import RekapKas from "../pos/RekapKas";
import TglRekapKas from "../pos/TglRekapKas";
import TambahRekapKas from "../pos/TambahRekapKas";
import DashboardInventory from "../inventory/DashboardInventory";
import KelolaDataInventory from "../inventory/KelolaDataInventory";
import TransaksiInventory from "../inventory/TransaksiInventory";
import TransaksiInv from "../inventory/TransaksiInv";
import OrderInventory from "../inventory/OrderInventory";
import EditInventory from "../inventory/EditInventory";
import DaftarProdukInventory from "../inventory/DaftarProdukInventory";
import RiwayatStok from "../inventory/RiwayatStok";
import TambahProdukInv from "../inventory/TambahProdukInv";
import DashboardPos from "../pos/DashboardPos";
import KelolaData from "../pos/KelolaData";
import DetailTransaksi from "../pos/DetailTransaksi";
import DataSatuanUnit from "../inventory/DataSatuanUnit";
import TambahSatuanUnit from "../inventory/TambahSatuanUnit";
import LaporanTransaksiInventory from "../inventory/LaporanTransaksiInventory";
import RiwayatTransaksiInventori from "../inventory/RiwayatTransaksiInventori";
import PesananPos from "../pos/PesananPos";
import OrderPos from "../pos/OrderPos";
import TambahJumlah from "../pos/TambahJumlah";
import SuksesPembayaran from "../pos/SuksesPembayaran";
import PembayaranPos from "../pos/PembayaranPos";
import PengaturanPos from "../pos/PengaturanPos";
import DetailTransaksiInventori from "../inventory/DetailTransaksiInventori";
import SuksesPembayaranInventori from "../inventory/SuksesPembayaranInventori";
import TambahJumlahInventori from "../inventory/TambahJumlahInventori";
import CetakDetailTransaksi from "../inventory/CetakDetailTransaksi";
import TransaksiSukses from "../inventory/TransaksiSukses";
import StrukInventory from "../inventory/StrukInventory";
import ModalSpinner from "../../components/spinner/ModalSpinner";
import { AppContext } from "../../context/AppContext";
import StrukPembelian from "../pos/StrukPembelian";
import RiwayatTransaksiPos from "../pos/RiwayatTransaksiPos";
import LaporanPenjualan from "../pos/LaporanPenjualan";
import UpdatePelanggan from "../admin/UpdatePelanggan";
import ProductPrice from "../admin/ProductPrice";
import DetailPegawai from "../pos/DetailPegawai";
import UpdatePegawai from "../pos/UpdatePegawai";
import UpdateSatuanUnit from "../inventory/UpdateSatuanUnit";
import DataBrand from "../admin/DataBrand";
import TambahBrand from "../admin/TambahBrand";
import UpdateBrand from "../admin/UpdateBrand";
import LaporanNeraca from "../admin/LaporanNeraca";
import RekapBank from "../admin/RekapBank";
import DashboardGroup from "../admin/DashboardGroup";
import CashAccount from "../admin/CashAccount";
import TambahCashAccount from "../admin/TambahCashAccount";
import UpdateCashAccount from "../admin/UpdateCashAccount";
import DetailCashAccount from "../admin/DetailCashAccount";
import OrderPosMP from "../pos/OrderPosMP";
import PesananPosMP from "../pos/PesananPosMP";
import Sales from "../admin/Sales";
import EditPos from "../pos/EditPos";
import TargetPenjualan from "../admin/TargetPenjualan";
import TambahTarget from "../admin/TambahTarget";
import UpdateTarget from "../admin/UpdateTarget";
import PembayaranPosMp from "../pos/PembayaranPosMp";
import MemberRegister from "../membership/member/Register";
import ListMember from "../membership/member/ListMember";
import EditMember from "../membership/member/EditMember";
import AddRewardPoints from "../membership/rewardPoints/AddRewardPoints";
import ListRewardPoints from "../membership/rewardPoints/ListRewardPoints";
import EditRewardPoints from "../membership/rewardPoints/EditRewardPoints";
import TukarPoint from "../membership/TukarPoint";
import DeskripsiRewardProduk from "../membership/DeskripsiRewardProduk";
import RedeemHistory from "../membership/RedeemHistory";
import DetailMember from "../membership/member/DetailMember";
import VerifikasiToko from "../membership/VerifikasiToko";
import DataPointHistory from "../membership/DataPointHistory";
import PointHistory from "../membership/PointHistory";
import KalkulasiPoint from "./Point/KalkulasiPoint";
import DataPointKalkulasi from "./Point/DataPoint";
import EditPointKalkulasi from "./Point/EditPoint";
import ProdukPublik from "../produk/ProdukPublik";

const Index = () => {
  const { spinner, isInventory, isSales } = useContext(AppContext);
  return (
    <div className="main-beranda">
      <div className="row justify-content-center no-gutters min-vh-100">
        <div
          className={
            isInventory || isSales
              ? "col-md-12 min-vh-100"
              : "col-md-6 p-0 min-vh-100"
          }
          style={{ backgroundColor: "#F9F9F9" }}
        >
          <ModalSpinner show={spinner} />
          <ScrollTop />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/brands">
              <Brands />
            </Route>
            <Route path="/products">
              <Products />
            </Route>
            <Route path="/payment">
              <Payment />
            </Route>
            <Route path="/status-payment">
              <StatusPayment />
            </Route>
            <Route path="/detail-transaction">
              <Rincian />
            </Route>
            <Route path="/games">
              <Games />
            </Route>
            <Route path="/history">
              <History />
            </Route>
            <Route path="/bantuan">
              <Bantuan />
            </Route>
            <Route path="/akun">
              <Akun />
            </Route>
            <Route path="/pengaturan-profile">
              <PengaturanProfile />
            </Route>
            <Route path="/ubahdata">
              <UbahData />
            </Route>
            <Route path="/ubah-foto">
              <UbahFoto />
            </Route>
            <Route path="/kebijakan-privasi">
              <KebijakanPrivasi />
            </Route>
            <Route path="/syarat-ketentuan">
              <SyaratKetentuan />
            </Route>
            <Route path="/keamanan">
              <Keamanan />
            </Route>
            <Route path="/pengaturan-koneksi">
              <PengaturanKoneksi />
            </Route>
            <Route path="/atur-koneksi">
              <AturKoneksi />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/register">
              <Register />
            </Route>
            <Route path="/otp">
              <OTP />
            </Route>
            <Route path="/topup-saldo">
              <TopupSaldo />
            </Route>
            <Route path="/transfer">
              <Transfer />
            </Route>
            <Route path="/payment-saldo">
              <PaymentSaldo />
            </Route>
            <Route path="/forgot">
              <Forgot />
            </Route>
            <Route path="/reset-password">
              <Reset />
            </Route>
            <Route path="/detailusaha">
              <DetailUsaha />
            </Route>
            <Route path="/deskripsi-produk">
              <DeskripsiProduk />
            </Route>
            <Route path="/filterbelisekarang">
              <FilterBeliSekarang />
            </Route>
            <Route path="/penilaian">
              <PenilaianProduk />
            </Route>
            <Route path="/checkout">
              <CheckOut />
            </Route>
            <Route path="/filterbelisekarang">
              <FilterBeliSekarang />
            </Route>
            <Route path="/pembayaranco">
              <PembayaranCO />
            </Route>
            <Route path="/pembayaran-berhasil">
              <PembayaranBerhasil />
            </Route>
            <Route path="/ganti-gudang">
              <GantiGudang />
            </Route>
            <Route path="/ganti-pengiriman">
              <GantiPengiriman />
            </Route>
            <Route path="/data-biaya">
              <DataBiaya />
            </Route>
            <Route path="/detail-biaya">
              <DetailBiaya />
            </Route>
            <Route path="/tambah-biaya">
              <TambahBiaya />
            </Route>
            <Route path="/data-ongkir">
              <DataOngkir />
            </Route>
            <Route path="/detail-ongkir">
              <DetailOngkir />
            </Route>
            <Route path="/tambah-ongkir">
              <TambahOngkir />
            </Route>
            <Route path="/barang-laku">
              <BarangLaku />
            </Route>
            <Route path="/untung-rugi">
              <UntungRugi />
            </Route>
            <Route path="/update-biaya">
              <UpdateBiaya />
            </Route>
            <Route path="/update-data-ongkir">
              <UpdateDataOngkir />
            </Route>
            <Route path="/grosir-home">
              <GrosirHome />
            </Route>
            <Route path="/keranjang">
              <Keranjang />
            </Route>
            <Route path="/pesanan-saya">
              <PesananSaya />
            </Route>
            <Route path="/rincian-pesanan">
              <RincianPesanan />
            </Route>
            <Route path="/proses-pengiriman">
              <ProsesPengiriman />
            </Route>
            <Route path="/daftar-produk">
              <DaftarProduk />
            </Route>
            <Route path="/admin-detail">
              <AdminDetail />
            </Route>
            <Route path="/daftar-supplier">
              <DaftarSupplier />
            </Route>
            <Route path="/detail-supplier">
              <DetailSuplier />
            </Route>
            <Route path="/data-pelanggan">
              <DataPelanggan />
            </Route>
            <Route path="/detail-pelanggan">
              <DetailPelanggan />
            </Route>
            <Route path="/tambah-produk">
              <TambahProduk />
            </Route>
            <Route path="/tambah-supplier">
              <TambahSupplier />
            </Route>
            <Route path="/pengaturan-admin">
              <PengaturanAdmin />
            </Route>
            <Route path="/bantuan-admin">
              <BantuanAdmin />
          </Route>
            <Route path="/stok-rendah">
              <StokRendah />
            </Route>
            <Route path="/update-product">
              <UpdateProduct />
            </Route>
            <Route path="/update-supplier">
              <UpdateSupplier />
            </Route>
            <Route path="/grosir-wishlist">
              <GrosirWishlist />
            </Route>
            <Route path="/user-proses">
              <UserProses />
            </Route>
            <Route path="/user-selesai">
              <UserSelesai />
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/brands">
              <Brands />
            </Route>
            <Route path="/products">
              <Products />
            </Route>
            <Route path="/payment">
              <Payment />
            </Route>
            <Route path="/status-payment">
              <StatusPayment />
            </Route>
            <Route path="/detail-transaction">
              <Rincian />
            </Route>
            <Route path="/games">
              <Games />
            </Route>
            <Route path="/history">
              <History />
            </Route>
            <Route path="/bantuan">
              <Bantuan />
            </Route>
            <Route path="/akun">
              <Akun />
            </Route>
            <Route path="/pengaturan-profile">
              <PengaturanProfile />
            </Route>
            <Route path="/ubahdata">
              <UbahData />
            </Route>
            <Route path="/ubah-foto">
              <UbahFoto />
            </Route>
            <Route path="/kebijakan-privasi">
              <KebijakanPrivasi />
            </Route>
            <Route path="/syarat-ketentuan">
              <SyaratKetentuan />
            </Route>
            <Route path="/keamanan">
              <Keamanan />
            </Route>
            <Route path="/pengaturan-koneksi">
              <PengaturanKoneksi />
            </Route>
            <Route path="/atur-koneksi">
              <AturKoneksi />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/register">
              <Register />
            </Route>
            <Route path="/otp">
              <OTP />
            </Route>
            <Route path="/topup-saldo">
              <TopupSaldo />
            </Route>
            <Route path="/transfer">
              <Transfer />
            </Route>
            <Route path="/payment-saldo">
              <PaymentSaldo />
            </Route>
            <Route path="/forgot">
              <Forgot />
            </Route>
            <Route path="/reset-password">
              <Reset />
            </Route>
            <Route path="/detailusaha">
              <DetailUsaha />
            </Route>
            <Route path="/pages-product">
              <PagesProduct />
            </Route>
            <Route path="/dashboard">
              <Dashboard />
            </Route>
            <Route path="/dashboard-page">
              <DashboardPage />
            </Route>
            <Route path="/category-data">
              <CategoryData />
            </Route>
            <Route path="/category-detail">
              <CategoryDetail />
            </Route>
            <Route path="/category-form">
              <CategoryForm />
            </Route>
            <Route path="/data-gudang">
              <DataGudang />
            </Route>
            <Route path="/gudang-detail">
              <GudangDetail />
            </Route>
            <Route path="/gudang-form">
              <GudangForm />
            </Route>
            <Route path="/pesanan-admin">
              <PesananAdmin />
            </Route>
            <Route path="/rincian-pesanan-admin">
              <RincianPesananAdmin />
            </Route>
            <Route path="/laporan">
              <Laporan />
            </Route>
            <Route path="/ringkasan-penjualan">
              <RingkasanPenjualan />
            </Route>
            <Route path="/data-master">
              <DataMaster />
            </Route>
            <Route path="/update-gudang">
              <UpdateGudang />
            </Route>
            <Route path="/update-category">
              <UpdateCategory />
            </Route>
            <Route path="/user-batalkan">
              <UserBatalkan />
            </Route>
            <Route path="/beri-penilaian">
              <BeriPenilaian />
            </Route>
            <Route path="/pajak">
              <Pajak />
            </Route>
            <Route path="/tambah-pajak">
              <TambahPajak />
            </Route>
            <Route path="/diskon">
              <Diskon />
            </Route>
            <Route path="/tambah-diskon">
              <TambahDiskon />
            </Route>
            <Route path="/data-pegawai">
              <DataPegawai />
            </Route>
            <Route path="/tambah-pegawai">
              <TambahPegawai />
            </Route>
            <Route path="/rekap-kas">
              <RekapKas />
            </Route>
            <Route path="/tanggal-rekap">
              <TglRekapKas />
            </Route>
            <Route path="/tambah-kas">
              <TambahRekapKas />
            </Route>
            <Route path="/dashboard-inventory">
              <DashboardInventory />
            </Route>
            <Route path="/kelola-data-inventory">
              <KelolaDataInventory />
            </Route>
            <Route path="/transaksi-inventory">
              <TransaksiInventory />
            </Route>
            <Route path="/transaksi-inv">
              <TransaksiInv />
            </Route>
            <Route path="/daftar-produk-inventory">
              <DaftarProdukInventory />
            </Route>
            <Route path="/order-inventory">
              <OrderInventory />
            </Route>
            <Route path="/edit-inventory">
              <EditInventory />
            </Route>
            <Route path="/transaksi-sukses">
              <TransaksiSukses />
            </Route>
            <Route path="/struk-inventory">
              <StrukInventory />
            </Route>
            <Route path="/cetak-detail-transaksi">
              <CetakDetailTransaksi />
            </Route>
            <Route path="/riwayat-stok">
              <RiwayatStok />
            </Route>
            <Route path="/tambah-produk-inv">
              <TambahProdukInv />
            </Route>
            <Route path="/dashboard-pos">
              <DashboardPos />
            </Route>
            <Route path="/kelola-data">
              <KelolaData />
            </Route>
            <Route path="/riwayat-transaksi-pos">
              <RiwayatTransaksiPos />
            </Route>
            <Route path="/detail-transaksi">
              <DetailTransaksi />
            </Route>
            <Route path="/data-satuan-unit">
              <DataSatuanUnit />
            </Route>
            <Route path="/tambah-satuan-unit">
              <TambahSatuanUnit />
            </Route>
            <Route path="/laporan-transaksi-inventory">
              <LaporanTransaksiInventory />
            </Route>
            <Route path="/pesanan-pos">
              <PesananPos />
            </Route>
            <Route path="/order-pos">
              <OrderPos />
            </Route>
            <Route path="/tambah-jumlah">
              <TambahJumlah />
            </Route>
            <Route path="/sukses-pembayaran">
              <SuksesPembayaran />
            </Route>
            <Route path="/pembayaran-pos">
              <PembayaranPos />
            </Route>
            <Route path="/pengaturan-pos">
              <PengaturanPos />
            </Route>
            <Route path="/riwayat-transaksi-inventori">
              <RiwayatTransaksiInventori />
            </Route>
            <Route path="/detail-transaksi-inventori">
              <DetailTransaksiInventori />
            </Route>
            <Route path="/sukses-pembayaran-inventori">
              <SuksesPembayaranInventori />
            </Route>
            <Route path="/tambah-jumlah-inventori">
              <TambahJumlahInventori />
            </Route>
            <Route path="/struk-pembelian">
              <StrukPembelian />
            </Route>
            <Route path="/laporan-penjualan">
              <LaporanPenjualan />
            </Route>
            <Route path="/update-pelanggan">
              <UpdatePelanggan />
            </Route>
            <Route path="/product-price">
              <ProductPrice />
            </Route>
            <Route path="/detail-pegawai">
              <DetailPegawai />
            </Route>
            <Route path="/update-pegawai">
              <UpdatePegawai />
            </Route>
            <Route path="/update-satuan-unit">
              <UpdateSatuanUnit />
            </Route>
            <Route path="/data-brand">
              <DataBrand />
            </Route>
            <Route path="/tambah-brand">
              <TambahBrand />
            </Route>
            <Route path="/update-brand">
              <UpdateBrand />
            </Route>
            <Route path="/laporan-neraca">
              <LaporanNeraca />
            </Route>
            <Route path="/rekap-bank">
              <RekapBank />
            </Route>
            <Route path="/dashboard-group">
              <DashboardGroup />
            </Route>
            <Route path="/cash-account">
              <CashAccount />
            </Route>
            <Route path="/tambah-cash-account">
              <TambahCashAccount />
            </Route>
            <Route path="/update-cash-account">
              <UpdateCashAccount />
            </Route>
            <Route path="/detail-cash-account">
              <DetailCashAccount />
            </Route>
            <Route path="/order-pos-marketplace">
              <OrderPosMP />
            </Route>
            <Route path="/pesanan-pos-marketplace">
              <PesananPosMP />
            </Route>
            <Route path="/pembayaran-pos-marketplace">
              <PembayaranPosMp />
            </Route>
            <Route path="/edit-pos">
              <EditPos />
            </Route>
            <Route path="/sales">
              <Sales />
            </Route>
            <Route path="/target-penjualan">
              <TargetPenjualan />
            </Route>
            <Route path="/tambah-target">
              <TambahTarget />
            </Route>
            <Route path="/update-target">
              <UpdateTarget />
            </Route>
            <Route path="/data-member/register">
              <MemberRegister />
            </Route>
            <Route path="/edit-member">
              <EditMember />
            </Route>
            <Route path="/detail-member">
              <DetailMember />
            </Route>
            <Route path="/data-member">
              <ListMember />
            </Route>
            <Route path="/reward-points/store">
              <AddRewardPoints />
            </Route>
            <Route path="/reward-points/:id">
              <EditRewardPoints />
            </Route>
            <Route path="/reward-points">
              <ListRewardPoints />
            </Route>
            <Route path="/tukar-point">
              <TukarPoint />
            </Route>
            <Route path="/redeem-history">
              <RedeemHistory />
            </Route>
            <Route path="/deskripsi-reward-produk">
              <DeskripsiRewardProduk />
            </Route>
            <Route path="/verifikasi-toko">
              <VerifikasiToko />
            </Route>
            <Route path="/data-point-history">
              <DataPointHistory />
            </Route>
            <Route path="/point-history">
              <PointHistory />
            </Route>
            <Route path="/kalkulasi-point">
              <KalkulasiPoint />
            </Route>
            <Route path="/data-point-kalkulasi">
              <DataPointKalkulasi />
            </Route>
            <Route path="/edit-point-kalkulasi">
              <EditPointKalkulasi />
            </Route>
            <Route path="/produk-publik">
              <ProdukPublik />
            </Route>
          </Switch>
         
        </div>
      </div>
    </div>
  );
};

export default Index;
