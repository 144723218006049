import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { NotificationManager } from "react-notifications";
import DatePicker from "react-datepicker";

const TambahTarget = () => {
  const [isLoading, setLoading] = useState(false);
  const { api } = useContext(AppContext);
  const history = useHistory();
  const [date, setDate] = useState(new Date());
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = async (params) => {
    const formData = new FormData();
    formData.append("name", params.name);
    formData.append("target", params.target);
    formData.append("month", date.getMonth() + 1);
    formData.append("year", date.getFullYear());

    setLoading(true);
    let TargetPenjualan = await api("post", "target-grosir/post", formData);
    if (TargetPenjualan.success) {
      history.push({ pathname: "/target-penjualan" });
      NotificationManager.success(TargetPenjualan.message);
    } else NotificationManager.error(TargetPenjualan.error);

    setLoading(false);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/target-penjualan")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Tambah Target Penjualan</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <form onSubmit={handleSubmit(onSubmit)} className="bg-white p-3">
        <Form.Group>
          <Form.Label className="w-100">Bulan & Tahun Target</Form.Label>
          <DatePicker
            selected={date}
            onChange={(date) => setDate(date)}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            showFullMonthYearPicker
            className="form-control"
          />
        </Form.Group>
        <Form.Group className="font-14 mt-2">
          <Form.Label>Nama Target</Form.Label>
          <Form.Control
            className="font-14 shadow-none"
            {...register("name", {
              required: "Name is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="name" />
        </div>

        <Row>
          <Form.Group className="font-14 mt-2">
            <Form.Label>Jumlah Target</Form.Label>
            <Form.Control
              className="font-14 shadow-none"
              {...register("target", {
                required: "Target is required",
              })}
            />
          </Form.Group>

          <div className="my-2 text-left float-start font-12 text-danger">
            <ErrorMessage errors={errors} name="target" />
          </div>
        </Row>

        <div className="d-grid py-4">
          <center>
            <Button
              variant="primary"
              className="mt-2 btn btn-primary btn-lg d-block text-white w-100 font-16"
              disabled={isLoading}
              type="submit"
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Tambah"
              )}
            </Button>
          </center>
        </div>
      </form>
    </React.Fragment>
  );
};

export default TambahTarget;
