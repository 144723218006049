import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { showImage } from "../../utils/Utils";
import { Card, Carousel, Col, Nav, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import shop from "../../assets/images/shop.svg";
import _ from "lodash";
import { commafy } from "../../utils/Utils";
import box from "../../assets/images/box-icon.svg";
import { NotificationManager } from "react-notifications";
import { useDebounce } from "use-debounce";

const PesananPos = () => {
  const { api } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [cart, setCart] = useState([]);
  const [category, setCategory] = useState([]);
  const [grosir, setGrosir] = useState([]);
  const [categoryId, setCategoryId] = useState(0);
  const [total, setTotal] = useState(0);
  const [profile, setProfile] = useState([]);
  const [qtyProd, setQtyProd] = useState();
  const history = useHistory();
  const refInput = useRef();
  const { setSpinner } = useContext(AppContext);
  const [isLoading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [value] = useDebounce(text, 1000);

  useEffect(() => {
    getData();
    getCart();
  }, []);

  useEffect(() => {
    let keyword = {
      admin: true,
      name: value,
    };

    getProduct(keyword);
  }, [value]);

  const getProduct = async (params) => {
    let data = await api("get", "products-grosir/get", params);
    setData(data.data);
  };

  useEffect(() => {
    let price = 0;
    let isSelectedAxis = false;
    cart.map((item) => {
      price = parseInt(price) + item.qty * parseInt(item.price);
      isSelectedAxis = true;
      setTotal(price);
    });
    setTotal(price);
  }, [cart]);

  const getCart = async () => {
    let paramcart = {
      source: "POS",
    };
    let cart = await api("get", "cart-grosir/get-by-user", paramcart);
    setCart(cart.data);
  };

  const getData = async () => {
    setSpinner(true);
    if (history.location.category_id) {
      setCategoryId(history.location.category_id);
      let data = await api("get", "products-grosir/get", {
        admin: true,
        category_id: history.location.category_id,
      });
      setData(data.data);
    } else {
      let data = await api("get", "products-grosir/get", { admin: true });
      setData(data.data);
    }
    setSpinner(false);
    let category = await api("get", "category-grosir/get", { admin: true });
    setCategory(category.data);

    let profile = await api("get", "user/profile");
    setProfile(profile.data);

    let paramcount = {
      source: "POS",
    };
    const count_cart = await api(
      "get",
      "cart-grosir/count-by-user",
      paramcount
    );
    setGrosir(count_cart.data);
  };

  const handleClickType = async (param) => {
    let category_id = {
      admin: true,
      category_id: param.id,
    };

    let data = await api("get", "products-grosir/get", category_id);
    setData(data.data);
  };

  const changeInput = async () => {
    let value = refInput.current.value;
    setText(value);
  };

  const addCart = async (param) => {
    setSpinner(true);
    let paramsCart = {
      product_id: param.id,
      status: "open",
      price: param.price,
      price_before: param.price_before,
      total_price: param.price - param.discount,
      total_price_before: param.price,
      source: "POS",
      qty: qtyProd ? qtyProd.qty : 1,
    };

    let postCart = await api("post", "cart-grosir/post", paramsCart);
    if (postCart.success) {
      // getData();
      getCart();
      NotificationManager.success(postCart.message);
    } else NotificationManager.error(postCart.message);
    setSpinner(false);
  };

  const semuaProduct = async () => {
    setSpinner(true);
    let semua = await api("get", "products-grosir/get", { admin: true });
    setData(semua.data);
    setSpinner(false);
  };

  const incrementDecrement = async (param, isInc) => {
    console.log(param);
    // let params = { qty: 0, id: 0 };

    // let newData = data.map((item) => {
    //   if (item.qty === 1 && !isInc) return item;
    //   if (item.qty >= item.products_grosir.stock && isInc) return item;
    //   if (item.id === param.id) {
    //     item.qty = isInc ? item.qty + 1 : item.qty - 1;
    //     params.qty = item.qty;
    //     params.id = item.id;
    //   }
    //   return item;
    // });
    // if (params.qty === 0) return;
    // setSpinner(true);

    // let update = await api("post", "cart-grosir/update", params);
    // if (update.success) {
    //   getData();
    // } else NotificationManager.error(update.message);
    // setSpinner(false);
  };

  const handleChangeQty = async (item, e) => {
    let params = { qty: e.target.textContent, id: item.id };
    setQtyProd(params);
    if (params.qty === "") return;
    // let update = await api("post", "cart-grosir/update", params);
    // if (!update.success) {
    //   NotificationManager.error(update.message);
    //   e.target.textContent = item.qty;
    // }
    // getData();
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm  w-100">
        <div className="p-2 pb-2 ps-4 w-100 d-flex align-items-center">
          <div className="d-inline-block">
            <img
              src="img/arrow-left.svg"
              alt=""
              width="30"
              height="24"
              onClick={() => history.push("/dashboard-pos")}
              className="d-inline-block align-text-top cursor-pointer"
            />
          </div>
          <div className="d-inline-block ms-2" style={{ width: "90%" }}>
            <form className="w-100">
              <div className="input-group w-100">
                <input
                  type="text"
                  className="form-control form-control-lg font-16"
                  placeholder="Cari Produk"
                  ref={refInput}
                  aria-describedby="basic-addon2"
                  onChange={() => changeInput()}
                />
                <button className="input-group-text bg-white border">
                  <i className="bi bi-search"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="riwayat w-100  overflow-scroll">
          <div className="bg-light mx-4">
            <ul
              className="nav nav-pills nav-fill mb-3"
              id="pills-tab"
              role="tablist"
            >
              <div className="d-flex">
                <li
                  className="nav-item "
                  role="presentation"
                  hidden={categoryId === 0 ? false : true}
                >
                  <button
                    className="nav-link py-3 active no-wrap"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    role="tab"
                    onClick={() => semuaProduct()}
                  >
                    Semua
                  </button>
                </li>
                {category.map((item, key) => {
                  if (categoryId !== 0 && item.id !== categoryId) return;
                  return (
                    <li className="nav-item" role="presentation" key={key}>
                      <button
                        className={
                          categoryId === item.id
                            ? "nav-link py-3 no-wrap active"
                            : "nav-link py-3 no-wrap"
                        }
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        role="tab"
                        key={key}
                        md={category.column}
                        onClick={() => handleClickType(item)}
                      >
                        {item.name}
                      </button>
                    </li>
                  );
                })}
              </div>
            </ul>
          </div>
        </div>
      </nav>

      <div className="container px-4 mt-4">
        {/* <h6>Pilih Nominal</h6> */}
        <Row className="no-gutters px-3">
          {data.map((item, key) => {
            return (
              <div className="col-6 p-2" key={key}>
                <div
                  className="card w-100 border-0 shadow cursor-pointer"
                  style={{ minHeight: "320px" }}
                >
                  <center>
                    <div className="bg-lime rounded m-2">
                      <div className="w-100"></div>
                      <div className="p-3">
                        <img
                          className="card-img-top mt-2"
                          src={showImage(item.img)}
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>
                    </div>
                  </center>
                  <div className="card-body">
                    <div
                      className="font-16 font-weight-bold"
                      style={{ height: "45px" }}
                    >
                      <span className="">{item.name}</span>
                    </div>
                    <span className="text-muted font-13">
                      {item.weight} {item.unit_weight}
                    </span>
                    <div className="w-100 d-flex mt-1 font-14">
                      <h6 className="font-weight-bold text-primary ">
                        Rp {commafy(item.price)}
                      </h6>
                    </div>
                    <span className=" font-14">
                      Stok: {item.stock + " " + item.satuan}
                    </span>
                    <img
                      src={shop}
                      onClick={() => addCart(item, profile)}
                      hidden={item.stock === 0 ? true : false}
                      className="cursor-pointer float-end"
                      width="30"
                    />
                    <div
                      hidden
                      className="input-group ms-auto mb-0"
                      style={{ width: "126px" }}
                    >
                      <button
                        className="col btn border rounded-left"
                        type="submit"
                        onClick={() => incrementDecrement(item, false)}
                      >
                        -
                      </button>
                      <small
                        className="col btn border"
                        onInput={(e) => handleChangeQty(item, e)}
                        contentEditable={true}
                        suppressContentEditableWarning={true}
                      >
                        1
                      </small>
                      <button
                        className="col btn border rounded-right"
                        type="submit"
                        onClick={() => incrementDecrement(item, true)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Row>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        {grosir.map((item, key) => {
          return (
            <center className="sticky-bottom me-2 mx-3" key={key}>
              <div
                className=" btn btn-primary w-100  mb-3 "
                onClick={() => history.push("/order-pos")}
              >
                <div className="d-flex align-items-center flex-grow-1 ">
                  <div className="flex-shrink-0">
                    <img src={box} width="25" alt="" className="ms-3 mb-1" />
                    <h6 className="d-inline-block ml-2 text-white mt- mx-2">
                      {item.count} Items
                    </h6>
                  </div>
                  <div className="flex-grow-1 ms-3"></div>
                  <div className="flex-grow-2 ms-3">
                    <div className="text-muted mb-0">
                      <h6 className="text-white mb-0">Rp {commafy(total)}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </center>
          );
        })}
      </div>
      {/* <div className="tab-content min-vh-100" id="pills-tabContent">
        <UserProses/>
        <UserBatalkan />
        <UserSelesai />
      </div> */}
    </React.Fragment>
  );
};

export default PesananPos;
