import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { NotificationManager } from "react-notifications";
import pemasukan from "../../assets/images/in.svg";
import Saldo from "../../assets/images/saldo.svg";
import pengeluaran from "../../assets/images/out.svg";
import lurusatas from "../../assets/images/lurusatas.svg";
import tambah from "../../assets/images/tambah.svg";
import { commafy } from "../../utils/Utils";

const TglRekapKas = () => {
  const history = useHistory();
  const { api, setSpinner } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [saldo, setSaldo] = useState([]);
  const [summary, setSummary] = useState({});
  const [date, setDate] = useState([]);
  
  useEffect(() => {
    if (!history.location.date) history.push("/rekap-kas");
    else {
      setDate(history.location.date);
    }
  }, [history.location]);
  
  useEffect(() => {
    getData();
  }, [date]);

  const getData = async () => {
    setSpinner(true);
    let data = await api("get", "cash-grosir/get-per-date", { date: date });
    if (data.success) {
      setData(data.data);
    }

    let summaryData = await api("get", "cash-grosir/summary-cash", { date: date });
    if (summaryData.success) {
      setSummary(summaryData.data);
    }

    let saldo = await api("get", "saldo-history/get-saldo");
    setSaldo(saldo.data);
    setSpinner(false);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm">
        <div className="contain  px-cst2  ps-4">
          <div className="row">
            <div className="col-md-10 align-items-center py-2 d-flex">
              <div className="d-inline-block pe-2 align-text-top">
                <img
                  src="img/arrow-left.svg"
                  width="30"
                  height="24"
                  onClick={() => history.push("/rekap-kas")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ms-3" style={{ width: "300px" }}>
                <form className="w-100">
                  <div className="d-inline-block ">
                    <span className="ml-3">Rekap Kas </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div className="g-col-6  px-cst1 pb-2 list-denom ">
        <Row className="mt-4">
          <Col>
            <div className="card cursor-pointer">
              <div className="card-body">
                <Row>
                  <div className="col-md-4">
                    <div className="d-flex align-items-center ">
                      <div className="flex-shrink-0 px-3">
                        <img src={pemasukan} width="40" alt="" />
                      </div>
                      <div className="flex-grow-1">
                        <small className="text-muted mt-2">Pemasukan</small>
                        <h6 className="text-dark">
                          Rp {commafy(summary.pemasukan)}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 ">
                    <div className="d-flex align-items-center">
                      <img src={lurusatas} />
                      <div className="flex-shrink-0 px-3 float-end">
                        <img src={Saldo} width="40" alt="" />
                      </div>
                      <div className="flex-grow-1">
                        <small className="text-muted mt-2">Saldo</small>
                        <h6 className="text-dark">
                          Rp {commafy(saldo.nominal)}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 ">
                    <div className="d-flex align-items-center ">
                      <img src={lurusatas} />
                      <div className="flex-shrink-0 px-3 float-end">
                        <img src={pengeluaran} width="40" alt="" />
                      </div>
                      <div className="flex-grow-1">
                        <small className="text-muted mt-2">Pengeluaran</small>
                        <h6 className="text-dark">
                          Rp {commafy(summary.pengeluaran)}
                        </h6>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </div>
          </Col>
        </Row>

        {data.map((item, key) => {
          return (
            <Row className="mt-4" key={key}>
              <Col>
                {item.type === "Pemasukan" ? (
                  <div className="card cursor-pointer">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 pe-4">
                          <img src={pemasukan} width="40" alt="" />
                        </div>
                        <div className="flex-grow-1 py-1">
                          <h6 className="text-dark mt-2">{item.category}</h6>
                          <div>
                            <span className="font-12">{item.description}</span>
                          </div>
                          <small className="text-muted mt-2">{item.date}</small>
                        </div>
                        <div className="flex-grow-2 ms-3">
                          <div className="text-muted mb-0">
                            <h6 className="mt-2 text-primary">
                              Rp {commafy(item.nominal)}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : item.type === "Pengeluaran" ? (
                  <div className="card cursor-pointer">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 pe-4">
                          <img src={pengeluaran} width="40" alt="" />
                        </div>
                        <div className="flex-grow-1 py-1">
                          <h6 className="text-dark mt-2">{item.category}</h6>
                          <small className="text-muted mt-1">{item.date}</small>
                        </div>
                        <div className="flex-grow-2 ms-3">
                          <div className="text-muted mb-0">
                            <h6 className="mt-2 text-danger">
                              Rp {commafy(item.nominal)}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </Col>
            </Row>
          );
        })}
        {/* <Row className="mt-4">
                                <Col>
                                <div 
                                    className="card cursor-pointer" 
                                    style={{borderRadius:"10px"}}
                                >
                                    <div className="card-body">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 pe-4">
                                                <img src={Pemasukan}width="40" alt="" />
                                            </div>
                                            <div className="flex-grow-1 py-1">
                                                <h6 className="text-dark mt-2">Penjualan#002</h6>
                                                <small className="text-muted mt-1">26 Sep 2022 | 12.50</small>    
                                            </div>
                                            <div className="flex-grow-2 ms-3">
                                                <div className="text-muted mb-0">
                                                    <h6 className="mt-2 text-primary">Rp. 5.500</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                                <Col>
                                <div 
                                    className="card cursor-pointer" 
                                    style={{borderRadius:"10px"}}
                                >
                                    <div className="card-body">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 pe-4">
                                                <img src={Pemasukan}width="40" alt="" />
                                            </div>
                                            <div className="flex-grow-1 py-1">
                                                <h6 className="text-dark mt-2">Penjualan#001</h6>
                                                <small className="text-muted mt-1">26 Sep 2022 | 12.50</small>    
                                            </div>
                                            <div className="flex-grow-2 ms-3">
                                                <div className="text-muted mb-0">
                                                    <h6 className="mt-2 text-primary">Rp. 5.500</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </Col>
                        </Row> */}
      </div>
      <div
        className="float-end me-4 sticky-bottom py-3 cursor-pointer"
        onClick={() => history.push("/tambah-kas")}
      >
        <img src={tambah} />
      </div>

      <div
        className="offcanvas offcanvas-bottom g-col-6 rounded-top"
        tabIndex="-1"
        id="tanggal"
        aria-labelledby="shortLabel"
        style={{ height: "400px" }}
      >
        <div className="offcanvas-body">
          <div className="grid">
            <div className="g-col-12 px-4 py-3 bg-white">
              <p className="fs-6 mb- fw-bold">Filter</p>
              <div className="container px-0 py-1">
                <div className="row gx-8">
                  <p className="mb-2">Pilih tanggal yang ingin ditampilkan</p>
                  <a href="#" className="col text-decoration-none">
                    <div className="card p-3">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img src="img/kalender.svg" width="50" alt="" />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p id="alternate2" className="mb-1 text-primary">
                            Tanggal Awal
                          </p>
                          <input
                            id="datepicker1"
                            className="form-control form-control-sm"
                            autoComplete="off"
                            type="text"
                            placeholder="Pilih tanggal"
                            aria-label="example"
                          />
                        </div>
                      </div>
                    </div>
                  </a>
                  <a href="#" className="col text-decoration-none">
                    <div className="card p-3">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img src="img/kalender.svg" width="50" alt="" />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p id="alternate2" className="mb-1 text-primary">
                            Tanggal Akhir
                          </p>
                          <input
                            id="datepicker2"
                            className="form-control form-control-sm"
                            autoComplete="off"
                            type="text"
                            placeholder="Pilih Tanggal"
                            aria-label="example"
                          />
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="container px-0 py-3 category">
                <div className="row gx-8">
                  <div className="col text-decoration-none">
                    <a
                      href="#"
                      className="btn btn-outline-primary border-secondary d-block"
                    >
                      Harian
                    </a>
                  </div>
                  <div className="col text-decoration-none">
                    <a
                      href="#"
                      className="btn btn-outline-primary border-secondary d-block"
                    >
                      Mingguan
                    </a>
                  </div>
                  <div className="col text-decoration-none">
                    <a
                      href="#"
                      className="btn btn-outline-primary border-secondary d-block"
                    >
                      Bulanan
                    </a>
                  </div>
                </div>
              </div>
              <div className="container px-0 py-4 text-center">
                <div className="row gx-8">
                  <a href="#" className="col text-decoration-none">
                    <div className="d-grid">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-lg d-block font-16"
                      >
                        Reset
                      </button>
                    </div>
                  </a>
                  <a href="#" className="col text-decoration-none">
                    <div className="d-grid">
                      <button
                        type="button"
                        className="btn btn-primary btn-lg d-block text-white font-16"
                      >
                        Terapkan
                      </button>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TglRekapKas;
