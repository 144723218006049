import React, { useContext, useEffect, useState } from "react"; 
import {Col, Row } from "react-bootstrap";
import { AppContext } from "../../../context/AppContext";
import { useHistory } from "react-router-dom";
import Bottom from "../../app/Bottom";
import { showImage } from "../../../utils/Utils";
import { useForm } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { Form, Button } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message";

const UbahData = () =>{
    const [isLoading, setLoading] = useState(false);
    const history = useHistory();
    const [token, setToken] = useState("");
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const { api } = useContext(AppContext);

    useEffect(() => {
        // setToken = (otp.data.token)
    }, [history]);

    const onSubmit = async (params) => {
        setLoading(true);
        let paramDatausaha = {
            token: token,
        };
        let Datausaha = await api("post", "user/update", params);

        if (Datausaha.success) {
            history.push({ pathname: "/pengaturan-profile" });
        } else NotificationManager.error(register.message);

        setLoading(false);
    };

    return (
        <React.Fragment>
            <nav className="navbar bg-light shadow-sm cursor-pointer">
                <div className="contain p-2 px-cst1 pb-2 ps-4">
                    <Row>
                        <Col className="align-items-center d-flex">
                            <div className="d-inline-block pe-2">
                                <img
                                src="img/arrow-left.svg"
                                alt=""
                                width="30"
                                height="24"
                                onClick={() => history.push("/pengaturan-profile")}
                                className="d-inline-block align-text-top "
                                />
                            </div>

                            <div className="d-inline-block ml-2">
                                <span className="ml-3">Ubah Data</span>
                            </div>
                        </Col>
                    </Row>
                </div>
            </nav>

            <div className="px-4 pt-4 min-vh-100">
                <div className="my-3">
                <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Control
                            className="form"
                            type="text"
                            id="bussiness_name"
                            aria-describedby="passwordHelpBlock"
                            placeholder="Nama Usaha"
                            {...register("business_name", {
                                    required: "Nama usaha is required",
                                })
                            }
                        />
                        <br />
                        <Form.Control
                            className="form"
                            type="text"
                            id="bussiness_type"
                            aria-describedby="passwordHelpBlock"
                            placeholder="Jenis Usaha"
                            {...register("business_type", {
                            required: "jenis usaha is required",
                            })}
                        />
                            <div className="my-2 text-left float-start font-12 text-danger">
                                <ErrorMessage errors={errors} name="business_type" />
                            </div>
                        <br />
                        <div className="form-group">
                            <textarea
                            class="form-control"
                            id="Address"
                            rows="3"
                            placeholder="Alamat"
                            {...register("address", {
                                required: "alamat is required",
                            })}
                            ></textarea>
                             <div className="my-2 text-left float-start font-12 text-danger">
                                <ErrorMessage errors={errors} name="address" />
                            </div>
                        </div>
                    </Form>
                </div>
                <div class="d-grid ">
                        <button
                            onClick={() => {
                            history.push("/pengaturan-profile");
                            }}
                            type="button"
                            class="mt-2 btn btn-primary btn-lg d-block text-white  font-16"
                        >
                            Simpan
                        </button>
                </div> 
            </div>
        </React.Fragment>
    )
};

export default UbahData;