import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { showImage } from "../../utils/Utils";
import { AppContext } from "../../context/AppContext";
import tambah from "../../assets/images/tambah.svg";

const DataPelanggan = () => {
  const history = useHistory();
  const { api, setSpinner } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [param, setParam] = useState("/dashboard");

  useEffect(() => {
    if (history.location.param === "kelola-data") {
      setParam("/kelola-data");
    } else {
      setParam("/dashboard");
    }
  }, [history.location]);

  useEffect(() => {
    getData();
  },[history]);

  const getData = async () => {
    setSpinner(true);
    let data = await api("get", "user/get-for-admin");
    setData(data.data);
    setSpinner(false);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push(param)}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Data Pelanggan</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="g-col-6  px-cst1 pb-2 list-denom ">
        <Row className="mt-2">
          {data.map((item, key) => {
            return (
              <div className="col-md-12 mt-3" key={key}>
                <div
                  className="card"
                  style={{ borderRadius: "10px" }}
                  //   onClick={() => history.push("/gudang-detail")}
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 px-3">
                        <img src={showImage(item.image)} width="40" alt="" />
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="text-dark">{item.first_name}</h6>
                        <small className="text-dark">
                          {item.phone}
                        </small>
                      </div>
                      <div className="flex-grow-2 ms-3">
                        <div className="text-muted mb-0">
                          <img
                            className="cursor-pointer"
                            src="img/next.svg"
                            alt=""
                            onClick={() =>
                              history.push({
                                pathname: "/detail-pelanggan",
                                pelanggan: item,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Row>
      </div>
      
    </React.Fragment>
  );
};

export default DataPelanggan;
