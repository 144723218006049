import React, { useContext, useEffect, useState, useRef } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import { commafy, showImage } from "../../utils/Utils";
import { useHistory } from "react-router-dom";
import target from "../../assets/images/target.png";
import pelanggan from "../../assets/images/customer.svg";
import Highcharts from "highcharts";
import moment from "moment";
import _ from "lodash";
import HighchartsReact from "highcharts-react-official";

const Sales = () => {
  const { api, setSpinner, setIsSales } = useContext(AppContext);
  const [local, setLocal] = useState(null);
  const [selectBy, setSelectBy] = useState("Margin Tertinggi");
  const [selectType, setSelectType] = useState("Daily");
  const [selectByTertinggi, setSelectByTertinggi] =
    useState("Margin Tertinggi");

  const refInputStartDate = useRef();
  const refInputEndDate = useRef();
  const [startDate, setStartDate] = useState(
    moment().add(-6, "days").format("YYYY-MM-DD")
  ); 

  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [dataChart, setDataChart] = useState({});
  const [dataChartBar, setDataChartBar] = useState({});
  const [dataChartPenjualanTertinggi, setDataChartPenjualanTertinggi] =
    useState({});
  const [dataChartPie, setDataChartPie] = useState({});
  const [dataTarget, setDataTarget] = useState({});
  const [dataPencapaian, setDataPencapaian] = useState({});
  const [dataPenjualan, setDataPenjualan] = useState({});
  const [transaksiPelanggan, setTransaksiPelanggan] = useState([]);
  const [pencapaianKiri, setPencapaianKiri] = useState("");
  const [pencapaianKanan, setPencapaianKanan] = useState("");
  const history = useHistory();

  useEffect(() => {
    let localData = localStorage.getItem("useranarastore");
    if (localData !== null) setLocal(JSON.parse(localData));
    if (localData !== null) {
      setLocal(JSON.parse(localData));
      setIsSales(true);
    }
  }, []);

  useEffect(() => { 
    getDataChartBar();
    getDataChartPenjualanTertinggi();
    getDataChartPie();
    getData();
  }, [startDate, endDate, selectBy, selectByTertinggi]);

  useEffect(() => {
    getDataChart();
    
  }, [selectType, startDate]);


  const getData = async () => {
    let paramDate = { startDate: startDate, date: startDate, endDate: endDate };

    const target = await api("get", "target-grosir/get-by-date", paramDate);
    setDataTarget(target.data);

    const pencapaian = await api(
      "get",
      "transactions-grosir/get-for-pencapaian",
      paramDate
    );
    setDataPencapaian(pencapaian.data);

    const penjualan = await api(
      "get",
      "transactions-grosir/summary",
      paramDate
    );
    setDataPenjualan(penjualan.data);

    const transaksi = await api(
      "get",
      "transactions-grosir/get-for-penjualan-pelanggan",
      paramDate
    );
    setTransaksiPelanggan(transaksi.data);
  };

  const changeSelectBy = async (value) => {
    setSelectBy(value);
  };

  const changeSelectByTertinggi = async (value) => {
    setSelectByTertinggi(value);
  };

  const changeInputStartDate = async () => {
    let value = refInputStartDate.current.value;
    setStartDate(value);
  };

  const changeInputEndDate = async () => {
    let value = refInputEndDate.current.value;
    setEndDate(value);
  };

  const getDataChart = async () => {
    setSpinner(true);
    let paramChart = { startDate: startDate, endDate: endDate, type: selectType};
    let chartData = await api(
      "get",
      "transactions-grosir/summary-chart",
      paramChart
    );
    setSpinner(false);

    let categorys = _.map(chartData.data, "date");
    let penjualans = _.map(chartData.data, "penjualan");
    let margins = _.map(chartData.data, "margin");

    const options = {
      chart: {
        type: "column",
        height: "270",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: categorys,
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
      },
      tooltip: {
        shared: true,
      },
      plotOptions: {
        series: {
          stacking: "normal",
        },
      },
      series: [
        {
          name: "Margin",
          data: margins,
          color: "#F0B83ABF",
        },
        {
          name: "Penjualan",
          data: penjualans,
          color: "#00C6C8BF",
        },
      ],
      credits: {
        enabled: false,
      },
    };
    setDataChart(options);
  };

  const getDataChartBar = async () => {
    setSpinner(true);
    let paramChart = {
      startDate: startDate,
      endDate: endDate,
      selectBy: selectBy,
    };
    let chartData = await api(
      "get",
      "transactions-grosir/get-for-sales",
      paramChart
    );
    setSpinner(false);

    let categorys = _.map(chartData.data, "name");
    let margins = _.map(chartData.data, "margin");
    let sells = _.map(chartData.data, "sell");
    let stocks = _.map(chartData.data, "stock");

    const chartBar = {
      chart: {
        type: "bar",
        height: "280",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: categorys,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
      },
      tooltip: {
        shared: true,
      },
      plotOptions: {
        series: {
          stacking: "normal",
        },
      },
      series: [
        {
          name:
            selectBy === "Margin Tertinggi" || selectBy === "Margin Terendah"
              ? "Margin"
              : selectBy === "Stok Tertinggi" || selectBy === "Stok Terendah"
              ? "Stok"
              : "Penjualan",
          data:
            selectBy === "Margin Tertinggi" || selectBy === "Margin Terendah"
              ? margins
              : selectBy === "Stok Tertinggi" || selectBy === "Stok Terendah"
              ? stocks
              : sells,
          color: "#F0B83ABF",
        },
      ],
      credits: {
        enabled: false,
      },
    };
    setDataChartBar(chartBar);
  };

  const getDataChartPenjualanTertinggi = async () => {
    setSpinner(true);
    let paramChart = {
      startDate: startDate,
      endDate: endDate,
      selectBy: selectByTertinggi,
    };
    let chartData = await api(
      "get",
      "transactions-grosir/get-for-sales",
      paramChart
    );
    setSpinner(false);

    let categorys = _.map(chartData.data, "name");
    let margins = _.map(chartData.data, "margin");
    let sells = _.map(chartData.data, "sell");
    let stocks = _.map(chartData.data, "stock");

    const chartPenjualanTertinggi = {
      chart: {
        type: "bar",
        height: "280",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: categorys,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
      },
      tooltip: {
        shared: true,
      },
      plotOptions: {
        series: {
          stacking: "normal",
        },
      },
      series: [
        {
          name:
            selectByTertinggi === "Margin Tertinggi" ||
            selectByTertinggi === "Margin Terendah"
              ? "Margin"
              : selectByTertinggi === "Stok Tertinggi" ||
                selectByTertinggi === "Stok Terendah"
              ? "Stok"
              : "Penjualan",
          data:
            selectByTertinggi === "Margin Tertinggi" ||
            selectByTertinggi === "Margin Terendah"
              ? margins
              : selectByTertinggi === "Stok Tertinggi" ||
                selectByTertinggi === "Stok Terendah"
              ? stocks
              : sells,
          color: "#F0B83ABF",
        },
      ],
      credits: {
        enabled: false,
      },
    };
    setDataChartPenjualanTertinggi(chartPenjualanTertinggi);
  };

  const getDataChartPie = async () => {
    setSpinner(true);
    let paramChart = {
      startDate: startDate,
      endDate: endDate,
    };
    let chartData = await api(
      "get",
      "transactions-grosir/get-for-pie-chart",
      paramChart
    );
    setSpinner(false);

    let data = [];

    chartData.data.map((item) => {
      let obj = {};
      obj.name = item.ordered_from === null ? "Nara Store" : item.ordered_from;
      obj.y = item.margin;
      obj.color =
        item.ordered_from === "Shopee"
          ? "#EE4C2D"
          : item.ordered_from === "Tokopedia"
          ? "#47B74D"
          : item.ordered_from === "Offline"
          ? "#00C6C8BF"
          : "#F0B83A";
      data.push(obj);
    });

    const chartPie = {
      chart: {
        type: "pie",
        height: "300",
      },
      title: {
        text: "",
      },
      xAxis: {
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
      },
      series: [
        {
          innerSize: "70%",
          type: "pie",
          name: "Margin",
          data: data,
          color: "#F0B83ABF",
        },
      ],
      credits: {
        enabled: false,
      },
    };
    setDataChartPie(chartPie);
  };

  
  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("dashboard-group")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Sales</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>
      <div className="px-1  pb-5 list-denom mt-2">
        <Row>
          <div className="d-flex align-items-center mt-2">
            <div className="w-100 d-flex fs-6">
              <p className="flex-grow-1 text-dark"></p>
              <input
                type="date"
                ref={refInputStartDate}
                onChange={() => changeInputStartDate()}
                className="d-flex px-2 rounded border flex-grow-2 me-2 cursor-pointer tanggal"
                defaultValue={startDate}
              />
              <input
                type="date"
                ref={refInputEndDate}
                onChange={() => changeInputEndDate()}
                className="d-flex px-2 rounded border flex-grow-2 cursor-pointer tanggal"
                defaultValue={endDate}
              />
            </div>
          </div>
        </Row>

        <Row className="ps-2">
          <Col>
            <Row className="p-2">
              <Col>
                <div className="card shadow-sm mt-3 me-4 w-100 border-0">
                  <div className="card-body mt-2">
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 ms-3">
                        <small className="text-dark mb-0">Target Margin</small>
                        <h6 className="mt-2 text-dark fw-bold">
                          Rp {commafy(dataTarget ? dataTarget.target : 0)}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="card shadow-sm mt-3 me-4 w-100 border-0">
                  <div className="card-body mt-2">
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 ms-3">
                        <small className="text-dark mb-0">
                          Pencapaian Margin
                        </small>
                        <h6 className="mt-2 text-dark fw-bold">
                          {commafy(
                            dataPencapaian.pencapaian
                              ? dataPencapaian.pencapaian
                              : 0
                          )}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="card shadow-sm mt-3 w-100 border-0">
                  <div className="card-body mt-2">
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 ms-3">
                        <small className="text-dark mb-0">
                          Total Penjualan
                        </small>
                        <h6 className="mt-2 text-dark fw-bold">
                          Rp {commafy(dataPenjualan.penjualan)}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="p-2">
          <Col md="8">
            <div className="card ms-2 mt-1 border-0 h-100">
              <div className="card-body m-2 h-100">
                <div className="d-flex align-items-center">
                  <div className="w-100 d-flex font-14"></div>
                </div>
                <div className="w-100">
                  <div className="w-50 d-inline-block">
                    <h6 className="flex-grow-1 text-dark mt-2 fw-bold">
                      Margin
                    </h6>
                  </div>
                  <div className="w-50 d-inline-block  text-end">
                    <Form.Select
                      className="form-select form-select-sm float-end"
                      aria-label="Default select example"
                      style={{ width: "250px" }}
                      onChange={(e) => {
                        setSelectType(e.target.value);
                      }}
                    >
                      <option selected value="Daily">
                        Daily
                      </option>
                      <option value="Monthly">Monthly</option>
                    </Form.Select>
                  </div>
                </div>
                <div className="mt-3 h-100">
                  <HighchartsReact
                    highcharts={Highcharts}
                    containerProps={{ style: { height: "100%" } }}
                    options={dataChart}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <div className="card  border-0 ">
              <div className="card-body m-2">
                <div className="d-flex align-items-center">
                  <div className="w-100 d-flex font-14"></div>
                </div>
                <h6 className="flex-grow-1 text-dark mt-2 fw-bold">
                  Margin Per Platform
                </h6>
                <center>
                  <div className="mt-2">
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={dataChartPie}
                    />
                  </div>
                </center>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="p-2">
          <Col md="4">
            <div className="card border-0">
              <div className="card-body m-3">
                <div className="align-items-center">
                  <div className="w-100 d-flex font-14">
                    <Row>
                      <Col md="6">
                        <Form.Select
                          className="form-select form-select-sm mt-2"
                          aria-label="Default select example"
                          style={{ width: "250px" }}
                          onChange={(e) => {
                            changeSelectBy(e.target.value);
                          }}
                        >
                          <option selected value="Margin Tertinggi">
                            Margin Tertinggi
                          </option>
                          <option value="Margin Terendah">
                            Margin Terendah
                          </option>
                          <option value="Penjualan Tertinggi">
                            Penjualan Tertinggi
                          </option>
                          <option value="Penjualan Terendah">
                            Penjualan Terendah
                          </option>
                          <option value="Penjualan Kategori Tertinggi">
                            Penjualan Kategori Tertinggi
                          </option>
                          <option value="Penjualan Kategori Terendah">
                            Penjualan Kategori Terendah
                          </option>
                          <option value="Stok Tertinggi">Stok Tertinggi</option>
                          <option value="Stok Terendah">Stok Terendah</option>
                        </Form.Select>
                      </Col>
                      <Col className="d-flex justify-content-center" md="6">
                        <div className="d-flex align-items-center">
                          {selectBy === "Stok Tertinggi" ||
                          selectBy === "Stok Terendah" ? null : (
                            <div className="w-100 d-flex font-14"></div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="mt-2">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dataChartBar}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="flex-grow-1">
              <div className="card border-0">
                <div className="card-body m-3">
                  <div className="align-items-center">
                    <div className="w-100 d-flex font-14">
                      <Row>
                        <Col md="6">
                          <Form.Select
                            className="form-select form-select-sm mt-2"
                            aria-label="Default select example"
                            style={{ width: "250px" }}
                            onChange={(e) => {
                              changeSelectByTertinggi(e.target.value);
                            }}
                          >
                            <option selected value="Margin Tertinggi">
                              Margin Tertinggi
                            </option>
                            <option value="Margin Terendah">
                              Margin Terendah
                            </option>
                            <option value="Penjualan Tertinggi">
                              Penjualan Tertinggi
                            </option>
                            <option value="Penjualan Terendah">
                              Penjualan Terendah
                            </option>
                            <option value="Penjualan Kategori Tertinggi">
                              Penjualan Kategori Tertinggi
                            </option>
                            <option value="Penjualan Kategori Terendah">
                              Penjualan Kategori Terendah
                            </option>
                            <option value="Stok Tertinggi">
                              Stok Tertinggi
                            </option>
                            <option value="Stok Terendah">Stok Terendah</option>
                          </Form.Select>
                        </Col>
                        <Col className="d-flex justify-content-center" md="6">
                          <div className="d-flex align-items-center">
                            {selectByTertinggi === "Stok Tertinggi" ||
                            selectByTertinggi === "Stok Terendah" ? null : (
                              <div className="w-100 d-flex font-14"></div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="mt-2">
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={dataChartPenjualanTertinggi}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="card border-0 h-100">
              <div className="card-body m-2">
                <div className="d-flex align-items-center mb-3">
                  <div className=" d-flex font-14">
                    <h6 className="flex-grow-1 text-dark">
                      Penjualan Perpelanggan
                    </h6>
                  </div>
                </div>
                {transaksiPelanggan.map((item, key) => {
                  return (
                    <Row key={key}>
                      <Col md="1">
                        <img
                          src={pelanggan}
                          width="20"
                          alt=""
                          className="dashboardfoto"
                        />
                      </Col>
                      <Col md="6">
                        <p className="text-dark">{item.name}</p>
                      </Col>
                      <Col md="1">
                        <p className="text-dark">{item.transaksi}</p>
                      </Col>
                      <Col md="4">
                        <p className="text-dark">Rp {commafy(item.nominal)}</p>
                      </Col>
                    </Row>
                  );
                })}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Sales;
