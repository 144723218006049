import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { showImage } from "../../utils/Utils";
import pencil from "../../assets/images/pencil-square.svg";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import ReactQuill from "react-quill";

const UpdateProduct = () => {
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const [img, setImg] = useState([]);
  const [id, setId] = useState([]);
  const [product, setProduct] = useState({
    category_grosir: {},
    brands_grosir: {},
    gudang: {},
  });
  const [category, setCategory] = useState([]);
  const [brand, setBrand] = useState([]);
  const [unit, setUnit] = useState([]);
  const [gudang, setGudang] = useState([]);
  const [price, setPrice] = useState();
  const [valueDescription, setValueDescription] = useState("");

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm();

  const { api } = useContext(AppContext);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!history.location.product) {
      return history.push("/daftar-produk");
    }
    let product = history.location.product;
    setValue("name", product.name, {
      shouldValidate: false,
    });
    setValue("sku", product.sku, {
      shouldValidate: false,
    });

    setValue("stock", product.stock, {
      shouldValidate: false,
    });
    setValue("purchase_price", product.purchase_price, {
      shouldValidate: false,
    });
    setValue("weight", product.weight, {
      shouldValidate: false,
    });
    setValue("margin", product.margin, {
      shouldValidate: false,
    });
    setValue("description", product.description, {
      shouldValidate: false,
    });
    setValue("category_id", product.category_grosir.id, {
      shouldValidate: false,
    });
    setValue("brand_id", product.brands_grosir.id, {
      shouldValidate: false,
    });
    setValue("gudang_id", product.gudang.id, {
      shouldValidate: false,
    });
    setValue("satuan", product.unit_weight, {
      shouldValidate: false,
    });
    setValue("discount", product.discount, {
      shouldValidate: false,
    });
    setValue("price", product.price_before, {
      shouldValidate: false,
    });
    setValue("unit_weight", product.unit_weight, {
      shouldValidate: false,
    });
    setValue("purchase_price", product.purchase_price, {
      shouldValidate: false,
    });
    setValue("discount", product.discount, {
      shouldValidate: false,
    });
    setValue("limit_stock", product.limit_stock, {
      shouldValidate: false,
    });
    setValue("satuan", product.satuan, {
      shouldValidate: false,
    });

    setProduct(history.location.product);
    setId(history.location.product.id);
  }, [history.location.product]);

  const onSubmit = async (params) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("id", id);
    formData.append("img", params.img[0]);
    formData.append("name", params.name);
    formData.append("category_id", params.category_id);
    formData.append("brand_id", params.brand_id);
    formData.append("satuan", params.satuan);
    formData.append("stock", params.stock);
    formData.append("weight", params.weight);
    formData.append("unit_weight", params.unit_weight);
    formData.append("price", params.price);
    formData.append("discount", params.discount);
    formData.append(
      "description",
      valueDescription === "" ? product.description : valueDescription
    );
    formData.append("gudang_id", params.gudang_id);
    formData.append("sku", params.sku);
    formData.append("margin", params.margin);
    formData.append("limit_stock", params.limit_stock);
    formData.append("purchase_price", params.purchase_price);

    let UpdateProduct = await api("post", "products-grosir/update", formData);
    if (UpdateProduct.success) {
      history.push({ pathname: "/daftar-produk" });
    } else NotificationManager.error(UpdateProduct.message);
    setLoading(false);
  };
  const inputImage = async () => {
    setImg(true);
  };

  const getData = async () => {
    let category = await api("get", "category-grosir/get");
    if (category.success) {
      setCategory(category.data);
    }

    let brand = await api("get", "brands-grosir/get");
    if (brand.success) {
      setBrand(brand.data);
    }

    let unit = await api("get", "data-unit/get");
    if (unit.success) {
      setUnit(unit.data);
    }

    let gudang = await api("get", "gudang/get");
    if (gudang.success) {
      setGudang(gudang.data);
    }
  };

  const handleChange = async (value) => {
    let discount = watch("discount");
    let purchasePrice = watch("purchase_price");
    let margin = parseInt(value);
    // let newProduct = _.cloneDeep(product);
    // newProduct.price = parseInt(purchasePrice) + margin;
    // setProduct(newProduct);
    // console.log(newProduct);
    setValue("price", parseInt(purchasePrice) + margin, {
      shouldValidate: false,
    });
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/daftar-produk")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Ubah Produk</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <form
        onSubmit={handleSubmit(onSubmit)}
        id={product.price}
        className="bg-white p-3"
      >
        <div className="row justify-content-center">
          <div className="App">
            <div className="d-flex justify-content-center align-items-center">
              <img src={showImage(product.img)} width="150" />
              <img
                src={pencil}
                className="ms-3"
                width="23"
                height="45"
                type="file"
                onClick={() => inputImage()}
              />
            </div>

            <Form.Group controlId="formFile" className="font-11">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                className="font-14"
                {...register("img", {})}
              />
            </Form.Group>

            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="img" />
            </div>
          </div>
        </div>

        <Form.Group className="font-14 mt-2">
          <Form.Label>Nama Produk</Form.Label>
          <Form.Control
            defaultValue={product.name}
            className="font-14 shadow-none"
            {...register("name", {
              required: "Name is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="name" />
        </div>
        <Row>
          <Col>
            <Form.Group className="font-14 mt-4">
              <Form.Label>Kategori Produk</Form.Label>
              <Form.Select
                defaultValue={product.category_id}
                {...register("category_id", {
                  required: "category_id is required",
                })}
                className="font-14 shadow-none"
              >
                {category.map((item, key) => {
                  return (
                    <option
                      key={key}
                      value={item.id}
                      selected={
                        item.id === product.category_grosir.id ? true : false
                      }
                    >
                      {item.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="category_id" />
            </div>
          </Col>
          <Col>
            <Form.Group className="font-14 mt-4">
              <Form.Label>Brand Produk</Form.Label>
              <Form.Select
                defaultValue={product.brand_id}
                {...register("brand_id", {
                  required: "brand_id is required",
                })}
                className="font-14 shadow-none"
              >
                {brand.map((item, key) => {
                  return (
                    <option
                      key={key}
                      value={item.id}
                      selected={
                        item.id === product.brands_grosir.id ? true : false
                      }
                    >
                      {item.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="brand_id" />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="font-14 mt-2">
              <Form.Label>Gudang</Form.Label>
              <Form.Select
                defaultValue={product.gudang_id}
                {...register("gudang_id", {
                  required: "gudang_id is required",
                })}
                className="font-14 shadow-none"
              >
                {gudang.map((item, key) => {
                  return (
                    <option
                      key={key}
                      value={item.id}
                      selected={item.id === product.gudang.id ? true : false}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="gudang_id" />
            </div>
          </Col>
          <Col>
            <Form.Group className="font-14 mt-2">
              <Form.Label>SKU</Form.Label>
              <Form.Control
                defaultValue={product.sku}
                className="font-14 shadow-none"
                {...register("sku", {
                  required: "SKU is required",
                })}
              />
            </Form.Group>
            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="sku" />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="font-14 mt-1">
              <Form.Label>Stock</Form.Label>
              <Form.Control
                defaultValue={product.stock}
                className="font-14 shadow-none"
                {...register("stock", {
                  required: "stock is required",
                })}
              />
            </Form.Group>
            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="stock" />
            </div>
          </Col>
          <Col>
            <Form.Group className="font-14 mt-1">
              <Form.Label>Satuan</Form.Label>
              <Form.Select
                defaultValue={product.satuan}
                {...register("satuan", {
                  required: "satuan is required",
                })}
                className="font-14 shadow-none"
              >
                {unit.map((item, key) => {
                  return (
                    <option
                      key={key}
                      selected={item.name === product.satuan ? true : false}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="satuan" />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="font-14 mt-1">
              <Form.Label>Berat Product</Form.Label>
              <Form.Control
                defaultValue={product.weight}
                className="font-14 shadow-none"
                {...register("weight", {
                  required: "weight is required",
                })}
              />
            </Form.Group>
            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="weight" />
            </div>
          </Col>
          <Col>
            <Form.Group className="font-14 mt-1">
              <Form.Label>Satuan Berat</Form.Label>
              <Form.Select
                defaultValue={product.unit_weight}
                {...register("unit_weight", {
                  required: "unit_weight is required",
                })}
                className="font-14 shadow-none"
              >
                {unit.map((item, key) => {
                  return (
                    <option
                      key={key}
                      selected={
                        item.name === product.unit_weight ? true : false
                      }
                    >
                      {item.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="unit_weight" />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="font-14 mt-1">
              <Form.Label>Harga Beli</Form.Label>
              <Form.Control
                disabled
                defaultValue={product.purchase_price}
                className="font-14 shadow-none"
                {...register("purchase_price", {})}
              />
            </Form.Group>
            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="weight" />
            </div>
          </Col>
          <Col>
            <Form.Group className="font-14 mt-1">
              <Form.Label>Harga Jual</Form.Label>
              <Form.Control
                defaultValue={product.price_before}
                className="font-14 shadow-none"
                {...register("price", {
                  required: "price is required",
                })}
              />
            </Form.Group>
            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="unit_weight" />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="font-14 mt-1">
              <Form.Label>Margin</Form.Label>
              <Form.Control
                // disabled
                defaultValue={product.margin}
                onInput={(e) => handleChange(e.target.value)}
                className="font-14 shadow-none"
                {...register("margin", {
                  required: "margin is required",
                })}
              />
            </Form.Group>
            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="weight" />
            </div>
          </Col>
          <Col>
            <Form.Group className="font-14 mt-1">
              <Form.Label>Diskon</Form.Label>
              <Form.Control
                defaultValue={product.discount}
                className="font-14 shadow-none"
                {...register("discount", {
                  required: "discount is required",
                })}
              />
            </Form.Group>
            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="unit_weight" />
            </div>
          </Col>
        </Row>

        <Row>
          <Form.Group className="font-14 mt-2">
            <Form.Label>Batas Stok Rendah</Form.Label>
            <Form.Control
              defaultValue={product.limit_stock}
              className="font-14 shadow-none"
              {...register("limit_stock", {
                required: "Limit_stock is required",
              })}
            />
          </Form.Group>
          <div className="my-2 text-left float-start font-12 text-danger">
            <ErrorMessage errors={errors} name="Limit Stock" />
          </div>
        </Row>

        <Form.Group className="font-14 mt-2">
          <ReactQuill
            theme="snow"
            value={
              valueDescription === "" ? product.description : valueDescription
            }
            onChange={setValueDescription}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="description" />
        </div>
        <Form.Group className="font-14 mt-2 w-100">
          <Button
            variant="primary"
            className="btn sticky-bottom btn-primary mt-3  w-100 text-white font-14"
            type="submit"
          >
            Update
          </Button>
          <div
            hidden
            className="mt-3 w-100 text-center border-primary rounded p-2 cursor-pointer"
            onClick={() =>
              history.push({
                pathname: "/product-price",
                product: id,
              })
            }
          >
            <span className="daftar ps-1 text-primary">Varian Harga</span>
          </div>
        </Form.Group>
      </form>
    </React.Fragment>
  );
};

export default UpdateProduct;
