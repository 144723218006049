import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { useForm } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { showImage } from "../../utils/Utils";
import { ErrorMessage } from "@hookform/error-message";

const UpdateBiaya = () => {
  const [isLoading, setLoading] = useState(false);
  const { api } = useContext(AppContext);
  const history = useHistory();
  const [id, setId] = useState("");
  const [data, setData] = useState();
  const [dataBiaya, setDataBiaya] = useState([]);
  const [image, setImage] = useState([]);
  const [statusPembayaran, setStatusPembayaran] = useState("Belum Lunas");
  const [oldStatusPembayaran, setOldStatusPembayaran] = useState("");
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  useEffect(() => {
    if (!history.location.data) {
      return history.push("/data-biaya");
    }

    let dataState = history.location.data;

    setValue("name", dataState.name, {
      shouldValidate: false,
    });
    setValue("type", dataState.type, {
      shouldValidate: false,
    });
    setValue("status_pembayaran", dataState.status_pembayaran, {
      shouldValidate: false,
    });
    setValue("pic", dataState.pic, {
      shouldValidate: false,
    });
    setValue("amount", dataState.amount, {
      shouldValidate: false,
    });
    setValue("description", dataState.description, {
      shouldValidate: false,
    });
    setValue("date", dataState.date, {
      shouldValidate: false,
    });
    setValue("img", image.img, {
      shouldValidate: false,
    });
    setId(history.location.id);
    setDataBiaya(history.location.data);
    setOldStatusPembayaran(history.location.data.status_pembayaran);
  }, [history.location.data]);

  const onSubmit = async (params) => {
    setLoading(true);

    if (oldStatusPembayaran !== params.status_pembayaran) {
      if (oldStatusPembayaran === "Belum Lunas" && params.status_pembayaran === "Lunas") {

        if (params.type === "Gaji Karyawan") {
          const formDataBayarHutang = new FormData();
          formDataBayarHutang.append("type", "Pengeluaran");
          formDataBayarHutang.append("category", "Hutang Gaji");
          formDataBayarHutang.append("nominal", params.amount);
          formDataBayarHutang.append("description", "Bayar Hutang Gaji");

          await api("post", "cash-grosir/post", formDataBayarHutang);
        }

        if (params.type === "Bangunan" || params.type === "Kendaraan" || params.type === "Perlengkapan Kantor") {
          const formDataBayarHutang = new FormData();
          formDataBayarHutang.append("type", "Pengeluaran");
          formDataBayarHutang.append("category", "Hutang Kantor");
          formDataBayarHutang.append("nominal", params.amount);
          formDataBayarHutang.append("description", "Bayar Hutang Kantor");

          await api("post", "cash-grosir/post", formDataBayarHutang);
        } 

        if (params.type === "Biaya Kantor" || params.type === "Biaya Transport" || params.type === "Depresiasi") {
          const formDataBayarHutang = new FormData();
          formDataBayarHutang.append("type", "Pengeluaran");
          formDataBayarHutang.append("category", "Hutang Dagang");
          formDataBayarHutang.append("nominal", params.amount);
          formDataBayarHutang.append("description", "Bayar Hutang Dagang");

          await api("post", "cash-grosir/post", formDataBayarHutang);
        }

        const formDataBayar = new FormData();
        formDataBayar.append("type", "Pengeluaran");
        formDataBayar.append("category", params.pay_from);
        formDataBayar.append("nominal", params.amount);
        formDataBayar.append("description", "Pengeluaran");

        await api("post", "cash-grosir/post", formDataBayar);
        
        const formData = new FormData();
        formData.append("id", id);
        formData.append("name", params.name);
        formData.append("type", params.type);
        formData.append("pic", params.pic);
        formData.append("amount", params.amount);
        formData.append("status_pembayaran", params.status_pembayaran);
        formData.append("description", params.description);
        formData.append("date", params.date);
        formData.append("img", params.img[0]);
        
        let UpdateBiaya = await api("post", "data-biaya/update", formData);
    
        if (UpdateBiaya.success) {
          history.push({ pathname: "/data-biaya" });
          NotificationManager.success(UpdateBiaya.message);
        } else NotificationManager.error(UpdateBiaya.error);
      }
    } else {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("name", params.name);
      formData.append("type", params.type);
      formData.append("pic", params.pic);
      formData.append("amount", params.amount);
      formData.append("status_pembayaran", params.status_pembayaran);
      formData.append("description", params.description);
      formData.append("date", params.date);
      formData.append("img", params.img[0]);
  
      let UpdateBiaya = await api("post", "data-biaya/update", formData);
  
      if (UpdateBiaya.success) {
        history.push({ pathname: "data-biaya" });
        NotificationManager.success(UpdateBiaya.message);
      } else NotificationManager.error(UpdateBiaya.error);
    }

    setLoading(false);
  };

  const changeStatusPembayaran = async (value) => {
    setStatusPembayaran(value);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push({ pathname: "/data-biaya" })}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Update Biaya</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>
      <form onSubmit={handleSubmit(onSubmit)} className="bg-white p-3">
        <Form.Group className="font-14 mt-2">
          <Form.Label>Nama Biaya</Form.Label>
          <Form.Control
            defaultValue={dataBiaya.name}
            className="font-14 shadow-none"
            {...register("name", {
              required: "Name is required",
            })}
          />
        </Form.Group>
        <div className="text-danger">
          <ErrorMessage errors={errors} name="name" />
        </div>

        <Form.Group className="font-14 mt-1">
          <Form.Label>Jenis Biaya</Form.Label>
          <Form.Select
            defaultValue={dataBiaya.type}
            {...register("type", {})}
            className="font-14 shadow-none"
          >
            <option selected={dataBiaya.type === "Gaji Karyawan" ? true : false}>Gaji Karyawan</option>
            <option selected={dataBiaya.type === "Biaya Kantor" ? true : false}>Biaya Kantor</option>
            <option selected={dataBiaya.type === "Biaya Transport" ? true : false}>Biaya Transport</option>
            <option selected={dataBiaya.type === "Depresiasi" ? true : false}>Depresiasi</option>
            <option selected={dataBiaya.type === "Bangunan" ? true : false}>Bangunan</option>
            <option selected={dataBiaya.type === "Kendaraan" ? true : false}>Kendaraan</option>
            <option selected={dataBiaya.type === "Perlengkapan Kantor" ? true : false}>Perlengkapan Kantor</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="font-14 mt-2">
          <Form.Label>PIC</Form.Label>
          <Form.Control
            defaultValue={dataBiaya.pic}
            className="font-14 shadow-none"
            {...register("pic", {
              required: "pic is required",
            })}
          />
        </Form.Group>

        <Form.Group className="font-14 mt-2">
          <Form.Label>Jumlah Biaya</Form.Label>
          <Form.Control
            defaultValue={dataBiaya.amount}
            className="font-14 shadow-none"
            {...register("amount", {
              required: "amount is required",
            })}
          />
        </Form.Group>

        <Form.Group className="font-14 mt-1">
          <Form.Label>Status Pembayaran</Form.Label>

          <Form.Select
            defaultValue={dataBiaya.status_pembayaran}
            {...register("status_pembayaran", {})}
            className="font-14 shadow-none"
            onChange={(e) => {
              changeStatusPembayaran(e.target.value);
            }}
          >
            <option selected={dataBiaya.status_pembayaran === "Lunas" ? true : false}>Lunas</option>
            <option selected={dataBiaya.status_pembayaran === "Belum Lunas" ? true : false}>Belum Lunas</option>
          </Form.Select>
        </Form.Group>

        {
          statusPembayaran === "Lunas" ?
            <Form.Group className="font-14 mt-1">
              <Form.Label>Bayar Dari</Form.Label>
              <Form.Select
                {...register("pay_from", {})}
                className="font-14 shadow-none"
              >
                <option>BCA</option>
                <option>Mandiri</option>
              </Form.Select>
            </Form.Group> : null
        }

        <Form.Group className="font-14 mt-2">
          <Form.Label>Deskripsi</Form.Label>
          <textarea
            defaultValue={dataBiaya.description}
            className="font-14 shadow-none w-100 rounded form-control"
            placeholder="Keterangan"
            {...register("description", {
              required: "description is required",
            })}
          />
        </Form.Group>
        <div className="text-danger">
          <ErrorMessage errors={errors} name="description" />
        </div>

        <Form.Group className="font-14 mt-2">
          <Form.Label>Tanggal</Form.Label>
          <Form.Control
            defaultValue={dataBiaya.date}
            type="date"
            className="form-control form-control-lg font-14 shadow-none"
            placeholder="Tanggal"
            style={{ backgroundColor: "#F9F9F9" }}
          />
        </Form.Group>

        <div className="row justify-content-center">
          <div className="App">
            <Form.Group controlId="formFile" className="font-11">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                className="font-14"
                {...register("img", {})}
              />
              <img
                src={showImage(image)}
                width="200"
                height="100"
                className="mt-4"
              />
            </Form.Group>

            <div className="text-danger">
              <ErrorMessage errors={errors} name="img" />
            </div>
          </div>
        </div>

        <Form.Group className="font-14 mt-2 w-100">
          <Button
            variant="primary"
            className="btn sticky-bottom btn-primary  w-100 text-white font-14"
            type="submit"
          >
            Update
          </Button>
        </Form.Group>
      </form>
    </React.Fragment>
  );
};

export default UpdateBiaya;
