import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import box from "../../assets/images/box.svg";
import telepon from "../../assets/images/telepon.svg";
import jam from "../../assets/images/jam.svg";
import alamat from "../../assets/images/alamatpenerima.svg";

const ProsesPengiriman = () => {
  const history = useHistory();

  return (
    <React.Fragment>
      <div className="container px-4 mt-4">
        <div className="align-items-center">
          <center>
            <img src={box} className="mt-5" />
            <div>
              <h5 className="mt-3">Proses Pengiriman</h5>
              <span className="">
                Produk yang kamu pesan masih dalam proses pengiriman
              </span>
              <br />
              <p className="mt-3">
                Pesanan#: 10212140 <br />
                <span>Tanggal Pesanan 22 Agustus 2022 </span>
              </p>
            </div>
          </center>
        </div>
        <div className="card-body border rounded mt-3">
          <br />
          <div className="d-flex align-items-center">
            <div className="d-flex flex-row flex-shrink-0">
              <div className="ms-3">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 mb-3">
                    <img src={alamat} className="mx-3" width="20" alt="" />
                  </div>
                  <div className="flex-grow-1 ms-3 ">
                    <h6 className="text-black">Alamat</h6>
                    <p className="text-black">kp bieuow, desa jeoiwwwp, kecamatan hdoqqw, kab, kota. </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-grow-2 ms-3"></div>
          </div>
          <hr className=" hr-product " />
          <br />
          <div className="d-flex align-items-center">
            <div className="d-flex flex-row flex-shrink-0">
              <div className="ms-3">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 mb-3">
                    <img src={jam} className="mx-3" width="20" alt="" />
                  </div>
                  <div className="flex-grow-1 ms-3 ">
                    <h6 className="text-black">Estimasi Pengiriman </h6>
                    <p className="text-black">30 menit ke lokasi </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-grow-2 ms-3"></div>
          </div>
          <hr className=" hr-product " />
          <div className="d-flex align-items-center">
            <div className="d-flex flex-row flex-shrink-0">
              <div className="ms-3 mt-3">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 mb-3">
                    <img src={telepon} className="mx-3" width="20" alt="" />
                  </div>
                  <div className="flex-grow-1 ms-3 ">
                    <h6 className="text-black">Hubungi Kurir</h6>
                    <p className="text-black">
                      082113831489
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProsesPengiriman;
