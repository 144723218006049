import React, { useContext, useEffect, useState, useRef } from "react";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { showImage } from "../../utils/Utils";
import { AppContext } from "../../context/AppContext";
import tambah from "../../assets/images/tambah.svg";
import trash from "../../assets/images/trash-icon.svg";
import { NotificationManager } from "react-notifications";
import { useDebounce } from "use-debounce";

const DaftarProduk = () => {
  const { api, setSpinner } = useContext(AppContext);
  const [category, setCategory] = useState([]);
  const history = useHistory();
  const [data, setData] = useState([]);
  const [categoryId, setCategoryId] = useState(0);
  const refInput = useRef();
  const [text, setText] = useState("");
  const [value] = useDebounce(text, 1000);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => { 
    let keyword = {
      admin: true,
      name: value,
    };

    getProduct(keyword);
  }, [value]);

  const getProduct = async (params) => {
    let data = await api("get", "products-grosir/get", params);
    setData(data.data);
  };

  const getData = async () => {
    setSpinner(true);
    if (history.location.category_id) {
      setCategoryId(history.location.category_id);
      let data = await api("get", "products-grosir/get", {
        admin: true,
        category_id: history.location.category_id,
      });
      setData(data.data);
    } else {
      let data = await api("get", "products-grosir/get", { admin: true });
      setData(data.data);
    }
  
    let category = await api("get", "category-grosir/get", { admin:true});
    setCategory(category.data);
    setSpinner(false);
  };

  const selectAll = (e) => {
    let newData = data.map((item) => {
      item.selected = e.target.checked;
      return item;
    });
    setData(newData);
  };

  const selectSingle = (id, selected) => {
    let newData = data.map((item) => {
      if (item.id === id) item.selected = selected;
      return item;
    });

    setData(newData);
  };

  const delData = async () => {
    let id = "",
      separator = "";
    data.map((item) => {
      if (item.selected === true) {
        id = id + separator + item.id;
        separator = ",";
      }
    });

    let param = { id_all: id };
    let del = await api("post", "products-grosir/delete-all", param);

    if (del.success) getData();
  };

  const changeInput = async () => {
    let value = refInput.current.value;
    setText(value) 
  };

  const handleChange = async (id, isActive) => {
    setSpinner(true);
    let data = await api("post", "products-grosir/update-status", {
      id: id,
      is_active: isActive,
    });
    setSpinner(false);
    if (data.success) {
      NotificationManager.success(data.message);
      getData();
    }
  };
  const semuaProduct = async () => {
    setSpinner(true);
    let semua = await api("get", "products-grosir/get", { admin: true });
    setData(semua.data);
    setSpinner(false);
  };

  const handleClickType = async (param) => {
    let category_id = {
      admin: true,
      category_id: param.id,
    };
    setSpinner(true);
    let data = await api("get", "products-grosir/get", category_id);
    setData(data.data);
    setSpinner(false);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm  w-100">
        <div className="p-2 pb-2 ps-4 w-100 d-flex align-items-center">
          <div className="d-inline-block">
            <img
              src="img/arrow-left.svg"
              alt=""
              width="30"
              height="24"
              onClick={() => history.push("/dashboard-inventory")}
              className="d-inline-block align-text-top cursor-pointer"
            />
          </div>
          <div className="d-inline-block ms-2" style={{ width: "90%" }}>
            <form className="w-100">
              <div className="input-group w-100">
                <input
                  type="text"
                  className="form-control form-control-lg font-16"
                  placeholder="Cari Produk"
                  ref={refInput}
                  aria-describedby="basic-addon2"
                  onChange={() => changeInput()}
                />
                <button className="input-group-text bg-white border">
                  <i className="bi bi-search"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="riwayat w-100  overflow-scroll">
          <div className="bg-light mx-4">
            <ul
              className="nav nav-pills nav-fill mb-3"
              id="pills-tab"
              role="tablist"
            >
              <div className="d-flex">
                <li
                  className="nav-item "
                  role="presentation"
                  hidden={categoryId === 0 ? false : true}
                >
                  <button
                    className="nav-link py-3 active no-wrap"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    role="tab"
                    onClick={() => semuaProduct()}
                  >
                    Semua
                  </button>
                </li>
                {category.map((item, key) => {
                  if (categoryId !== 0 && item.id !== categoryId) return;
                  return (
                    <li className="nav-item" role="presentation">
                      <button
                        className={
                          categoryId === item.id
                            ? "nav-link py-3 no-wrap active"
                            : "nav-link py-3 no-wrap"
                        }
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        role="tab"
                        key={key}
                        md={category.column}
                        onClick={() => handleClickType(item)}
                      >
                        {item.name}
                      </button>
                    </li>
                  );
                })}
              </div>
            </ul>
          </div>
        </div>
      </nav>

      <div className="container px-4">
        <div className="deleteform d-flex align-item-center ms-3 mt-2">
          <input
            className="form-check-input  "
            type="checkbox"
            onClick={(e) => selectAll(e)}
            value=""
            id="flexCheckDefault"
          />
          <label
            className="form-check-label flex-grow-1 ms-3"
            htmlFor="flexCheckDefault"
          >
            Pilih semua
          </label>
          <div className="flex-grow-2 mx-3">
            <div className="text-muted mb-0">
              <img src={trash} width="25" onClick={() => delData()} />
            </div>
          </div>
        </div>

        <br />
        <div className="row">
          {data.map((item, key) => {
            return (
              <div className="col-mb-12" key={key}>
                <div className="card mb-3 ">
                  <div className="card-body ">
                    <div className="d-flex align-items-center">
                      <div className="deleteform form-check-delete">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onClick={(e) =>
                            selectSingle(item.id, e.target.checked)
                          }
                          value=""
                          checked={item.selected}
                          id="flexCheckDefault "
                        />
                      </div>
                      <div
                        className="flex-shrink-0 p-3 rounded bg-lime ms-4"
                        onClick={() =>
                          history.push({
                            pathname: "/riwayat-stok",
                            data: item,
                          })
                        }
                      >
                        <img
                          src={showImage(item.img)}
                          width="50"
                          className="daftarprodukimg"
                        />
                      </div>
                      <div className="flex-grow-1 ms-3 mt-3 ">
                        <small className="mb-1 text-secondary font-14">
                          {item.sku}
                        </small>
                        <div className="d-flex mb-2 ">
                          <span className="mb-0 text-primary date-time font-16 nameproduk">
                            {item.name}
                          </span>
                        </div>
                        <div className="d-flex">
                          <span className="text-secondary  font-14 jumlahproduk">
                            Stock : {item.stock + " " + item.satuan}
                          </span>
                          <div
                            className="input-group ms-auto mb-0 "
                            style={{ width: "126px" }}
                          ></div>
                        </div>
                      </div>
                      <div>
                        <Form.Check
                          type="switch"
                          className={
                            item.is_active ? "text-primary" : "text-danger"
                          }
                          id={item.id}
                          label={item.is_active ? "Aktif" : "Tidak Aktif"}
                          onChange={(e) =>
                            handleChange(item.id, e.target.checked)
                          }
                          defaultChecked={item.is_active}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="float-end  sticky-bottom py-3 cursor-pointer">
          <img src={tambah} onClick={() => history.push("/tambah-produk")} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DaftarProduk;
