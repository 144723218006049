import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { useForm } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { showImage } from "../../utils/Utils";
import pencil from "../../assets/images/pencil-square.svg"
import { ErrorMessage } from "@hookform/error-message";


const UpdateCategory = () => {
  const [isLoading, setLoading] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const history = useHistory();
  const [id, setId] = useState("");
  const [category, setCategory] = useState([]);
  const [file, setFile] = useState([])
  const [image, setImage] = useState([]);
  const [data, setData] = useState([]);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const { api } = useContext(AppContext);

  useEffect(() => {
    setId(history.location.state.id)
    setCategory(history.location.state.category)
    setImage(history.location.state.image)
  }, [history])

  function handleChange(e){
    console.log(e.target.file[0]);
    setFile(URL.createObjectURL(e.target.file[0]));
  }

  const handleFileSelected = async(event) =>{
    const formData = new formData()
    formData.append('img', event.target.files)
  }

  const onSubmit = async (params) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("id", id);
    formData.append("name", params.name);
    formData.append("img", params.img[0]);

    let UpdateCategory = await api("post", "category-grosir/update", formData);
    
    const getData = async () => {
      let param = { id: id}
      let data = await api("get", "category-grosir/detail", param);
      setData(data.data);
      console.log(data)
  }

    if (UpdateCategory.success) {
      history.push({ pathname: "category-data" });
    } else NotificationManager.error(UpdateCategory.message);

    setLoading(false);
  };

  const inputImage = async() => {
    setIsImage(true)
  }

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/category-data")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3"> Kategori</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <form onSubmit={handleSubmit(onSubmit)}  className="bg-white p-3">
      <Form.Group className="font-14 mt-2">
          <Form.Label>Kategori</Form.Label>
          <Form.Control
            defaultValue={category}
            className="font-14 shadow-none"
            {...register("name", {
              required: "name is required",
            })}
          />
        </Form.Group>
        
        <div className="row justify-content-center">
          <div className="App">
            <Form.Group controlId="formFile" className="font-11">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                className="font-14"
                {...register("img", {})}
              />
              <img src={showImage(image)} width="200" height="100" className="mt-4"/>
            </Form.Group>

            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="img" />
            </div>
          </div>
        </div>
        <br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>

       
        <Form.Group className="font-14 mt-2 w-100">
          <Button
            variant="primary"
            className="btn sticky-bottom btn-primary  w-100 text-white font-14"
            type="submit"
          >
            Simpan
          </Button>
        </Form.Group>
{/*     
        <div className="d-grid py-4  fixed-bottom" >
          <center>
            <Button
              style={{width:"700px"}}
              variant="primary"
              className="mt-2 btn btn-primary btn-lg d-block text-white  font-14"
              disabled={isLoading}
              type="submit"
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Ubah Data"
              )}
            </Button>
          </center>
        </div> */}
      </form>
    </React.Fragment>
  );
};

export default UpdateCategory;