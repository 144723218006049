import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, NavItem, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import imgtiga from "../../assets/images/titiktiga.svg";
import cewek1 from "../../assets/images/cewek1.svg";
import tambah from "../../assets/images/tambah.svg";
import diskonfoto from "../../assets/images/diskonfoto.svg";
import nama from "../../assets/images/nama-user.svg";
import telp from "../../assets/images/telp-user.svg";
import email from "../../assets/images/email-user.svg";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";

const DetailPegawai = () => {
  const history = useHistory();
  const { api, setSpinner } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [pegawai, setPegawai] = useState({});
  const [id, setId] = useState("");
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  useEffect(() => {
    if (!history.location.pegawai) history.push("/data-pegawai");
    else {
      setPegawai(history.location.pegawai);
      setId(history.location.pegawai.id);
    }
  }, []);

  useEffect(() => {
    if (pegawai.id) getData();
  }, [pegawai]);

  const getData = async () => {
    setSpinner(true);
    let data = await api("get", "user/detail-pegawai", {id: id});
    setData(data.data);
    setSpinner(false);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/data-pegawai")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Detail Pegawai</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>
      <div className="container px-4 mt-4">
        <div className="row">
          <div className="col-md-12">
            <img src={cewek1} alt="" className="w-100" height="170px" />
            {data.map((item, key) => {
              return (
                <div className="card my-3 border-0" key={key}>
                  <div className="card-body my-3">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 ">
                        <img
                          src={nama}
                          className="mx-4 mb-3"
                          width="25"
                          alt=""
                        />
                      </div>
                      <div className="flex-grow-1 ">
                        <small className="text-muted">Nama Pegawai</small>
                        <h6 className="text-black mt-1">{item.first_name}</h6>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mt-5">
                      <div className="flex-shrink-0 ">
                        <img
                          src={nama}
                          className="mx-4 mb-3"
                          width="25"
                          alt=""
                        />
                      </div>
                      <div className="flex-grow-1 ">
                        <small className="text-muted">Role</small>
                        <h6 className="text-black mt-1">
                          {item.users_role.role.name}
                        </h6>
                      </div>
                    </div>

                    <div className="d-flex align-items-center mt-5">
                      <div className="flex-shrink-0 ">
                        <img
                          src={telp}
                          className="mx-4 mb-3"
                          width="25"
                          alt=""
                        />
                      </div>
                      <div className="flex-grow-1 ">
                        <small className="text-muted">No. Handphone</small>
                        <h6 className="text-black mt-1">{item.phone}</h6>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mt-5">
                      <div className="flex-shrink-0 ">
                        <img
                          src={email}
                          className="mx-4 mb-3"
                          width="25"
                          alt=""
                        />
                      </div>
                      <div className="flex-grow-1 ">
                        <small className="text-muted">Email</small>
                        <h6 className="text-black mt-1">{item.email}</h6>
                      </div>
                    </div>
                    <div className="d-flex mt-5 ms-4">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Active"
                        defaultChecked = {item.is_active? true: false}
                      />
                      

                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        className="ms-3"
                        label="Verified"
                        defaultChecked = {item.is_verified? true: false}
                      />
                      
                    </div>
                  </div>
                </div>
              );
            })}
            <button
              onClick={() =>
                history.push({
                  pathname: "/update-pegawai",
                  data: data[0],
                })
              }
              type="button"
              className="mt-2 btn btn-primary sticky-bottom btn-lg d-block text-white w-100 font-16"
            >
              Ubah Data
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DetailPegawai;
