import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import grosir from "../../assets/images/grosir.svg"

const Bottom = () => {
  const history = useHistory();
  const [local, setLocal] = useState(null);

  useEffect(() => {
    let localData = localStorage.getItem("useranarastore");
   setLocal(JSON.parse(localData));
  }, []);

  return (
    <div className="sticky-bottom">
      <div className="border-top py-2 px-0 bg-white">
        <div className="row row-cols-1 row-cols-md-5 g-0 text-center navbar-bottom">
          <div
            className="col-3 cursor-pointer"
            onClick={() => history.push("/")}
          >
            <div className="card border border-0 bg-transparent">
              <img
                src="img/beranda.svg"
                className="img-fluid mx-auto d-block"
              />
              <div className="card-footer p-0 bg-transparent border border-0">
                <small className="text-muted">Beranda</small>
              </div>
            </div>
          </div>
          <div
            className="col-3 cursor-pointer"
            onClick={() => history.push(local ? "/history" : "/login")}
          >
            <div className="card border border-0 bg-transparent">
              <img
                src="img/riwayat.svg"
                className="img-fluid mx-auto d-block"
              />
              <div className="card-footer p-0 bg-transparent border border-0">
                <small className="text-muted">Riwayat</small>
              </div>
            </div>
          </div>
          <div
            className="col-3 cursor-pointer"
            onClick={() => history.push("/grosir-home")}
          >
            <div className="card border border-0 bg-transparent">
              <img
                src={grosir} 
                className="img-fluid mx-auto d-block t-1"
                style={{height:'30px'}}
              />
              <div className="card-footer p-0 bg-transparent border border-0">
                <small className="text-muted">Grosir</small>
              </div>
            </div>
          </div>
          <div
            className="col-3 cursor-pointer"
            onClick={() => history.push("/bantuan")}
          >
            <div className="card border border-0 bg-transparent">
              <img
                src="img/bantuan.svg"
                className="img-fluid mx-auto d-block"
              />
              <div className="card-footer p-0 bg-transparent border border-0">
                <small className="text-muted">Bantuan</small>
              </div>
            </div>
          </div>
          <div
            className="col-3 cursor-pointer"
            onClick={() => history.push(local ? "/akun" : "/login")}
          >
            <div className="card border border-0 bg-transparent">
              <img src="img/akun.svg" className="img-fluid mx-auto d-block" />
              <div className="card-footer p-0 bg-transparent border border-0">
                <small className="text-muted">Akun</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bottom;