import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import dokumentasi from "../../assets/images/dokumentasi-icon.svg";
import Video from "../../assets/images/video-tutorial.svg";
import Rate from "../../assets/images/rate-app.svg";
import Bagikan from "../../assets/images/bagikan-app.svg";
import Hubungi from "../../assets/images/hubungi-kami.svg";

const BantuanAdmin = () => {
  const history = useHistory();
  const { api } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    getData();
    console.log(111);
  });

  const getData = async (prefix) => {
    let data = await api("get", "user/profile");
    setData(data.data);
    console.log(data);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/dashboard")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Bantuan</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="g-col-6  px-cst1 pb-2 list-denom ">
        <Row className="mt-2">
          <Col>
            <div
              className="card cursor-pointer"
              style={{ borderRadius: "10px" }}
              onClick={() => history.push("/detail-biaya")}
            >
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 px-3">
                    <img src={dokumentasi} width="40" alt="" />
                  </div>
                  <div className="flex-grow-1">
                    <h6 className="text-dark mt-2">Dokumentasi</h6>
                    <small className="text-muted">Dokumentasi Aplikasi </small>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="g-col-6  px-cst1 pb-2 list-denom ">
        <Row className="mt-2">
          <Col>
            <div
              className="card cursor-pointer"
              style={{ borderRadius: "10px" }}
              onClick={() => history.push("/detail-biaya")}
            >
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 px-3">
                    <img src={Video} width="40" alt="" />
                  </div>
                  <div className="flex-grow-1">
                    <h6 className="text-dark mt-2">Video Tutorial</h6>
                    <small className="text-muted">
                      Video cara menggunakan aplikasi{" "}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="g-col-6  px-cst1 pb-2 list-denom ">
        <Row className="mt-2">
          <Col>
            <div
              className="card cursor-pointer"
              style={{ borderRadius: "10px" }}
              onClick={() => history.push("/detail-biaya")}
            >
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 px-3">
                    <img src={Rate} width="40" alt="" />
                  </div>
                  <div className="flex-grow-1">
                    <h6 className="text-dark mt-2">Rate App</h6>
                    <small className="text-muted">
                      Tulis ulasan, kritik, saran dan rating aplikasi di
                      playstore{" "}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="g-col-6  px-cst1 pb-2 list-denom ">
        <Row className="mt-2">
          <Col>
            <div
              className="card cursor-pointer"
              style={{ borderRadius: "10px" }}
              onClick={() => history.push("/detail-biaya")}
            >
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 px-3">
                    <img src={Bagikan} width="40" alt="" />
                  </div>
                  <div className="flex-grow-1">
                    <h6 className="text-dark mt-2">Bagikan App</h6>
                    <small className="text-muted">Dokumentasi Aplikasi </small>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="g-col-6  px-cst1 pb-2 list-denom ">
        <Row className="mt-2">
          <Col>
            <div
              className="card cursor-pointer"
              style={{ borderRadius: "10px" }}
              onClick={() => history.push("/detail-biaya")}
            >
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 px-3">
                    <img src={Hubungi} width="40" alt="" />
                  </div>
                  <div className="flex-grow-1">
                    <h6 className="text-dark mt-2">Hubungi Kami </h6>
                    <small className="text-muted">
                      Hubungi kami untuk saran, pertanyaan, keluhan dan lainnya
                      melalui email atau whatsapp{" "}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default BantuanAdmin;
