import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { NotificationManager } from "react-notifications";
import DatePicker from "react-datepicker";

const UpdateTarget = () => {
  const { api } = useContext(AppContext);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const [targetPenjualan, setTargetPenjualan] = useState([]);
  const [id, setId] = useState("");
  const [date, setDate] = useState(new Date());

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  useEffect(() => {
    setId(history.location.data.id);
    setTargetPenjualan(history.location.data);
    const date = new Date(
      parseInt(history.location.data.year),
      parseInt(history.location.data.month - 1)
    );
    setDate(date);

    setValue("name", history.location.data.name, {
      shouldValidate: false,
    });
    setValue("target", history.location.data.target, {
      shouldValidate: false,
    });
  }, [history.location]);

  const onSubmit = async (params) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("id", id);
    formData.append("name", params.name);
    formData.append("target", params.target);
    formData.append("month", date.getMonth() + 1);
    formData.append("year", date.getFullYear());

    let UpdateTarget = await api("post", "target-grosir/update", formData);

    if (UpdateTarget.success) {
      history.push({ pathname: "/target-penjualan" });
      NotificationManager.success(UpdateTarget.message);
    } else NotificationManager.error(UpdateTarget.error);

    setLoading(false);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/target-penjualan")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Update Target Penjualan</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <form onSubmit={handleSubmit(onSubmit)} className="bg-white p-3">
        <Form.Group>
          <Form.Label className="w-100">Bulan & Tahun Target</Form.Label>
          <DatePicker
            selected={date}
            onChange={(date) => setDate(date)}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            showFullMonthYearPicker
            className="form-control w-100"
          />
        </Form.Group>
        <Form.Group className="font-14 mt-2">
          <Form.Label>Nama Target</Form.Label>
          <Form.Control
            defaultValue={targetPenjualan.name}
            className="font-14 shadow-none"
            {...register("name", {
              required: "Name is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="name" />
        </div>

        <Row>
          <Form.Group className="font-14 mt-2">
            <Form.Label>Jumlah Target</Form.Label>
            <Form.Control
              defaultValue={targetPenjualan.target}
              className="font-14 shadow-none"
              {...register("target", {
                required: "Target is required",
              })}
            />
          </Form.Group>
          <div className="my-2 text-left float-start font-12 text-danger">
            <ErrorMessage errors={errors} name="target" />
          </div>
        </Row>

        <div className="d-grid py-4">
          <center>
            <Button
              variant="primary"
              className="mt-2 btn btn-primary sticky-bottom btn-lg d-block text-white w-100 font-16"
              disabled={isLoading}
              type="submit"
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Update"
              )}
            </Button>
          </center>
        </div>
      </form>
    </React.Fragment>
  );
};

export default UpdateTarget;
