import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import tambah from "../../assets/images/tambah.svg";
import cod from "../../assets/images/cod.svg";
import trash from "../../assets/images/trash.svg";
import { NotificationManager } from "react-notifications";

const DataOngkir = () => {
  const history = useHistory();
  const { api, setSpinner } = useContext(AppContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setSpinner(true);
    let data = await api("get", "data-ongkir/get");
    setData(data.data);
    setSpinner(false);
  };

  const selectAll = (e) => {
    console.log(e.target.checked);
    let newData = data.map((item) => {
      item.selected = e.target.checked;
      return item;
    });

    setData(newData);
  };

  const selectSingle = (id, selected) => {
    let newData = data.map((item) => {
      if (item.id === id) item.selected = selected;
      return item;
    });

    setData(newData);
  };

  const delData = async () => {
    let id = "",
      separator = "";
    data.map((item) => {
      if (item.selected === true) {
        id = id + separator + item.id;
        separator = ",";
      }
    });
    let param = { id_all: id };
    let del = await api("post", "data-ongkir/delete-all", param);

    if (del.success) {
      NotificationManager.success(del.massage);
    }
    getData();

    console.log(del);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/dashboard")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Data Ongkir</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="d-flex align-item-center ms-4 mt-4">
        <input
          class="form-check-input  "
          type="checkbox"
          onClick={(e) => selectAll(e)}
          value=""
          id="flexCheckDefault"
        />
        <label
          className="form-check-label flex-grow-1 ms-3"
          for="flexCheckDefault"
        >
          Pilih semua
        </label>
        <div className="flex-grow-2 mx-5">
          <div className="text-muted mb-0">
            <img src={trash} width="25" onClick={() => delData()} />
          </div>
        </div>
      </div>
      <div className="g-col-6  px-cst1 pb-2 list-denom ">
        <Row>
          {data.map((item, key) => {
            return (
              <div className="col-md-12 mt-3" key={key}>
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        onClick={(e) => selectSingle(item.id, e.target.checked)}
                        value=""
                        checked={item.selected}
                        id="flexCheckDefault"
                      />
                      <div className="flex-shrink-0 px-3">
                        <img src={cod} width="40" alt="" />
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="text-dark">{item.name}</h6>
                        <small className="text-dark">{item.address}</small>
                      </div>
                      <div
                        className="flex-grow-2 ms-3"
                        onClick={() =>
                          history.push({
                            pathname: "/detail-ongkir",
                            ongkir: item,
                          })
                        }
                      >
                        <div className="text-muted mb-0">
                          <img
                            src="img/next.svg"
                            alt=""
                            className="cursor-pointer"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Row>
      </div>
      <div
        className="float-end me-4 sticky-bottom py-3 cursor-pointer"
        onClick={() => history.push("/tambah-ongkir")}
      >
        <img src={tambah} />
      </div>
    </React.Fragment>
  );
};

export default DataOngkir;