import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { showImage } from "../../utils/Utils";
import { useHistory } from "react-router-dom"; 
import { commafy } from "../../utils/Utils";
import { Col, Row } from "react-bootstrap";

const UserProses = ({keyword}) => {
  const { api, setSpinner } = useContext(AppContext);
  const [data, setData] = useState([]);
  const history = useHistory();
  const isFirstRun = useRef(true);

  useEffect(() => {
    // if (isFirstRun.current) {
    //   isFirstRun.current = false;
    //   return;
    // }
    getData(); 
  }, [keyword]);

  const getData = async () => {
    let param = {
      source: "Nara Store",
      status: "Proses",
      no: keyword.no,
    };

    setSpinner(true);
    let data = await api("get", "transactions-grosir/get-by-user", param);
    setData(data.data);
    setSpinner(false);
  };

  return (
    <div
      className="tab-pane fade show active"
      id="pills-userproses"
      role="tabpanel"
      aria-labelledby="pills-userproses-tab"
      tabIndex="0"
    >
      <div className="container px-4 mt-4">
        <div className="row">
          {data.map((item, key) => {
            return (
              <div className="col-mb-12" key={key}>
                <div className="card mb-3 ">
                  <div
                    className="card-body cursor-pointer"
                    onClick={() =>
                      history.push({
                        pathname: "/rincian-pesanan",
                        pesanan: item,
                      })
                    }
                  >
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 p-3 rounded bg-lime">
                        <img
                          src={showImage(
                            item.transactions_details[0].cart_grosir
                              .products_grosir.img
                          )}
                          width="80" 
                        />
                      </div>

                      <div className="flex-grow-1 ms-3 mt-3 ">
                        <span>{item.no}</span>
                        <h5 className="mb-1 fs-6 mt-1">
                          {
                            item.transactions_details[0].cart_grosir
                              .products_grosir.name
                          }
                        </h5>
                        <div className="d-flex mb-2">
                          <span className="mb-0 text-primary date-time">
                            Rp{" "}
                            {commafy(
                              item.transactions_details[0].cart_grosir.price
                            )}
                          </span>
                          <small className="ms-2 text-secondary font-13">
                            <del>
                              {" "}
                              Rp{" "}
                              {commafy(
                                item.transactions_details[0].cart_grosir
                                  .price_before
                              )}
                            </del>
                          </small>
                          <div className="col ">
                            <h6 className=" text-black float-end mg-5 font-14 ">
                              x{item.transactions_details[0].cart_grosir.qty}
                            </h6>
                          </div>
                        </div>
                        <div className="d-flex">
                          <span className="text-secondary ">
                            {item.createdAt}
                          </span>
                          <div
                            className="input-group ms-auto mb-0 "
                            style={{ width: "126px" }}
                          >
                            <div className="col ">
                              <span className=" text-primary float-end mg-5 ">
                                {item.status_operations}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="mt-4 hr-product " />
                    <Row>
                      <Col>
                        <div>
                          <span className="mt-3 font-12">
                            {item.transactions_details.length} Produk
                          </span>
                        </div>
                      </Col>
                      <Col>
                        <div className="font-13">
                          <span className="mt-3">
                            Total Pesanan : 
                          </span>
                          <span className=" text-primary float-end mg-5 ">
                            Rp {commafy(item.total)}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default UserProses;
