import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { showImage } from "../../utils/Utils";

const DataMaster = () => {
  const { api, setSpinner } = useContext(AppContext);
  const history = useHistory();
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    getMenu();
  }, []);

  const getMenu = async () => {
    setSpinner(true);
    let menu = await api("get", "menu/get-data-master");
    setMenu(menu.data);
    setSpinner(false);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-2">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/dashboard")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ml-2">
                <span className="ml-3">Data Master</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="g-col-6  px-cst1 pb-2 list-denom mt-4">
        {menu.map((item, key) => {
          return (
            <div
              key={key}
              className="card mb-3 cursor-pointer"
              onClick={() =>
                history.push({
                  pathname: item.url,
                  param: "data-master",
                })
              }
            >
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <img src={showImage(item.img)} width="40" alt="" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h6 className="text-dark mb-0">{item.name}</h6>
                  </div>
                  <div className="flex-grow-2 ms-3">
                    <div className="text-muted mb-0">
                      <img src="img/next.svg" alt="" className="cursor-pointer"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default DataMaster;
