import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Button, Col, Row } from "react-bootstrap";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import ImgBg from "../../assets/images/bg-top.svg";
import { useHistory } from "react-router-dom";
import { commafy } from "../../utils/Utils";
import moment from "moment";
import nara from "../../assets/images/logo-mid.svg";
import GudangDetail from "../admin/GudangDetail";

const StrukInventory = () => {
  const { api } = useContext(AppContext);
  const history = useHistory();
  const [data, setData] = useState({});
  const [product, setProduct] = useState([]);
  const [supplier, setSupplier] = useState("");
  const [pegawaiGudang, setPegawaiGudang] = useState({users_gudang:{gudang:{}}});
  const [date, setDate] = useState(
    moment().format("dddd, DD MMMM  yyy | HH:mm")
  );

  useEffect(() => {
    setData(history.location.data);
    setProduct(history.location.product);
    setSupplier(history.location.supplier);
    setDate(history.location.date);
  }, [history])

  useEffect(() => {
    getPegawaiGudang()
  }, [])

  const getPegawaiGudang = async() => {
    let pegawaiGudang = await api("get", "user/get-pegawai-gudang")
    setPegawaiGudang(pegawaiGudang.data[0]);
  }

  console.log(product);

  return (
    <React.Fragment>
      <div className="container px-4 mt-4 border-none">
        <div className="w-100 min-vh-50  text-center">
          <div className="d-inline-block ">
            <div className="mt-2 font-24 text-dark fw-bold">
              <span>
                <img src={nara} />
              </span>
            </div>
          </div>
        </div>
        <div className="font-16 text-center mt-3">
          <span>{pegawaiGudang.users_gudang.gudang.address}</span>
        </div>

        <div className="grid">
          <div className="w-100 d-flex px-3 font-16 mt-3">
            <div className="flex-grow-1">Gudang : {pegawaiGudang.users_gudang.gudang.name}</div>
            {/* <div className="flex-grow-1">Gudang : {product.products_grosir.gudang.name}</div> */}
            <div className="d-flex flex-grow-2">{date} </div>
          </div>
          <div className="w-100 d-flex px-3 font-16">
            <div className="flex-grow-1">Nama Supplier : {supplier} </div>
            <div className="d-flex flex-grow-2">Nama Pegawai : {pegawaiGudang.first_name} </div>
          </div>
        </div>
        <hr style={{ borderTop: " 5px dashed " }} />
        <div className="row font-16 ms-1">
          <Col className="">
            <h6>Barang</h6>
          </Col>
          <Col className="ms-5">
            <h6>Harga</h6>
          </Col>
          <Col className="ms-5">
            <h6>Jumlah</h6>
          </Col>
          <Col className="ms-5">
            <h6>Total</h6>
          </Col>
        </div>
        <hr style={{ borderTop: "3px dashed" }} />
        {product.map((item, key) => {
          return(
            <div className="row font-16 ms-1" key={key}>
            <Col>
              <span>{item.products_grosir.name}</span>
            </Col>
            <Col className="ms-5">
              <span>Rp {commafy(item.price)}</span>
            </Col>
            <Col className="ms-5">
              <span>{item.qty}</span>
            </Col>
            <Col className="ms-5">
              <span>Rp {commafy(item.price * item.qty)}</span>
            </Col>
          </div>
          )
        })}
        <hr style={{ borderTop: " 3px dashed " }} />
        <div className="float-end ms-5">
          <div className="row font-16 fw-bold ms-1 ">
            <Col className="">
              <span>Subtotal</span>
            </Col>
            <Col className="ms-2">
              <span></span>
            </Col>
            <Col className="ms-3">
              <span>Rp {commafy(data.price)}</span>
            </Col>
            <Col className="">
              <span></span>
            </Col>
          </div>
          {/* <hr style={{ borderTop: " 3px dashed ", width: "330px" }} />
          <div className="row font-16 fw-bold ms-1">
            <Col className="">
              <span>Total Diskon</span>
            </Col>
            <Col className="ms-5">
              <span></span>
            </Col>
            <Col className="ms-3 d-flex">
              <span>
                {diskonselected}%{" "}
                <small className="">
                  {" "}
                  ({commafy((total * diskonselected) / 100)})
                </small>{" "}
              </span>
            </Col>
            <Col className="ms-5">
              <span></span>
            </Col>
          </div>
          <hr style={{ borderTop: " 3px dashed ", width: "330px" }} />

          <div className="row font-16 fw-bold ms-1">
            <Col className="">
              <span>Total</span>
            </Col>
            <Col className="ms-5">
              <span></span>
            </Col>
            <Col className="ms-4">
              <span>{commafy(totalakhir)}</span>
            </Col>
            <Col className="ms-5">
              <span></span>
            </Col>
          </div>
          <hr style={{ borderTop: " 3px dashed ", width: "330px" }} />

          <div className="row font-16 fw-bold ms-1">
            <Col className="">
              <span>Tunai</span>
            </Col>
            <Col className="ms-5">
              <span></span>
            </Col>
            <Col className="ms-4">
              <span>{commafy(nominal)}</span>
            </Col>
            <Col className="ms-5">
              <span></span>
            </Col>
          </div>
          <hr style={{ borderTop: " 3px dashed ", width: "330px" }} />

          <div className="row font-16 fw-bold ms-1">
            <Col className="">
              <span>Kembalian</span>
            </Col>
            <Col className="ms-4">
              <span></span>
            </Col>
            <Col className="ms-3">
              <span>{commafy(nominal - totalakhir)}</span>
            </Col>
            <Col className="ms-5">
              <span></span>
            </Col>
          </div> */}
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <hr style={{ borderTop: " 3px dashed " }} />

        <div className="font-16 text-center">
          <span>
            Barang tidak dapat ditukar / dikembalian dengan alasan apapun.
            Kerusakan barang bukan tanggung jawab kami.
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StrukInventory;
