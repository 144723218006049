import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Row, Modal, Form, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { commafy } from "../../utils/Utils";
import { AppContext } from "../../context/AppContext";
import trash from "../../assets/images/trash-icon.svg";
import popup from "../../assets/images/popup-icon.svg";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import _ from "lodash";

const EditInventory = () => {
  const { api, setSpinner, setIsInventory } = useContext(AppContext);
  const history = useHistory();
  const [data, setData] = useState([]);
  const [supplier, setSupplier] = useState();
  const [idSupplier, setIdSupplier] = useState();
  const [id, setId] = useState("");
  const [idAll, setIdAll] = useState("");
  const [grosir, setGrosir] = useState([]);
  const [user, setUser] = useState([]);
  const refInputQty = useRef([]);
  const refInputPrice = useRef([]);
  const refInputDiscPct = useRef([]);
  const refInputDiscPctAdd = useRef([]);
  const refInputDisc = useRef([]);
  const refInputTax = useRef([]);
  const refInputOrder = useRef("");
  const refInputNotes = useRef("");
  const [date, setDate] = useState(moment().format("dddd, DD MMMM  yyy"));
  const [dateOrder, setdateOrder] = useState(moment().format("YYYY-MM-DD"));
  const [local, setLocal] = useState({});
  const [keyword, setKeyword] = useState({});
  const [type, setType] = useState("Barang Masuk");
  const [statusPembayaran, setStatusPembayaran] = useState("Lunas");
  const [buktiPembayaran, setBuktiPembayaran] = useState([]);
  const [show, setShow] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showCalc, setShowCalc] = useState(false);
  const [qtySatuan, setQtySatuan] = useState(0);
  const [discTambahan, setDiscTambahan] = useState(0);
  const [feeLainnya, setFeeLainnya] = useState(0);
  const [feeDesc, setFeeDesc] = useState(0);
  const [priceSatuan, setPriceSatuan] = useState(0);
  const [transactionId, setTransactionId] = useState();
  const [dataDetail, setDataDetail] = useState({});
  const refInputPriceCalc = useRef(0);
  const refInputDusCalc = useRef(0);
  const refInputPackCalc = useRef(0);
  const refInputPriceSatuanCalc = useRef(0);
  const refInputPricePackCalc = useRef(0);
  const refInputQtySatuanCalc = useRef(0);
  const refInputQtyDusCalc = useRef(0);
  const refDiscTambah = useRef(0);

  useEffect(() => {
    let localData = localStorage.getItem("useranarastore");
    if (localData !== null) setLocal(JSON.parse(localData));
    if (localData !== null) {
      setLocal(JSON.parse(localData));
      setIsInventory(true);
    }
  }, []);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (history.location.detailTransaksi) {
      let data = history.location.detailTransaksi;
      let newData = data.map((item, k) => {
        let newItem = item;
        newItem.total_price_before = item.cart_grosir.total_price_before;
        newItem.discount = item.cart_grosir.discount;
        newItem.total_discount = item.cart_grosir.total_discount;
        newItem.discount_pct = item.cart_grosir.discount_pct;
        newItem.discount_pct_additional = item.cart_grosir.discount_pct_additional;
        newItem.tax = item.cart_grosir.tax;
        newItem.tax_pct = item.cart_grosir.tax_pct;
        newItem.total_tax = item.cart_grosir.total_tax;
        newItem.total_price = item.cart_grosir.total_price;
        return newItem;
      })
      setData(newData);
      setTransactionId(history.location.detailTransaksi[0].transaction_id);
      setDataDetail(history.location.data);
    }
  }, [history.location]);

  useEffect(() => {
    if (!showDetail) return;
    getCountData();
  }, [showDetail]);

  const getData = async () => {
    setSpinner(false);
    getCountData();

    const newUser = await api("get", "supplier-grosir/get", keyword);
    setUser(newUser.data);
  };

  const getCountData = async () => {
    let paramcount = {
      source: "Inventory",
    };
    const count_cart = await api(
      "get",
      "cart-grosir/count-by-user",
      paramcount
    );

    setGrosir(count_cart.data);
  };

  const delData = async (item) => {
    let paramdel = { id: item.id };
    let del = await api("post", "cart-grosir/delete", paramdel);

    if (del.success) {
      getData();
      NotificationManager.success(del.message);
    }
  };

  const handleClickSupplier = async (item) => {
    setSupplier(item.options[item.selectedIndex].text);
    setIdSupplier(item.value);
    setShow(false);
  };

  const changeInputImg = async (value) => {
    setBuktiPembayaran(value);
  };

  const handleClick = async () => {
    let noOrder = refInputOrder.current.value;
    if (idSupplier === undefined || idSupplier === "Pilih Supplier") {
      return NotificationManager.error("Supplier harus dipilih");
    }

    if (noOrder === "") {
      return NotificationManager.error("No Order tidak boleh kosong");
    }

    let formData = new FormData();
    formData.append("no_order", noOrder);
    formData.append("date_order", dateOrder);
    formData.append("category", type);
    formData.append("supplier_id", idSupplier);
    formData.append("status_pembayaran", statusPembayaran);
    formData.append("notes", refInputNotes.current.value);
    formData.append("price", grosir[0].sub_total.toFixed(0));
    formData.append("discount", grosir[0].disc.toFixed(0));
    formData.append("qty", grosir[0].count);
    formData.append("tax", grosir[0].tax.toFixed(0));
    formData.append("total", (grosir[0].sub_total + grosir[0].tax).toFixed(0));
    formData.append("discount_additional", parseFloat(discTambahan));
    formData.append("fee", parseFloat(feeLainnya));
    formData.append("fee_desc", feeDesc);

    for (let i = 0; i < buktiPembayaran.length; i++) {
      formData.append("img", buktiPembayaran[i]);
    }

    setSpinner(true);
    let transactionsInventory = await api(
      "post",
      "transactions-inventory/post",
      formData
    );
    setSpinner(false);
    if (transactionsInventory.success) {
      NotificationManager.success(transactionsInventory.message);
      history.push({
        pathname: "/transaksi-sukses",
        data: transactionsInventory.data,
        product: data,
        supplier: supplier,
      });
    }
  };

  const changeInputType = async (value) => {
    setType(value);
  };

  const changeInputStatusPembayaran = async (value) => {
    setStatusPembayaran(value);
  };

  const handleChangeData = (val) => {
    setdateOrder(val);
  };

  const handleSave = async (key, id) => {
    let item = _.find(data, (item, k) => {
      return k === key;
    });
    let param = {
      id: id,
      price: refInputPrice.current[key].value,
      disc_pct: refInputDiscPct.current[key].value,
      disc_pct_additional: refInputDiscPctAdd.current[key].value,
      disc: item.total_discount,
      qty: refInputQty.current[key].value,
      tax_pct: refInputTax.current[key].value,
      tax: item.total_tax,
    };
    setSpinner(true);
    let update = await api("post", "cart-grosir/update-inv-edit", param);
    setSpinner(false);
    if (update.success) {
      getCountData();
      NotificationManager.success(update.message);
    }
  };

  const handleCalc = () => {
    let price = refInputPriceCalc.current.value;
    let dus = refInputDusCalc.current.value;
    let dusQty = refInputQtyDusCalc.current.value;

    let pack = refInputPackCalc.current.value;
    let priceSatuan = parseFloat(price) / parseFloat(dus) / parseFloat(pack);
    refInputPriceSatuanCalc.current.value = priceSatuan.toFixed(3);
    setPriceSatuan(priceSatuan);
    let qtySatuan = parseFloat(dus) * parseFloat(pack) * parseInt(dusQty);
    setQtySatuan(qtySatuan);
    refInputQtySatuanCalc.current.value = qtySatuan;
    refInputPricePackCalc.current.value = (
      parseFloat(price) / parseFloat(dus)
    ).toFixed(3);
  };

  const calculatePrice = (key) => {
    let qty = parseInt(refInputQty.current[key]?.value);
    let price = parseFloat(refInputPrice.current[key]?.value);
    let discPct = parseFloat(refInputDiscPct.current[key]?.value);
    let discPctAdd = parseFloat(refInputDiscPctAdd.current[key]?.value);
    let ppn = refInputTax.current[key]?.value;

    let newData = data.map((item, k) => {
      if (k === key) {
        let totalPrice = qty * price;
        let discount = (discPct * totalPrice) / 100;
        let subtotal = totalPrice - discount;
        let discountAdd = (discPctAdd * subtotal) / 100;
        let pajak = (ppn * (subtotal - discountAdd)) / 100;
        item.qty = qty;
        item.total_price_before = totalPrice;
        item.total_discount = (discount + discountAdd).toFixed(3);
        item.total_tax = pajak.toFixed(3);
        item.total_price = (subtotal - discountAdd + pajak).toFixed(3);
      }
      return item;
    });
    setData(newData);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm">
        <div className="contain p-2 px-cst1 pb-2 ps-4 w-100">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-2">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => {
                    history.push({
                      pathname: "/detail-transaksi-inventori",
                      idFromEdit: transactionId,
                      dataDetail: dataDetail,
                    });
                    setIsInventory(false);
                  }}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>

              <div className="d-inline-block ml-2">
                <span className="ml-3">Pesanan</span>
              </div>
              <div
                className="w-100 me-4 cursor-pointer"
                onClick={() => setShowCalc(true)}
              >
                <div className="float-end">
                  <span className="ms-2" style={{ fontSize: "24px" }}>
                    <i className="fa fa-calculator text-primar text-primary"></i>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="bg-primary">
        <div
          className=" row p-2 me-1 mx-1"
          style={{ backgroundColor: "#DCF4F4" }}
        >
          <h6 className="text-dark mt-2">{date}</h6>
          <div className="d-flex align-items-center">
            <div className="mb-0 pt-1"></div>

            <div className="flex-shrink-0 ">
              <span> Admin | {local.first_name} </span>
            </div>

            <div className="flex-grow-1 ms-3"></div>
            <div className="flex-grow-2 ms-3">
              {grosir.map((item, key) => {
                return (
                  <p className="text-dark mb-0 pt-1" key={key}>
                    Jumlah Produk : {item.count}
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 mt-2 border-none">
        <br />
        <div>
          <Row className="font-12">
            <Col md="2">
              <span>Produk</span>
            </Col>
            <Col>
              <span>Qty</span>
            </Col>
            <Col>
              <span>Harga</span>
            </Col>
            <Col>
              <span>Total Harga</span>
            </Col>
            <Col md="1">
              <span>Diskon (%)</span>
            </Col>
            <Col>
              <span>Diskon (Rp)</span>
            </Col>
            <Col>
              <span>Pajak (%)</span>
            </Col>
            <Col>
              <span>Pajak (Rp)</span>
            </Col>
            <Col>
              <span>Jumlah (-PPN)</span>
            </Col>
            <Col>
              <span>Jumlah </span>
            </Col>
            <Col md="1">Action</Col>
          </Row>
          {data.map((item, key) => {
            return (
              <div className="col-mb-12" key={key}>
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 ">
                    <Row className="mt-2">
                      <Col md="2">
                        <input
                          type="text"
                          disabled
                          className="form form-control w-100 font-12"
                          defaultValue={item.products_grosir.name}
                        />
                      </Col>
                      <Col>
                        <div>
                          <input
                            type="text"
                            onInput={(e) => calculatePrice(key)}
                            className="form form-control w-100 font-12 text-end"
                            defaultValue={commafy(item.qty)}
                            ref={(ref) => (refInputQty.current[key] = ref)}
                          />
                        </div>
                      </Col>
                      <Col>
                        <input
                          type="text"
                          className="form form-control w-100 font-12  text-end"
                          defaultValue={item.price}
                          onInput={(e) => calculatePrice(key)}
                          ref={(ref) => (refInputPrice.current[key] = ref)}
                        />
                      </Col>
                      <Col>
                        <input
                          type="text"
                          className="form form-control w-100 font-12  text-end"
                          value={commafy(parseFloat(item.total_price_before).toFixed(0))}
                          disabled
                        />
                      </Col>
                      <Col md="1">
                        <div className="w-50 d-inline-block">
                          <input
                            type="text"
                            defaultValue={
                              item.discount_pct ? item.discount_pct : 0
                            }
                            className="form form-control w-100 font-12  text-end"
                            onInput={(e) => calculatePrice(key)}
                            ref={(ref) => (refInputDiscPct.current[key] = ref)}
                          />
                        </div>
                        <div className="w-50 d-inline-block">
                          <input
                            type="text"
                            defaultValue={
                              item.discount_pct_additional
                                ? item.discount_pct_additional
                                : 0
                            }
                            className="form form-control w-100 font-12  text-end"
                            onInput={(e) => calculatePrice(key)}
                            ref={(ref) =>
                              (refInputDiscPctAdd.current[key] = ref)
                            }
                          />
                        </div>
                      </Col>
                      <Col>
                        <input
                          type="text"
                          disabled
                          value={commafy(
                            item.total_discount
                              ? parseFloat(item.total_discount).toFixed(0)
                              : 0
                          )}
                          className="form form-control w-100 font-12  text-end"
                        />
                      </Col>
                      <Col>
                        <input
                          onInput={(e) => calculatePrice(key)}
                          type="text"
                          placeholder="0"
                          defaultValue={item.tax_pct ? item.tax_pct : 0}
                          className="form form-control w-100 font-12  text-end"
                          ref={(ref) => (refInputTax.current[key] = ref)}
                        />
                      </Col>
                      <Col>
                        <input
                          type="text"
                          disabled
                          placeholder="0"
                          value={commafy(
                            item.total_tax
                              ? parseFloat(item.total_tax).toFixed(0)
                              : 0
                          )}
                          className="form form-control w-100 font-12  text-end"
                        />
                      </Col>
                      <Col>
                        <input
                          type="text"
                          disabled
                          placeholder="Sub total"
                          className="form form-control w-100 font-12  text-end"
                          value={commafy(
                            parseFloat(
                              item.total_price - item.total_tax
                            ).toFixed(0)
                          )}
                        />
                      </Col>
                      <Col>
                        <input
                          type="text"
                          disabled
                          placeholder="Sub total"
                          className="form form-control w-100 font-12  text-end"
                          value={commafy(
                            parseFloat(item.total_price).toFixed(0)
                          )}
                        />
                      </Col>
                      <Col md="1" className="d-flex align-items-center">
                        <div className="fixed cursor-pointer text-center d-flex align-items-center">
                          <span onClick={() => handleSave(key, item.cart_id)}>
                            <i className="fa fa-save text-primary fa-lg"></i>
                          </span>
                          <img
                            src={trash}
                            className="ms-4"
                            width="20"
                            onClick={() => delData(item)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <hr />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="row justify-content-center sticky-bottom order">
        <div className="col-md-12">
          <div className="grid">
            <div
              className="offcanvas offcanvas-bottom g-col-6 rounded-top order-product"
              tabIndex="-1"
              id="short"
              aria-labelledby="shortLabel"
            ></div>

            <div
              className="offcanvas offcanvas-bottom g-col-6 rounded-top order-product"
              tabIndex="-1"
              id="order"
              aria-labelledby="shortLabel"
            >
              <div className="offcanvas-body"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="sticky-bottom bg-white mt-2 ps-3">
        <div className="d-flex justify-content-end p-3">
          <div className=" pt-2">
            <div
              className="text-dark cursor-pointer"
              onClick={() => setShowDetail(true)}
            >
              <div className="font-14">
                <span>Sub Total</span>
                <img className="mx-3 cursor-pointer" src={popup} />
              </div>
            </div>
          </div>
          <div className="pt-2">
            {grosir.map((item, key) => {
              return (
                <span className=" font-14 fw-bold" key={key}>
                  Rp {commafy(item.sub_total ? item.sub_total.toFixed(0) : 0)}
                </span>
              );
            })}
          </div>
        </div>
      </div>

      {/* Modal Kalkulator */}

      <Modal show={showCalc} onHide={() => setShowCalc(false)} centered>
        <Modal.Header className="bg-primary text-white">
          <span>Kalkulator</span>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="4" className="mt-2">
              <Form.Label>Harga Produk </Form.Label>
              <Form.Control
                type="number"
                onInput={() => handleCalc()}
                className="font-12 shadow-none"
                ref={refInputPriceCalc}
              />
            </Col>
            <Col md="4" className="mt-2">
              <Form.Label>Isi / Dus </Form.Label>
              <Form.Control
                type="number"
                onInput={() => handleCalc()}
                className="font-12 shadow-none"
                ref={refInputDusCalc}
              />
            </Col>
            <Col md="4" className="mt-2">
              <Form.Label>Isi / Pack </Form.Label>
              <Form.Control
                type="number"
                onInput={() => handleCalc()}
                className="font-12 shadow-none"
                ref={refInputPackCalc}
              />
            </Col>
          </Row>
          <Row>
            <Col md="4" className="mt-2">
              <Form.Label>Qty Dus</Form.Label>
              <Form.Control
                type="number"
                onInput={() => handleCalc()}
                className="font-12 shadow-none"
                ref={refInputQtyDusCalc}
              />
            </Col>
            <Col md="4" className="mt-2">
              <Form.Label>Qty Satuan</Form.Label>
              <Form.Control
                type="number"
                defaultValue={qtySatuan}
                className="font-12 shadow-none"
                ref={refInputQtySatuanCalc}
              />
            </Col>
            <Col md="4" className="mt-2">
              <Form.Label>Harga / Pack </Form.Label>
              <Form.Control
                type="number"
                defaultValue={priceSatuan}
                className="font-12 shadow-none"
                ref={refInputPricePackCalc}
              />
            </Col>
            <Col md="4" className="mt-2">
              <Form.Label>Harga Satuan </Form.Label>
              <Form.Control
                type="number"
                defaultValue={priceSatuan}
                className="font-12 shadow-none"
                ref={refInputPriceSatuanCalc}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {/* Modal Detail */}

      <Modal show={showDetail} onHide={() => setShowDetail(false)} size={"lg"}>
        <Modal.Header className="bg-primary text-white">
          <span>Detail</span>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="4" className="mt-2 font-12">
              <Form.Label>Supplier</Form.Label>
              <Form.Select
                className="font-12"
                onChange={(e) => {
                  handleClickSupplier(e.target);
                }}
              >
                <option value="Pilih Supplier">Pilih Supplier</option>
                {user.map((item, key) => {
                  return (
                    <option value={item.id} key={key}>
                      {item.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>

            <Col md="4" className="mt-2 font-12">
              <Form.Label>No Order</Form.Label>
              <Form.Control
                className="font-12 shadow-none"
                ref={refInputOrder}
              />
            </Col>

            <Col md="4" className="mt-2 font-12">
              <Form.Label>Type</Form.Label>
              <Form.Select
                onChange={(e) => {
                  changeInputType(e.target.value);
                }}
                className="font-12 shadow-none form form-control w-100"
              >
                <option value="Barang Masuk">Barang Masuk</option>
                <option value="Barang Keluar">Barang Keluar</option>
              </Form.Select>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="mt-2 font-12">
              <Form.Label>Status Pembayaran</Form.Label>
              <Form.Select
                onChange={(e) => {
                  changeInputStatusPembayaran(e.target.value);
                }}
                className="font-12 shadow-none form form-control w-100"
              >
                <option value="Lunas">Lunas</option>
                <option value="Belum Lunas">Belum Lunas</option>
              </Form.Select>
            </Col>

            <Col className="mt-2 font-12">
              <Form.Label>Tgl Order</Form.Label>
              <Form.Control
                type="date"
                className="font-12 shadow-none"
                defaultValue={dateOrder}
                onChange={(date) => handleChangeData(date.target.value)}
              />
            </Col>

            <Col className="mt-2 font-12">
              <Form.Label>Upload Faktur</Form.Label>
              <Form.Control
                type="file"
                className="font-12"
                onChange={(e) => {
                  changeInputImg(e.target.files);
                }}
                multiple
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Form.Control
                as="textarea"
                placeholder="Catatan"
                ref={refInputNotes}
                className="font-12 shadow-none"
              />
            </Col>
          </Row>
          <hr></hr>
          <Row className="mt-3 font-12">
            <Col md="6">
              <div className="float-end">Sub Total</div>
            </Col>
            <Col md="6">
              <div className="float-end">
                {grosir.length &&
                  "Rp " +
                    commafy(
                      grosir[0].sub_total ? grosir[0].sub_total.toFixed(0) : 0
                    )}
              </div>
            </Col>
          </Row>
          <Row className="mt-1 font-12">
            <Col md="6">
              <div className="float-end">Diskon</div>
            </Col>
            <Col md="6">
              <div className="float-end">
                {grosir.length &&
                  "Rp " +
                    commafy(grosir[0].disc ? grosir[0].disc.toFixed(0) : 0)}
              </div>
            </Col>
          </Row>
          <Row className="mt-1 font-12">
            <Col md="6">
              <div className="float-end">PPN</div>
            </Col>
            <Col md="6">
              <div className="float-end">
                {grosir.length &&
                  "Rp " + commafy(grosir[0].tax ? grosir[0].tax.toFixed(0) : 0)}
              </div>
            </Col>
          </Row>
          <Row className="mt-1 font-12">
            <Col md="6">
              <div className="float-end">Total</div>
            </Col>
            <Col md="6">
              <div className="float-end">
                {grosir.length &&
                  "Rp " +
                    commafy(
                      (
                        grosir[0].sub_total +
                        grosir[0].tax -
                        discTambahan + feeLainnya
                      ).toFixed(0)
                    )}
              </div>
            </Col>
          </Row>
          <Row className="mt-1 font-12">
            <Col md="6">
              <div className="float-end">Diskon Tambahan</div>
            </Col>
            <Col md="6">
              <div className="float-end">
                <Form.Control
                  className="font-12 shadow-none"
                  onInput={(e) =>
                    setDiscTambahan(
                      parseFloat(e.target.value ? e.target.value : 0)
                    )
                  }
                  ref={refDiscTambah}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-1 font-12">
            <Col md="6">
              <div className="float-end">Biaya Lainnya</div>
            </Col>
            <Col md="6">
              <div className="float-end">
                <Row>
                  <Col> 
                    <Form.Control
                      className="font-12 shadow-none"
                      onInput={(e) =>
                        setFeeLainnya(
                          parseFloat(e.target.value ? e.target.value : 0)
                        )
                      }
                     
                    />
                  </Col>
                  <Col> 
                    <Form.Control
                      className="font-12 shadow-none"
                      placeholder="Keterangan"
                      onInput={(e) =>
                        setFeeDesc(
                          (e.target.value ? e.target.value : "")
                        )
                      }
                     
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary font-12"
            onClick={() => setShowDetail(false)}
          >
            Tutup
          </Button>
          <Button
            variant="primary text-white font-12"
            onClick={() => handleClick()}
          >
            Proses
            {grosir.map((item, key) => {
              return <span key={key}>{" " + item.count + " "}</span>;
            })}
            Barang
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default EditInventory;
