import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { showImage } from "../../utils/Utils";
import { Card, Carousel, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Promo from "./Promo";

const Games = () => {
  const { api } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [category, setCategory] = useState({});
  const history = useHistory();
  const refInput = useRef();

  useEffect(() => {
    if (!history.location.category) history.push("/");
    else setCategory(history.location.category);
  }, []);

  useEffect(() => {
    if (!category.id) return;
    getData();
  }, [category]);

  const getData = async (prefix) => {
    let param = {
      id: "i8l7DzgKEatN3e7SNCI38mA67ey0o5SiFgMKEJl7Va5pnD0BKW",
      view: "brand",
      category: category.id,
      prefix: prefix,
    };
    let data = await api("get", "produk/public", param);
    setData(data.data);
  };

  const changeInput = () => {
    let value = refInput.current.value;
    if (value.length === 4) {
      getData(value);
    }

    if (value.length === 0) {
      getData("");
    }
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm cursor-pointer">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-2">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/")}
                  className="d-inline-block align-text-top "
                />
              </div>

              <div className="d-inline-block ml-2">
                <span className="ml-3">{category.name}</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="g-col-6 content bg-light py-4">
        
          <div className="input-group px-4">
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="Cari Games"
              ref={refInput}
              onChange={() => changeInput()}
              aria-describedby="basic-addon2"
            />
            <button class="input-group-text bg-white">
              <i class="bi bi-search"></i>
            </button>
          </div>
        
        <div class="g-col-6 bg-light px-cst1 pb-2 mt-3">
          <Promo isGame={true} />
        </div>
        <div class="g-col-6 bg-light px-cst1 pb-2 mt-5" hidden>
          <h6>Game Populer</h6>
          <div className="g-col-6 bg-light pb-2 list-denom mt-4 w-100">
            <div className="d-flex w-100" style={{ overflow: "scroll" }}>
              {data.map((item, key) => {
                if (!item.is_populer) return;
                return (
                  <div key={key + 11}>
                    <img
                      src={showImage(item.img)}
                      width="100"
                      className="me-5"
                      alt=""
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        
        <div className="g-col-6 bg-light px-cst1 pb-2 list-denom mt-4">
          <h6>Pilih Game</h6>
          <Row>
            {data.map((item, key) => {
              return (
                <Col key={key} md="3" xs="4" className="cursor-pointer">
                  <div
                    class="card h-100 border-0 bg-transparent text-center"
                    onClick={() =>
                      history.push({
                        pathname: "/products",
                        brand: item,
                        category: category,
                        input: refInput.current.value,
                      })
                    }
                  >
                    <img
                      src={showImage(item.img)}
                      class="card-img-top rounded"
                      style={{ width: "100%" }}
                      alt=""
                    />
                    <div class="card-footer border-top-0 bg-transparent">
                      <small class="text-dark font-12">{item.name}</small>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Games;
