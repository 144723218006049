import React, { useContext, useEffect, useState, useRef } from "react";
import { Button, Col, Row, Spinner, Form } from "react-bootstrap";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom"; 
import { useHistory } from "react-router-dom";
import { commafy, showImage } from "../../utils/Utils"; 
import { AppContext } from "../../context/AppContext"; 

const AdminDetail = () => {
  const { api } = useContext(AppContext);
  const history = useHistory(); 
  const [product, setProduct] = useState({category_grosir: {},brands_grosir:{}}); 

  useEffect(() => {
    if (!history.location.product) {
      history.push("/daftar-produk");
      return;
    }
    setProduct(history.location.product); 
  },[]);

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-2">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  className="d-inline-block align-text-top cursor-pointer"
                  onClick={() => history.push("/daftar-produk")}
                />
              </div>

              <div className="d-inline-block ml-2">
                <span className="ml-3">Detail Produk</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="container px-4 mt-4">
        <div className="row">
          <div className="col-mb-12">
            <div className="card mb-3 border-0 bg-li">
              <div className="bg-lime p-2 rounded align-items-center justify-content-center d-flex">
                <img
                  src={showImage(product.img)}
                  alt=""
                  style={{ height: "100px" }}
                />
              </div>
              <div className="card-body">
                <div className="grid">
                  <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                    <div className="flex-grow-1">
                      <small className="">SKU produk</small>
                    </div>
                    <div className="d-flex flex-grow-4">
                      <small>Nama Produk</small>
                    </div>
                  </div>
                  <div className="w-100 d-flex px-3 font-14 ">
                    <div className="flex-grow-1">
                      <p>{product.sku}</p>
                    </div>
                    <div className="d-flex flex-grow-2">
                      <p>{product.name}</p>
                    </div>
                  </div>
                  <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                    <div className="flex-grow-1">
                      <small className="">Kategori produk</small>
                    </div>
                    <div className="d-flex flex-grow-2">
                      <small>Brand</small>
                    </div>
                  </div>
                  <div className="w-100 d-flex px-3 font-14 ">
                    <div className="flex-grow-1">
                      <p>{product.category_grosir.name}</p>
                    </div>
                    <div className="d-flex flex-grow-2">
                      <p>{product.brands_grosir.name} </p>
                    </div>
                  </div>
                  <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                    <div className="flex-grow-1">
                      <small className="">Stok</small>
                    </div>
                    <div className="d-flex flex-grow-2">
                      <small>Harga</small>
                    </div>
                  </div>

                  <div className="w-100 d-flex px-3 font-14 ">
                    <div className="flex-grow-1">
                      <p>{product.stock} {product.satuan}</p>
                    </div>
                    <div className="d-flex flex-grow-2"> 
                      <p className="font-weight-bold text-primary ">
                          Rp {commafy(product.price)}
                        </p>
                        <small className="ms-2 text-secondary font-13">
                          <del> Rp{commafy(product.price_before)}</del>
                        </small>
                        <span className="ms-2 text-secondary font-13">
                         (-{commafy(product.discount)}) Disc.
                        </span>
                    </div>
                  </div>
                  <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                    <div className="flex-grow-1">
                      <small className="">Margin</small>
                    </div>
                    <div className="d-flex flex-grow-2">
                      <small>Harga Beli</small>
                    </div>
                  </div>
                  <div className="w-100 d-flex px-3 font-14 ">
                    <div className="flex-grow-1">
                      <p>Rp {commafy(product.margin)}</p>
                    </div>
                    <div className="d-flex flex-grow-2">
                      <p>Rp {commafy(product.purchase_price)}</p>
                    </div>
                  </div>

                  <div className="w-100 d-flex px-3 text-secondary font-14 mt-3">
                    <div className="flex-grow-1">
                      <small className="">Rekomendasi Harga Shopee</small>
                    </div>
                  </div>
                  <div className="w-100 d-flex px-3 font-14 ">
                    <div className="flex-grow-1">
                      <p>Rp {commafy(Math.round((product.purchase_price) * (1 + 5/100) / (1 - 9.7/100)))}</p>
                    </div>
                  </div>

                  <div className="w-100 px-3 font-14 mt-3">
                    <div className="flex-grow-1">
                      <small className="text-secondary">Deskripsi</small>
                      <div dangerouslySetInnerHTML={{ __html: product.description }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
            <button
              type="button"
              className="btn btn-primary btn-lg d-block text-white w-100 font-14"
              onClick={() =>
                history.push({
                  pathname: "update-product",
                  product: product,
                })
              }
            >
              Ubah Data
            </button>
          </div>
          <div
            className="w-100 text-center border-primary rounded p-2 mt-3 mb-3 cursor-pointer"
            onClick={() =>
              history.push({
                pathname: "/product-price",
                product: product.id,
                purchase_price: product.purchase_price,
              })
            }
          >
            <span className="daftar text-primary">Varian Harga</span>
          </div>

          </div>

          
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdminDetail;
