import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { showImage } from "../../utils/Utils";
import { Card, Carousel, Col, Nav, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom"; 
import shop from "../../assets/images/shop.svg";
import _ from "lodash";
import { commafy } from "../../utils/Utils"; 
import box from "../../assets/images/box-icon.svg";
import { NotificationManager } from "react-notifications";
import { useDebounce } from "use-debounce";

const TransaksiInventory = () => {
  const { api,setIsInventory } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [cart, setCart] = useState([]);
  const [category, setCategory] = useState([]);
  const [grosir, setGrosir] = useState([]);
  const [categoryId, setCategoryId] = useState(0);
  const [total, setTotal] = useState(0);
  const [profile, setProfile] = useState([]);
  const history = useHistory();
  const refInput = useRef();
  const { setSpinner } = useContext(AppContext);
  const [isLoading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [value] = useDebounce(text, 1000);

  useEffect(() => {
    getData();
    getCart();
    setIsInventory(false)
  }, []);

  useEffect(() => { 
    let keyword = {
      admin: true,
      name: value,
    };

    getProduct(keyword);
  }, [value]);

  const getProduct = async (params) => {
    let data = await api("get", "products-grosir/get", params);
    setData(data.data);
  };
  
  useEffect(() => {
    let price = 0;
    cart.map((item) => {
      price = parseInt(price) + parseInt(item.total_price_before);
      setTotal(price);
    });
    setTotal(price);
  }, [cart]);

  const getCart = async () => {
    let paramcart = {
      source: "Inventory",
    };
    let cart = await api("get", "cart-grosir/get-by-user", paramcart);
    setCart(cart.data);
  };

  const getData = async () => {
    setSpinner(true);
    if (history.location.category_id) {
      setCategoryId(history.location.category_id);
      let data = await api("get", "products-grosir/get", {
        admin: true,
        category_id: history.location.category_id,
      });
      setData(data.data);
    } else {
      let data = await api("get", "products-grosir/get", { admin: true });
      setData(data.data);
    }

    setSpinner(false);
    getCategogry();

    let profile = await api("get", "user/profile");
    setProfile(profile.data);

    getChart();
  };
  const getChart = async () => {
    let paramcount = {
      source: "Inventory",
    };
    const count_cart = await api(
      "get",
      "cart-grosir/count-by-user",
      paramcount
    );
    setGrosir(count_cart.data);
  };

  const getCategogry = async () => {
    let category = await api("get", "category-grosir/get", { admin: true });
    setCategory(category.data);
  };

  const handleClickType = async (param) => {
    setSpinner(true)
    let category_id = {
      admin: true,
      category_id: param.id,
    };

    let data = await api("get", "products-grosir/get", category_id);
    setData(data.data);
    setSpinner(false)
  };

  const changeInput = async () => {
    let value = refInput.current.value;
    setText(value) 
  };

  const addCart = async (param) => {
    let paramsCart = {
      product_id: param.id,
      status: "open",
      price: param.purchase_price,
      price_before: param.purchase_price,
      total_price: param.purchase_price,
      total_price_before: param.purchase_price,
      source: "Inventory",
    };
    setSpinner(true);
    let postCart = await api("post", "cart-grosir/post", paramsCart);
    if (postCart.success) {
      // getData();
      getChart();
      NotificationManager.success(postCart.message);
    } else NotificationManager.error(postCart.message);
    setSpinner(false);
  };

  const semuaProduct = async () => {
    setSpinner(true);
    let semua = await api("get", "products-grosir/get", { admin: true });
    setData(semua.data);
    setSpinner(false);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm  w-100">
        <div className="p-2 pb-2 ps-4 w-100 d-flex align-items-center">
          <div className="d-inline-block">
            <img
              src="img/arrow-left.svg"
              alt=""
              width="30"
              height="24"
              onClick={() => history.push("/dashboard-inventory")}
              className="d-inline-block align-text-top cursor-pointer"
            />
          </div>
          <div className="d-inline-block ms-2" style={{ width: "90%" }}>
            <form className="w-100">
              <div className="input-group w-100">
                <input
                  type="text"
                  className="form-control form-control-lg font-16"
                  placeholder="Cari Produk"
                  ref={refInput}
                  aria-describedby="basic-addon2"
                  onChange={() => changeInput()}
                />
                <button className="input-group-text bg-white border">
                  <i className="bi bi-search"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="riwayat w-100  overflow-scroll">
          <div className="bg-light mx-4">
            <ul
              className="nav nav-pills nav-fill mb-3"
              id="pills-tab"
              role="tablist"
            >
              <div className="d-flex">
                <li
                  className="nav-item "
                  role="presentation"
                  hidden={categoryId === 0 ? false : true}
                >
                  <button
                    className="nav-link py-3 active no-wrap"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    role="tab"
                    onClick={() => semuaProduct()}
                  >
                    Semua
                  </button>
                </li>
                {category.map((item, key) => {
                  if (categoryId !== 0 && item.id !== categoryId) return;
                  return (
                    <li className="nav-item" role="presentation" key={key}>
                      <button
                        className={
                          categoryId === item.id
                            ? "nav-link py-3 no-wrap active"
                            : "nav-link py-3 no-wrap"
                        }
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        role="tab"
                        key={key}
                        md={category.column}
                        onClick={() => handleClickType(item)}
                      >
                        {item.name}
                      </button>
                    </li>
                  );
                })}
              </div>
            </ul>
          </div>
        </div>
      </nav>

      <div className="container px-4 mt-4">
        {/* <h6>Pilih Nominal</h6> */}
        <Row className="no-gutters px-3">
          {data.map((item, key) => {
            return (
              <div className="col-6 p-2" key={key}>
                <div
                  className="card w-100 border-0 shadow cursor-pointer"
                  style={{ minHeight: "320px" }}
                  // data-bs-toggle="offcanvas"
                  // data-bs-target="#cari"
                  // aria-controls="cari"
                >
                  <center>
                    <div className="bg-lime rounded m-2">
                      <div className="w-100"></div>
                      <div className="p-3">
                        <img
                          className="card-img-top mt-2"
                          src={showImage(item.img)}
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>
                    </div>
                  </center>
                  <div className="card-body">
                    <div
                      className="font-16 font-weight-bold"
                      style={{ height: "45px" }}
                    >
                      <span className="">{item.name}</span>
                    </div>
                    <span className="text-muted font-13">
                      {item.weight} {item.unit_weight}
                    </span>
                    <div className="w-100 d-flex mt-1 font-14">
                      <h6 className="font-weight-bold text-primary ">
                        Rp {commafy(item.purchase_price)}
                      </h6>
                    </div>
                    <span className=" font-14">Stok: {item.stock + " " + item.satuan}</span>
                    <img
                      src={shop}
                      onClick={() => addCart(item)}
                      className="cursor-pointer float-end"
                      width="30"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </Row>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        {grosir.map((item, key) => {
          return (
            <center className="sticky-bottom me-2 mx-3" key={key}>
              <div
                className=" btn btn-primary w-100  mb-3 "
                onClick={() => history.push("/order-inventory")}
              >
                <div className="d-flex align-items-center flex-grow-1 ">
                  <div className="flex-shrink-0">
                    <img src={box} width="25" alt="" className="ms-3 mb-1" />
                    <h6 className="d-inline-block ml-2 text-white mt- mx-2">
                      {item.count} Items
                    </h6>
                  </div>
                  <div className="flex-grow-1 ms-3"></div>
                  <div className="flex-grow-2 ms-3">
                    <div className="text-muted mb-0">
                      <h6 className="text-white mb-0">Rp {commafy(item.sub_total?item.sub_total.toFixed(0): 0)}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </center>
          );
        })}
      </div>

      <div
        className="offcanvas offcanvas-bottom g-col-6 rounded-top"
        tabIndex="-1"
        id="cari"
        aria-labelledby="shortLabel"
        style={{ height: "160px" }}
      >
        <div className="offcanvas-body">
          <div className="grid">
            <div className="g-col-12 px-4 py-3 bg-white">
              <p className="fs-6 fw-bold text-primary">Jenis Transaksi</p>
              <div className="container px-0 py-4 text-center">
                <div className="row gx-8">
                  <a href="#" className="col text-decoration-none">
                    <div className="d-grid">
                      <button
                        type="button"
                        className="btn btn-primary btn-lg d-block text-white font-16"
                        onClick={() => history.push("/transaksi-inv")}
                      >
                        Barang Masuk
                      </button>
                    </div>
                  </a>
                  <a href="#" className="col text-decoration-none">
                    <div className="d-grid">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-lg d-block  font-16"
                      >
                        Barang Keluar
                      </button>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TransaksiInventory;
