import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { showImage } from "../../utils/Utils";
import {Carousel} from 'react-bootstrap';

const PromoGrosir = ({isGame, isGrosir}) => { 
  const { api } = useContext(AppContext);
  const [data, setData] = useState([]); 
  const isFirstRun = useRef(true)

  useEffect(() => {
    // if (isFirstRun.current) {
    //   isFirstRun.current = false;
    //   return;
    // }
    getData();
  }, []);

  const getData = async () => {
    const data = await api("get", "promo/get-for-grosir", {});
    setData(data.data);
  };

  return (
    <div className="row mt-4">
      <div className="col">
        <div className="slider mx-auto d-block rounded-4">
          <Carousel interval={2000} className="text-center">
            {data.map((item, key)=>{ 
              if(item.isGrosir !== isGrosir) 
              {return (
                <Carousel.Item key={key} >
                  <img
                    className="img-fluid w-75"
                    src={showImage(item.img)}
                    alt="First slide"
                  />
                  <Carousel.Caption>
                  
                  </Carousel.Caption>
                </Carousel.Item>
              ) }
              if(item.isGrosir !== isGrosir) 
              {return (
                <Carousel.Item key={key} >
                  <img
                    className="img-fluid w-75"
                    src={showImage(item.img)}
                    alt="First slide"
                  />
                  <Carousel.Caption>
                  
                  </Carousel.Caption>
                </Carousel.Item>
              ) }
            })}
          
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default PromoGrosir;
