import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { commafy } from "../../utils/Utils";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { NotificationManager } from "react-notifications";
import Pemasukan from "../../assets/images/in.svg";
import Saldo from "../../assets/images/saldo.svg";
import pengeluaran from "../../assets/images/out.svg";
import lurusatas from "../../assets/images/lurusatas.svg";
import koin from "../../assets/images/koin.svg";
import filterbiru from "../../assets/images/filterbiru.svg";
import tambah from "../../assets/images/tambah.svg";

const RekapKas = () => {
  const history = useHistory();
  const { api } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [saldo, setSaldo] = useState([]);
  const [summary, setSummary] = useState([]);
  const refInputStart = useRef();
  const refInputEnd = useRef();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    getData();
  }, [startDate, endDate]);

  const changeInputStart = () => {
    let value = refInputStart.current.value;
    setStartDate(value);
  };

  const changeInputEnd = () => {
    let value = refInputEnd.current.value;
    setEndDate(value);
  };

  const getData = async () => {
    let data = await api("get", "cash-grosir/get", {
      startDate: startDate,
      endDate: endDate,
    });
    setData(data.data);

    let summaryData = await api("get", "cash-grosir/summary-cash");
    setSummary(summaryData.data);

    let saldo = await api("get", "saldo-history/get-saldo");
    setSaldo(saldo.data);
  };

  const resetApi = async () => {
    let data = await api("get", "cash-grosir/get");
    setData(data.data);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm">
        <div className="contain p-2 px-cst1 pb-2 ps-4 w-100">
          <Row>
            <Col className="align-items-center w-100">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/dashboard-pos")}
                  className="d-inline-block mb-1 cursor-pointer"
                />
              </div>
              <div className="d-inline-block align-items-center">
                <span className="font-15 datapelanggan">Rekap Kas</span>
              </div>
              <div
                className="d-inline-block float-end pe-4"
                data-bs-toggle="offcanvas"
                data-bs-target="#rekapkas"
                aria-controls="rekapkas"
              >
                <div className="align-text-top cursor-pointer">
                  <img src={filterbiru} width={25} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="g-col-6  px-cst1 pb-2 list-denom ">
        <Row className="mt-4">
          <Col>
            <div className="card cursor-pointer">
              <div className="card-body">
                <Row>
                  <div className="col-md-4">
                    <div className="d-flex align-items-center ">
                      <div className="flex-shrink-0 px-3">
                        <img src={Pemasukan} width="40" alt="" />
                      </div>
                      <div className="flex-grow-1">
                        <small className="text-muted mt-2">Pemasukan</small>
                        <h6 className="text-dark">
                          Rp {commafy(summary.pemasukan)}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 ">
                    <div className="d-flex align-items-center">
                      {/* <img  src={lurusatas} /> */}
                      <div className="flex-shrink-0 px-3 float-end">
                        <img src={Saldo} width="40" alt="" />
                      </div>
                      <div className="flex-grow-1">
                        <small className="text-muted mt-2">Saldo</small>
                        <h6 className="text-dark">
                          Rp {commafy(saldo.nominal)}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 ">
                    <div className="d-flex align-items-center ">
                      {/* <img  src={lurusatas}/> */}
                      <div className="flex-shrink-0 px-3 float-end">
                        <img src={pengeluaran} width="40" alt="" />
                      </div>
                      <div className="flex-grow-1">
                        <small className="text-muted mt-2">Pengeluaran</small>
                        <h6 className="text-dark">
                          Rp {commafy(summary.pengeluaran)}
                        </h6>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        {data.map((item, key) => {
          return (
            <Row className="mt-4" key={key}>
              <Col>
                <div
                  className="card cursor-pointer"
                  onClick={() =>
                    history.push({
                      pathname: "/tanggal-rekap",
                      date: item.date,
                    })
                  }
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 pe-4">
                        <img src={koin} width="40" alt="" />
                      </div>
                      <div className="flex-grow-1 py-1">
                        <h6 className="text-dark mt-2">{item.date}</h6>
                      </div>
                      <div className="flex-grow-2 ms-3">
                        <div className="text-muted mb-0">
                          <h6 className="mt-2"> Rp.{commafy(item.jumlah)}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          );
        })}
      </div>
      <div
        className="float-end me-4 sticky-bottom py-3 cursor-pointer"
        onClick={() => history.push("/tambah-kas")}
      >
        <img src={tambah} />
      </div>

      <div
        className="offcanvas offcanvas-bottom g-col-6 rounded-top"
        tabIndex="-1"
        id="rekapkas"
        aria-labelledby="shortLabel"
        style={{ height: "400px" }}
      >
        <div className="offcanvas-body">
          <div className="grid">
            <div className="g-col-12 px-4 py-3 bg-white">
              <p className="fs-6 mb- fw-bold">Filter</p>
              <div className="container px-0 py-1">
                <div className="row gx-8">
                  <p className="mb-2">Pilih tanggal yang ingin ditampilkan</p>
                  <a className="col text-decoration-none">
                    <div className="card p-3">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img src="img/kalender.svg" width="50" alt="" />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p id="alternate2" className="mb-1 text-primary">
                            Tanggal Awal
                          </p>
                          <input
                            id="datepicker1"
                            className="form-control form-control-sm"
                            autoComplete="off"
                            type="date"
                            placeholder="Pilih tanggal"
                            aria-label="example"
                            ref={refInputStart}
                            onChange={() => changeInputStart()}
                            defaultValue={startDate}
                          />
                        </div>
                      </div>
                    </div>
                  </a>
                  <a className="col text-decoration-none">
                    <div className="card p-3">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img src="img/kalender.svg" width="50" alt="" />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p id="alternate2" className="mb-1 text-primary">
                            Tanggal Akhir
                          </p>
                          <input
                            id="datepicker2"
                            className="form-control form-control-sm"
                            autoComplete="off"
                            type="date"
                            placeholder="Pilih Tanggal"
                            aria-label="example"
                            ref={refInputEnd}
                            onChange={() => changeInputEnd()}
                            defaultValue={endDate}
                          />
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="container px-0 py-3 category">
                <div className="row gx-8">
                  <div className="col text-decoration-none">
                    <a
                      className="btn btn-outline-primary border-secondary d-block"
                      onClick={() => getData()}
                    >
                      Harian
                    </a>
                  </div>
                  {/* <div className="col text-decoration-none">
                                        <a href="#" className="btn btn-outline-primary border-secondary d-block">Mingguan</a>
                                    </div> */}
                  <div className="col text-decoration-none">
                    <a
                      className="btn btn-outline-primary border-secondary d-block"
                      // onClick={() => setBulanan()}
                    >
                      Bulanan
                    </a>
                  </div>
                </div>
              </div>
              <div className="container px-0 py-4 text-center">
                <div className="row gx-8">
                  <a className="col text-decoration-none">
                    <div className="d-grid">
                      <button
                        type="button"
                        className="btn btn-outline-secondary btn-lg d-block font-16"
                        onClick={() => resetApi()}
                      >
                        Reset
                      </button>
                    </div>
                  </a>
                  <div className="col text-decoration-none">
                    <div className="d-grid">
                      <button
                        type="button"
                        className="btn btn-primary btn-lg d-block text-white font-16"
                      >
                        Terapkan
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RekapKas;
