import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import ImgBg from "../../assets/images/bg-top.svg";
import { useHistory } from "react-router-dom";
import { commafy } from "../../utils/Utils";

const Rincian = () => {
  const history = useHistory();
  const [data, setData] = useState({});
  const [from, setFrom] = useState("");

  useEffect(() => {
    if (!history.location.data) return;
    setData(history.location.data);
    setFrom(history.location.from);
    
  }, [history.location.data]);

  return (
    <React.Fragment>
      <div className="w-100 min-vh-100">
        <div style={{ backgroundImage: `url(${ImgBg})` }}>
          <div className="navbar bg-transparent cursor-pointer">
            <div className="p-2 px-cst1 pb-2 ps-4">
              <Row>
                <Col className="d-flex">
                  <div
                    className="d-inline-block pe-2"
                    onClick={() =>
                      history.push({
                        pathname: from === "history" ? "/history" : "/",
                      })
                    }
                  >
                    <img
                      src="img/arrow-left-white.svg"
                      alt=""
                      width="30"
                      height="24"
                      className="d-inline-block align-text-top "
                    />
                  </div>

                  <div className="d-inline-block ml-2">
                    <span className="ml-3 text-white">Rincian Transaksi</span>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div
            className="w-100 text-white text-center py-3"
            style={{ height: "100px" }}
          ></div>
        </div>
        <div
          className="card border-0 shadow-sm rounded mx-4"
          style={{ marginTop: "-70px" }}
        >
          <div className="card-header border-0 bg-white py-3 text-center mt-2">
            <p className="mb-0 fs-6 font-16 text-dark">Rincian Transaksi</p>
          </div>
          <div className="w-100 d-flex px-3 font-14">
            <div className="hr w-100"></div>
          </div>

          <div className="w-100 d-flex px-3 font-14 mt-3">
            <div className="flex-grow-1">Tanggal</div>
            <div className="d-flex flex-grow-2">{data.createdAt}</div>
          </div>
          <div className="w-100 d-flex px-3 font-14 mt-3">
            <div className="flex-grow-1">ID Transaksi</div>
            <div className="d-flex flex-grow-2">{data.trxid}</div>
          </div>
          <div className="w-100 d-flex px-3 font-14 mt-3">
            <div className="flex-grow-1">SN/Token</div>
            <div className="d-flex flex-grow-2">{data.sn}</div>
          </div>
          <div className="w-100 d-flex px-3 font-14 mt-3">
            <div className="flex-grow-1">No. Tujuan</div>
            <div className="d-flex flex-grow-2">{data.tujuan}</div>
          </div>
          <div className="w-100 d-flex px-3 font-14 mt-3">
            <div className="flex-grow-1">Kode Produk</div>
            <div className="d-flex flex-grow-2">{data.code}</div>
          </div>

          <div className="w-100 d-flex px-3 font-14 mt-3">
            <div className="flex-grow-1">Harga</div>
            <div className="d-flex flex-grow-2">
              Rp {commafy(data.price_margin)}
            </div>
          </div>
          <div className="w-100 d-flex px-3 font-14 mt-3">
            <div className="flex-grow-1">Saldo Awal</div>
            <div className="d-flex flex-grow-2">
              Rp {commafy(data.saldo_awal)}
            </div>
          </div>
          <div className="w-100 d-flex px-3 font-14 mt-3">
            <div className="flex-grow-1">Saldo Akhir</div>
            <div className="d-flex flex-grow-2">
              Rp {commafy(data.saldo_akhir)}
            </div>
          </div>
          <div className="w-100 d-flex px-3 font-14 mt-3">
            <div className="flex-grow-1">Status</div>
            <div className="d-flex flex-grow-2">{data.status}</div>
          </div>
          <div className="w-100 d-flex px-3 font-14 my-3">
            <div className="flex-grow-1">Keterangan</div>
            <div className="d-flex flex-grow-2">{data.note}</div>
          </div>
          <div className="w-100 d-flex px-3 pb-3 font-14">
            <div className="hr w-100"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Rincian;
