import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { NotificationManager } from "react-notifications";
import { showImage } from "../../utils/Utils";
import pencil from "../../assets/images/pencil-square.svg";



const TambahProdukInv = () => {
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const [category, setCategory] = useState([]);
  const [brand, setBrand] = useState([]);
  const [unit, setUnit] = useState([]);
  const [gudang, setGudang] = useState([]);
  const [img, setImg] = useState([]);
  const [product, setProduct] = useState({
    category_grosir: {},
    brands_grosir: {},
    gudang: {},
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  useEffect(() => {
    getData();
  }, []);

  const { api, setSpinner } = useContext(AppContext);

  const onSubmit = async (params) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("img", params.img[0]);
    formData.append("name", params.name);
    formData.append("category_id", params.category_id);
    formData.append("brand_id", params.brand_id);
    formData.append("satuan", params.satuan);
    formData.append("stock", params.stock);
    formData.append("gudang_id", params.gudang_id);
    formData.append("weight", params.weight);
    formData.append("unit_weight", params.unit_weight);
    formData.append("price", params.price);
    formData.append("discount", params.discount);
    formData.append("description", params.description);
    formData.append("margin", params.margin);
    formData.append("purchase_price", params.purchase_price);
    let TambahProduk = await api("post", "products-grosir/post", formData);

    if (TambahProduk.success) {
      history.push({ pathname: "/daftar-produk-inventory" });
    } else NotificationManager.error(TambahProduk.message);

    setLoading(false);
  };

  const inputImage = async () => {
    setImg(true);
  };

  const getData = async () => {
    let category = await api("get", "category-grosir/get");
    if (category.success) {
      setCategory(category.data);
      setValue("category_id", category.data.category_id, {
        shouldValidate: false,
      });
    }
    console.log(category);
    let brand = await api("get", "brands-grosir/get");
    if (brand.success) {
      setBrand(brand.data);
      setValue("brand_id", category.data.brand_id, {
        shouldValidate: false,
      });
    }
    let unit = await api("get", "data-unit/get");
    if (unit.success) {
      setUnit(unit.data);
      setValue("unit_weight", category.data.unit_weight, {
        shouldValidate: false,
      });
    }
    let gudang = await api("get", "gudang/get");
    if (gudang.success) {
      setGudang(gudang.data);
      setValue("gudang_id", category.data.gudang_id, {
        shouldValidate: false,
      });
    }
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/daftar-produk-inventory")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Tambah Produk</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <form onSubmit={handleSubmit(onSubmit)}  className="bg-white p-3">
      <div className="row justify-content-center">
          <div className="App">
            {/* <div className="d-flex justify-content-center align-items-center">
              <img src={showImage(product.img)} width="150" />
              <img
                src={pencil}
                className="ms-3"
                width="23"
                height="45"
                type="file"
                onClick={() => inputImage()}
              />
            </div> */}

            <Form.Group controlId="formFile" className="font-11">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                className="font-14"
                {...register("img", {})}
              />
            </Form.Group>

            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="img" />
            </div>
          </div>
        </div>

        <Form.Group className="font-14 mt-2">
          <Form.Label>Nama Produk</Form.Label>
          <Form.Control
            defaultValue={product.name}
            className="font-14 shadow-none"
            {...register("name", {
              required: "Name is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="name" />
        </div>
        

        <Row>
          <Col>
            <Form.Group className="font-14 mt-4">
              <Form.Label>Kategori Produk</Form.Label>
              <Form.Select
                defaultValue={product.category_id}
                {...register("category_id", {})}
                className="font-14 shadow-none"
              >
                {category.map((item, key) => {
                  return (
                    <option
                      key={key}
                      value={item.id}
                      selected={
                        item.id === product.category_grosir.id ? true : false
                      }
                    >
                      {item.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="category_id" />
            </div>
          </Col>
          <Col>
            <Form.Group className="font-14 mt-4">
              <Form.Label>Brand Produk</Form.Label>
              <Form.Select
                defaultValue={product.brand_id}
                {...register("brand_id", {})}
                className="font-14 shadow-none"
              >
                {brand.map((item, key) => {
                  return (
                    <option
                      key={key}
                      value={item.id}
                      selected={
                        item.id === product.brands_grosir.id ? true : false
                      }
                    >
                      {item.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="brand_id" />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="font-14 mt-1">
              <Form.Label>Gudang</Form.Label>
              <Form.Select
                defaultValue={product.gudang_id}
                {...register("gudang_id", {})}
                className="font-14 shadow-none"
              >
                {gudang.map((item, key) => {
                  return (
                    <option
                      key={key}
                      value={item.id}
                      selected={item.id === product.gudang.id ? true : false}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="gudang_id" />
            </div>
          </Col>
          <Col>
            <Form.Group className="font-14 mt-2">
              <Form.Label>SKU</Form.Label>
              <Form.Control
                defaultValue={product.sku}
                className="font-14 shadow-none"
                {...register("sku", {
                  required: "SKU is required",
                })}
              />
            </Form.Group>
            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="sku" />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="font-14 mt-2">
              <Form.Label>Stock</Form.Label>
              <Form.Control
                defaultValue={product.stock}
                className="font-14 shadow-none"
                {...register("stock", {
                  required: "stock is required",
                })}
              />
            </Form.Group>
            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="stock" />
            </div>
          </Col>
          <Col>
            <Form.Group className="font-14 mt-1">
              <Form.Label>Satuan</Form.Label>
              <Form.Select
                defaultValue={product.satuan}
                {...register("satuan", {})}
                className="font-14 shadow-none"
              >
                {unit.map((item, key) => {
                  return (
                    <option
                      key={key}
                      selected={item.name === product.satuan ? true : false}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="satuan" />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="font-14 mt-2">
              <Form.Label>Berat Product</Form.Label>
              <Form.Control
                defaultValue={product.weight}
                className="font-14 shadow-none"
                {...register("weight", {
                  required: "weight is required",
                })}
              />
            </Form.Group>
            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="weight" />
            </div>
          </Col>
          <Col>
            <Form.Group className="font-14 mt-1">
              <Form.Label>Satuan Berat</Form.Label>
              <Form.Select
                defaultValue={product.unit_weight}
                {...register("unit_weight", {})}
                className="font-14 shadow-none"
              >
                {unit.map((item, key) => {
                  return (
                    <option
                      key={key}
                      selected={
                        item.name === product.unit_weight ? true : false
                      }
                    >
                      {item.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="unit_weight" />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="font-14 mt-2">
              <Form.Label>Harga Beli</Form.Label>
              <Form.Control
                defaultValue={product.purchase_price}
                className="font-14 shadow-none"
                {...register("purchase_price", {
                  required: "purchase_price is required",
                })}
              />
            </Form.Group>
            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="weight" />
            </div>
          </Col>
          <Col>
            <Form.Group className="font-14 mt-1">
              <Form.Label>Harga Jual</Form.Label>
              <Form.Control
                defaultValue={product.price}
                className="font-14 shadow-none"
                {...register("price", {
                  required: "price is required",
                })}
              />
            </Form.Group>
            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="unit_weight" />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="font-14 mt-2">
              <Form.Label>Margin</Form.Label>
              <Form.Control
                defaultValue={product.margin}
                className="font-14 shadow-none"
                {...register("margin", {
                  required: "margin is required",
                })}
              />
            </Form.Group>
            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="weight" />
            </div>
          </Col>
          <Col>
            <Form.Group className="font-14 mt-1">
              <Form.Label>Diskon</Form.Label>
              <Form.Control
                defaultValue={product.discount}
                className="font-14 shadow-none"
                {...register("discount", {
                  required: "discount is required",
                })}
              />
            </Form.Group>
            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="unit_weight" />
            </div>
          </Col>
        </Row>

        <Form.Group className="font-14 mt-2">
          <textarea
            defaultValue={product.description}
            className="font-14 shadow-none w-100 rounded form-control"
            placeholder="Deskripsi Produk"
            {...register("description", {
              required: "description is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="description" />
        </div>

        <Form.Group className="font-14 mt-2 w-100">
          <Button
            variant="primary"
            className="btn sticky-bottom btn-primary  w-100 text-white font-14"
            type="submit"
          >
            Update
          </Button>
        </Form.Group>
      </form>
    </React.Fragment>
  );
};

export default TambahProdukInv;
