import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import ImgBg from "../../assets/images/bg-top.svg";
import { useHistory } from "react-router-dom";
import { commafy } from "../../utils/Utils";
import Bottom from "./Bottom";

const Bantuan = () => {
  const history = useHistory();

  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm cursor-pointer">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-2">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/")}
                  className="d-inline-block align-text-top "
                />
              </div>

              <div className="d-inline-block ml-2">
                <span className="ml-3">Bantuan</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="px-5 py-4 bg-white min-vh-100">
        <div>
          <span className="text-primary font-16">
            Hubungi Kami Melalui Chat
          </span>
        </div>
        <div className="mt-2">
          <span className="text-grey font-14">
            Jika anda memiliki pertanyaan atau keluhan terkait layanan kami,
            silahkan hubungi customer service kami sesuai dengan kebutuhan anda
          </span>
        </div>
        <div className="card my-3 cursor-pointer">
          <a href="https://wa.me/6282113831489/" target="_blank" className="text-decoration-none">
            <div className="card-body px-3 pt-2 pb-0">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src="img/wa.svg" width="40" alt="" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <p className="text-dark mb-0">Whatsapp CS</p>
                </div>
                <div className="flex-grow-2 ms-3">
                  <div className="text-muted mb-0">
                    <img src="img/next.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </a>
          <div className="hr-line" />
          <a href="https://t.me/cs_narantraya/" target="_blank" className="text-decoration-none">
            <div className="card-body px-3 pt-1 pb-0">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src="img/telegram.svg" width="40" alt="" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <p className="text-dark mb-0">Telegram CS</p>
                </div>
                <div className="flex-grow-2 ms-3">
                  <div className="text-muted mb-0">
                    <img src="img/next.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </a>
          <div className="hr-line" />
          <a href="https://t.me/narastoreofficial2022/" target="_blank" className="text-decoration-none">
            <div className="card-body px-3 pt-1 pb-0">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src="img/telegram.svg" width="40" alt="" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <p className="text-dark mb-0">Telegram Channel</p>
                </div>
                <div className="flex-grow-2 ms-3">
                  <div className="text-muted mb-0">
                    <img src="img/next.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </a>
          <div className="hr-line" />
          <a href="https://instagram.com/narastore_official" target="_blank" className="text-decoration-none">
            <div className="card-body px-3 pt-1 pb-0">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src="img/instagram.svg" width="40" alt="" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <p className="text-dark mb-0">Instagram</p>
                </div>
                <div className="flex-grow-2 ms-3">
                  <div className="text-muted mb-0">
                    <img src="img/next.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </a>
          <div className="hr-line" />
          <a href="https://tiktok.com/@narastore_official" target="_blank" className="text-decoration-none">
            <div className="card-body px-3 pt-1 pb-0">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src="img/tiktok.svg" width="40" alt="" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <p className="text-dark mb-0">Tiktok</p>
                </div>
                <div className="flex-grow-2 ms-3">
                  <div className="text-muted mb-0">
                    <img src="img/next.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </a>
          <div className="hr-line" />
          <a href="https://www.facebook.com/people/Narastore/100084815017817/" target="_blank" className="text-decoration-none">
            <div className="card-body px-3 pt-1 pb-0">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src="img/fb.svg" width="40" alt="" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <p className="text-dark mb-0">Facebook</p>
                </div>
                <div className="flex-grow-2 ms-3">
                  <div className="text-muted mb-0">
                    <img src="img/next.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </a>
          <div className="hr-line" />
          <a href="https://twitter.com/narastoretalk" target="_blank" className="text-decoration-none">
            <div className="card-body px-3 pt-1 pb-0">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src="img/twitter.svg" width="40" alt="" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <p className="text-dark mb-0">Twitter</p>
                </div>
                <div className="flex-grow-2 ms-3">
                  <div className="text-muted mb-0">
                    <img src="img/next.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </a>
          <div className="hr-line" />
          <a href="https://nara-store.id/" target="_blank" className="text-decoration-none">
            <div className="card-body px-3 pt-1 pb-2">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img src="img/website.png" width="40" alt="" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <p className="text-dark mb-0">Website Nara Store</p>
                </div>
                <div className="flex-grow-2 ms-3">
                  <div className="text-muted mb-0">
                    <img src="img/next.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      {/* <Bottom/> */}
    </React.Fragment>
  );
};

export default Bantuan;
