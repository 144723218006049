import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { showImage } from "../../../utils/Utils";
import { useHistory } from "react-router-dom"; 
import { commafy } from "../../../utils/Utils";
import { Col, Row } from "react-bootstrap";

const Semua = ({keyword}) => {
  const { api, setSpinner } = useContext(AppContext);
  const [data, setData] = useState([]);
  const history = useHistory();
  const isFirstRun = useRef(true);

  useEffect(() => {
    // if (isFirstRun.current) {
    //   isFirstRun.current = false;
    //   return;
    // }
    getData();
  }, [keyword]);

    const getData = async () => {
        let params = {
            status: "Semua",
            user: keyword.name,
        };
        
        setSpinner(true);
        let data = await api("get", "transactions-grosir/get-for-admin", params);
        setData(data.data);
        setSpinner(false);
    }
    console.log(data);

    return (
        <React.Fragment>
            <div
                className="tab-pane fade show active"
                id="pills-semua"
                role="tabpanel"
                aria-labelledby="pills-semua-tab"
                tabIndex="0"
            >
                <div className="container px-4 mt-4">
                    <div className="row">
                        {data.map((item, key) => {
                            return (
                                <div className="col-mb-12" key={key}>
                                    <div className="card mb-3 ">
                                        <div className="card-body cursor-pointer"
                                            onClick={() =>
                                            history.push({
                                                pathname: "/rincian-pesanan-admin",
                                                pesanan: item,
                                            })
                                            }
                                        >
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 p-3 rounded bg-lime">
                                                  <img src={showImage(item.transactions_details[0].cart_grosir.products_grosir.img)} width="80" />
                                                </div>
                                                <div className="flex-grow-1 ms-3 mt-3 ">
                                                    <div className="d-flex ">
                                                        <span className="mb-0 text-black ">
                                                            {item.no}
                                                        </span>
                                                        <div className="col ">
                                                            <h6 className=" text-black float-end mg-5 font-14 ">
                                                                {item.user.first_name}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <h5 className="mb-1 fs-6 mt-1">
                                                        {item.transactions_details[0].cart_grosir.products_grosir.name}
                                                    </h5>
                                                    <div className="d-flex mb-2">
                                                        <span className="mb-0 text-primary date-time">
                                                            Rp {commafy(item.total)} | {item.delivery_type}
                                                        </span>
                                                        <div className="col ">
                                                            <h6 className=" text-black float-end mg-5 font-14 ">
                                                            x{item.transactions_details[0].cart_grosir.qty}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex">
                                                        <span className="text-secondary">{item.createdAt}</span>
                                                        <div className="input-group ms-auto mb-0" style={{ width: "126px" }}>
                                                            <div className="col ">
                                                                <span className=" text-primary float-end mg-5 ">
                                                                {
                                                                    item.status === "Dikirim" ?
                                                                        <small className="text-warning" style={{fontWeight:"bold"}}>
                                                                            {item.status_operations}
                                                                        </small>
                                                                    : item.status === "Dibatalkan" ?
                                                                        <small className="text-danger" style={{fontWeight:"bold"}}>
                                                                            {item.status_operations}
                                                                        </small>
                                                                    : 
                                                                    <small className="text-primary" style={{fontWeight:"bold"}}>
                                                                        {item.status_operations}
                                                                    </small>
                                                                }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                                <hr className="mt-4 hr-product " />
                                                <Row>
                                                    <Col>
                                                        <div>
                                                            <span className="mt-3 font-12">
                                                            {item.transactions_details.length} Produk
                                                            </span>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="font-13">
                                                            <span className="mt-3">
                                                                Total Pesanan : 
                                                            </span>
                                                            <span className=" text-primary float-end mg-5 ">
                                                                Rp {commafy(item.total)}
                                                            </span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                            );
                        })}
                    </div>
                </div>
            </div> 
        </React.Fragment>
    );
};

export default Semua