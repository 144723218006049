import React, { useContext, useEffect, useState, useRef } from "react";
import { Form, Modal, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { commafy, showImage } from "../../utils/Utils";
import { AppContext } from "../../context/AppContext";
import tambah from "../../assets/images/tambah.svg";
import trash from "../../assets/images/trash-icon.svg";
import sortIcon from "../../assets/images/sort.svg";
import alarm from "../../assets/images/icon-info.png";
import { NotificationManager } from "react-notifications";
import { useDebounce } from "use-debounce";
import _ from "lodash";

const DaftarProduk = () => {
  const { api, setSpinner } = useContext(AppContext);
  const history = useHistory();
  const [category, setCategory] = useState([]);
  const [categoryId, setCategoryId] = useState(0);
  const [idCategory, setIdCategory] = useState(0);
  const [data, setData] = useState([]);
  const refInput = useRef();
  const [sort, setSort] = useState("DESC");
  const [text, setText] = useState("");
  const [value] = useDebounce(text, 1000);
  const [param, setParam] = useState("/kelola-data");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const refInputScan = useRef([]);

  useEffect(() => {
    if (history.location.param === "dashboard") {
      setParam("/dashboard");
    }
  }, [history.location]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    let keyword = {
      admin: true,
      name: value,
    };

    getProduct(keyword);
  }, [value]);

  const getProduct = async (params) => {
    let data = await api("get", "products-grosir/get", params);
    setData(data.data);
  };

  const semuaProduct = async () => {
    setIdCategory(0);
    setSpinner(true);
    let semua = await api("get", "products-grosir/get", {
      admin: true,
      is_web: true,
    });
    setData(semua.data);
    setSpinner(false);
  };

  const getData = async (sort) => {
    if (history.location.category_id) {
      setCategoryId(history.location.category_id);
      let data = await api("get", "products-grosir/get", {
        category_id: history.location.category_id,
        admin: true,
        is_web: true,
        sort: sort,
      });
      setData(data.data);
    } else {
      let data = await api("get", "products-grosir/get", {
        admin: true,
        is_web: true,
        sort: sort,
      });
      setData(data.data);
    }
    setSpinner(true);
    let category = await api("get", "category-grosir/get", {is_web: true});
    setCategory(category.data);
    setSpinner(false);
  };

  const handleClickType = async (param) => {
    let category_id = {
      category_id: param.id,
      admin: true,
      is_web: true,
      sort: sort,
    };
    setIdCategory(param.id);
    setSpinner(true);
    let data = await api("get", "products-grosir/get", category_id);
    setData(data.data);
    setSpinner(false);
  };

  const selectAll = (e) => {
    let newData = data.map((item) => {
      item.selected = e.target.checked;
      return item;
    });
    setData(newData);
  };

  const selectSingle = (id, selected) => {
    let newData = data.map((item) => {
      if (item.id === id) item.selected = selected;
      return item;
    });

    setData(newData);
  };

  const delData = async () => {
    let id = "",
      separator = "";
    data.map((item) => {
      if (item.selected === true) {
        id = id + separator + item.id;
        separator = ",";
      }
    });

    if (!id) {
      NotificationManager.error("Pilih produk terlebih dahulu");
      setShow(false);
    }

    let param = { id_all: id };
    let del = await api("post", "products-grosir/delete-all", param);

    if (del.success) {
      NotificationManager.success(del.message);
      getData();
    }
    setShow(false);
  };

  const changeInput = async () => {
    refInputScan.current = [];
    let value = refInput.current.value;
    setText(value);
  };

  const handleChange = async (id, isActive) => {
    setSpinner(true);
    let data = await api("post", "products-grosir/update-status", {
      id: id,
      is_active: isActive,
    });
    setSpinner(false);
    if (data.success) {
      NotificationManager.success(data.message);
      getData();
    }
  };

  const handleSave = async (id, key) => {
    let tmpItem = _.find(data, (Item, keyItem) => {
      return keyItem === key;
    });
    setSpinner(true);
    let tmpData = await api("post", "products-grosir/update-scan", {
      id: id,
      id_scan: tmpItem.id_scan,
    });
    setSpinner(false);
    if (tmpData.success) {
      refInputScan.current[key].value = "";
      NotificationManager.success(tmpData.message);
    }
  };

  const changeSort = async (value) => {
    setSort(value);
  };

  const handleClickSort = async () => {
    getData(sort);
  };

  const duplicateProduct = async (value) => {
    setSpinner(true);
    const formData = new FormData();
    formData.append("img", value.img);
    formData.append("name", value.name);
    formData.append("category_id", value.category_grosir.id);
    formData.append("brand_id", value.brands_grosir.id);
    formData.append("satuan", value.satuan);
    formData.append("stock", 0);
    formData.append("weight", value.weight);
    formData.append("unit_weight", value.unit_weight);
    formData.append("price", 0);
    formData.append("discount", 0);
    formData.append("description", value.description);
    formData.append("gudang_id", value.gudang.id);
    formData.append("margin", 0);
    formData.append("sku", value.sku);
    formData.append("limit_stock", 0);
    formData.append("purchase_price", 0);

    let TambahProduk = await api("post", "products-grosir/post", formData);

    if (TambahProduk.success) {
      NotificationManager.success("Duplikasi Produk Berhasil.");
      getData();
    } else NotificationManager.error(TambahProduk.message);
    setSpinner(false);
  };

  const handleChangeScan = async (key, e) => {
    let tmpData = data.map((item, keyItem) => {
      if (key === keyItem) item.id_scan = e.target.value;
      return item;
    });

    setData(tmpData);
  };

  const handleExport = async () => {
    window.open('https://nara-store.id/api/products-grosir/export', '_blank', 'noopener,noreferrer')
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm w-100">
        <div className="p-2 pb-2 ps-4 w-100 d-flex align-items-center">
          <div className="d-inline-block">
            <img
              src="img/arrow-left.svg"
              alt=""
              width="30"
              height="24"
              onClick={() => history.push(param)}
              className="d-inline-block align-text-top cursor-pointer"
            />
          </div>
          <div className="d-inline-block ms-2" style={{ width: "90%" }}>
            <div className="input-group w-100">
              <input
                type="text"
                className="form-control form-control-lg font-16"
                placeholder="Cari Produk"
                ref={refInput}
                aria-describedby="basic-addon2"
                onChange={() => changeInput()}
              />
              <button className="input-group-text bg-white border">
                <i className="bi bi-search"></i>
              </button>
            </div>
          </div>
          <div
            className="col-md-1"
            data-bs-toggle="offcanvas"
            data-bs-target="#sort"
            aria-controls="sort"
          >
            <div className="d-inline-block align-text-top">
              <img
                src={sortIcon}
                className="float-right"
                width="70"
                height="30"
              />
            </div>
          </div>
        </div>
        <div className="riwayat w-100  overflow-scroll">
          <div className="bg-light mx-4">
            <ul
              className="nav nav-pills nav-fill mb-3"
              id="pills-tab"
              role="tablist"
            >
              <div className="d-flex">
                <li
                  className="nav-item "
                  role="presentation"
                  hidden={categoryId === 0 ? false : true}
                >
                  <button
                    className="nav-link py-3 active no-wrap"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    role="tab"
                    onClick={() => semuaProduct()}
                  >
                    Semua
                  </button>
                </li>
                {category.map((item, key) => {
                  if (categoryId !== 0 && item.id !== categoryId) return;
                  return (
                    <li className="nav-item" role="presentation" key={key}>
                      <button
                        className={
                          categoryId === item.id
                            ? "nav-link py-3 no-wrap active"
                            : "nav-link py-3 no-wrap"
                        }
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        role="tab"
                        key={key}
                        md={category.column}
                        onClick={() => handleClickType(item)}
                      >
                        {item.name}
                      </button>
                    </li>
                  );
                })}
              </div>
            </ul>
          </div>
        </div>
      </nav>

      <div className="container px-4 ">
        <div className="deleteform d-flex align-item-center ms-3 mt-3">
          <input
            className="form-check-input  cursor-pointer"
            type="checkbox"
            onClick={(e) => selectAll(e)}
            value=""
            id="flexCheckDefault"
          />
          <label
            className="form-check-label flex-grow-1 ms-3"
            htmlFor="flexCheckDefault"
          >
            Pilih semua
          </label>
          <div className="flex-grow-2">
            <div
              className="text-muted mt-1 cursor-pointer"
              onClick={() => handleExport()}
            >
              <span>
                <i className="fa fa-file-excel fa-lg"></i>
              </span>
            </div>
          </div>
          <div className="flex-grow-2 mx-3">
            <div className="text-muted mb-0 cursor-pointer">
              {/* <img src={trash} width="25" onClick={() => delData()} /> */}
              <img src={trash} width="25" onClick={() => setShow(true)} />
            </div>
          </div>
        </div>

        <br />
        <div className="row">
          {data.map((item, key) => {
            return (
              <div className="col-mb-12" key={key}>
                <div className="card mb-3 ">
                  <div className="card-body ">
                    <div className="d-flex align-items-center">
                      <div className="deleteform form-check-delete">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onClick={(e) =>
                            selectSingle(item.id, e.target.checked)
                          }
                          value=""
                          checked={item.selected}
                          id="flexCheckDefault "
                        />
                      </div>
                      <div
                        className="flex-shrink-0 p-3 rounded bg-lime ms-4"
                        onClick={() =>
                          history.push({
                            pathname: "/admin-detail",
                            product: item,
                          })
                        }
                      >
                        <img
                          src={showImage(item.img)}
                          width="50"
                          className="daftarprodukimg"
                        />
                      </div>
                      <div className="flex-grow-1 ms-3 mt-3 ">
                        <div
                          onClick={() =>
                            history.push({
                              pathname: "/admin-detail",
                              product: item,
                            })
                          }
                        >
                          <small className="mb-1 text-secondary font-14">
                            {item.sku}
                          </small>
                          <div className="d-flex ">
                            <span className="mb-0 text-primary date-time font-16 nameproduk">
                              {item.name}
                            </span>
                          </div>
                          <div className="w-100 d-flex mt-1 font-14">
                            <h6 className="font-weight-bold text-primary ">
                              Rp {commafy(item.price)}
                            </h6>
                            <small className="ms-2 text-secondary font-13">
                              <del> Rp{commafy(item.price_before)}</del>
                            </small>
                          </div>
                          <div className="d-flex">
                            <span className="text-secondary  font-14 jumlahproduk">
                              Stock : {item.stock + " " + item.satuan}
                            </span>
                            <div
                              className="input-group ms-auto mb-0 "
                              style={{ width: "126px" }}
                            ></div>
                          </div>
                        </div>
                        <Row className="mt-3">
                          <Col>
                            <div>
                              <input
                                type="text"
                                placeholder="Scan disini"
                                value={item.id_scan ? item.id_scan : ""}
                                className="form form-control font-14"
                                onChange={(e) => handleChangeScan(key, e)}
                                // ref={(ref) => (refInputScan.current[key] = ref)}
                              ></input>
                            </div>
                          </Col>
                          <Col
                            md="2"
                            className="d-flex justify-content-center align-center h-100"
                          >
                            <div
                              className="h-100 mt-2"
                              onClick={() => handleSave(item.id, key)}
                            >
                              <i className="fa fa-save fa-lg text-primary cursor-pointer"></i>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div>
                        <i
                          className="bi bi-clipboard cursor-pointer"
                          onClick={() => duplicateProduct(item)}
                        ></i>
                        <Form.Check
                          type="switch"
                          className={
                            item.is_active ? "text-primary" : "text-danger"
                          }
                          id={item.id}
                          label={item.is_active ? "Aktif" : "Tidak Aktif"}
                          onChange={(e) =>
                            handleChange(item.id, e.target.checked)
                          }
                          defaultChecked={item.is_active}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="float-end  sticky-bottom py-3 cursor-pointer">
          <img src={tambah} onClick={() => history.push("/tambah-produk")} />
        </div>
      </div>
      <div className="row justify-content-center sticky-bottom border-top">
        <div className="col-md-12">
          <div className="grid">
            <div
              className="offcanvas offcanvas-bottom g-col-6 rounded-top"
              tabIndex="-1"
              id="sort"
              aria-labelledby="shortLabel"
              style={{ height: "350px", width: "400px" }}
            >
              <div className="offcanvas-body">
                <div className="grid">
                  <div className="g-col-12 px-4 py-3 bg-white">
                    <p className="fs-6 mb- fw-bold">Urutkan</p>
                    <div className="container px-0  text-center">
                      <div className="row gx-8">
                        <div className="col d-grid">
                          <button
                            type="button"
                            className="text-gudang btn btn-outline-primary w-100  btn-sm border d-block"
                            onClick={() => changeSort("nameAsc")}
                          >
                            A - Z
                            <br />
                          </button>
                        </div>
                      </div>
                      <div className="row gx-8 mt-2">
                        <div className="d-grid">
                          <button
                            type="button"
                            className="text-gudang btn btn-outline-primary w-100  btn-sm border d-block"
                            onClick={() => changeSort("nameDesc")}
                          >
                            Z - A
                            <br />
                          </button>
                        </div>
                      </div>
                      <div className="row gx-8 mt-2">
                        <div className="d-grid">
                          <button
                            type="button"
                            className="text-gudang btn btn-outline-primary w-100 btn-sm border d-block"
                            onClick={() => changeSort("stockAsc")}
                          >
                            Stok Terendah
                            <br />
                          </button>
                        </div>
                      </div>
                      <div className="row gx-8 mt-2">
                        <div className="d-grid">
                          <button
                            type="button"
                            className="text-gudang btn btn-outline-primary w-100 btn-sm border d-block"
                            onClick={() => changeSort("stockDesc")}
                          >
                            Stok Tertinggi
                            <br />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="container px-0 py-5 text-center">
                      <div className="row gx-8">
                        <div className="d-grid">
                          <button
                            type="button"
                            className="btn btn-primary btn-sm d-block text-white"
                            onClick={() => handleClickSort()}
                          >
                            Terapkan
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal className="border-0" show={show} centered onHide={handleClose}>
        <Modal.Header className="bg-warning rounded-top">
          <Modal.Title className="w-100">
            <center>
              <img src={alarm} />
              <h6 className="mt-2">Perhatian!</h6>
            </center>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            <div className="row g-2  g-lg-3 me-2 mx-2">
              <div>
                <span>Yakin untuk menghapus produk?</span>
              </div>
              <div className="d-grid col-sm-6 col-md">
                <button
                  type="button"
                  className="mt-2 btn-outline-secondary bg-light border rounded  d-block text-muted font-16"
                  onClick={handleClose}
                >
                  No
                </button>
              </div>
              <div className="d-grid col-sm-6 col-md">
                <button
                  type="button"
                  className="mt-2 btn btn-primary btn-lg d-block text-white  font-16"
                  onClick={() => delData()}
                >
                  Yes
                </button>
              </div>
            </div>
          </center>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default DaftarProduk;
