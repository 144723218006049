import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { NotificationManager } from "react-notifications";

const CategoryForm = () => {
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const { api } = useContext(AppContext);

  const onSubmit = async (params) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("img", params.img[0]);
    formData.append("name", params.name);

    let CategoryForm = await api("post", "category-grosir/post", formData);

    if (CategoryForm.success) {
      history.push({ pathname: "category-data" });
    } else NotificationManager.error(CategoryForm.message);

    setLoading(false);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/category-data")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Tambah Kategori</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <form onSubmit={handleSubmit(onSubmit)} className="bg-white p-3">
      <Form.Group className="font-14 mt-2">
          <Form.Label>Kategori</Form.Label>
          <Form.Control
            className="font-14 shadow-none"
            {...register("name", {
              required: "name is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="name" />
        </div>
        
        
        <div className="row justify-content-center">
          <div className="App">
            <Form.Group controlId="formFile" className="font-11">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                className="font-14"
                {...register("img", {})}
              />
            </Form.Group>

            <div className="my-2 text-left float-start font-12 text-danger">
              <ErrorMessage errors={errors} name="img" />
            </div>
          </div>
        </div>
    
        
        <div className="d-grid py-4 fixed-bottom" >
          <center>
            <Button
              style={{width:"700px"}}
              variant="primary"
              className="mt-2 btn btn-primary btn-lg d-block text-white  font-14"
              disabled={isLoading}
              type="submit"
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Ubah Data"
              )}
            </Button>
          </center>
        </div>
      </form>
    </React.Fragment>
  );
};

export default CategoryForm;