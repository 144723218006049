import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import { showImage } from "../../utils/Utils";
import { useHistory } from "react-router-dom";

const DashboardGroup = () => {
  const { api, setSpinner } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [profile, setProfile] = useState([]);
  const [saldo, setSaldo] = useState([]);
  const [local, setLocal] = useState(null);
  const history = useHistory();
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    let localData = localStorage.getItem("useranarastore");
    if (localData !== null) setLocal(JSON.parse(localData));
    getMenu();
  }, []);

  const getMenu = async () => {
    setSpinner(true);
    let menu = await api("get", "menu/get-for-dashboard-group");
    setMenu(menu.data);
    setSpinner(false);
  };

  return (
    <React.Fragment>
      <div className="g-col-6  px-cst1 pb-5 list-denom mt-3">
        <Row className="mt-4">
          {menu.map((item, key) => {
            return (
              <Col md={4} className="py-2" key={key}>
                <div
                  className="card cursor-pointer w-100 border-0"
                  onClick={() =>
                    history.push({
                      pathname: item.url,
                    })
                  }
                >
                  <div className="card-body">
                    <center>
                      <div className=" py-2 ">
                        <img
                          src={showImage(item.img)}
                          width="70"
                          alt=""
                          className="dashboardfoto"
                        />
                      </div>
                      <div className="flex-grow-1  pt-2">
                        <h6 className="text-dark mb-0">{item.name}</h6>
                        <small className="">{item.description}</small>
                      </div>

                    </center>
                    
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </React.Fragment>
  );
};

export default DashboardGroup;
