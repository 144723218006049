import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { NotificationManager } from "react-notifications";
import { Button, Spinner } from "react-bootstrap";

const Login = () => {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const { api } = useContext(AppContext);

  useEffect(() => {}, []);

  const onSubmit = async (params) => {
    setLoading(true);
    let login = await api("post", "auth/login", params);

    if (login.success) {
      localStorage.removeItem("useranarastore");
      localStorage.setItem("useranarastoretoken", login.data.token);
      let profile = await api("get", "user/profile", {});
      if (profile.success) {
        localStorage.removeItem("useranarastore");
        localStorage.setItem("useranarastore", JSON.stringify(profile.data));
        window.location.href = "/";
      }
    } else NotificationManager.error(login.message);

    setLoading(false);
  };

  return (
    <div className="w-100">
      <div className="float-right" style={{ height: "200px" }}>
        <img src="img/bg-top-right.svg" className="float-end"></img>
      </div>
      <div className="text-center px-5">
        <div>
          <img src="img/logo-small.svg"></img>
        </div>
        <div className="mt-3">
          <span className="text-dark font-14">Masuk Akun Nara Store</span>
        </div>

        <div className="mt-4 w-100">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div class="mb-3">
              <input
                type="email"
                placeholder="Email"
                autocomplete="off"
                class="form-control form-control-lg font-14"
                id="exampleInputText1"
                {...register("email", {
                  required: "Email is required",
                })}
              />
              <div className="my-2 text-left float-start font-12 text-danger">
                <ErrorMessage errors={errors} name="email" />
              </div>
            </div>

            <div class="mb-3">
              <input
                type="password"
                placeholder="Password"
                autocomplete="off"
                class="form-control form-control-lg font-14"
                id="exampleInputText1"
                {...register("password", {
                  required: "Password is required",
                })}
              />
              <div className="my-2 text-left float-start font-12 text-danger">
                <ErrorMessage errors={errors} name="password" />
              </div>
            </div>
            <div class="mb-3 font-14 float-end text-pink cursor-pointer" onClick={()=>history.push("forgot")}> 
                <span>Lupa Password</span> 
            </div>
            <Button
              variant="primary"
              className="button w-100 text-white font-16"
              disabled={isLoading}
              type="submit"
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Masuk"
              )}
            </Button>
          </form>
          <div className="mt-3">
            <span className="regist">Tidak punya akun?</span>
            <span
              className="daftar ps-1 text-primary cursor-pointer"
              onClick={() => history.push("/register")}
            >
              Daftar
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
