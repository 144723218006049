import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { commafy } from "../../../utils/Utils";
import tambah from "../../../assets/images/tambah.svg";
import money from "../../../assets/images/money.png";



const DataPoint = () => {
  const history = useHistory();

    const { api, setSpinner } = useContext(AppContext);
    const [kalkulasi, setKalkulasi] = useState([]);
        useEffect(() => {
            getData();
        }, []);

  const getData = async () => {
    setSpinner(true);
    let result = await api("get", "point-calculations");
    if (result.success) {
      setKalkulasi(result.data);
    }
    setSpinner(false);
  };

  const deleteKalkulasi = async (kalkulasiId) => {
    setSpinner(true);
    let params = {
      id: kalkulasiId,
    };
    let result = await api("post", "point-calculations/destroy", params);
    if (result.success) {
      getData();
      return;
    } else {
      alert(result.message);
    }
    setSpinner(false);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/data-master")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Data Point</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="g-col-6  px-cst1 pb-2 list-denom ">
        <Row className="mt-2">
          {kalkulasi.map((kalkulasi, item) => {
            return (
              <div className="col-md-13 mt-3" >
                <div
                  className="card "
                  style={{ borderRadius: "10px" }}
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 px-3">
                        <img src={money} width="40" alt="" />
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="text-dark">Minimal Transaksi</h6>
                        <small className="text-dark">
                          Rp {commafy(kalkulasi.min_transaction)}
                        </small>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="text-dark">Maksimal Transaksi</h6>
                        <small className="text-dark">
                          Rp {commafy(kalkulasi.max_transaction)}
                        </small>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="text-dark">Point</h6>
                        <small className="text-dark">
                          {kalkulasi.point}
                        </small>
                      </div>

                      <div className="flex-grow-2 ms-3 ">
                        <h6 className="text-dark">Action</h6>
                        <div className="text-muted mb-0 justify-between ">
                          <i 
                            className="bi bi-pencil-square cursor-pointer"
                            onClick={() =>
                                history.push({
                                pathname: "/edit-point-kalkulasi",
                                kalkulasiPoint: kalkulasi
                                })
                              }>  
                            </i>

                          <i 
                            className="bi bi-trash cursor-pointer"
                            onClick={() => deleteKalkulasi(kalkulasi.id)} >
                          </i>  
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Row>
      </div>

      <div
        className="float-end me-4 sticky-bottom py-3 cursor-pointer"
        onClick={() => history.push("/kalkulasi-point")}
      >
        <img src={tambah} />
      </div>
       
    </React.Fragment>
   
  );
};

export default DataPoint;
