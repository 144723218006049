import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import tambah from "../../assets/images/tambah.svg";
import tanda from "../../assets/images/tanda.svg";
import { NotificationManager } from "react-notifications";
import diskonfoto from "../../assets/images/diskonfoto.svg";
import cari from "../../assets/images/cari.svg";
import hapus from "../../assets/images/hapus.svg";

const Pajak = () => {
  const history = useHistory();
  const { api } = useContext(AppContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async (prefix) => {
    let data = await api("get", "data-pajak/get");
    setData(data.data);
  };

  const delData = async (item) => {
    let param = { id: item.id };
    let del = await api("delete", "data-pajak/delete", param);

    if (del.success) {
      NotificationManager.success(del.message);
    }

    getData();

    console.log(del);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/kelola-data")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Pajak</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>
      {data.map((item) => {
        return (
          <>
            <div className="g-col-6  px-cst1 pb-2 list-denom mt-4">
              <div
                className="card"
              >
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <img src={diskonfoto} width="40" alt="" />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h6 className="text-dark mb-0">
                        {item.name} ({item.percentage} %)
                      </h6>
                    </div>
                    <div className="flex-grow-2 ms-3 mt-1">
                      <div className="text-muted mb-0">
                        <img
                          src={hapus}
                          width="25"
                          onClick={() => delData(item)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}

      <div
        className="float-end me-4 sticky-bottom py-3 cursor-pointer"
        onClick={() => history.push("/tambah-pajak")}
      >
        <img src={tambah} />
      </div>
    </React.Fragment>
  );
};

export default Pajak;
