import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { showImage } from "../../utils/Utils";
import { Modal, Card, Carousel, Col, Nav, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import filterbiru from "../../assets/images/filterbiru.svg";
import transaksi from "../../assets/images/transaksi-pos.svg";
// import FilterProduct from "./FilterProduct";
import printIcon from "../../assets/images/print-icon.svg";
import _ from "lodash";
import { commafy } from "../../utils/Utils";
import editIcon from "../../assets/images/pencil-square.svg";
import moment from "moment";

const RiwayatTransaksiPos = () => {
  const { api, setSpinner } = useContext(AppContext);
  const [data, setData] = useState([]);
  const history = useHistory();
  const refInputStart = useRef();
  const refInputEnd = useRef();
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [showDetail, setShowDetail] = useState(false);
  const [showPrint, setShowPrint] = useState(false);
  const [id, setId] = useState("");
  const [detail, setDetail] = useState({
    kasir: {},
    buyer: {},
    transactions_details: [],
  });

  useEffect(() => {
    getData();
  }, [startDate, endDate]);

  const changeInputStart = () => {
    let value = refInputStart.current.value;
    setStartDate(value);
  };

  const changeInputEnd = () => {
    let value = refInputEnd.current.value;
    setEndDate(value);
  };

  const getData = async () => {
    setSpinner(true);
    let param = {
      status: "Semua",
      source: "POS",
      startDate: startDate,
      endDate: endDate,
    };

    let data = await api("get", "transactions-grosir/get-for-pos", param);
    setData(data.data);
    setSpinner(false);
  };

  const handleClickDetail = async (item) => {
    setShowDetail(true);
    setId(item.id);
    setDetail(item);
  };
  const handleClickPrint = async (item) => {
    setShowDetail(false);
    setShowPrint(true);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4 w-100">
          <Row>
            <Col className="align-items-center w-100">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/dashboard-pos")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block">
                <span className="font-15 datapelanggan">Riwayat Transaksi</span>
              </div>
              <div
                className="d-inline-block float-end pe-4"
                data-bs-toggle="offcanvas"
                data-bs-target="#riwayat"
                aria-controls="riwayat"
              >
                <div className="align-text-top ">
                  <img src={filterbiru} width={25} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="g-col-6  px-cst1 pb-2 list-denom ">
        {data.map((item, key) => {
          return (
            <Row key={key}>
              <Col>
                <div className="col-md-12 mt-4">
                  <div className="flex-shrink-0 ms-3 cursor-pointer">
                    <div
                      className="card cursor-pointer"
                      style={{ borderRadius: "10px" }}
                      onClick={() => handleClickDetail(item)}
                    >
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 pe-4">
                            <img src={transaksi} width="40" alt="" />
                          </div>
                          <div className="flex-grow-1 py-1">
                            <h6 className="text-dark">{item.no}</h6>
                            <small className="text-secondary">
                              {item.createdAt}
                            </small>
                          </div>
                          <div className="flex-grow-2 ms-3">
                            <div className="text-muted mb-0">
                              <h6>{commafy(item.total)}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          );
        })}
      </div>

      <div
        className="offcanvas offcanvas-bottom g-col-6 rounded-top"
        tabIndex="-1"
        id="riwayat"
        aria-labelledby="shortLabel"
        style={{ height: "280px" }}
      >
        <div className="offcanvas-body">
          <div className="grid">
            <div className="g-col-12 px-4 py-3 bg-white">
              <p className="fs-6 mb- fw-bold">Filter</p>
              <div className="container px-0 py-1">
                <div className="row gx-8">
                  <p className="mb-2">Pilih tanggal yang ingin ditampilkan</p>
                  <a href="#" className="col text-decoration-none">
                    <div className="card p-3">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img src="img/kalender.svg" width="50" alt="" />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p id="alternate2" className="mb-1">
                            Tanggal Awal
                          </p>
                          <input
                            id="datepicker1"
                            className="form-control form-control-sm"
                            autoComplete="off"
                            type="date"
                            placeholder="Pilih tanggal"
                            aria-label="example"
                            ref={refInputStart}
                            onChange={() => changeInputStart()}
                            defaultValue={startDate}
                          />
                        </div>
                      </div>
                    </div>
                  </a>
                  <a href="#" className="col text-decoration-none">
                    <div className="card p-3">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img src="img/kalender.svg" width="50" alt="" />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p id="alternate2" className="mb-1">
                            Tanggal Akhir
                          </p>
                          <input
                            id="datepicker2"
                            className="form-control form-control-sm"
                            autoComplete="off"
                            type="date"
                            placeholder="Pilih Tanggal"
                            aria-label="example"
                            ref={refInputEnd}
                            onChange={() => changeInputEnd()}
                            defaultValue={endDate}
                          />
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showDetail}
        onHide={() => setShowDetail(false)}
        centered
        size="lg"
      >
        <Modal.Header className="bg-primary">
          <center>
            <h1 className="modal-title fs-5 text-white" id="diskon">
              Detail Transaksi
            </h1>
          </center>
          <div className="d-inline-block float-end pe-4">
            <div className="float-end align-text-top py-2">
              <img
                src={printIcon}
                width={25}
                className="cursor-pointer"
                onClick={() => {
                  handleClickPrint();
                }}
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="overflow-scroll">
            <div className="card-body border bg-light mx-4 mt-4 py-2">
              <div className="w-100 d-flex  mt-4 px-4 font-14 fw-bold">
                <h6 className="flex-grow-1 text-dark">Detail Transaksi</h6>
                <h6 className="d-flex px-2 flex-grow-2">{detail.no}</h6>
              </div>
              <div className="w-100 d-flex px-4 py-2 font-14 text-muted">
                <p className="flex-grow-1  ">Pembayaran</p>
                <p className="d-flex px-2 flex-grow-2">
                  Rp {commafy(detail.total)}
                </p>
              </div>
              <div className="w-100 d-flex px-4  font-14 text-muted">
                <p className="flex-grow-1  ">Pegawai</p>
                <p className="d-flex px-2 flex-grow-2">
                  {detail.kasir.first_name}
                </p>
              </div>
              <div className="w-100 d-flex px-4 py-2 font-14 text-muted">
                <p className="flex-grow-1  ">Tanggal Transaksi</p>
                <p className="d-flex px-2 flex-grow-2">
                  {commafy(detail.createdAt)}
                </p>
              </div>
              <div className="w-100 d-flex px-4  pb-2 font-14 text-muted">
                <p className="flex-grow-1  ">Member</p>
                <p className="d-flex px-2 flex-grow-2">
                  {/* {detail.buyer.first_name} */}
                  {_.isNull(detail.buyer) ? "-" : detail.buyer.username}
                </p>
              </div>
            </div>

            <div
              className="card-body border bg-light mx-4 mt-4 py-2"
              style={{ borderRadius: "15px" }}
            >
              <div className="w-100 mb-2 px-4 font-14 fw-bold">
                <div className="text-muted float-end m-2">
                  <img
                    className="cursor-pointer"
                    src={editIcon}
                    onClick={() =>
                      history.push({
                        pathname: "/edit-pos",
                        detail: detail,
                        kasir: detail.kasir,
                        total: detail.total,
                        orderedFrom: detail.ordered_from,
                      })
                    }
                  />
                </div>
              </div>
              <div className="w-100 d-flex  mt-4 px-4 font-14 fw-bold">
                <h6 className="flex-grow-1 text-dark">Detail Pembelian</h6>
              </div>
              {detail.transactions_details.map((detail, key) => {
                return (
                  <div className="d-flex align-items-center" key={key}>
                    <div className="flex-grow-1 ms-4 py-2">
                      <h6 className="text-dark fw-light">
                        {detail.cart_grosir.products_grosir.name}
                      </h6>
                      <small className="text-secondary">
                        Rp {commafy(detail.cart_grosir.price)} x
                        {detail.cart_grosir.qty}
                      </small>
                    </div>
                    <div className="flex-grow-2 ">
                      <div className="text-muted px-4 mb-0">
                        <h6 className="px-2">
                          {commafy(
                            detail.cart_grosir.price * detail.cart_grosir.qty
                          )}
                        </h6>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="w-100 d-flex px-4 py-2 mt-3 font-14 text-muted">
                <p className="flex-grow-1  ">Subtotal</p>
                <p className="d-flex px-2 flex-grow-2">
                  Rp {commafy(detail.total)}
                </p>
              </div>
              <div className="w-100 d-flex px-4 font-14 text-muted">
                <p className="flex-grow-1  ">Total</p>
                <p className="d-flex px-2 flex-grow-2">
                  Rp {commafy(detail.total)}
                </p>
              </div>
              <div className="w-100 d-flex px-4 py-2 pb-2 font-14 text-muted">
                <p className="flex-grow-1  ">Uang Diterima</p>
                <p className="d-flex px-2 flex-grow-2">
                  Rp {commafy(detail.cash)}
                </p>
              </div>
              <div className="w-100 d-flex px-4 font-14 text-muted">
                <p className="flex-grow-1  ">Kembalian</p>
                <p className="d-flex px-2 flex-grow-2">
                  Rp {commafy(detail.change)}
                </p>
              </div>
              <div className="w-100 d-flex px-4 font-14 text-muted">
                <p className="flex-grow-1  ">Keuntungan (margin)</p>
                <p className="d-flex px-2 flex-grow-2">
                  Rp {commafy(detail.margin)}
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="border-0"
        show={showPrint}
        size="lg"
        onHide={setShowPrint}
      >
        <Modal.Body>
          <div id="section-to-print" className="font-14 w-100">
            <div className="text-center">Nara Store</div>
            <div className="text-center">
              <span>{detail.alamat_gudang}</span>
            </div>
            <div className="grid border-bottom text-center">
              <div className=""> {detail.no}</div>
              <div className="">{detail.createdAt} </div>
            </div>
            <div className="mt-2 border-bottom pb-1">
              {detail.transactions_details.map((item, key) => {
                return (
                  <React.Fragment key={key}>
                    <Row className="font-14" key={key}>
                      <Col>
                        <span>{item.cart_grosir.products_grosir.name}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <span>
                          {commafy(item.cart_grosir.price.toFixed(0))} x{" "}
                          {commafy(item.cart_grosir.qty)} = Rp{" "}
                          {commafy(item.cart_grosir.total_price.toFixed(0))}
                        </span>
                      </Col>
                    </Row>
                  </React.Fragment>
                );
              })}
            </div>
            <div className="mt-2">
              <span>Total : Rp {commafy(detail.total)}</span>
            </div>
            <div className="mt-2">
              <span>Uang diterima : Rp {commafy(detail.cash)}</span>
            </div>
            <div className="pb-1 border-bottom">
              <span>Kembalian : Rp {commafy(detail.change)}</span>
            </div>
            <div className="text-center mt-2 mx-auto ">
              Order via WA Hubungi 082113831489
            </div>
            <div className="text-center mx-auto">Gratis Antar Sampai Dapur</div>
            <div className="text-center mx-auto">
              Link Daftar Produk & Harga 
            </div>
            <div className="text-center mx-auto">
               https://bit.ly/nara-produk
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default RiwayatTransaksiPos;
