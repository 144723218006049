import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { showImage } from "../../../utils/Utils";
import { Card, Carousel, Col, Nav, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import imgMie3 from "../../../assets/images/indomie3.svg"
import { commafy } from "../../../utils/Utils";

const FilterBeliSekarang = () => {
  const { api } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [gudang, setGudang] = useState([]);
  const [category, setCategory] = useState({});
  const history = useHistory();
  const refInput = useRef();
  const [num, setNum] = useState(1);

  useEffect(() => {}, []);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const param = {
      id: 6
    }
    const data = await api("get", "products-grosir/detail-product", param)
    console.log(data);
    setData(data.data);

    const gudang = await api("get", "gudang/get");
    console.log(gudang);
      setGudang(gudang.data);
    
    
  };

  const changeInput = () => {
    let value = refInput.current.value;
    if (value.length === 4) {
      getData(value);
    }

    if (value.length === 0) {
      getData("");
    }
  };

  const incNum = () =>{
    setNum(num + 1)
  };

  const decNum = () => {
    if(num > 1){
    setNum(num -1)
    }else{
        setNum(1);
    }
  };


  return (
    <React.Fragment>                
        <div className="row justify-content-center sticky-bottom border-top filter">
            <div className="col-md-12"> 
                <div className="grid">
                    <div className="offcanvas offcanvas-bottom g-col-6 rounded-top filter-product" tabIndex="-1" id="beli"
                            aria-labelledby="shortLabel">
                            <div className="offcanvas-body">
                                <div className="grid">
                                    <div className="g-col-12 px-4 py-3 bg-white">
                                        <div className="container px-0 py-1">
                                        <div className="row gx-8">
                                               {data.map((item) => {
                                                return(

                                               <div className="d-inline-block pe-2 w-100  d-flex align-items-center">
                                                       <img
                                                       src={showImage(item.img)}
                                                       alt=""
                                                       onClick={() => history.push("/deskripsi-produk")}
                                                       className="d-inline-block align-text-top w-25"
                                                       />
                                                       <div className="flex-grow-1 ms-3">
                                                            <div className="row">
                                                               <div className="d-flex align-items-center">    
                                                                   <del className="text-secondary fs-6">Rp {commafy(item.price)}</del>
                                                               </div>
                                                            </div>
                                                            <h2 className="text-dark mb-0">Rp {commafy(item.price - item.discount)}</h2>
                                                            <p className="text-dark mb-0 text-muted">{item.weight}g</p>
                                                            <p className="text-dark mb-0 text-muted">Stok:{item.stock}</p>
                                                            <small className="text-dark mb-0 text-muted">{item.name}</small>
                                                            

                                                        </div>   
                                                </div>
                                                )
                                               })}
                                           </div>
                                       </div>
                                        <hr className="mt-4 hr-product "/>

                                        {/* <div className="container px-0 py-3 category">
                                            <div className="row gx-8">
                                                <p className="mb-2 ">Pilih Gudang</p>
                                                <div className="d-inline-flex p-2">
                                        {gudang.map((item) => {
                                        return(
                                                    <div className="col btn btn-outline-primary border rounded-2  me-3 ">
                                                        <a href="#" className=" text-decoration-none text-dark pt-2 d-block">{item.name}</a>
                                                        <center><small className=" d-block pb-2 font-12">jarak 8,3km</small></center>
                                                    </div>
                                                )
                                                })}
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="row">
                                            <div className="col  ms-auto">
                                                <div className="">
                                                    <p className="mb-2">Jumlah</p>
                                                    <button className="col btn border rounded-2   " type="button" onClick={decNum}>-</button>
                                                    <small className="col btn border rounded-2 ">{num}</small>
                                                    <button className="col btn border rounded-2 " type="button" onClick={incNum}>+</button>
                                                </div>
                                            </div>
                                        </div>  
                                        <div className="container px-0 py-4 text-center">
                                            <div className="row gx-8">
                                                <a href="#" className="col text-decoration-none">
                                                    <div className="d-grid">
                                                        <button 
                                                        type="button" 
                                                        className="btn btn-primary btn-lg d-block text-white"
                                                        onClick={() => 
                                                        history.push("/checkout")}
                                                        >Beli Sekarang</button>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      
                    </div>
                </div>
               
            </div>              
                 
    </React.Fragment>
  );
};

export default FilterBeliSekarang;