import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { NotificationManager } from "react-notifications";
import { Button, Spinner } from "react-bootstrap";

const Detailusaha = () => {
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const [token, setToken] = useState("");
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const { api } = useContext(AppContext);

  useEffect(() => {
    // setToken = (otp.data.token)
  }, [history]);

  const onSubmit = async (params) => {
    setLoading(true);
    let paramDetailusaha = {
      token: token,
    };
    let Detailusaha = await api("post", "user/update", params);

    if (Detailusaha.success) {
      history.push({ pathname: "/login" });
    } else NotificationManager.error(Detailusaha.message);

    setLoading(false);
  };
  return (
    <div className="w-100">
      <div className="float-right" style={{ height: "200px" }}>
        <img src="img/bg-top-right.svg" className="float-end"></img>
      </div>
      <div className="text-center px-5">
        <div>
          <img src="img/logo-small.svg"></img>
        </div>
        <div className="mt-3">
          <span className="text-dark font-14">Lengkapi Detail Usaha</span>
        </div>

        <div className="mt-4 w-100">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div class="mb-3">
              <input
                type="text"
                placeholder="Nama Usaha"
                autocomplete="off"
                class="form-control form-control-lg font-14"
                id="exampleInputText1"
                {...register("business_name", {
                  required: "Nama usaha is required",
                })}
              />
              <div className="my-2 text-left float-start font-12 text-danger">
                <ErrorMessage errors={errors} name="business_name" />
              </div>
            </div>

            <div class="mb-3">
              <input
                type="text"
                placeholder="Jenis Usaha"
                autocomplete="off"
                class="form-control form-control-lg font-14"
                id="exampleInputText1"
                {...register("business_type", {
                  required: "jenis usaha is required",
                })}
              />
              <div className="my-2 text-left float-start font-12 text-danger">
                <ErrorMessage errors={errors} name="business_type" />
              </div>
            </div>
            <div class="mb-3">
              <input
                type="Text"
                placeholder="Alamat"
                autocomplete="off"
                class="form-control form-control-lg font-14"
                id="exampleInputText1"
                {...register("address", {
                  required: "Alamat is required",
                })}
              />
              <div className="my-2 text-left float-start font-12 text-danger">
                <ErrorMessage errors={errors} name="address" />
              </div>
            </div>

            <Button
              variant="primary"
              className="button w-100 text-white font-14"
              disabled={isLoading}
              type="submit"
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Selesai"
              )}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Detailusaha;
