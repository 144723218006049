import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal, Col, NavItem, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { useForm } from "react-hook-form";
import { commafy } from "../../utils/Utils";
import printIcon from "../../assets/images/print-icon.svg";

const DetailTransaksi = () => {
  const history = useHistory();
  const { api } = useContext(AppContext);
  const [showPrint, setShowPrint] = useState(false);
  const [id, setId] = useState("");
  const [data, setData] = useState({
    kasir: {},
    buyer: {},
    transactions_details: [],
  });

  useEffect(() => {
    if (!history.location.transaksi) history.push("/riwayat-transaksi-pos");
    else {
      setId(history.location.transaksi.id);
      setData(history.location.transaksi);
    }
  }, [history.location]);

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm">
        <div className="contain px-cst1 pb-2 ps-4 w-100">
          <Row>
            <Col className="align-items-center w-100">
              <div className="d-inline-block">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/riwayat-transaksi-pos")}
                  className="d-inline-block align-text-top "
                />
              </div>

              <div className="d-inline-block ms-4 mt-2">
                <span>Detail Transaksi</span>
              </div>

              <div className="d-inline-block float-end pe-4">
                <div className="float-end align-text-top py-2">
                  <img
                    src={printIcon}
                    width={25}
                    className="cursor-pointer"
                    onClick={() => {
                      setShowPrint(true);
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="card-body border bg-light mx-4 mt-4 py-2">
        <div className="w-100 d-flex  mt-4 px-4 font-14 fw-bold">
          <h6 className="flex-grow-1 text-dark">Detail Transaksi</h6>
          <h6 className="d-flex px-2 flex-grow-2">{data.no}</h6>
        </div>
        <div className="w-100 d-flex px-4 py-2 font-14 text-muted">
          <p className="flex-grow-1  ">Pembayaran</p>
          <p className="d-flex px-2 flex-grow-2">Rp {commafy(data.total)}</p>
        </div>
        <div className="w-100 d-flex px-4  font-14 text-muted">
          <p className="flex-grow-1  ">Pegawai</p>
          <p className="d-flex px-2 flex-grow-2">{data.kasir.first_name}</p>
        </div>
        <div className="w-100 d-flex px-4 py-2 font-14 text-muted">
          <p className="flex-grow-1  ">Tanggal Transaksi</p>
          <p className="d-flex px-2 flex-grow-2">{commafy(data.createdAt)}</p>
        </div>
        <div className="w-100 d-flex px-4  pb-2 font-14 text-muted">
          <p className="flex-grow-1  ">Pelanggan</p>
          <p className="d-flex px-2 flex-grow-2">{data.buyer.first_name}</p>
        </div>
      </div>

      <div
        className="card-body border bg-light mx-4 mt-4 py-2"
        style={{ borderRadius: "15px" }}
      >
        <div className="w-100 d-flex  mt-4 px-4 font-14 fw-bold">
          <h6 className="flex-grow-1 text-dark">Detail Pembelian</h6>
        </div>
        {data.transactions_details.map((detail, key) => {
          return (
            <div className="d-flex align-items-center" key={key}>
              <div className="flex-grow-1 ms-4 py-2">
                <h6 className="text-dark fw-light">
                  {detail.cart_grosir.products_grosir.name}
                </h6>
                <small className="text-secondary">
                  Rp {commafy(detail.cart_grosir.price)} x
                  {detail.cart_grosir.qty}
                </small>
              </div>
              <div className="flex-grow-2 ">
                <div className="text-muted px-4 mb-0">
                  <h6 className="px-2">
                    {commafy(detail.cart_grosir.price * detail.cart_grosir.qty)}
                  </h6>
                </div>
              </div>
            </div>
          );
        })}
        <div className="w-100 d-flex px-4 py-2 mt-3 font-14 text-muted">
          <p className="flex-grow-1  ">Subtotal</p>
          <p className="d-flex px-2 flex-grow-2">Rp {commafy(data.total)}</p>
        </div>
        <div className="w-100 d-flex px-4 font-14 text-muted">
          <p className="flex-grow-1  ">Total</p>
          <p className="d-flex px-2 flex-grow-2">Rp {commafy(data.total)}</p>
        </div>
        <div className="w-100 d-flex px-4 py-2 pb-2 font-14 text-muted">
          <p className="flex-grow-1  ">Uang Diterima</p>
          <p className="d-flex px-2 flex-grow-2">Rp {commafy(data.cash)}</p>
        </div>
        <div className="w-100 d-flex px-4 font-14 text-muted">
          <p className="flex-grow-1  ">Kembalian</p>
          <p className="d-flex px-2 flex-grow-2">Rp {commafy(data.change)}</p>
        </div>
      </div>

      <Modal
        className="border-0"
        show={showPrint}
        size="lg"
        onHide={setShowPrint}
      >
        <Modal.Body>
          <div id="section-to-print" className="font-14 w-100">
            <div className="text-center">Nara Store</div>
            <div className="text-center">
              <span>{data.alamat_gudang}</span>
            </div>
            <div className="grid border-bottom text-center"> 
              <div className=""> {data.no}</div>
              <div className="">{data.createdAt} </div>
            </div>
            <div className="mt-2 border-bottom">
              {data.transactions_details.map((item, key) => {
                return (
                  <React.Fragment>
                    <Row className="font-14" key={key}>
                      <Col>
                        <span>{item.cart_grosir.products_grosir.name}</span>
                      </Col>
                    </Row>
                    <Row >
                      <Col>
                        <span>
                          {commafy(item.cart_grosir.price.toFixed(0))} x {commafy(item.cart_grosir.qty)}{" "}
                          = Rp {commafy((item.cart_grosir.total_price).toFixed(0))}
                        </span>
                      </Col>
                    </Row>
                  </React.Fragment>
                );
              })}
            </div>
            <div className="mt-2">
              <span>Total : Rp  {commafy(data.total)}</span>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default DetailTransaksi;
