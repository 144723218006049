import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { commafy, showImage } from "../../utils/Utils";
import { Card, Carousel, Col, Nav, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const PopupRincian = ({total}) => {
  const { api } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [category, setCategory] = useState([]);
  const [product, setProduct] = useState([]);
  const history = useHistory();
  const refInput = useRef();

  useEffect(() => {
    // getData();
  }, []);

  return (
    <React.Fragment>
      <div className="row sticky-bottom rincian">
        <div className="col-md-6">
          <div className="grid">
            <div
              className="offcanvas offcanvas-bottom g-col-6 rounded-top rincian-product"
              tabIndex="-1"
              id="short"
              aria-labelledby="shortLabel"
            ></div>

            <div
              className="offcanvas offcanvas-bottom g-col-6 rounded-top rincian-product"
              tabIndex="-1"
              id="rincian"
              aria-labelledby="shortLabel"
            >
              <div className="offcanvas-body">
                <div className="grid">
                  <div className="mx-3">
                    <h6>Rincian Belanja</h6>
                  </div>
                  <div className="w-100 d-flex px-3 font-14 mt-3">
                    <div className="flex-grow-1">Total belanja</div>
                    <div className="d-flex flex-grow-2">Rp {commafy(total)}</div>
                  </div>
                  <div className="w-100 d-flex px-3 font-14 mt-3">
                    <div className="flex-grow-1">diskon produk</div>
                    <div className="d-flex flex-grow-2">
                      <del> Rp -</del>
                    </div>
                  </div>
                  <div className="w-100 d-flex px-3 font-14 mt-3">
                    <div className="flex-grow-1">Hemat</div>
                    <div className="d-flex flex-grow-2 text-warning">Rp -</div>
                  </div>
                  <div className="w-100 d-flex px-3 font-14 my-3">
                    <div className="flex-grow-1">Jumlah total</div>
                    <div className="d-flex flex-grow-2">Rp -</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PopupRincian;