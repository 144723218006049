import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { showImage } from "../../utils/Utils";
import { Card, Carousel, Col, Nav, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import filterbiru from "../../assets/images/filterbiru.svg";
import love from "../../assets/images/love.svg";
import birupanah from "../../assets/images/panahbiru.svg";
import shop from "../../assets/images/shop.svg";
// import FilterProduct from "./FilterProduct";
import _ from "lodash";
import { commafy } from "../../utils/Utils";
import FilterLaporan from "./FilterLaporan";

const LaporanNeraca = () => {
  const { api } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [childData, setChildData] = useState([]);
  const [type, setType] = useState([]);
  const [category, setCategory] = useState([]);
  const [profile, setProfile] = useState([]);
  const history = useHistory();
  const refInput = useRef();
  const { setSpinner } = useContext(AppContext);

  useEffect(() => {
    getData();
  }, []);

  const getData = async() => {
    const dataNeraca = await api("get", "cash-account/get-for-neraca");
    setData(dataNeraca.data);

    const childDataNeraca = await api("get", "cash-account/get-for-child-neraca");
    setChildData(childDataNeraca.data);
  }

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-2">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/dashboard")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ml-2">
                <span className="ml-3">Laporan Neraca</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div
        className="card-body border bg-light mx-4 mt-3"
        style={{ borderRadius: "10px" }}
      >
        <div className="d-flex align-items-center">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 px-3 pt-3 ">
                <h5 className="font-15">Aset</h5>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-1 hr-product" />
        <div className=" d-flex px-3  font-14 mt-3">
          <div className="flex-grow-1">
            <h6 className="text-dark ">Aset Lancar</h6>
          </div>
        </div>
        <div className="ms-4">
          <div className="d-flex px-3 ">
            <div className="flex-grow-1">
              <h6 className="text-secondary">Bank</h6>
            </div>
          </div>
          <div className="ms-4">
            <div className="d-flex px-3  ">
              <div className="flex-grow-1">
                <h6 className="text-secondary">BCA</h6>
              </div>
              <div className="d-flex flex-grow-2">
                <h6
                  className="text-secondary"
                >
                  Rp {commafy(_.filter(childData, { 'name': "BCA"})[0]?.nominal)}
                </h6>
              </div>
            </div>
            <div className="d-flex px-3  ">
              <div className="flex-grow-1">
                <h6 className="text-secondary">Mandiri</h6>
              </div>
              <div className="d-flex flex-grow-2">
                <h6
                  className="text-secondary"
                >
                  Rp {commafy(_.filter(childData, { 'name': "Mandiri"})[0]?.nominal)}
                </h6>
              </div>
            </div>
          </div>
          <div className="d-flex px-3 ">
            <div className="flex-grow-1">
              <h6 className="text-secondary">Marketplace</h6>
            </div>
          </div>
          <div className="ms-4">
            <div className="d-flex px-3  ">
              <div className="flex-grow-1">
                <h6 className="text-secondary">Tokopedia</h6>
              </div>
              <div className="d-flex flex-grow-2">
                <h6
                  className="text-secondary"
                >
                  Rp {commafy(_.filter(childData, { 'name': "Tokopedia"})[0]?.nominal)}
                </h6>
              </div>
            </div>
            <div className="d-flex px-3  ">
              <div className="flex-grow-1">
                <h6 className="text-secondary">Shopee</h6>
              </div>
              <div className="d-flex flex-grow-2">
                <h6
                  className="text-secondary"
                >
                  Rp {commafy(_.filter(childData, { 'name': "Shopee"})[0]?.nominal)}
                </h6>
              </div>
            </div>
          </div>
          <div className="d-flex px-3  ">
            <div className="flex-grow-1">
              <h6 className="text-secondary">Kas</h6>
            </div>
            <div className="d-flex flex-grow-2">
              <h6
                className="text-secondary"
              >
                Rp {commafy(_.filter(childData, { 'name': "Kas"})[0]?.nominal)}
              </h6>
            </div>
          </div>
          <div className="d-flex px-3  ">
            <div className="flex-grow-1">
              <h6 className="text-secondary">Piutang</h6>
            </div>
            <div className="d-flex flex-grow-2">
              <h6
                className="text-secondary"
              >
                  Rp {commafy(_.filter(childData, { 'name': "Piutang"})[0]?.nominal)}
              </h6>
            </div>
          </div>
          <div className="d-flex px-3  ">
            <div className="flex-grow-1">
              <h6 className="text-secondary">Persedian</h6>
            </div>
            <div className="d-flex flex-grow-2">
              <h6
                className="text-secondary"
              >
                  Rp {commafy(_.filter(childData, { 'name': "Persediaan"})[0]?.nominal.toFixed(0))}
              </h6>
            </div>
          </div>
        </div>
        <div className="d-flex px-3 font-14 py-2 mb-1">
          <div className="flex-grow-1">
            <h6 className="text-dark">Total Aset Lancar</h6>
          </div>
          <div className="d-flex flex-grow-2">
            <h6 className="text-dark">Rp {commafy(data.total_aset_lancar_persediaan)}</h6>
          </div>
        </div>

        <div className=" d-flex px-3 font-14 mt-3">
          <div className="flex-grow-1">
            <h6 className="text-dark ">Aset Tetap</h6>
          </div>
        </div>
        <div className="ms-4">
          <div className="d-flex px-3  ">
            <div className="flex-grow-1">
              <h6 className="text-secondary">Bangunan</h6>
            </div>
            <div className="d-flex flex-grow-2">
              <h6
                className="text-secondary"
              >
                  Rp {commafy(_.filter(childData, { 'name': "Bangunan"})[0]?.nominal)}
              </h6>
            </div>
          </div>
          <div className="d-flex px-3  ">
            <div className="flex-grow-1">
              <h6 className="text-secondary">Kendaraan</h6>
            </div>
            <div className="d-flex flex-grow-2">
              <h6
                className="text-secondary"
              >
                  Rp {commafy(_.filter(childData, { 'name': "Kendaraan"})[0]?.nominal)}
              </h6>
            </div>
          </div>
          <div className="d-flex px-3  ">
            <div className="flex-grow-1">
              <h6 className="text-secondary">Perlengkapan Kantor</h6>
            </div>
            <div className="d-flex flex-grow-2">
              <h6
                className="text-secondary"
              >
                  Rp {commafy(_.filter(childData, { 'name': "Perlengkapan Kantor"})[0]?.nominal)}
              </h6>
            </div>
          </div>
        </div>
        <div className="d-flex px-3 font-14 py-2 mb-1">
          <div className="flex-grow-1">
            <h6 className="text-dark">Total Aset Tetap</h6>
          </div>
          <div className="d-flex flex-grow-2">
            <h6 className="text-dark">Rp {commafy(data.total_aset_tetap)}</h6>
          </div>
        </div>
        <hr className="mt-1 hr-product" />
        <div className="d-flex px-3 font-14 py-2 mb-1">
          <div className="flex-grow-1">
            <h6 className="text-dark">Total Aset</h6>
          </div>
          <div className="d-flex flex-grow-2">
            <h6 className="text-dark">Rp {commafy(data.total_aset)}</h6>
          </div>
        </div>
      </div>

      <div
        className="card-body border bg-light mx-4 mt-3"
        style={{ borderRadius: "10px" }}
      >
        <div className="d-flex align-items-center">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 px-3 pt-3 ">
                <h5 className="font-15">Liabilitas & Modal</h5>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-1 hr-product" />
        <div className=" d-flex px-3  font-14 mt-3">
          <div className="flex-grow-1">
            <h6 className="text-dark ">Liabilitas Jangka Pendek</h6>
          </div>
        </div>
        <div className="ms-4">
          <div className="d-flex px-3">
            <div className="flex-grow-1">
              <h6 className="text-secondary">Hutang Dagang</h6>
            </div>
            <div className="d-flex flex-grow-2">
              <h6
                className="text-secondary"
              >
                  Rp {commafy(_.filter(childData, { 'name': "Hutang Dagang"})[0]?.nominal)}
              </h6>
            </div>
          </div>
          <div className="d-flex px-3">
            <div className="flex-grow-1">
              <h6 className="text-secondary">Hutang Gaji</h6>
            </div>
            <div className="d-flex flex-grow-2">
              <h6
                className="text-secondary"
              >
                  Rp {commafy(_.filter(childData, { 'name': "Hutang Gaji"})[0]?.nominal)}
              </h6>
            </div>
          </div>
          <div className="d-flex px-3">
            <div className="flex-grow-1">
              <h6 className="text-secondary">Hutang Kantor</h6>
            </div>
            <div className="d-flex flex-grow-2">
              <h6
                className="text-secondary"
              >
                  Rp {commafy(_.filter(childData, { 'name': "Hutang Kantor"})[0]?.nominal)}
              </h6>
            </div>
          </div>
        </div>
        <div className="d-flex px-3 font-14 py-2 mb-1">
          <div className="flex-grow-1">
            <h6 className="text-dark">Total Liabilitas Jangka Pendek</h6>
          </div>
          <div className="d-flex flex-grow-2">
            <h6 className="text-dark">Rp {commafy(data.total_liabilitas_jangka_pendek)}</h6>
          </div>
        </div>

        <div className=" d-flex px-3  font-14 mt-3">
          <div className="flex-grow-1">
            <h6 className="text-dark ">Modal</h6>
          </div>
        </div>
        <div className="ms-4">
          <div className="d-flex px-3  ">
            <div className="flex-grow-1">
              <h6 className="text-secondary">Modal Pemilik</h6>
            </div>
            <div className="d-flex flex-grow-2">
              <h6
                className="text-secondary"
              >
                  Rp {commafy(_.filter(childData, { 'name': "Modal Pemilik"})[0]?.nominal)}
              </h6>
            </div>
          </div>
          <div className="d-flex px-3  ">
            <div className="flex-grow-1">
              <h6 className="text-dark ">Laba Ditahan</h6>
            </div>
          </div>
          <div className="ms-4">
            <div className="d-flex px-3  ">
              <div className="flex-grow-1">
                <h6 className="text-secondary">Keuntungan periode ini</h6>
              </div>
              <div className="d-flex flex-grow-2">
                <h6
                  className="text-secondary"
                >
                  Rp {commafy(data.keuntungan_periode_ini)}
                </h6>
              </div>
            </div>
            <div className="d-flex px-3  ">
              <div className="flex-grow-1">
                <h6 className="text-secondary">
                  Keuntungan sampai periode lalu
                </h6>
              </div>
              <div className="d-flex flex-grow-2">
                <h6
                  className="text-secondary"
                >
                  Rp {commafy(data.keuntungan_sampai_periode_lalu)}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex px-3 font-14 py-2 mb-1">
          <div className="flex-grow-1">
            <h6 className="text-dark">Total Modal</h6>
          </div>
          <div className="d-flex flex-grow-2">
            <h6 className="text-dark">Rp {commafy(parseFloat(data.total_modal) + data.keuntungan_periode_ini + data.keuntungan_sampai_periode_lalu)}</h6>
          </div>
        </div>
        <hr className="mt-1 hr-product" />
        <div className="d-flex px-3 font-14 py-2 mb-1">
          <div className="flex-grow-1">
            <h6 className="text-dark">Total Liabilitas & Modal</h6>
          </div>
          <div className="d-flex flex-grow-2">
            <h6 className="text-dark">Rp {commafy(data.total_liabilitas_modal)}</h6>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LaporanNeraca;
