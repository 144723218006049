import React, { useContext, useEffect, useState, useRef } from "react";
import { Row, Offcanvas, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { showImage } from "../../utils/Utils";
import DesProduk from "../../pages/Grosir/deskripsi-produk/DesProduk";
import moment from "moment";
import { useForm } from "react-hook-form";
import { AppContext } from "../../context/AppContext";
import { NotificationManager } from "react-notifications";
moment.locale("id");

const DeskripsiRewardProduk = () => {
  const { api } = useContext(AppContext);
  const history = useHistory();
  useEffect(() => {}, []);
  const [product, setProduct] = useState([]);
  const [description, setDescription] = useState([]);
  let refDiv = useRef(null);
  const [show, setShow] = useState(false);
  const [showPrice, setShowPrice] = useState(false);
  const [rewardPoint, setRewardPoint] = useState(0);
  const [member, setMember] = useState({});
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    let localDataMember = JSON.parse(localStorage.getItem("member"));
    getDataMember(localDataMember.username);
    if (localDataMember == null) {
      history.push("/tukar-points");
    } else {
      console.log("Data Member masih ada : " + member);
    }
    if (!history.location.product) history.push("/grosir-home");
    else {
      setProduct(history.location.product);
      setRewardPoint(history.location.rewardPoint);
      setDescription(history.location.product.description);
    }
  }, []);

  const getDataMember = async (username) => {
    let data = await api("post", "member/findMember", { username });
    setMember(data.data);
  };

  const onSubmit = async (params) => {
    setLoading(true);
    params = {
      member_id: member.id,
      reward_point_id: rewardPoint.id,
    };
    let confirm = await api("post", "point-redeem-history/store", params);
    if (confirm.success === true) {
      NotificationManager.success(confirm.message, "Sukses");
    } else {
      NotificationManager.error(confirm.message, "Gagal");
    }
    getDataMember(member.username);
    setLoading(false);
  };

  return (
    <React.Fragment>
      <div>
        <div className="bg-lime" ref={refDiv}>
          <div className="p-2 px-2 pb-2 ps-4">
            <Row>
              <Col className="align-items-center d-flex cursor-pointer me-2">
                <div className="d-inline-block pe-2">
                  <img
                    src="img/arrow-left.svg"
                    alt=""
                    width="30"
                    height="24"
                    onClick={() => history.push("/tukar-point")}
                    className="d-inline-block align-text-top "
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <Carousel style={{ minHeight: "250px" }}>
              <Carousel.Item className="w-100 d-flex justify-content-center align-items-center">
                <img style={{ width: "250px" }} src={showImage(product.img)} />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>

        <div className="container pt-3 bg-white">
          <div className="row">
            <div className="text-center">
              <h4>{product.name}</h4>
              <h6 className="text-muted">
                Periode Penukaran sampai tanggal :{" "}
                {moment(rewardPoint.expired_at).format("DD MMMM YYYY")}
              </h6>
              <button className="btn btn-primary text-white">
                Jumlah Poin Kamu : {member.points}
              </button>
              {/* <p class="">{item.description.split(".")[0]}</p> */}
              {/* <div
                dangerouslySetInnerHTML={{
                  __html: product.description,
                }}
              /> */}
            </div>
          </div>
        </div>

        <div
          className="grid riwayat bg-white mt-3"
          style={{ minHeight: "300px" }}
        >
          <div className="g-col-6 content">
            <ul
              className="nav nav-pills nav-fill mb-2 "
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item text-muted" role="presentation">
                <button
                  className="nav-link active py-3"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Deskripsi Produk
                </button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <DesProduk description={description} />
            </div>
          </div>
        </div>
        <div className="bg-primary p-2 mx-0 sticky-bottom d-flex justify-content-between align-items-center">
          <div className="p-2 btn btn-light text-primary text-light fw-bold  ">
            {rewardPoint.point} Point
          </div>
          <button className="btn btn-light fw-bold" onClick={handleShow}>
            Tukarkan Poin
          </button>
        </div>
        <div
          className="bg-primary p-2 mx-0 sticky-bottom"
          hidden={product.stock === 0 ? false : true}
        >
          <div className="p-2 text-light">
            Maaf stok produk habis, silahkan pilih produk lainnya.
          </div>
        </div>
      </div>

      <div className="row justify-content-center sticky-bottom border-top filter">
        <div className="col-md-12">
          <Offcanvas
            show={show}
            placement="bottom"
            onHide={() => setShow(false)}
            className="rounded-top detail-product"
            style={{ height: "55vh" }}
          >
            <Offcanvas.Body>
              <div className="fw-bold text fs-5 border-bottom mb-3">
                <p>Konfirmasi Penukaran Point</p>
              </div>
              <div className="fw-bold border border-3 p-2 rounded">
                <p className="mb-0">{product.name}</p>
                <p className="text-muted mb-0">Sisa Stock : {product.stock}</p>
                <p className="mb-0">{rewardPoint.point} Poin</p>
              </div>
              <div className="text-dark fw-bold mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <p className="mb-0">Poin Kamu</p>
                  <p className="mb-0">{member.points} Poin</p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p>Poin Terpakai</p>
                  <p>{rewardPoint.point} Poin</p>
                </div>
              </div>
              <hr className="m-0 mb-3" />
              <div className="text-dark fw-bold mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <p>Sisa Poin Kamu</p>
                  <p
                    className={`${
                      member.points - rewardPoint.point < 0
                        ? "text-danger"
                        : "text-primary"
                    }`}
                  >
                    {member.points - rewardPoint.point} Poin
                  </p>
                </div>
                <div className="d-flex justify-content-between gap-4">
                  <button
                    className="btn btn-outline-primary rounded-pill w-50"
                    onClick={() => {
                      setShow(false);
                    }}
                  >
                    Cancel
                  </button>
                  <form className="w-50" onSubmit={handleSubmit(onSubmit)}>
                    <button
                      type="submit"
                      className="btn btn-primary text-white rounded-pill w-100"
                    >
                      {loading ? "Loading..." : "Konfirmasi"}
                    </button>
                  </form>
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </div>
    </React.Fragment>
  );
};
export default DeskripsiRewardProduk;
