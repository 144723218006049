import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import ImgBg from "../../assets/images/bg-top.svg";
import { useHistory } from "react-router-dom";
import { showImage } from "../../utils/Utils";
import { commafy } from "../../utils/Utils";
import { AppContext } from "../../context/AppContext";
import Info from "../../assets/images/icon-info.svg";
import trash from "../../assets/images/trash-icon.svg";
import order from "../../assets/images/order-kontak.svg";
import promo from "../../assets/images/order-promo.svg";
import popup from "../../assets/images/popup-icon.svg";
import moment from "moment";
import { NotificationManager } from "react-notifications";

const TransaksiInv = () => {
  const { api, setSpinner } = useContext(AppContext);
  const history = useHistory();
  const [data, setData] = useState([]);
  const [profile, setProfile] = useState({});
  const [diskon, setDiskon] = useState([]);
  const [pelanggan, setPelanggan] = useState();
  const [diskonselected, setDiskonselected] = useState(0);
  const [diskonname, setDiskonname] = useState();
  const [grosir, setGrosir] = useState([]);
  const [user, setUser] = useState([]);
  const refInput = useRef();
  const [date, setDate] = useState(moment().format("dddd, DD MMMM  yyy"));
  const [isLoading, setLoading] = useState(false);
  const [local, setLocal] = useState(null);
  const [num, setNum] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalakhir, setTotalakhir] = useState(0);
  const [keyword, setKeyword] = useState({});

  useEffect(() => {
    getData();
    getprofile();
  }, []);

  useEffect(() => {
    let localData = localStorage.getItem("useranarastore");
    if (localData !== null) setLocal(JSON.parse(localData));
    if (localData !== null) {
      setLocal(JSON.parse(localData));
    }

    let isSelectedAxis = false;
    let price = 0;
    data.map((item) => {
      price = parseInt(price) + item.qty * parseInt(item.products_grosir.price);
      isSelectedAxis = true;
      setTotal(price);
    });
    setTotalakhir(total - (total * diskonselected) / 100);
    console.log(totalakhir);
  }, [data, total]);

  const changeInput = async () => {
    let value = refInput.current.value;
    setKeyword({ name: value });

    const newUser = await api("get", "user/get-for-admin", keyword);
    setUser(newUser.data);
  };

  const getData = async () => {
    let paramcart = {
      source: "Inventory",
    };
    let data = await api("get", "cart-grosir/get-by-user", paramcart);
    setData(data.data);
    let paramcount = {
      source: "Inventory",
    };
    console.log(data);
    const count_cart = await api(
      "get",
      "cart-grosir/count-by-user",
      paramcount
    );

    setGrosir(count_cart.data);

    const diskon = await api("get", "data-discount/get");
    setDiskon(diskon.data);

    const newUser = await api("get", "user/get-for-admin", keyword);
    setUser(newUser.data);
  };

  const getprofile = async () => {
    let profile = await api("get", "user/profile");
    setProfile(profile.data);
  };

  const delData = async (item) => {
    let paramdel = { id: item.id };
    let del = await api("post", "cart-grosir/delete", paramdel);

    if (del.success) {
      NotificationManager.success(del.message);
    }
  };

  const incrementDecrement = async (param, isInc) => {
    let params = { qty: 0, id: 0 };
    let newData = data.map((item) => {
      if (item.qty === 1 && !isInc) return item;
      if (item.qty >= item.products_grosir.stock && isInc) return item;
      if (item.id === param.id) {
        item.qty = isInc ? item.qty + 1 : item.qty - 1;
        params.qty = item.qty;
        params.id = item.id;
      }
      return item;
      
    });
    if (params.qty === 0) return; 
    setSpinner(true);
    let update = await api("post", "cart-grosir/update", params);
    if (update.success) setData(newData);
    else NotificationManager.error(update.message);
    setSpinner(false);
  };

  const handleClickDiscount = async (item) => {
    setDiskonselected(item.percentage);
    setDiskonname(item.name);
  };

  const handleClickPelanggan = async (item) => {
    setPelanggan(item.first_name);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-2">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/transaksi-inventory")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>

              <div className="d-inline-block ml-2">
                <span className="ml-3">Pesanan</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <div className="bg-primary">
        <div
          className=" row p-2 me-1 mx-1"
          style={{ backgroundColor: "#DCF4F4" }}
        >
          <h6 className="text-dark mb-3 mt-2">{date}</h6>
          <div className="d-flex align-items-center">
            <div className="mb-0 pt-1"></div>

            <div className="flex-shrink-0 ">
              <span> Admin | {profile.first_name} </span>
            </div>

            <div className="flex-grow-1 ms-3"></div>
            <div className="flex-grow-2 ms-3">
              {" "}
              {grosir.map((item, key) => {
                return (
                  <p className="text-dark mb-0 pt-1" key={key}>
                    Jumlah Produk : {item.count}
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="container px-4 mt-4 border-none">
        <br />
        <div className="row">
          {data.map((item, key) => {
            return (
              <div className="col-mb-12">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 ms-3 mt-3 ">
                    <div className="d-flex" key={key}>
                      <h6
                        className="mb-0 text-dark date-time"
                        onClick={() =>
                          history.push({
                            pathname: "/tambah-jumlah-inventori",
                            cart: item,
                          })
                        }
                      >
                        {" "}
                        {item.products_grosir["name"]}
                      </h6>
                      <h4 className="ms-auto mb-0 text-warning">
                        <img
                          src={trash}
                          width="25"
                          onClick={() => delData(item)}
                        />
                      </h4>
                    </div>
                    <div className="d-flex">
                      <span className="mb-0 align-middle text-secondary">
                        Rp {commafy(item.products_grosir["price"])}
                      </span>
                      <div
                        className="input-group ms-auto mb-0 "
                        style={{ width: "126px" }}
                      >
                        <button
                          className="col btn border rounded-left   "
                          type="submit"
                          onClick={() => incrementDecrement(item, false)}
                        >
                          -
                        </button>
                        <small className="col btn border ">{item.qty}</small>
                        <button
                          className="col btn border rounded-right "
                          type="submit"
                          onClick={() => incrementDecrement(item, true)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    
     
     
      <div className="row justify-content-center sticky-bottom  order">
        <div className="col-md-12">
          <div className="grid">
            <div
              className="offcanvas offcanvas-bottom g-col-6 rounded-top order-product"
              tabIndex="-1"
              id="short"
              aria-labelledby="shortLabel"
              
            ></div>

            <div
              className="offcanvas offcanvas-bottom g-col-6 rounded-top order-product"
              tabIndex="-1"
              id="order"
              aria-labelledby="shortLabel"
              style={{height : "350px"}}
            >
              <div className="offcanvas-body">
                <div className="grid">
                <div className="w-100 d-flex px-3 font-14 mt-3">
                    <h6 className="flex-grow-1">Luwak White Coffe</h6>
                    <h6 className="d-flex flex-grow-2"> 2 dus </h6>
                  </div>
                  <div className="w-100 d-flex px-3 font-14">
                    <small className="flex-grow-1 text-muted">Stok awal 10 dus</small>
                    <small className="d-flex flex-grow-2"> Stok akhir 8 duss </small>
                  </div>
                  <div className="w-100 d-flex px-3 font-14 mt-3">
                    <h6 className="flex-grow-1">Indomie Goreng Hot & Spicy</h6>
                    <h6 className="d-flex flex-grow-2"> 2 dus </h6>
                  </div>
                  <div className="w-100 d-flex px-3 font-14">
                    <small className="flex-grow-1 text-muted">Stok awal 10 dus</small>
                    <small className="d-flex flex-grow-2"> Stok akhir 8 duss </small>
                  </div> 
                  <div className="w-100 d-flex px-3 font-14 mt-3">
                    <h6 className="flex-grow-1">Teh Celup Sariwangi</h6>
                    <h6 className="d-flex flex-grow-2"> 2 dus </h6>
                  </div>
                  <div className="w-100 d-flex px-3 font-14">
                    <small className="flex-grow-1 text-muted">Stok awal 10 dus</small>
                    <small className="d-flex flex-grow-2"> Stok akhir 8 duss </small>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sticky-bottom bg-white mt-5 ">
        <div className=" py-2 px-0  d-flex">
          <div className="px-4 py-2 flex-grow-1">
            <div className="mt-1 text-dark ">
              <div
                data-bs-toggle="offcanvas"
                data-bs-target="#order"
                aria-controls="order"
              >
                <span>Total</span>
                <img
                  className="mx-3"
                  src={popup}
                  // onClick={() => totalBayar()}
                />
              </div>
            </div>
          </div>
          <div className="px-4 py-2 flex-grow-2">
            <h6>Rp {commafy(total - (total * diskonselected) / 100)} </h6>
          </div>
        </div>
        <div className="d-flex align-items-center flex-grow-1 ms-1">
          <div className="flex-shrink-0">
            <img
              src={order}
              width="50"
              alt=""
              className="ms-3"
            />
          </div>
          <div className="flex-grow-2 ms-3">
            <div className="text-muted mb-0 me-4">
                  <h6 className="btn btn-primary text-white mb-0 mx-3" 
                  style={{width : "730px"}}
                   onClick={() =>
                    history.push({
                      pathname: "/sukses-pembayaran-inventori"
                    })
                  }>
                    Proses 3 Barang
                  </h6>
            </div>
          </div>
        
        </div>

        <br />
      </div>
      
      
    </React.Fragment>
  );
};
export default TransaksiInv;
