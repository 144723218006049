import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import ImgBg from "../../assets/images/bg-top.svg";
import { useHistory } from "react-router-dom";
import { commafy } from "../../utils/Utils";
import { AppContext } from "../../context/AppContext";

const Payment = () => {
  const history = useHistory();
  const [product, setProduct] = useState({});
  const [category, setCategory] = useState({});
  const [brand, setBrand] = useState({});
  const [input, setInput] = useState("");
  const [isLoading, setLoading] = useState(false);
  const { api } = useContext(AppContext);
  const [saldo, setSaldo] = useState("");

  useEffect(() => {
    if (!history.location.product) history.push("/products");
    else {
      setProduct(history.location.product);
      setCategory(history.location.category);
      setBrand(history.location.brand);
      setInput(history.location.input);
    }
    getSaldo()

  }, []);

  const getSaldo = async()=>{
    let saldo = await api("get", "saldo-history/get-saldo");
    setSaldo(saldo.data);
  }

  const handleClick = async () => {
    setLoading(true)
    let param = { tujuan: input, code: product.code };
    let data = await api("post", "transaction/inquiry", param);
    if(data.success){
      history.push({pathname:"/status-payment", data:data.data})
    }
    setLoading(false);
   
  }

  return (
    <React.Fragment>
      <div className="w-100 min-vh-100">
        <div style={{ backgroundImage: `url(${ImgBg})` }}>
          <div className="navbar bg-transparent cursor-pointer">
            <div className="p-2 px-cst1 pb-2 ps-4">
              <Row>
                <Col className="d-flex">
                  <div
                    className="d-inline-block pe-2"
                    onClick={() =>
                      history.push({
                        pathname: "/products",
                        brand: brand,
                        category: category,
                        input: input,
                      })
                    }
                  >
                    <img
                      src="img/arrow-left-white.svg"
                      alt=""
                      width="30"
                      height="24"
                      className="d-inline-block align-text-top "
                    />
                  </div>

                  <div className="d-inline-block ml-2">
                    <span className="ml-3 text-white">Pembayaran</span>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="w-100 text-white text-center py-3">
            <div className="font-14">
              <span>Saldo</span>
            </div>
            <div className="font-32">
              <span>Rp {saldo.nominal?commafy(saldo.nominal):0}</span>
            </div>
          </div>
        </div>

        <div className="card border-0 shadow-sm rounded m-4">
          <div className="card-header border-0 bg-white py-3">
            <p className="mb-0 fs-6 font-16 text-primary">{category.name}</p>
          </div>

          <div className="w-100 d-flex px-3 font-14">
            <div className="flex-grow-1">Nomor Hp</div>
            <div className="d-flex flex-grow-2">{input}</div>
          </div>
          <div className="w-100 d-flex px-3 font-14 mt-3">
            <div className="flex-grow-1">Kode Produk</div>
            <div className="d-flex flex-grow-2">{product.code}</div>
          </div>
          <div className="w-100 d-flex px-3 font-14 mt-3">
            <div className="flex-grow-1">Produk</div>
            <div className="d-flex flex-grow-2">{product.name}</div>
          </div>
          <div className="w-100 d-flex px-3 font-14 mt-3">
            <div className="hr w-100"></div>
          </div>

          <div className="card-header border-0 bg-white py-3">
            <p className="mb-0 fs-6 font-16 text-primary">Detail Pembayaran</p>
          </div>
          <div className="w-100 d-flex px-3 font-14 mt-1">
            <div className="flex-grow-1">Metode Pembayaran</div>
            <div className="d-flex flex-grow-2">Saldo</div>
          </div>
          <div className="w-100 d-flex px-3 font-14 mt-3 ">
            <div className="flex-grow-1">Harga</div>
            <div className="d-flex flex-grow-2">
              Rp {commafy(product.price)}
            </div>
          </div>
          <div className="w-100 d-flex px-3 font-14 mt-3 ">
            <div className="flex-grow-1">Biaya Admin</div>
            <div className="d-flex flex-grow-2">0</div>
          </div>
          <div className="w-100 d-flex px-3 font-14 my-3 ">
            <div className="flex-grow-1">Total harga</div>
            <div className="d-flex flex-grow-2">
              Rp {commafy(product.price)}
            </div>
          </div>
        </div>

        <div className="card border-0 shadow-sm rounded m-4">
          {/* <div className="p-3">Image Perhatian</div> */}
        </div>

        <div className="sticky-bottom bg-white">
          <div className="border-top py-2 px-0  d-flex">
            <div className="px-4 py-2 flex-grow-1">
              <div>
                <span>Total Bayar</span>
              </div>
              <div className="mt-1 text-primary ">
                <span>Rp {commafy(product.price)}</span>
              </div>
            </div>
            <div className="px-4 py-2 flex-grow-2">
             <Button
              variant="primary"
              className="button w-100 text-white font-16 btn btn-primary btn-lg"
              disabled={isLoading}
              onClick={()=>handleClick()}
              type="submit"
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Bayar"
              )}
            </Button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Payment;
