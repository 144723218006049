import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { NotificationManager } from "react-notifications";

const TambahPajak = () => {
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const { api } = useContext(AppContext);

  const onSubmit = async (params) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", params.name);
    formData.append("percentage", params.percentage);

    console.log(params);

    let TambahPajak = await api("post", "data-pajak/post", formData);

    if (TambahPajak.success) {
      history.push({ pathname: "pajak" });
    } else NotificationManager.error(TambahPajak.message);

    setLoading(false);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/pajak")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Pajak</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <form onSubmit={handleSubmit(onSubmit)} className="bg-white p-3">
        <Form.Group className="font-14 mt-2">
          <Form.Label>Nama Pajak</Form.Label>
          <Form.Control
            className="font-14 shadow-none"
            {...register("name", {
              required: "name is required",
            })}
          />
        </Form.Group>
        <div className="my-2 text-left float-start font-12 text-danger">
          <ErrorMessage errors={errors} name="name" />
        </div>

        <Form.Group className="font-14 mt-2">
          <Form.Label>Jumlah</Form.Label>
          <Form.Control
            className="font-14 shadow-none"
            {...register("percentage", {
              required: "percentage is required",
            })}
          />
        </Form.Group>

        <Form.Group className="font-14 mt-4 w-100">
          <Button
            variant="primary"
            className="btn sticky-bottom btn-primary  w-100 text-white font-14"
            type="submit"
          >
            Tambah
          </Button>
        </Form.Group>
      </form>
    </React.Fragment>
  );
};

export default TambahPajak;
