import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const GudangDetail = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!history.location.gudang) history.push("/data-gudang");
    else {
      setData(history.location.gudang);
    }
  });

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm cursor-pointer">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/data-gudang")}
                  className="d-inline-block align-text-top "
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Detail Gudang</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>
      <div className="g-col-6  px-cst1 pb-2 list-denom mt-4">
        <div className="card cursor-pointer">
          <div className="card-body">
            <div className="flex-grow-1">
              <small className="text-secondary">Kode Gudang</small>
              <p>#GDG-{data.id}</p>
            </div>
            <div className="flex-grow-1">
              <small className="text-secondary">Nama Gudang</small>
              <p>{data.name}</p>
            </div>
            <div className="flex-grow-1">
              <small className="text-secondary">Alamat</small>
              <p>{data.address}</p>
            </div>
            <div className="flex-grow-1">
              <small className="text-secondary">Latitude</small>
              <p>{data.lat}</p>
            </div>
            <div className="flex-grow-1">
              <small className="text-secondary">Longitude</small>
              <p>{data.lng}</p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="d-grid">
        <center>
          <Button
            variant="primary"
            className="mt-2 btn btn-primary btn-lg d-block text-white w-75 font-16"
            disabled={isLoading}
            type="submit"
            onClick={() =>
              history.push({
                pathname: "/update-gudang",
                gudang: data,
              })
            }
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Ubah Data"
            )}
          </Button>
        </center>
      </div>
    </React.Fragment>
  );
};

export default GudangDetail;
