import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { NotificationManager } from "react-notifications";
import { Button, Spinner } from "react-bootstrap";
import { Col, Row, Form } from "react-bootstrap";

const KalkulasiPoint = (props) => {
  const { api } = useContext(AppContext);
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  useEffect(() => {}, []);

  const onSubmit = async (params) => {
    setLoading(true);
    params = {
      min_transaction: parseInt(params.min_transaction),
      max_transaction: parseInt(params.max_transaction),
      point: parseInt(params.point),
    };
    console.log(params);
    let register = await api("post", "point-calculations/store", params);
    if (register.success) {
      NotificationManager.success(register.message);
      history.push("/data-point-kalkulasi");
    } else {
      NotificationManager.error(register.message);
    }

    setLoading(false);
  };

  return (
    <React.Fragment>
      <nav className="navbar bg-light shadow-sm ">
        <div className="contain p-2 px-cst1 pb-2 ps-4">
          <Row>
            <Col className="align-items-center d-flex">
              <div className="d-inline-block pe-4">
                <img
                  src="img/arrow-left.svg"
                  alt=""
                  width="30"
                  height="24"
                  onClick={() => history.push("/data-point-kalkulasi")}
                  className="d-inline-block align-text-top cursor-pointer"
                />
              </div>
              <div className="d-inline-block ">
                <span className="ml-3">Kalkulasi Point</span>
              </div>
            </Col>
          </Row>
        </div>
      </nav>

      <form onSubmit={handleSubmit(onSubmit)} className="bg-white p-3">
        <Form.Group className="font-14 mt-2">
          <Form.Label>Minimal Transaksi</Form.Label>
          <Form.Control
            type="number"
            autocomplete="off"
            className="form-control form-control-lg font-14"
            {...register("min_transaction", {
              required: "Minimal transaksi is required",
            })}
          />
          <div className="my-2 text-left float-start font-12 text-danger">
            <ErrorMessage errors={errors} name="min_transaction" />
          </div>
        </Form.Group>

        <Form.Group className="font-14 mt-2">
          <Form.Label>Maksimal Transaksi</Form.Label>
          <Form.Control
            type="number"
            autocomplete="off"
            className="form-control form-control-lg font-14"
            {...register("max_transaction", {
              required: "Maksimal transaksi is required",
            })}
          />
          <div className="my-2 text-left float-start font-12 text-danger">
            <ErrorMessage errors={errors} name="max_transaction" />
          </div>
        </Form.Group>

        <Form.Group className="font-14 mt-2">
          <Form.Label>Point</Form.Label>
          <Form.Control
            type="number"
            autocomplete="off"
            className="form-control form-control-lg font-14"
            {...register("point", {
              required: "Point is required",
            })}
          />
          <div className="my-2 text-left float-start font-12 text-danger">
            <ErrorMessage errors={errors} name="point" />
          </div>
        </Form.Group>
        <br />
        <Form.Group className="font-14 mt-2 w-100">
          <Button
            variant="primary"
            className="button w-100 text-white font-16"
            disabled={isLoading}
            type="submit"
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                aria-hidden="true"
              />
            ) : (
              "Tambahkan"
            )}
          </Button>
        </Form.Group>
      </form>
    </React.Fragment>
  );
};

export default KalkulasiPoint;
